import styled, { css } from "styled-components";
import { width, WidthProps, space, SpaceProps } from "styled-system";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import { theme } from "../theme/toyotaTheme";
import * as Type from "../globals/Type";

const inputStyle = css`
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryBlack};
    appearance: none;
    background-color: transparent;
    border: 0;
    outline: 0;
`;

export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
`;

export const FormRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
`;

export const FormCol = styled.div<WidthProps>`
    padding: 0 15px;
    ${width};

    &:not(:first-child) {
        margin-top: ${theme.space[3]}px;
    }
`;

export const InputField = styled.div<{ hasError?: boolean; disabled?: boolean } & SpaceProps>`
    display: block;
    position: relative;
    width: 100%;
    max-height: 56px;
    margin: 0;
    padding: 0;
    border: 1px solid
        ${({ hasError, disabled }) =>
            hasError ? theme.colors.primaryRed : disabled ? theme.colors.grey2 : theme.colors.grey2};
    ${space}
    &:focus-within {
        border-color: ${({ hasError }) => (hasError ? theme.colors.primaryRedHover : theme.colors.primaryBlack)};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${theme.colors.grey2};

            label,
            ${Select} select,
            ${Icon} {
                color: ${theme.colors.grey4};
            }
        `};
`;

export const ErrorField = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.space[1]}px;
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.primaryRed};
`;

export const InputLabel = styled.label`
    display: flex;
    position: relative;
    top: -4px;
    margin: 0;
    padding: 8px 16px 0;
    font-family: ${theme.fonts.regular};
    font-size: 1rem;
    line-height: 2.4rem;
    color: ${theme.colors.grey7};
    pointer-events: none;

    span:first-of-type {
        display: inline-block;
        max-width: calc(100% - 10px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    @media ${getBreakpoint("up", "lg")} {
        padding: 4px 20px 0;
    }
`;

export const Input = styled.div`
    display: flex;
    position: relative;
    top: -10px;
    width: 100%;
    margin: 0;
    padding: 0 16px 10px;

    @media ${getBreakpoint("up", "lg")} {
        padding: 0 20px 10px;
    }

    input {
        ${inputStyle};
        width: 100%;
        margin: 0;
        padding: 0;
    }
`;

export const HelpText = styled.div`
    margin-top: 10px;
    font-size: 1.3rem;
    color: ${theme.colors.grey7};
`;

export const Select = styled.div`
    display: flex;
    position: relative;
    top: -10px;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0 16px 10px;

    @media ${getBreakpoint("up", "lg")} {
        padding: 0 20px 10px;
    }

    select {
        width: 100%;
        margin: 4px 0 0;
        ${inputStyle};
    }

    select:disabled {
        opacity: 50%;
    }
`;

export const Checkbox = styled.div<{ hasError?: boolean } & WidthProps & SpaceProps>`
    display: flex;
    align-items: center;
    margin: ${theme.space[2]}px 0;
    ${width}
    ${space}
    label {
        display: inline-flex;
        position: relative;
        margin: 0;
        padding-${getDirection("left")}: 36px; /* 20 (width checkbox) + 16 (spacing) */
        font-family: ${theme.fonts.base};
        font-size: 1.3rem;
        line-height: 2rem;
        color: ${theme.colors.primaryBlack};
        cursor: pointer;

        a {
            margin-${getDirection("left")}: ${theme.space[1]}px;
            text-decoration: underline;
        }

        ${Type.SmallText} {
            margin-inline-start: ${theme.space[1]}px;
            color: ${theme.colors.grey4};
        }

        &::before {
            display: block;
            position: absolute;
            top: 2px;
            ${getDirection("left")}: 0;
            width: 20px;
            height: 20px;
            content: "";
            border: 1px solid ${({ hasError }) => (hasError ? theme.colors.primaryRed : theme.colors.grey3)};
            border-radius: 4px;
            cursor: pointer;
        }
    }

    input {
        display: none;
    }

    input:checked + label::before {
        background-color: ${theme.colors.primaryBlack};
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='1.5' d='m6.364 10.303 2.666 2.666M7.97 12.97l6-6'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        border-color: ${theme.colors.primaryBlack};
    }

    input:disabled + label {
        cursor: default;
        &::before {
            background-color: ${theme.colors.grey7};
            border-color: ${theme.colors.grey7};
            cursor: default;
        }
    }

    &:hover {
        label::before {
            background-color: ${theme.colors.grey3};
        }
    }

    &:focus-within {
        label::before {
            outline: 2px solid ${theme.colors.outline};
            outline-offset: 2px;
        }
    }
`;

export const LabelTitle = styled.div``;

/**
 * Custom radio input style
 * It's a larger variant of the default radio input style and looks a bit like a checkbox
 */
export const Check = styled.div<{ hasError?: boolean; hasDescription?: boolean } & SpaceProps>`
    display: flex;
    align-items: center;
    min-height: 32px;
    margin: ${theme.space[2]}px 0;
    ${space};

    label {
        display: inline-block;
        position: relative;
        margin: 0;
        padding-${getDirection("left")}: 48px; /* 32 (width checkbox) + 16 (spacing) */
        font-family: ${theme.fonts.base};
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${theme.colors.primaryBlack};
        cursor: pointer;
        ${LabelTitle} {
          font-family: ${({ hasDescription }) => (hasDescription ? theme.fonts.regular : theme.fonts.base)};
        }
        a {
            text-decoration: underline;
        }

        &::before {
            display: block;
            position: absolute;
            top: ${({ hasDescription }) => (hasDescription ? "50%" : "-2px")};
            ${getDirection("left")}: 0;
            width: 32px;
            height: 32px;
            margin-top: ${({ hasDescription }) => (hasDescription ? "-16px" : "0")};
            content: "";
            border: 1px solid ${({ hasError }) => (hasError ? theme.colors.primaryRed : theme.colors.grey3)};
            border-radius: 50%;
            cursor: pointer;
        }
    }

    input {
        display: none;
    }

    input:checked + label::before {
        background-color: ${theme.colors.primaryBlack};
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='1.5' d='m11.73 16.268 3.201 3.2M13.87 19.47l7.2-7.2'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        border-color: ${theme.colors.primaryBlack};
    }

    &:hover {
        label::before {
            background-color: ${theme.colors.grey3};
        }
    }

    &:focus-within {
        label::before {
            outline: 2px solid ${theme.colors.outline};
            outline-offset: 2px;
        }
    }
`;

/**
 * Default radio input style
 */
export const Radio = styled.div<{ hasError?: boolean; hasDescription?: boolean }>`
    display: flex;
    align-items: center;
    min-height: 20px;
    margin: ${theme.space[2]}px 0;

    label {
        display: inline-block;
        position: relative;
        margin: 0;
        padding-${getDirection("left")}: 36px; /* 20 (width checkbox) + 16 (spacing) */
        font-family: ${theme.fonts.base};
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${theme.colors.primaryBlack};
        cursor: pointer;
        ${LabelTitle} {
          font-family: ${({ hasDescription }) => (hasDescription ? theme.fonts.regular : theme.fonts.base)};
        }
        a {
            text-decoration: underline;
        }

        &::before {
            display: block;
            position: absolute;
            top: ${({ hasDescription }) => (hasDescription ? "50%" : "3px")};
            ${getDirection("left")}: 0;
            width: 20px;
            height: 20px;
            margin-top: ${({ hasDescription }) => (hasDescription ? "-16px" : "0")};
            content: "";
            border: 1px solid ${({ hasError }) => (hasError ? theme.colors.primaryRed : theme.colors.grey3)};
            border-radius: 50%;
            cursor: pointer;
        }
    }

    input {
        display: none;
    }

    input:checked + label::before {
        background-color: ${theme.colors.primaryBlack};
        border-color: ${theme.colors.primaryBlack};
        box-shadow: inset 0 0 0 .3rem ${theme.colors.primaryWhite};
    }

    &:hover {
        label::before {
            background-color: ${theme.colors.grey3};
        }
    }

    &:focus-within {
        label::before {
            outline: 2px solid ${theme.colors.outline};
            outline-offset: 2px;
        }
    }

`;

export const RadioGroup = styled.div<{ isVertical?: boolean }>`
    display: flex;
    flex-direction: ${({ isVertical }) => (isVertical ? "column" : "row")};
    width: 100%;
    margin-bottom: ${theme.space[1]}px;

    ${Radio} {
        ${({ isVertical }) =>
            !isVertical &&
            css`
            margin-${getDirection("right")}: ${theme.space[3]}px;
        `}
    }
`;

export const Icon = styled.div`
    position: absolute;
    top: 50%;
    ${getDirection("right")}: 20px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
`;

export const MandatoryField = styled.span<{ largeFontSize?: boolean }>`
    padding-${getDirection("left")}: 4px;
    font-family: ${theme.fonts.regular};
    font-size: ${({ largeFontSize }) => (largeFontSize ? 1.6 : 1)}rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryRed};
`;

export const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: ${theme.space[3]}px;
`;

export const Title = styled.h2`
    margin: ${theme.space[3]}px 0;
    padding: 0;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
    color: ${theme.colors.primaryBlack};
`;

export const Description = styled.h2`
    margin-bottom: 0;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
    color: ${theme.colors.primaryBlack};

    /* Proper styling inline links */
    a {
        border-bottom: 1px solid ${theme.colors.primaryBlack};
    }
`;

export const Header = styled.header<SpaceProps>`
    margin-bottom: 60px;
    padding: 60px 0;
    text-align: center;
    background-color: ${theme.colors.grey1};
    ${space};
`;

export const HeaderTitle = styled.h2`
    margin: 0 0 ${theme.space[3]}px;
    font-family: ${theme.fonts.regular};
    font-size: 2.8rem;
    line-height: 3.2rem;
    text-transform: none;
    color: ${theme.colors.primaryBlack};
`;
