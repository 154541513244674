import React, { ChangeEvent, useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import {
    DealerRangeSearchRequestBodyType,
    DealerRangeSearchResultType,
} from "../../server/used-cars/fetchDealerRangeSearchResults";
import { LocationFilterIdType, LocationRangeType } from "../../utils/constants/filterConstants";
import { UsedCarFilterId } from "../../../shared-logic/types/UscCommonTypes";
import { useDebouncedEffect } from "../../../../common-deprecated/hooks";
import { toyotaMap } from "../../../../common-deprecated/ToyotaMap";
import { setDealer, setLocation, setSelectMultiFilter, setUserCoords } from "../../redux/actions/CarFiltersActions";
import Debug from "../../../../common-deprecated/Debug";
import { DealerResultType } from "../../../../common-deprecated/utils/dealerConstants";
import { CarFilterDispatchType, useCarFilterSelector } from "../../redux/store";
import { getAPI } from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { useCarFilterLabel } from "../../utils/constants/labels";
import {
    getAemCarFilterTrackParamsSelector,
    trackAemCarFilterValue,
    trackDeliverableCarsFilterClick,
} from "../../utils/tracking";
import {
    FilterConfigBaseType,
    MultipleChoiceValueType,
} from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { FormattedMapboxResultType } from "../../../../common-deprecated/types/ToyotaMapTypes";

type UseLocationFilter = {
    mapboxResults: FormattedMapboxResultType[];
    dealerResults: DealerResultType[];
    searchQuery: string;
    loading: boolean;
    errorLabel: string;
    userLocationButtonClick: () => void;
    mapboxLocationClick: (result: FormattedMapboxResultType) => Promise<void>;
    dealerLocationClick: (result: DealerResultType) => void;
    onSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    clearSearch: () => void;
    toggleCarDelivery: (
        event: React.FormEvent<HTMLDivElement>,
        active: FilterConfigBaseType & { values: MultipleChoiceValueType[] },
    ) => void;
};

// Execute a request to the dealer search API.
const fetchDealers = async (
    url: string,
    body: DealerRangeSearchRequestBodyType,
): Promise<DealerRangeSearchResultType> => {
    const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
    });

    return result.status === 200 ? result.json() : { results: [], dealerRanges: [] };
};

/**
 * Used in LocationFilter.
 */
const useLocationFilter = (filterId: LocationFilterIdType): UseLocationFilter => {
    const dispatch = useDispatch<CarFilterDispatchType>();

    // ----------------------------------------------------------------------
    // States
    // ----------------------------------------------------------------------
    // In theory this could be useReducer, but there doesn't seem to be a big upside as most of these can be executed individually as well.
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [dealerResults, setDealerResults] = useState<DealerResultType[]>([]);
    const [mapboxResults, setMapboxResults] = useState<FormattedMapboxResultType[]>([]);
    const [errorLabel, setErrorLabel] = useState<string>(""); // errorLabel can be used to determine general error state. (!!errorLabel)

    // ----------------------------------------------------------------------
    // Selectors/Labels
    // ----------------------------------------------------------------------
    const locationFilter = useCarFilterSelector((state) => state.carFilters[filterId]);
    const apiUrl = useCarFilterSelector(
        (state) => `${getAPI(state.commonSettings, "dealers-range")}/search?brand=${state.commonSettings.brand}`,
    );
    const mapboxToken = useCarFilterSelector((state) => state.commonSettings.mapboxToken);
    const country = useCarFilterSelector((state) => state.commonSettings.country);
    const currentFilterContext = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const trackParams = useCarFilterSelector(getAemCarFilterTrackParamsSelector(filterId), shallowEqual);
    const { dealerRangeValues, distributorCode, uscEnv } = useCarFilterSelector((state) => state.carFilterSettings);

    const [noResultsLabel, locationErrorLabel] = useCarFilterLabel([
        "carFilterLocationNoResults",
        "carFilterLocationError",
    ]);

    const selectedDealer = locationFilter.dealer;
    const selectedLocation = locationFilter.range;
    const defaultRange = locationFilter.rangeValues[0]?.km || 0;
    const defaultDealerSearchBody = { distributorCode, filter: currentFilterContext };

    // ----------------------------------------------------------------------
    // Text search input logic
    // ----------------------------------------------------------------------
    const onSearchInputChange = (event: ChangeEvent<HTMLInputElement>): void => setSearchQuery(event.target.value);

    const clearSearch = (): void => {
        if (searchQuery) setSearchQuery("");
        else if (selectedDealer) dispatch(setDealer(filterId, null));
        else if (selectedLocation) dispatch(setLocation(filterId, null));
    };

    useDebouncedEffect(
        () => {
            if (searchQuery.length > 2) {
                // Reset the related states.
                setLoading(true);
                setDealerResults([]);
                setMapboxResults([]);
                setErrorLabel("");

                // Execute a dealer/location search. User coordinates will be added if available.
                // Note that if either the mapbox or A2D request fails this call will fail, but both API's seem stable enough so no splitup yet.
                const searchDealers = async (): Promise<void> => {
                    const body: DealerRangeSearchRequestBodyType = {
                        ...defaultDealerSearchBody,
                        query: searchQuery,
                        uscEnv,
                        ranges: dealerRangeValues,
                    };
                    if (locationFilter.userCoords) body.coords = locationFilter.userCoords;

                    const [newDealerResults, newMapboxResults] = await Promise.all([
                        fetchDealers(apiUrl, body),
                        toyotaMap.geocode(searchQuery, mapboxToken, country, locationFilter.userCoords, true),
                    ]);

                    // Results retrieved set related states.
                    setDealerResults(newDealerResults.results);
                    setMapboxResults(newMapboxResults);
                    setLoading(false);
                    if (newDealerResults.results.length === 0 && newMapboxResults.length === 0) {
                        setErrorLabel(noResultsLabel);
                    }
                };

                try {
                    searchDealers();
                } catch (ex) {
                    setErrorLabel(locationErrorLabel);
                    setLoading(false);
                }
            } else {
                // If the search query is 2 or less, reset the results as that means the user is deleting the text.
                if (dealerResults.length) setDealerResults([]);
                if (mapboxResults.length) setMapboxResults([]);
            }
        },
        700,
        [searchQuery, toyotaMap],
    );

    // When a dealer is selected, reset the shown results and input query.
    useEffect(() => {
        if (selectedDealer) {
            setDealerResults([]);
            setMapboxResults([]);
            setSearchQuery("");
        }
    }, [selectedDealer]);

    // ----------------------------------------------------------------------
    // User geolocation logic
    // ----------------------------------------------------------------------
    const userLocationButtonClick = (): void => {
        setErrorLabel("");
        // Try to load the user's geolocation.
        if (navigator.geolocation) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const coords = { lat: position.coords.latitude, lon: position.coords.longitude };

                    try {
                        // Retrieve dealer ranges and current location details.
                        const [newDealerResults, locationGeocode] = await Promise.all([
                            fetchDealers(apiUrl, {
                                ...defaultDealerSearchBody,
                                coords,
                                ranges: dealerRangeValues,
                                uscEnv,
                            }),
                            toyotaMap.reverseGeocode(coords, mapboxToken),
                        ]);

                        if (newDealerResults && locationGeocode) {
                            const { dealerRanges, results } = newDealerResults;
                            const locationRange: LocationRangeType = {
                                coords,
                                name: locationGeocode.name,
                                range: defaultRange,
                                type: "user",
                            };

                            // Results received, set them in state.
                            // User coordinates are saved separately as these can be used even if the location filter is reset.
                            dispatch(setUserCoords(filterId, coords));
                            dispatch(setLocation(filterId, locationRange, dealerRanges, results));
                            trackAemCarFilterValue(trackParams, `location-${locationRange.name}`);
                        }
                    } catch (ex) {
                        Debug.error("Error retrieving user location details");
                        setErrorLabel(locationErrorLabel);
                    }

                    setLoading(false);
                },
                () => {
                    // User location denied or failed, set error message.
                    setErrorLabel(locationErrorLabel);
                    setLoading(false);
                },
            );
        }
    };

    // ----------------------------------------------------------------------
    // Select mapbox location logic
    // ----------------------------------------------------------------------
    const mapboxLocationClick = async (result: FormattedMapboxResultType): Promise<void> => {
        setLoading(true);
        setMapboxResults([]);
        setDealerResults([]);

        try {
            const dealerResult = await fetchDealers(apiUrl, {
                ...defaultDealerSearchBody,
                coords: {
                    lat: result.coords.lat,
                    lon: result.coords.lng,
                },
                ranges: dealerRangeValues,
                uscEnv,
            });
            setSearchQuery("");
            const locationRange: LocationRangeType = {
                ...result,
                coords: {
                    lat: result.coords.lat,
                    lon: result.coords.lng,
                },
                type: "location",
                range: defaultRange,
            };

            dispatch(setLocation(filterId, locationRange, dealerResult.dealerRanges, dealerResult.results));
            trackAemCarFilterValue(trackParams, `location-${locationRange.name}`);
        } catch (ex) {
            Debug.error("Error retrieving location details");
            setErrorLabel(locationErrorLabel);
        }
        setLoading(false);
    };

    // ----------------------------------------------------------------------
    // Select dealer location logic
    // ----------------------------------------------------------------------

    const dealerLocationClick = (result: DealerResultType): void => {
        dispatch(setDealer(filterId, result));
        trackAemCarFilterValue(trackParams, `dealer-${result.name}`);
    };

    // ----------------------------------------------------------------------
    // Toggle car delivery filter logic
    // ----------------------------------------------------------------------
    const toggleCarDelivery = (
        event: React.FormEvent<HTMLDivElement>,
        carDeliveryFilter: FilterConfigBaseType & { values: MultipleChoiceValueType[] },
    ): void => {
        event.stopPropagation();
        event.preventDefault();
        trackDeliverableCarsFilterClick(currentFilterContext);
        carDeliveryFilter.values.forEach((value) => {
            return dispatch(setSelectMultiFilter([value.id], !value.selected, UsedCarFilterId.Deliverable));
        });
    };

    return {
        mapboxResults,
        dealerResults,
        searchQuery,
        loading,
        errorLabel,
        userLocationButtonClick,
        mapboxLocationClick,
        dealerLocationClick,
        onSearchInputChange,
        clearSearch,
        toggleCarDelivery,
    };
};
export default useLocationFilter;
