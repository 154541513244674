import React, { useRef } from "react";
import * as Styles from "./styles/UsedCarPromotionStyles";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import { UsedCarResultPromotionDisclaimerType } from "../../../../utils/constants/filterRequestConstants";
import usePromotionModal from "../../../../hooks/car-results/usePromotionModal";
import TooltipContent from "../../common/TooltipContent";
import { useCarFilterSelector } from "../../../../redux/store";
import PopoverTooltip from "../../../../../../common-deprecated/components/popover/aem/PopoverTooltip";

type UscCarPromotionType = { promotionDisclaimer: UsedCarResultPromotionDisclaimerType };

const UsedCarPromotion = ({ promotionDisclaimer }: UscCarPromotionType): JSX.Element => {
    const popupRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const promotionLabel = useCarFilterLabel("carFilterPromo");
    const cultureName = useCarFilterSelector((state) => state.commonSettings.culture.name);

    const tooltipcontent = (
        <TooltipContent lastItem promotionDisclaimer={promotionDisclaimer} cultureName={cultureName} />
    );

    const { showPopup, setShowPopup } = usePromotionModal(popupRef, tooltipcontent);

    return (
        <Styles.Wrapper>
            <Styles.Button
                ref={buttonRef}
                type="button"
                onClick={(event) => {
                    event.preventDefault();
                    setShowPopup(!showPopup);
                }}
            >
                {promotionLabel}
            </Styles.Button>
            {showPopup && (
                <PopoverTooltip iconRef={buttonRef} ref={popupRef} onClose={() => setShowPopup(false)}>
                    {tooltipcontent}
                </PopoverTooltip>
            )}
        </Styles.Wrapper>
    );
};

export default UsedCarPromotion;
