/**
 * Types and constants for the car filter filters.
 */

import {
    defaultConfigBase,
    defaultMinMaxSliderFilter,
    FILTER_PROACE,
    FilterConfigBaseType,
    MinMaxSliderFilterConfigType,
    MultipleChoiceValueType,
} from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import {
    CoordinateType,
    DealerGroupResultType,
    DealerHoldingResultType,
    DealerResultType,
} from "../../../../common-deprecated/utils/dealerConstants";
import { StockCarFilterId, UscContext, UsedCarFilterId } from "../../../shared-logic/types/UscCommonTypes";

// ----------------------------------------------------------------------
// Multiple choice filter ids
// ----------------------------------------------------------------------
export const usedCarMultipleChoiceFilterIds = [
    UsedCarFilterId.Transmission,
    UsedCarFilterId.Equipment,
    UsedCarFilterId.Colour,
    UsedCarFilterId.EcoLabel,
    UsedCarFilterId.Warranty,
    UsedCarFilterId.License,
    UsedCarFilterId.VehicleStatus,
    UsedCarFilterId.EfficiencyClass,
    UsedCarFilterId.Deliverable,
    UsedCarFilterId.Brand,
    UsedCarFilterId.Model,
    UsedCarFilterId.Category,
    UsedCarFilterId.FuelType,
    UsedCarFilterId.BodyStyle,
    UsedCarFilterId.DriveType,
    UsedCarFilterId.Ownership,
] as const;
export type UsedCarMultipleChoiceFilterIdType = (typeof usedCarMultipleChoiceFilterIds)[number];

export const stockCarMultipleChoiceFilterIds = [
    StockCarFilterId.Transmission,
    StockCarFilterId.Equipment,
    StockCarFilterId.Colour,
    StockCarFilterId.VehicleStatus,
    StockCarFilterId.Brand,
    StockCarFilterId.Model,
    StockCarFilterId.Category,
    StockCarFilterId.FuelType,
    StockCarFilterId.BodyStyle,
    StockCarFilterId.DriveType,
] as const;
export type StockCarMultipleChoiceFilterIdType = (typeof stockCarMultipleChoiceFilterIds)[number];

export type MultipleChoiceFilterIdType = UsedCarMultipleChoiceFilterIdType | StockCarMultipleChoiceFilterIdType;

// ----------------------------------------------------------------------
// Min max slider filter ids
// ----------------------------------------------------------------------
export const usedCarMinMaxSliderFilterIds = [
    UsedCarFilterId.Seats,
    UsedCarFilterId.Doors,
    UsedCarFilterId.PowerOutput,
    UsedCarFilterId.Year,
    UsedCarFilterId.ModelYear,
    UsedCarFilterId.Mileage,
] as const;
export type UsedCarMinMaxSliderFilterIdType = (typeof usedCarMinMaxSliderFilterIds)[number];

export const stockCarMinMaxSliderFilterIds = [
    StockCarFilterId.Seats,
    StockCarFilterId.Doors,
    StockCarFilterId.PowerOutput,
    StockCarFilterId.Mileage,
] as const;
export type StockCarMinMaxSliderFilterIdType = (typeof stockCarMinMaxSliderFilterIds)[number];

export type MinMaxSliderFilterIdType = UsedCarMinMaxSliderFilterIdType | StockCarMinMaxSliderFilterIdType;

export const priceFilterIds = [UsedCarFilterId.Price, StockCarFilterId.Price] as const;
export type PriceFilterIdType = (typeof priceFilterIds)[number];

export const locationFilterIds = [UsedCarFilterId.Location, StockCarFilterId.Location] as const;
export type LocationFilterIdType = (typeof locationFilterIds)[number];

// ----------------------------------------------------------------------
// Helper enums and types
// ----------------------------------------------------------------------
// Default amount of results we retrieve with each request.
export const DEFAULT_RESULT_COUNT = 12;

export enum DfCarSortOrder {
    Published = "published",
    CashAsc = "cashAsc",
    CashDesc = "cashDesc",
    MonthlyAsc = "monthlyAsc",
    MonthlyDesc = "monthlyDesc",
    YearAsc = "yearAsc",
    YearDesc = "yearDesc",
    MileageAsc = "mileageAsc",
    MileageDesc = "mileageDesc",
    Distance = "distance",
    Brand = "brand",
}

// Specs used in a used car result dropdowns.
// These are configurable so they should map to the corresponding category in AEM.
export enum UsedCarDropdownSpec {
    Year = "year",
    Mileage = "mileage",
    FuelType = "fuelType",
    Transmission = "transmission",
    Warranty = "warranty",
    EcoLabel = "ecoLabel",
    Doors = "doors",
    PowerOutput = "powerOutput",
    OwnershipTax = "ownershipTax",
    EfficiencyClass = "efficiencyClass",
    ModelYear = "modelYear",
    SdkCode = "sdkCode",
}

// ----------------------------------------------------------------------
// Cross filter (new <=> used) value mapping
// ----------------------------------------------------------------------

// TODO These are not final yet but works with current UAT setup.
export enum UsedCarFuelTypeFilterId {
    Hybrid = "3",
    FuelCell = "10",
    Petrol = "1",
    Diesel = "2",
}

// ----------------------------------------------------------------------
// Multiple choice helpers
// ----------------------------------------------------------------------

// This is a helper var which should contain all filterIds supporting aggregation counts except for carType.
export const uscAggregationFilterIds = [
    UsedCarFilterId.EcoLabel,
    UsedCarFilterId.Equipment,
    UsedCarFilterId.Transmission,
    UsedCarFilterId.Warranty,
    UsedCarFilterId.VehicleStatus,
    UsedCarFilterId.EfficiencyClass,
    UsedCarFilterId.Brand,
    UsedCarFilterId.Model,
    UsedCarFilterId.FuelType,
    UsedCarFilterId.Category,
    UsedCarFilterId.BodyStyle,
    UsedCarFilterId.DriveType,
    UsedCarFilterId.Ownership,
    StockCarFilterId.Equipment,
    StockCarFilterId.Transmission,
    StockCarFilterId.VehicleStatus,
    StockCarFilterId.Brand,
    StockCarFilterId.Model,
    StockCarFilterId.FuelType,
    StockCarFilterId.Category,
    StockCarFilterId.BodyStyle,
    StockCarFilterId.DriveType,
] as const;

export const aggregationFilterIds = [...uscAggregationFilterIds] as const;

export type ResultCountType = { valueId: string; resultCount: number };
export type ValueResultCountType = Partial<{
    [UsedCarFilterId.Transmission]: ResultCountType[];
    [UsedCarFilterId.Equipment]: ResultCountType[];
    [UsedCarFilterId.Warranty]: ResultCountType[];
    [UsedCarFilterId.EcoLabel]: ResultCountType[];
    [UsedCarFilterId.EfficiencyClass]: ResultCountType[];
    [UsedCarFilterId.VehicleStatus]: ResultCountType[];
    [UsedCarFilterId.Brand]: ResultCountType[];
    [UsedCarFilterId.Model]: ResultCountType[];
    [UsedCarFilterId.FuelType]: ResultCountType[];
    [UsedCarFilterId.Category]: ResultCountType[];
    [UsedCarFilterId.BodyStyle]: ResultCountType[];
    [UsedCarFilterId.DriveType]: ResultCountType[];
    [UsedCarFilterId.Ownership]: ResultCountType[];
    [StockCarFilterId.Brand]: ResultCountType[];
    [StockCarFilterId.Model]: ResultCountType[];
    [StockCarFilterId.FuelType]: ResultCountType[];
    [StockCarFilterId.Category]: ResultCountType[];
    [StockCarFilterId.Transmission]: ResultCountType[];
    [StockCarFilterId.Equipment]: ResultCountType[];
    [StockCarFilterId.VehicleStatus]: ResultCountType[];
    [StockCarFilterId.BodyStyle]: ResultCountType[];
    [StockCarFilterId.DriveType]: ResultCountType[];
}>;

// ----------------------------------------------------------------------
// Mileage filter
// ----------------------------------------------------------------------
export type MileageFilterConfigType = MinMaxSliderFilterConfigType & { zeroMileage: boolean };

export const defaultMileageFilterConfig: MileageFilterConfigType = { ...defaultMinMaxSliderFilter, zeroMileage: false };

// ----------------------------------------------------------------------
// Transmission filter
// ----------------------------------------------------------------------

// These should be updated to their respective code once we have a final uuid.
export enum TransmissionId {
    Automatic = "automatic",
    Manual = "manual",
}

// ----------------------------------------------------------------------
// Colour filter
// ----------------------------------------------------------------------
export type UsedCarColourFilterValueType = MultipleChoiceValueType & { hexCode: string };

export type ColourFilterConfigType = FilterConfigBaseType & {
    values: UsedCarColourFilterValueType[];
    showBiTone: boolean;
};

export const defaultColourFilter: ColourFilterConfigType = {
    ...defaultConfigBase,
    values: [],
    showBiTone: true,
};

// ----------------------------------------------------------------------
// Location filter
// ----------------------------------------------------------------------
export type LocationDealerType = DealerResultType | null;
export type LocationRangeValueType = { km: number; dealerCount: number };
export type LocationRangeType = {
    fetchDealers?: true; // Only used for backend data fetching
    type: "user" | "location"; // 'user' indicates this is a result derived from the GPS data from the user. 'location' is a manually selected location.
    name: string; // Used as a filter label.
    range: number; // Range around the coordinates, determines search area.
    coords: CoordinateType; // Coordinates of the location.
} | null;
export type LocationDealerGroupType = DealerGroupResultType | null;
export type LocationDealerHoldingType = DealerHoldingResultType | null;

export type LocationFilterType = FilterConfigBaseType & {
    dealerGroup: LocationDealerGroupType;
    dealerHolding: LocationDealerHoldingType;
    dealer: LocationDealerType;
    range: LocationRangeType;
    rangeValues: LocationRangeValueType[];
    userCoords: CoordinateType | null;
    dealerCache: DealerResultType[];
};

export const defaultLocationFilter: LocationFilterType = {
    ...defaultConfigBase,
    dealerGroup: null,
    dealerHolding: null,
    dealer: null,
    range: null,
    rangeValues: [],
    userCoords: null,
    dealerCache: [],
};

export const DEFAULT_PARENT_ID = "00000000-0000-0000-0000-000000000000";

// ----------------------------------------------------------------------
// MultipleChoice filters
// ----------------------------------------------------------------------
export const FILTER_CAR_TYPE: "carType" = "carType";
export const FILTER_FUEL_TYPE: "fuelType" = "fuelType";
export const FILTER_TRANSMISSION: "transmission" = "transmission";
export const FILTER_EQUIPMENT: "equipment" = "equipment";

// ----------------------------------------------------------------------
// Plus minus filters
// ----------------------------------------------------------------------
export const FILTER_DOORS: "doors" = "doors";
export const FILTER_SEATS: "seats" = "seats";

// ----------------------------------------------------------------------
// Slider filters
// ----------------------------------------------------------------------
export const FILTER_LUGGAGE: "luggage" = "luggage";
export const FILTER_SPEED: "speed" = "speed";
export const FILTER_CO2: "co2" = "co2";
export const FILTER_FUEL_CONSUMPTION: "fuelConsumption" = "fuelConsumption";
export const FILTER_POWER_OUTPUT: "powerOutput" = "powerOutput";
export const FILTER_ETA: "eta" = "eta";
export const FILTER_TOWING_CAPACITY: "towingCapacity" = "towingCapacity";
export const FILTER_LOAD_VOLUME: "loadVolume" = "loadVolume";
export const FILTER_VEHICLEDIMENSIONS: "vehicleDimensions" = "vehicleDimensions";
export const FILTER_VEHICLEDIMENSIONS_LENGTH: "length" = "length";
export const FILTER_VEHICLEDIMENSIONS_WIDTH: "width" = "width";
export const FILTER_VEHICLEDIMENSIONS_HEIGHT: "height" = "height";
export const FILTER_MAXIMUM_PAYLOAD: "maximumPayload" = "maximumPayload";

export type SliderFilterIdType =
    | typeof FILTER_LUGGAGE
    | typeof FILTER_SPEED
    | typeof FILTER_CO2
    | typeof FILTER_FUEL_CONSUMPTION
    | typeof FILTER_ETA
    | typeof FILTER_POWER_OUTPUT;

// ----------------------------------------------------------------------
// Min max slider filters
// ----------------------------------------------------------------------
export const FILTER_PRICE: "price" = "price";

// ----------------------------------------------------------------------
// Common constants
// ----------------------------------------------------------------------

// Map filter constants to values used in the car-filter api.
export const CAR_FILTER_MAPPING = {
    [`${FILTER_PRICE}-cash`]: "PRICE",
    [`${FILTER_PRICE}-monthly`]: "MONTHLY_RATE",
    [FILTER_DOORS]: "CAP_SLIDER_8",
    [FILTER_LUGGAGE]: "BODY_SLIDE_1",
    [FILTER_CO2]: "ENV_SLIDE_1",
    [FILTER_FUEL_CONSUMPTION]: "ENV_SLIDE_2",
    [FILTER_SEATS]: "BODY_SLIDE_2",
    [FILTER_SPEED]: "ENG_SLIDE_1",
    [FILTER_POWER_OUTPUT]: "ENG_SLIDE_2",
    [FILTER_ETA]: "ETA",
    [FILTER_EQUIPMENT]: "EQUIPMENT",
    [`${FILTER_PROACE}-bodyType`]: "TYPE_SLIDER_1",
    [`${FILTER_PROACE}-wheelBase`]: "TYPE_SLIDER_2",
    [FILTER_TOWING_CAPACITY]: "CAP_SLIDER_9",
    [FILTER_LOAD_VOLUME]: "CAP_SLIDER_2",
    [FILTER_VEHICLEDIMENSIONS_LENGTH]: "CAP_SLIDER_10",
    [FILTER_VEHICLEDIMENSIONS_WIDTH]: "CAP_SLIDER_11",
    [FILTER_VEHICLEDIMENSIONS_HEIGHT]: "CAP_SLIDER_12",
    [FILTER_MAXIMUM_PAYLOAD]: "CAP_SLIDER_1",
};

// StockTypeMapping is used for the stockType.code filter
// stockType is used to differentiate between used and stock cars.
export const StockTypeMapping: Record<UscContext, string> = {
    [UscContext.Used]: "1",
    [UscContext.Stock]: "2",
};

// Used as the id for the car filter html element.
export const CAR_FILTER_ID = "tor-car-filter";
