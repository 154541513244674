import { useCallback } from "react";
import { FinanceOptionType } from "../../../common-deprecated/types/CommonTypes";
import {
    BUTTON_MAX,
    BUTTON_MIN,
    SliderButtonType,
} from "../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

type usePriceFilterSliderReturnType = {
    clippedMin: number;
    clippedMax: number;
    setValueFn: (newValue: number, type: SliderButtonType) => void;
    getValueLabelFn: (value: number) => string;
};

const usePriceFilterSlider = (
    minValue: number,
    maxValue: number,
    customMinValue: number,
    customMaxValue: number,
    valueLabel: string,
    setValue: (type: "max" | "min", value: number, financeOption: FinanceOptionType) => void,
    activeFinanceOption: FinanceOptionType,
    formatPrice: (price: number) => string,
): usePriceFilterSliderReturnType => {
    const clippedMin = customMinValue || minValue;
    const clippedMax = customMaxValue || maxValue;

    const setValueFn = useCallback(
        (newValue: number, type: SliderButtonType) => {
            if (type === BUTTON_MAX) {
                setValue("max", newValue === clippedMax ? maxValue : newValue, activeFinanceOption);
            }
            if (type === BUTTON_MIN) {
                setValue("min", newValue === clippedMin ? minValue : newValue, activeFinanceOption);
            }
        },
        [activeFinanceOption, clippedMax, clippedMin, maxValue, minValue],
    );

    const getValueLabelFn = useCallback(
        (value: number): string => valueLabel.replace("{value}", formatPrice(value)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [valueLabel],
    );

    return { clippedMin, clippedMax, setValueFn, getValueLabelFn };
};
export default usePriceFilterSlider;
