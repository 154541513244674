export const COMPONENT_NAME_TRADEIN: "trade_in" = "trade_in";
export const COMPONENT_NAME_GRADE_FILTER: "grade_filter" = "grade_filter";
export const COMPONENT_NAME_GRADE_FILTER_RESULTS: "grade_filter_results" = "grade_filter_results";
export const COMPONENT_NAME_STANDARD_EQUIPMENT: "standard-equipment" = "standard-equipment";
export const COMPONENT_NAME_OPTIONAL_EQUIPMENT: "optional-equipment" = "optional-equipment";
export const COMPONENT_NAME_PROACE_SPECS: "equipment-filter" = "equipment-filter";
export const COMPONENT_NAME_FILTERS_HOMEPAGE: "filters_homepage" = "filters_homepage";
export const COMPONENT_NAME_FILTERS_RESULTS: "filters_results" = "filters_results";
export const COMPONENT_NAME_MODEL_FILTER: "model-filter" = "model-filter"; // used for both new, used & stock model/car filter

// This is not a complete list of component names, newer or upgraded components use hardcoded values in a dedicated tracking.ts file instead
export type TrackComponentType =
    | typeof COMPONENT_NAME_FILTERS_HOMEPAGE
    | typeof COMPONENT_NAME_FILTERS_RESULTS
    | typeof COMPONENT_NAME_TRADEIN
    | typeof COMPONENT_NAME_GRADE_FILTER
    | typeof COMPONENT_NAME_GRADE_FILTER_RESULTS
    | typeof COMPONENT_NAME_OPTIONAL_EQUIPMENT
    | typeof COMPONENT_NAME_STANDARD_EQUIPMENT
    | typeof COMPONENT_NAME_PROACE_SPECS
    | typeof COMPONENT_NAME_MODEL_FILTER;

// Optional/standardEquipment actions.
export const ACTION_EXPAND_TAB: "expand_tab" = "expand_tab";
export const ACTION_SELECT_FILTER = "select-filter";
export const ACTION_CLICK_CTA = "click_cta";
export const ACTION_ADD_COMPARE = "add-compare";
export const ACTION_CLICK_BUTTON = "click_button";

// Tradein actions.
export const ACTION_WORKFLOW = "workflow";
export const ACTION_CLICK_FUNCTION = "click_function";
