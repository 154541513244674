import React, { useMemo } from "react";
import ActiveFilterSlider from "./ActiveFilterSlider";
import { useCarFilterSelector } from "../../../../redux/store";
import { getCurrentFilters, isFilterActive } from "../../../../utils/filters";

/**
 * Container for the ActiveFilterSlider.
 * This container is required because the ActiveFilterSlider works with a two pass rendering system so it cannot hide itself.
 */
const ActiveFilterSliderContainer = (): JSX.Element | null => {
    const carFilters = useCarFilterSelector((state) => state.carFilters);

    const sliderComponents = Object.values(getCurrentFilters(carFilters.currentFilter))
        .filter((filterId) => isFilterActive(filterId, carFilters) && !carFilters[filterId].disabled)
        .sort((a, b) => carFilters[a].index - carFilters[b].index);

    const filtersKey = [...sliderComponents].sort().join();

    // Prevent unnecessary rerenders by using filtersKey, which is also used in ActiveFilterSlider to prevent rerenders.
    return useMemo(
        () =>
            sliderComponents.length === 0 ? null : (
                <ActiveFilterSlider activeFilters={sliderComponents} activeFiltersKey={filtersKey} />
            ),
        [filtersKey],
    );
};

export default ActiveFilterSliderContainer;
