import React from "react";
import * as Styles from "./styles/FilterCheckInputStyles";
import * as Form from "../../../../../common-deprecated/styles/v2/toyota/components/Form";
import * as Type from "../../../../../common-deprecated/styles/v2/toyota/globals/Type";

type FilterCheckInput = {
    checked: boolean;
    label?: string;
    onChange?: () => void;
    disabled?: boolean;
    id: string;
    resultCount?: number;
    checkboxVariant?: boolean;
};

const FilterCheckInput = ({
    checked,
    label,
    onChange,
    disabled,
    id,
    resultCount,
    checkboxVariant,
}: FilterCheckInput): JSX.Element =>
    checkboxVariant ? (
        <Form.Checkbox my={0}>
            <input
                type="checkbox"
                checked={checked}
                id={id}
                onChange={onChange}
                readOnly={!onChange}
                disabled={disabled}
                aria-label={label}
            />
            <label htmlFor={id}>
                {label}
                {typeof resultCount !== "undefined" && !Number.isNaN(resultCount) && (
                    <Type.SmallText>{resultCount}</Type.SmallText>
                )}
            </label>
        </Form.Checkbox>
    ) : (
        <Styles.Wrapper variant="checkbox" large>
            <input
                type="checkbox"
                checked={checked}
                id={id}
                onChange={onChange}
                readOnly={!onChange}
                disabled={disabled}
                aria-label={label}
            />
            <Styles.Checkbox disabled={disabled} htmlFor={id}>
                {label}
                {typeof resultCount !== "undefined" && !Number.isNaN(resultCount) && (
                    <Styles.InfoText>{resultCount}</Styles.InfoText>
                )}
            </Styles.Checkbox>
        </Styles.Wrapper>
    );

export default FilterCheckInput;
