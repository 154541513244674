import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { CarFilterDisclaimerType, getDisclaimerSelector } from "../../../utils/disclaimerUtils";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../redux/store";
import { DisclaimerLocation } from "../../../../../common-deprecated/types/CommonTypes";
import { showModal } from "../../../../../common-deprecated/redux/actions/ModalActions";
import { MODAL_CONTENT } from "../../../../../common-deprecated/utils/modalConstants";
import * as Button from "../../../../../common-deprecated/styles/v2/toyota/components/Button";

type Props = { type: CarFilterDisclaimerType; vehicleForSaleId: string; reference?: string };

// This component should be used to render a disclaimer link which will trigger a modal containing a disclaimer when clicked.
const LinkTextDisclaimer = ({ type, vehicleForSaleId, reference }: Props): JSX.Element | null => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const disclaimers = useCarFilterSelector(getDisclaimerSelector(vehicleForSaleId), shallowEqual);
    const infoTextDisclaimer = disclaimers.find(
        (disclaimer) =>
            disclaimer.location === DisclaimerLocation.InfoText &&
            disclaimer.type === type &&
            disclaimer.reference === reference,
    );

    if (!infoTextDisclaimer) return null;

    return (
        <Button.Link
            type="button"
            onClick={(evt) => {
                evt.stopPropagation();
                dispatch(showModal({ type: MODAL_CONTENT, content: infoTextDisclaimer.value, useHTML: true }));
            }}
        >
            {infoTextDisclaimer.infoText}
        </Button.Link>
    );
};

export default LinkTextDisclaimer;
