import styled, { css } from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { breakpoints } from "../../../../../shared-logic/themes/toyotaTheme";
import * as Button from "../../../../../../common-deprecated/styles/toyota/Button";

export const Wrapper = styled.div<{ showMoreFilters?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* vp-width: 768px */
    @media screen and (min-width: ${breakpoints.md}) {
        grid-column: span 2;

        ${({ showMoreFilters }) =>
            showMoreFilters &&
            css`
                grid-column: span 1;
            `};
    }
    /* vp-width: 1024 */
    @media screen and (min-width: ${breakpoints.lg}) {
        grid-column: span 3;

        ${({ showMoreFilters }) =>
            showMoreFilters &&
            css`
                grid-column: span 2;
            `};
    }

    /* vp-width: 1200px */
    @media screen and (min-width: ${breakpoints.xl}) {
        grid-column: span 4;

        ${({ showMoreFilters }) =>
            showMoreFilters &&
            css`
                grid-column: span 3;
            `};
    }
`;

export const ReturnButton = styled(Button.Light)`
    display: block;

    &:hover {
        background-color: ${theme.colors.grey2};
    }
`;

export const OutOfBoundsTextWrapper = styled.div`
    margin: 20px 0;
    text-align: center;
`;

export const OutOfBoundsMessage = styled.div`
    font-family: ${theme.fonts.semiBold};
`;

export const OutOfBoundsDescription = styled.div`
    font-family: ${theme.fonts.base};
`;
