import styled from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Description = styled.div`
    margin-block-start: ${theme.space[3] / 2}px;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryBlack};

    @supports not (margin-block: 0) {
        margin-top: ${theme.space[3] / 2}px;
    }
`;

export const List = styled.ul`
    margin: ${theme.space[4]}px 0 0;
    padding: 0;

    li {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        gap: ${theme.space[1]}px;
        font-family: ${theme.fonts.base};
        font-size: 1.3rem;
        line-height: 2rem;
        color: ${theme.colors.primaryBlack};
    }
`;

export const Label = styled.div`
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    line-height: 2rem;
`;
