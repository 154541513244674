import React from "react";
import { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";
import ModalBaseView from "../../../../shared-components/toyota/modals/Modal";
import { IconClose } from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import * as ModalStyles from "../../../../shared-components/toyota/modals/Modal.styles";
import * as Styles from "./UscInjectionModal.styles";
import * as Preloader from "../../../../../common-deprecated/styles/toyota/Preloader";
import { UscInjectionModalType } from "../../../../shared-logic/utils/modalConstants";

const UscInjectionModal = (props: ModalViewType<UscInjectionModalType>): JSX.Element => {
    const { show, modalSettings } = props;
    // We're not making use of injectionError as it doesn't apply to iframes (which is what this modal's only purpose is atm)
    const { containerId, className, title, injectionLoading, onClose, onCloseAnimationEnd } = modalSettings;

    return (
        <ModalBaseView
            modalIdType={modalSettings.type}
            show={show}
            onClose={onClose}
            size="external"
            onAnimationEnd={show ? undefined : onCloseAnimationEnd}
        >
            <ModalStyles.Header noBorder>
                {title && <Styles.Title>{title}</Styles.Title>}
                <ModalStyles.Button type="button" onClick={onClose}>
                    <IconClose />
                </ModalStyles.Button>
            </ModalStyles.Header>
            <ModalStyles.Body noSpacing>
                {injectionLoading && (
                    <Preloader.Wrapper width={1} position="relative" data-testid="preloader">
                        <Preloader.default />
                    </Preloader.Wrapper>
                )}

                <div id={containerId} className={className} />
            </ModalStyles.Body>
        </ModalBaseView>
    );
};

export default UscInjectionModal;
