import styled from "styled-components";
import * as Flex from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

/**
 * Shared styles for a "popup list" which contains a clickable list of button options.
 */
export const Wrapper = styled.div`
    position: absolute;
    right: 0;
    min-width: 256px;
    z-index: ${theme.zIndex.popover};
`;

export const Button = styled.button`
    width: 100%;
    min-height: 50px;
    margin: 0;
    padding: 0 0 0 16px;
    text-align: left;
    color: ${theme.colors.primaryBlack};
    background: none;
    border: none;
    border-bottom: 1px solid ${theme.colors.grey2};
`;

export const Item = Flex.Col;

export const ItemRow = styled(Flex.Row)`
    &:last-child {
        ${Button} {
            border-bottom: none;
        }
    }
`;
