import styled, { css } from "styled-components";

export const DefList = styled.dl`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    dt,
    dd {
        font-size: 1.3rem;
        font-weight: normal;
        line-height: 2.8rem;
    }

    dt {
        width: 33.33333%;
        max-width: 100%;
        font-family: ${({ theme }) => theme.fontFamily.bold};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    dd {
        width: 66.66666%;
        ${({ theme }) => css`
            font-family: ${theme.fontFamily.light};
            color: ${theme.color.grey5};
        `};
    }
`;
