import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../../redux/store";
import * as Flex from "../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import { StockCarFilterId, UsedCarFilterId } from "../../../../../shared-logic/types/UscCommonTypes";
import * as Styles from "./styles/ColourFilterStyles";
import { setSelectMultiFilter } from "../../../../redux/actions/CarFiltersActions";
import IconCheck from "../../../../svg/toyota/check.svg";
import { isLightColour } from "../../../../../../common-deprecated/utils";
import { getAemCarFilterTrackParamsSelector, trackAemMultipleChoiceFilter } from "../../../../utils/tracking";

type ColourFilterType = { filterId: UsedCarFilterId.Colour | StockCarFilterId.Colour };
const ColourFilter = ({ filterId }: ColourFilterType): JSX.Element => {
    const filter = useCarFilterSelector((state) => state.carFilters[filterId]);
    const trackParams = useCarFilterSelector(getAemCarFilterTrackParamsSelector(filterId), shallowEqual);
    const dispatch = useDispatch<CarFilterDispatchType>();

    return (
        <>
            <Flex.Row mb="25px">
                {filter.values.map(({ id, selected, hexCode, label }) => {
                    const isLight = isLightColour(hexCode);
                    return (
                        <Styles.ColourWrapper
                            onClick={() => {
                                dispatch(setSelectMultiFilter([id], !selected, filterId));
                                trackAemMultipleChoiceFilter(trackParams, filter.values, id);
                            }}
                            key={id}
                        >
                            <Styles.Colour color={hexCode} addBorder={isLight} selected={selected}>
                                <Styles.CheckWrapper active={selected} isLightColour={isLight}>
                                    <IconCheck />
                                </Styles.CheckWrapper>
                            </Styles.Colour>
                            <Styles.Name>{label}</Styles.Name>
                        </Styles.ColourWrapper>
                    );
                })}
            </Flex.Row>

            {/*
                Disabling bitone filter as it doesn't seem to be used by either TFR or TES, no values to fitler on.
                Label should be: carFilterShowBiTone

            <MCFilterView
                // Re-using MCFilterView even though it is only one value as the layout should be identical.
                values={[{ id: "biTone", selected: filter.showBiTone, selectable: true, label: biToneLabel }]}
                selectValue={(valueId, selected) => dispatch(setBiTone(selected))}
                id={filterId}
            />
            */}
        </>
    );
};

export default ColourFilter;
