import styled from "styled-components";
import * as List from "../../styles/toyota/List";

/**
 * Do not add component specific styling here as this component is reused in both the build and car-filter.
 * Consider using component-specific overrides like ${DropdownContent}`background: white`
 */
export const DropdownContent = styled.div<{ show: boolean; height: number | "auto" }>`
    pointer-events: ${(props) => (props.show ? "inherit" : "none")};
    height: ${(props) => (typeof props.height === "number" ? `${props.height}px` : props.height)};
    transition: ${(props) => (props.show ? "height 0.3s, opacity 0.3s" : "height 0.3s")};
    opacity: ${(props) => (props.show ? 1 : 0)};

    ${List.DefList} {
        dt,
        dd {
            white-space: normal !important;
            word-wrap: break-word !important;
            overflow: visible !important;
        }
    }
`;

/**
 * If additional styling needs to be applied this wrapper can be used.
 */
export const DropdownChildWrapper = styled.div``;
