import {
    MODAL_RETAILER_DEBUG,
    RetailerDebugModalType,
} from "../../../common-deprecated/features/retailer/components/modals/constants";
import { ContactDealerType, MODAL_USC_FORM } from "../../shared-logic/utils/integrated-modal/integratedModalConstants";
import { UscVehicleSaleType } from "../../shared-logic/types/UscCommonTypes";

export const MODAL_FILTER_CONFIG_HELPER = "carFilterConfigHelper" as const;
export const MODAL_FILTER_RETAILER_SELECT = "carFilterRetailerSelect" as const;
export const MODAL_FILTER_VEHICLE_SALE_TYPE = "vehicleSaleType" as const;
export const MODAL_FILTER_CAR_FILTERS = "carFilterCarFilters" as const;
export const MODAL_FILTER_SORT_FILTERS = "carFilterSortFilters" as const;

export type ConfigHelperModalType = { type: typeof MODAL_FILTER_CONFIG_HELPER };
export type RetailerSelectModalType = { type: typeof MODAL_FILTER_RETAILER_SELECT };
export type CarFilterModalType = { type: typeof MODAL_FILTER_CAR_FILTERS };
export type CarFilterSortFiltersModalType = { type: typeof MODAL_FILTER_SORT_FILTERS };

export type VehicleSaleTypeModalType = {
    type: typeof MODAL_FILTER_VEHICLE_SALE_TYPE;
    vehicleForSaleId: string;
    vehicleSaleType: UscVehicleSaleType;
};

export type CarFilterModalIdsType =
    | typeof MODAL_FILTER_CONFIG_HELPER
    | typeof MODAL_FILTER_RETAILER_SELECT
    | typeof MODAL_RETAILER_DEBUG
    | typeof MODAL_USC_FORM
    | typeof MODAL_FILTER_VEHICLE_SALE_TYPE
    | typeof MODAL_FILTER_CAR_FILTERS
    | typeof MODAL_FILTER_SORT_FILTERS;

export type CarFilterModalsType =
    | ConfigHelperModalType
    | RetailerSelectModalType
    | RetailerDebugModalType
    | ContactDealerType
    | VehicleSaleTypeModalType
    | CarFilterModalType
    | CarFilterSortFiltersModalType;
