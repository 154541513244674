/* eslint-disable no-alert */
import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";
import { EnvironmentEnum } from "../../../../utils";
import { setTradeInAemExtData } from "../../../tradein/redux/actions/TradeInAemExternalDataActions";
import { useCommonSelector } from "../../../../redux/commonStore";
import { QueryType } from "../../../../server/types";
import { QueryObjectType } from "../../../../types/CommonTypes";
import Debug from "../../../../Debug";

type UseToolsModalType = {
    onClickHandler: (onClickType: LinkOnClickType) => void;
    queryBuilderState: QueryBuilderStateType;
    handleQueryBuilderChange: (
        queryName: string,
        isDropdown?: boolean,
        event?: React.ChangeEvent<HTMLSelectElement>,
    ) => void;
};

export type QueryBuilderStateType = Partial<Record<keyof QueryType, QueryObjectType>>;

export type LinkOnClickType = "set-trade-in-valuation";

export type LinkType = {
    title: string;
    href?: string;
    redirect?: boolean; // will redirect to href instead of opening new tab
    onClickLogic?: LinkOnClickType;
};

const useToolsModal = (extraQueryBuilderOptions?: QueryBuilderStateType): UseToolsModalType => {
    const dispatch = useDispatch();
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    const initialQueryBuilderState = useMemo(
        (): QueryBuilderStateType => ({
            variantBrand: {
                label: "Override layout variant brand",
                queryValues: ["toyota", "lexus"],
                isDropdown: true,
                currentValue: commonSettings.query.variantBrand || "",
            },
            showDictionaryKeys: {
                label: "Show dictionary keys",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.showDictionaryKeys || false,
            },
            disableTokens: {
                label: "Disable tokens",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.disableTokens || false,
            },
            disableErrorModal: {
                label: "Disable error modal",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.disableErrorModal || false,
            },
            logtracking: {
                label: "Log tracking events",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.logtracking || false,
            },
            useMockData: {
                label: "Use Mock Data",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.useMockData || false,
            },
            ...extraQueryBuilderOptions,
        }),
        [],
    );

    const [queryBuilderState, setQueryBuilderState] = useState<QueryBuilderStateType>(initialQueryBuilderState);

    const handleQueryBuilderChange = (
        queryName: string,
        isDropdown?: boolean,
        event?: React.ChangeEvent<HTMLSelectElement>,
    ): void => {
        const queryObject = (queryBuilderState as Record<string, QueryObjectType>)[queryName];
        const initialQueryObject = (initialQueryBuilderState as Record<string, QueryObjectType>)[queryName];
        if (!queryObject || !initialQueryObject) return;

        if (isDropdown && event?.target) {
            const newValue = event.target.value || initialQueryObject.currentValue;
            const newState = {
                ...queryBuilderState,
                [queryName]: { ...queryObject, currentValue: newValue, inputTouched: true },
            };
            setQueryBuilderState(newState);
            return;
        }

        // checkbox
        const newState = {
            ...queryBuilderState,
            [queryName]: { ...queryObject, currentValue: !queryObject.currentValue, inputTouched: true },
        };
        setQueryBuilderState(newState);
    };

    const setTradeinValuation = (): void => {
        if (commonSettings.environment === EnvironmentEnum.Production) return;
        const vehiclePrice = parseInt(prompt("Vehicle value (number in euro)") || "0");

        // This is what actually happens when using 'real' trade-in data as well.
        dispatch(
            setTradeInAemExtData({
                vehicleValue: vehiclePrice,
                vehicleName: { name: "Test vehicle", description: "test description" },
                quoteId: "test",
                licensePlate: "123-abc",
                vin: "",
            }),
        );
    };

    const onClickHandler = (onClickType: LinkOnClickType): void => {
        switch (onClickType) {
            case "set-trade-in-valuation": {
                setTradeinValuation();
                break;
            }
            default: {
                Debug.warn("Unsupported action in useToolsModal.");
                break;
            }
        }
    };

    return { onClickHandler, queryBuilderState, handleQueryBuilderChange };
};

export default useToolsModal;
