import styled, { css } from "styled-components";
import * as Flex from "./Flexbox";

const Preloader = styled.div<{ delayedVisibility?: boolean; size?: number }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: ${(props) => props.size || 45}px;
        height: ${(props) => props.size || 45}px;
        /* stylelint-disable */
        margin-top: calc(-${(props) => (props.size || 45) / 2}px);
        margin-left: calc(-${(props) => (props.size || 45) / 2}px);
        /* stylelint-enable */
        content: "";
        background-clip: padding-box;
        border-top: 2px solid ${({ theme }) => theme.color.primary};
        border-right: 2px solid ${({ theme }) => theme.color.primary};
        border-radius: 50%;

        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    &::before {
        border-top: 2px solid ${({ theme }) => (theme.isLexus ? "rgba(0, 0, 0, 0.8)" : "rgba(229, 0, 0, 0.8)")};
        border-bottom: 2px solid ${({ theme }) => theme.color.grey1};
        border-left: 2px solid ${({ theme }) => theme.color.grey1};
        animation-name: spin-loader;
        z-index: 10;
    }

    &::after {
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 1px solid ${({ theme }) => (theme.isLexus ? "rgba(0, 0, 0, 0.8)" : "rgba(229, 0, 0, 0.8)")};
        animation-name: spin-loader;
        z-index: 20;
    }
    @keyframes spin-loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /* Use this boolean when you want the showing of the loader to be delayed a half second */
    /* Useful for stopping the loader flashing when an item loads instantly */
    ${({ delayedVisibility }) =>
        delayedVisibility !== undefined &&
        css`
            @keyframes fadeInCutOut {
                0% {
                    opacity: 0;
                }
                99% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            ${delayedVisibility
                ? css`
                      animation: fadeInCutOut 0.3s ease-in;
                  `
                : css`
                      opacity: 0;
                  `};
        `};
`;

export const Wrapper = styled(Flex.Col)`
    display: flex;
    position: relative;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    &::after {
        display: block;
        width: 100%;
        height: 50vh;
        content: "";
    }
`;

export const PreLoaderFullScreen = styled(Preloader)`
    height: 80vh;
`;

export const PreLoaderWrapper = styled.div<{ hasBorder?: boolean; hasButtonHeight?: boolean }>`
    position: relative;
    height: 100%;

    ${({ hasButtonHeight }) =>
        hasButtonHeight &&
        css`
            min-width: 115px;
            height: 46px;
        `};

    ${({ hasBorder, theme }) =>
        hasBorder &&
        css`
            padding: 8px;
            border: 1px solid ${theme.colors.border};
        `};
`;

export const PreloaderBackdrop = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.85);
`;

export default Preloader;
