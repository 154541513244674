import React from "react";
import { shallowEqual } from "react-redux";
import { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";
import { VehicleSaleTypeModalType } from "../../../utils/modalConstants";
import ModalBaseView from "../../../../../common-deprecated/components/modals/aem/ModalBaseView";
import * as Modal from "../../../../../common-deprecated/styles/v2/toyota/globals/Modal";
import Icon, { IconClose } from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import IconWrapper from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { useCarFilterSelector } from "../../../redux/store";
import { customerPromiseBannerItemsSelector } from "../../../utils/selectorUtils";
import { UscVehicleSaleType } from "../../../../shared-logic/types/UscCommonTypes";
import * as Styles from "./VehicleSaleTypeModal.styles";
import UsedCarResultSaleTypeBadge from "../car-results/df-car-result/UsedCarResultSaleTypeBadge";
import ButtonSecondary from "../../../../../common-deprecated/components/aem/toyota/ButtonSecondary";
import { CommonLabel } from "../../../../../common-deprecated/utils/commonLabels";

/**
 * Because of styling differences between the Car Filter & USC (which will be aligned at a later date),
 * the modal has been split between the two projects. Keep them aligned where possible.
 */
const CarFilterVehicleSaleTypeModal = (props: ModalViewType<VehicleSaleTypeModalType>): JSX.Element | null => {
    const { show, close, modalSettings } = props;
    const { vehicleSaleType, vehicleForSaleId } = props.modalSettings;

    const customerPromiseBanner = useCarFilterSelector(
        customerPromiseBannerItemsSelector(vehicleForSaleId, vehicleSaleType),
        shallowEqual,
    );
    const brand = useCarFilterSelector((state) => state.commonSettings.brand);

    if (!customerPromiseBanner?.title || !customerPromiseBanner?.bannerItems.length) return null;

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="md">
            <Modal.Header>
                <Modal.Title>{customerPromiseBanner.title}</Modal.Title>
                <Modal.Button type="button" onClick={close}>
                    <IconWrapper>
                        <IconClose />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>

            <Modal.Body>
                <UsedCarResultSaleTypeBadge vehicleForSaleId={vehicleForSaleId} brand={brand} inModal />

                {customerPromiseBanner.description && (
                    <Styles.Description>{customerPromiseBanner.description}</Styles.Description>
                )}

                {customerPromiseBanner.bannerItems?.length && (
                    <Styles.List>
                        {customerPromiseBanner.bannerItems.map((item, index) => (
                            // The list is static and isn't going to change, safe to use index as key
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={index}>
                                <IconWrapper
                                    iconColor={
                                        vehicleSaleType === UscVehicleSaleType.Approved ? "primaryRed" : "primaryBlack"
                                    }
                                >
                                    <Icon
                                        variant={vehicleSaleType === UscVehicleSaleType.Approved ? "check" : "error"}
                                    />
                                </IconWrapper>
                                {item.label}
                            </li>
                        ))}
                    </Styles.List>
                )}
            </Modal.Body>
            <Modal.Actions rightAlignment>
                <ButtonSecondary onClick={close}>
                    <CommonLabel label="close" />
                </ButtonSecondary>
            </Modal.Actions>
        </ModalBaseView>
    );
};

export default CarFilterVehicleSaleTypeModal;
