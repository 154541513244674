import { createGlobalStyle } from "styled-components";
import { theme } from "../toyota/theme/toyotaTheme";

//This file overrides some styles from the loaded stylesheet. Mainly used for hard to reach z-indexes and global outlines for a11y

const GlobalStyle = createGlobalStyle`
  a,
  button {
    outline: 2px solid transparent !important;
    outline-offset: 8px;
    &:focus-visible {
      outline: 2px solid ${theme.colors.outline} !important;
      outline-offset: 2px;
      @media (prefers-reduced-motion: no-preference) {
        transition: outline 0.3s ease-out, outline-offset 0.3s ease-out;
      }
    }
    @supports not selector(:focus-visible) {
      &:focus-within {
        outline: 2px solid ${theme.colors.outline} !important;
        outline-offset: 2px;
        @media (prefers-reduced-motion: no-preference) {
          transition: outline 0.3s ease-out, outline-offset 0.3s ease-out;
        }
      }
    }
  }

  .material-box-content > .btn-close {
    z-index: 9999 !important;
  }
`;

export default GlobalStyle;
