import React from "react";
import { EcoLabelPopupLabelType } from "../../types/SettingsType";
import * as Styles from "./styles/EmissionLabelStyles";
import EmissionLabelComponent from "./EmissionLabelComponent";

type PropsType = {
    label: EcoLabelPopupLabelType;
    hideInfoIcon?: boolean;
};

const EmissionLabel = (props: PropsType): JSX.Element | null => {
    return (
        <Styles.Wrapper onClick={(evt) => evt.stopPropagation()}>
            <EmissionLabelComponent {...props} />
        </Styles.Wrapper>
    );
};

export default EmissionLabel;
