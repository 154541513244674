import styled from "styled-components";

export const PlaceholderWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 200px;
    background: ${(props) => props.theme.color.grey1};
    transition: opacity 0.3s;
    z-index: 1;
`;
