import styled, { css } from "styled-components";
import { getDirection } from "../../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Indicator = styled.div<{ position?: "left" | "right" }>`
    display: flex;
    position: relative;
    align-items: center;
    padding-block-end: ${theme.space[2]}px;
    border-bottom: 1px solid ${theme.colors.grey2};
    
    label {
        display: inline-flex;
        position: relative;
        width: 100%;
        margin: 0;
        padding-block: 0;
        padding-inline-end: 20px;
        font-family: ${theme.fonts.regular};
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${theme.colors.primaryBlack};
        cursor: pointer;

        @supports not (padding-inline-end: 0) {
            padding-${getDirection("right")}: 20px;
        }

        /* Custom style checkbox */
        &::before {
            display: block;
            position: absolute;
            top: 2px;
            width: 20px;
            height: 20px;
            content: "";
            border: 1px solid ${theme.colors.grey3};
            border-radius: 4px;
            cursor: pointer;

            @supports not (inset-inline-end: 0) {
                ${getDirection("right")}: 0;
            }
        }

        ${({ position }) =>
            position === "left"
                ? css`
                      &::before {
                          inset-inline-start: 0;
                          @supports not (inset-inline-end: 0) {
                              ${getDirection("left")}: 0;
                          }
                      }
                  `
                : css`
                      &::before {
                          inset-inline-end: 0;
                          @supports not (inset-inline-end: 0) {
                              ${getDirection("right")}: 0;
                          }
                      }
                  `}
    }

    input {
        position: absolute;
        width: 10px;
        height: 10px;
        white-space: nowrap;
        opacity: 0;
        overflow: hidden;

        ${({ position }) =>
            position === "left"
                ? css`
                      inset-inline-start: ${theme.space[2]}px;
                      @supports not (inset-inline-end: 0) {
                          ${getDirection("left")}: ${theme.space[2]}px;
                      }
                  `
                : css`
                      inset-inline-end: ${theme.space[2]}px;
                      @supports not (inset-inline-end: 0) {
                          ${getDirection("right")}: ${theme.space[2]}px;
                      }
                  `}
    }

    input:checked + label::before {
        background-color: ${theme.colors.primaryBlack};
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='1.5' d='m6.364 10.303 2.666 2.666M7.97 12.97l6-6'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        border-color: ${theme.colors.primaryBlack};
    }

    input:disabled + label {
        color: ${theme.colors.grey3};
        cursor: default;
        &::before {
            background-color: ${theme.colors.grey7};
            border-color: ${theme.colors.grey7};
            cursor: default;
        }
    }

    &:hover {
        label::before {
            background-color: ${theme.colors.grey3};
        }
    }

    &:focus-within {
        label::before {
            outline: 2px solid ${theme.colors.outline};
            outline-offset: 2px;
        }
    }

`;
