import React from "react";
import ModalBaseView from "./ModalBaseView";
import { ModalOkType } from "../../../utils/modalConstants";
import * as Flex from "../../../styles/v2/toyota/globals/Flex";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import * as Button from "../../../styles/v2/toyota/components/Button";
import * as Text from "../../../styles/v2/toyota/utilities/Text";
import * as Type from "../../../styles/v2/toyota/globals/Type";
import { IconWrapper } from "../../../styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../styles/v2/toyota/globals/Icon";
import { ModalViewType } from "../../../types/CommonTypes";
import { useCommonLabel } from "../../../utils/commonLabels";

// Basic modal with a title and an ok button that closes the modal.
const OkModal = (props: ModalViewType<ModalOkType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { contentLabel, titleLabel, okLabel } = modalSettings;
    const closeLabel = useCommonLabel("close");

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="md">
            <Flex.Col display="flex" width={1} p={3}>
                <Type.H2>{titleLabel}</Type.H2>
                <Button.Icon type="button" aria-label={closeLabel} onClick={close} ml="auto">
                    <IconWrapper>
                        <Icon variant="close" />
                    </IconWrapper>
                </Button.Icon>
            </Flex.Col>
            <Flex.Col width={1} px={3}>
                <Type.Text headingType="base">{contentLabel}</Type.Text>
            </Flex.Col>
            <Modal.Actions>
                <Button.Primary mx={0} onClick={close}>
                    <Text.Button>{okLabel}</Text.Button>
                </Button.Primary>
            </Modal.Actions>
        </ModalBaseView>
    );
};

export default OkModal;
