import React from "react";
import { useCarFilterSelector } from "../../../../redux/store";
import * as Styles from "./styles/FilterResultCountStyles";
import { useCarFilterLabel } from "../../../../utils/constants/labels";

type FilterResultCountType = { show: boolean };
const FilterResultCount = (props: FilterResultCountType): JSX.Element => {
    const { show } = props;
    const resultCount = useCarFilterSelector((state) => state.carResults.totalResultCount);
    const initialized = useCarFilterSelector((state) => state.carResults.initialized);

    const resultCountLabel = useCarFilterLabel("carFilterResultCount");
    // Split the resultCountLabel so we can apply styling to the count number.
    const resultCountLabelSplit = resultCountLabel.split("{value}");

    return (
        <Styles.Wrapper show={show && initialized}>
            <Styles.Label>{resultCountLabelSplit[0]}</Styles.Label>
            <Styles.Value>{resultCount}</Styles.Value>
            <Styles.Label>{resultCountLabelSplit[1]}</Styles.Label>
        </Styles.Wrapper>
    );
};

export default FilterResultCount;
