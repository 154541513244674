import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import * as Flex from "../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import MCFilterView from "../multiple-choice/MCFilterView";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../../redux/store";
import { setSelectMultiFilter } from "../../../../redux/actions/CarFiltersActions";
import { UsedCarFilterId } from "../../../../../shared-logic/types/UscCommonTypes";
import { useCarFilterLabel, useCarFilterOptionalLabel } from "../../../../utils/constants/labels";
import FilterCheckInput from "../../common/FilterCheckInput";
import * as Styles from "./styles/WarrantyFilterStyles";
import { getAemCarFilterTrackParamsSelector, trackAemMultipleChoiceFilter } from "../../../../utils/tracking";

const WarrantyFilter = (): JSX.Element => {
    const filter = useCarFilterSelector((state) => state.carFilters[UsedCarFilterId.Warranty]);
    const { showAnyWarranty, showTerms } = useCarFilterSelector(
        (state) => state.carFilterSettings.usedCarsWarrantyFilterConfig,
    );
    const trackParams = useCarFilterSelector(
        getAemCarFilterTrackParamsSelector(UsedCarFilterId.Warranty),
        shallowEqual,
    );
    const dispatch = useDispatch<CarFilterDispatchType>();
    const [anyWarrantyTitleLabel, durationLabel] = useCarFilterLabel([
        "carFilterAnyWarrantyTitle",
        "carFilterWarrantyDuration",
    ]);
    const anyWarrantyDescriptionLabel = useCarFilterOptionalLabel("carFilterAnyWarrantyDescription");

    // The 'any' value is shown as a separate option, so filter it from the default values.
    const selectableWarrantyValues = filter.values.filter((value) => value.id !== "any");

    // Easy access to the separately rendered any filter value.
    const anyFilterValue = filter.values.find((value) => value.id === "any");

    // Only show the warranty filter subtitles when both subfilters are shown (at the time of writing this is an edge case and doenst happen outside of UAT setups).
    const showFilterSubtitles = showAnyWarranty && showTerms;

    return (
        <Flex.Row>
            {showAnyWarranty && anyFilterValue && (
                <Flex.Col width={1}>
                    {showFilterSubtitles && <Styles.Subtitle>{anyWarrantyTitleLabel}</Styles.Subtitle>}
                    <FilterCheckInput
                        checked={anyFilterValue.selected}
                        onChange={() =>
                            dispatch(setSelectMultiFilter(["any"], !anyFilterValue.selected, UsedCarFilterId.Warranty))
                        }
                        label={anyFilterValue.label}
                        id="warranty"
                        checkboxVariant
                    />
                    {anyWarrantyDescriptionLabel && (
                        <Styles.Description>{anyWarrantyDescriptionLabel}</Styles.Description>
                    )}
                </Flex.Col>
            )}
            {showTerms && (
                <Flex.Col width={1}>
                    {showFilterSubtitles && <Styles.Subtitle>{durationLabel}</Styles.Subtitle>}
                    <MCFilterView
                        id="warrantyfilter"
                        selectValue={(valueId, selected) => {
                            dispatch(setSelectMultiFilter([valueId], selected, UsedCarFilterId.Warranty));
                            trackAemMultipleChoiceFilter(trackParams, selectableWarrantyValues, valueId);
                        }}
                        columnWidth={1}
                        values={selectableWarrantyValues.sort((a, b) => {
                            // For warranty filter we have to sort the largest amount of months on top.
                            // By lack of better means this is currently achieved by extracting the numeric values from the ids.
                            // These values should map to the actual warranty month count which we can use to determine sort order.
                            const aWarrantyMonth = Number(a.id.replace(/[^0-9]/gi, ""));
                            const bWarrantyMonth = Number(b.id.replace(/[^0-9]/gi, ""));
                            if (aWarrantyMonth !== bWarrantyMonth) return aWarrantyMonth > bWarrantyMonth ? -1 : 1;

                            // If the warranty months are the same, fall back on label sort.
                            if (a.label < b.label) return -1;
                            if (a.label > b.label) return 1;
                            return 0;
                        })}
                        checkboxVariant
                    />
                </Flex.Col>
            )}
        </Flex.Row>
    );
};

export default WarrantyFilter;
