import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import * as DOMUtils from "history/DOMUtils";
import debounce from "lodash/debounce";

type UseWindowDimensionsType = {
    width: number;
    height: number;
};

const getDimensions = (): UseWindowDimensionsType => ({ width: window.innerWidth, height: window.innerHeight });

// Create a global context for window dimensions
const WindowDimensionsContext = createContext<UseWindowDimensionsType>({
    width: 0,
    height: 0,
});

// Use the context provider to manage the window dimensions
export const WindowDimensionsProvider: FC<PropsWithChildren> = (props) => {
    const { children } = props;
    const { canUseDOM } = DOMUtils;
    const [windowDimensions, setWindowDimensions] = useState<UseWindowDimensionsType>(
        canUseDOM ? getDimensions() : { width: 0, height: 0 },
    );

    useEffect(() => {
        if (DOMUtils.canUseDOM) {
            const resizeWindow = debounce((): void => {
                setWindowDimensions(getDimensions());
            }, 100);
            window.addEventListener("resize", resizeWindow);
            return () => window.removeEventListener("resize", resizeWindow);
        }
        return () => null;
    }, []);

    return <WindowDimensionsContext.Provider value={windowDimensions}>{children}</WindowDimensionsContext.Provider>;
};

// Use the context consumer to access window dimensions
export const useWindowDimensionsContext = (): UseWindowDimensionsType => {
    return useContext(WindowDimensionsContext);
};
