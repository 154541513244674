import styled from "styled-components";
import { theme } from "../theme/toyotaTheme";

export const Wrapper = styled.div<{ isActive?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.space[2]}px ${theme.space[3]}px;
    border: 1px solid ${theme.colors.grey2};

    input {
        width: 100%;
        margin: 0;
        padding: 0;
        font-family: ${theme.fonts.base};
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${theme.colors.grey8};
        background-color: transparent;
        border: 0;
        outline: 0;
    }
`;

export const InputAppend = styled.div`
    display: flex;
`;
