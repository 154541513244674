import styled from "styled-components";
import * as FilterDropdownStyles from "../../../styles/FilterDropdownStyles";
import LabelStyle from "../../../../../../../../common-deprecated/features/filters/styles/Label";
import * as Flex from "../../../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import { Wrapper as BrandModelListWrapper } from "./BrandModelListStyles";
import { DropdownChildWrapper } from "../../../../../../../../common-deprecated/components/dropdown/DropdownContentStyles";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div`
    ${FilterDropdownStyles.Header} {
        font-family: ${theme.fonts.regular};
    }

    /* Add spacing to latest item in dropdown list */
    ${Flex.Row} {
        &:last-child {
            padding-bottom: 20px;
        }
    }

    ${BrandModelListWrapper} {
        padding-top: 0;
    }

    ${DropdownChildWrapper} {
        padding-bottom: 0;
    }
`;

export const Label = styled(LabelStyle)`
    margin: ${theme.space[3]}px 0 0;
    font-family: ${theme.fonts.semiBold};
`;

export const NestedTitle = styled(LabelStyle)`
    margin: 0 0 ${theme.space[3]}px;
    font-family: ${theme.fonts.regular};
`;
