import React from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../redux/store";
import { enabledSavedCarsSelector } from "../../../utils/selectorUtils";
import { useCarFilterLabel } from "../../../utils/constants/labels";
import { showOnlySavedCars } from "../../../redux/actions/CarFiltersActions";
import Checkbox from "../styles/molecules/Checkbox";

const FilterSavedCars: React.FC = () => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const savedCarsEnabled = useCarFilterSelector(enabledSavedCarsSelector, shallowEqual);
    const showSavedCars = useCarFilterSelector((state) => state.carFilters.savedCars.showOnlySavedCars);
    const hasSavedCars = useCarFilterSelector(
        ({ carFilters }) => !!carFilters.savedCars[carFilters.currentFilter].length,
    );

    const [noSavedLabel, showSavedLabel] = useCarFilterLabel(["carFilterNoSavedCars", "carFilterShowSavedCars"]);

    const savedCarClick = (): void => {
        if (hasSavedCars || showSavedCars) dispatch(showOnlySavedCars(!showSavedCars));
    };

    if (!savedCarsEnabled) return null;

    return (
        <Checkbox
            // Saved cars are not removed immediately to avoid the user accidentally losing the saved car.
            // Make sure the button keeps behaving consistently when the user removes all saved cars while having the saved car filter active (OR-3844)
            // To solve this only prevent the button being clickable when showSavedCars is not active.
            id="check-saved-cars"
            // Make sure the label still makes sense when the user has removed all saved cars while having the "show saved cars" active, see OR-3844
            label={hasSavedCars || showSavedCars ? showSavedLabel : noSavedLabel}
            position="right"
            value={String(showSavedCars)}
            checked={hasSavedCars && showSavedCars}
            disabled={!hasSavedCars && !showSavedCars}
            onChange={savedCarClick}
            onClick={savedCarClick}
        />
    );
};

export default FilterSavedCars;
