import { CommonSettingsType } from "../fetchCommonSettings";
import { LicenseFeesType } from "../../types/CommonTypes";

/**
 * General helper to indicate if prices should be shown/hidden as a whole.
 */
export const showPrice = (settings: CommonSettingsType): boolean => {
    if (typeof settings.query.hideprices !== "undefined" && !!settings.query.salesmanmaster) {
        return !(settings.query.hideprices.toString().toLowerCase() === "true");
    }
    return settings.showPrice;
};

/**
 * Indicates if a monthly rate should always contain the cash price as well.
 */
export const renderMonthlyRateWithPrice = (settings: CommonSettingsType): boolean =>
    settings.country === "de" && settings.language === "de";

/**
 * Helper method to get the corresponding licenseFee value
 * @param price
 * @param licenseFees
 */
export const getLicenseFee = (price: number, licenseFees: LicenseFeesType): number => {
    const match = licenseFees.find((range) => {
        return price >= range.min && price <= range.max;
    });
    return match ? match.fee : 0;
};
