import React from "react";
import * as Styles from "./styles/ResultImageStyles";
import Preloader from "../../../../../../common-deprecated/styles/toyota/Preloader";
import ResultPlaceholderImage from "./ResultPlaceholderImage";
import { useLoadImage } from "../../../../../../common-deprecated/hooks";

type ResultImageType = {
    altTag: string;
    src: string;
    srcSet?: string;
    startLoading: boolean;
};

const ResultImage = ({ src, startLoading, altTag, srcSet }: ResultImageType): JSX.Element => {
    const { loading, imageLoaded, loadFailed } = useLoadImage(startLoading, src, srcSet);
    return (
        <Styles.Wrapper>
            {startLoading && <Styles.Image src={src} srcSet={srcSet} show={imageLoaded} alt={altTag} />}
            <Styles.Content>
                {loadFailed && <ResultPlaceholderImage />}
                {loading && startLoading && (
                    // Only render this when we actually start loading, else the preloader is being rendered even when it isnt in view.
                    <Styles.Loader>
                        <Preloader delayedVisibility size={26} />
                    </Styles.Loader>
                )}
            </Styles.Content>
        </Styles.Wrapper>
    );
};

export default ResultImage;
