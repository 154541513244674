import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { CarFilterDisclaimerType, getCarFilterDisclaimerSelector } from "../../../utils/disclaimerUtils";
import { useCarFilterSelector } from "../../../redux/store";
import { DisclaimerLocation } from "../../../../../common-deprecated/types/CommonTypes";
import Popover from "../../../../../common-deprecated/components/popover/aem/Popover";

type Props = {
    vehicleForSaleId: string;
    type: CarFilterDisclaimerType;
    reference?: string | null;
};
const DisclaimerIconWrapper: FC<Props> = ({ type, reference, vehicleForSaleId }): JSX.Element | null => {
    const disclaimer = useCarFilterSelector(
        getCarFilterDisclaimerSelector(vehicleForSaleId, type, reference),
        shallowEqual,
    );

    if (!disclaimer) return null;

    switch (disclaimer.location) {
        case DisclaimerLocation.Inline:
        case DisclaimerLocation.InfoText:
            // Inline and infoText disclaimers are rendered elsewhere, no icon necessary.
            return null;
        case DisclaimerLocation.Footnote:
            // Footnotes are not yet supported in car-filter.
            return null;
        case DisclaimerLocation.InfoIcon:
            return <Popover showOnClick content={disclaimer.value} useHTML={disclaimer.containsHtml} />;
        default:
            return null;
    }
};

export default DisclaimerIconWrapper;
