import { useDispatch } from "react-redux";
import { MutableRefObject, useState } from "react";
import { CarFilterDispatchType, useCarFilterSelector } from "../../redux/store";
import { DfCarSortOrder } from "../../utils/constants/filterConstants";
import { getLocationFilterId } from "../../utils/filters";
import { CarFilterLabelType, sortOrderLabelMap } from "../../utils/constants/labels";
import { setUsedCarSortOrder } from "../../redux/actions/CarResultsActions";
import { useCloseOnOutsideClick } from "../../../../common-deprecated/hooks";
import { UscContext } from "../../../shared-logic/types/UscCommonTypes";

export type UseSortFiltersType = {
    values: {
        labelKey: CarFilterLabelType;
        id: DfCarSortOrder;
        selectFn: () => void;
    }[];
    showPopup: boolean;
    togglePopup: () => void;
};

/**
 * Used in SortFilters.
 */
const useSortFilters = (
    popupRef: MutableRefObject<HTMLDivElement | null>,
    selectItemTracking: (value: string) => void,
    openTracking?: (opened: boolean) => void,
): UseSortFiltersType => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const showLocation = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsShowLocation
            : state.carFilterSettings.stockCarsShowLocation,
    );
    const locationActive = useCarFilterSelector(
        ({ carFilters }) => carFilters[getLocationFilterId(carFilters.currentFilter)].active,
    );
    const configuredSortOptions = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsSortOptions
            : state.carFilterSettings.stockCarsSortOptions,
    );

    const values = Object.values(DfCarSortOrder)
        // Filter out sort orders that are not configured.
        .filter((sortOrderKey) => configuredSortOptions.includes(sortOrderKey))
        .filter((sortOrderKey) => {
            // Filter out specific sort orders.
            switch (sortOrderKey) {
                case DfCarSortOrder.Distance:
                    // Hide distance when the location filter is not active as distance is calculated based on location.
                    // If this ever is required to be visible at all times it requires some reworks to how we handle location data in the frontend.
                    return showLocation && locationActive;

                default:
                    return true;
            }
        })
        .map((sortOrderKey: DfCarSortOrder) => ({
            labelKey: sortOrderLabelMap[sortOrderKey],
            id: sortOrderKey,
            selectFn: () => {
                setShowPopup(false);
                selectItemTracking(sortOrderKey);
                dispatch(setUsedCarSortOrder(sortOrderKey as DfCarSortOrder));
            },
        }));

    useCloseOnOutsideClick(popupRef, () => setShowPopup(false));

    const togglePopup = (): void => {
        setShowPopup(!showPopup);
        if (openTracking) openTracking(!showPopup);
    };

    return { values, showPopup, togglePopup };
};

/**
 * Hook to check if the sort filters are available in the (mobile) Car Filter
 */
export const useMobileSortFilters = (): boolean => {
    const showLocation = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsShowLocation
            : state.carFilterSettings.stockCarsShowLocation,
    );
    const locationActive = useCarFilterSelector(
        ({ carFilters }) => carFilters[getLocationFilterId(carFilters.currentFilter)].active,
    );
    const configuredSortOptions = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsSortOptions
            : state.carFilterSettings.stockCarsSortOptions,
    );

    const values = Object.values(DfCarSortOrder)
        // Filter out sort orders that are not configured.
        .filter((sortOrderKey) => configuredSortOptions.includes(sortOrderKey))
        .filter((sortOrderKey) => {
            // Filter out specific sort orders.
            switch (sortOrderKey) {
                case DfCarSortOrder.Distance:
                    // Hide distance when the location filter is not active as distance is calculated based on location.
                    // If this ever is required to be visible at all times it requires some reworks to how we handle location data in the frontend.
                    return showLocation && locationActive;

                default:
                    return true;
            }
        });

    return values && values.length > 1;
};

export default useSortFilters;
