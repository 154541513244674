import { useEffect, useRef, useState } from "react";
import Fuse from "fuse.js";

const fuseOptions = { shouldSort: true, keys: ["label"], id: "id" };
type FuseFilterBaseObject = { id: string; label: string };

/**
 * Helper hook for using fuzzy search in arrays of objects.
 * Initially developed to search for brands/models in car-filter but possible to use in other implementations as well.
 *
 * Treat the threshold prop with care!
 * It walks a fine line of being strict but not too strict.
 * Current suggested values: 0.3 for brands, 0.35 for models.
 * Examples:
 * - With 0.35 a "COR" search query returns "Corolla" but also "Ford" as it could be a typo for "FOR" as well. With a 0.3 for brands ford is not returned.
 * - A "CHR" search query should return the chr car, but it should be lenient enough so it still returns the chr car when a user enters "c-hr", even though it is indexed as "chr"
 *
 * If this ever becomes a bigger issue consider hardcoding some edge cases, or consider using separate thresholds for Toyota/Lexus cars (or do some additional threshold experimenting, but take note of the examples above)
 */
const useFuseFilter = <T extends FuseFilterBaseObject>(
    query: string,
    list: T[],
    threshold: number = 0.35,
): string[] => {
    // It is important to only keep the filtered item ids and let the components take care of the actual rendering to avoid data sync issues.
    const [filteredItems, setFilteredItems] = useState<string[]>([]);
    const fuse = useRef(
        new Fuse(
            // Remove "-" from the labels as that messes with search (specifically chr queries).
            list.map((listItem) => ({ ...listItem, label: listItem.label.replace("-", "") })),
            { ...fuseOptions, threshold },
        ),
    );

    useEffect(() => {
        if (query) {
            setFilteredItems(fuse.current.search(query) as string[]);
        } else setFilteredItems([]);
    }, [query]);

    // Make sure to have a dependency on the items in the list as well as that can possibly change the output as well.
    const listKeys = list
        .map((listItem) => listItem.id)
        .sort()
        .join(",");

    // Add support for dynamically loaded data
    useEffect(
        () => {
            fuse.current.setCollection(list);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listKeys],
    );

    return filteredItems;
};

export default useFuseFilter;
