import styled, { css } from "styled-components";
import { getBreakpoint } from "../../../../themes/common";
import { theme } from "../theme/toyotaTheme";

export const Button = styled.span.attrs<{ ellipspis?: boolean; iconTextIcon?: boolean }>((props) => ({
    className: "btn-text",
}))<{ ellipsis?: boolean; iconTextIcon?: boolean }>`
    ${({ ellipsis }) =>
        ellipsis &&
        css`
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        `};

    ${({ iconTextIcon }) =>
        iconTextIcon &&
        css`
            display: grid;
            align-content: center;
            align-items: center;
            grid-template-columns: auto 1fr auto;
            gap: 0.5rem;
            padding: 0 ${theme.space[1]}px !important;
            text-align: left;
        `};
`;

export const Clamp = styled.span`
    display: block;
    min-width: 75px;
    max-height: 34px;
    line-height: 1;
    white-space: normal;
    @media ${getBreakpoint("up", "lg")} {
        display: block;
        width: 100%;
        min-width: auto;
        overflow: hidden;
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
    }
`;

export const Intro = styled.div.attrs((props) => ({
    className: "intro",
}))``;

export const Note = styled.div.attrs((props) => ({
    className: "note",
}))``;

export const Default = styled.div`
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2rem;
`;
