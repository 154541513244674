import styled, { css } from "styled-components";
import * as Flex from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";

export const ColourWrapper = styled(Flex.Col)`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    margin-bottom: 10px;
`;

export const CheckWrapper = styled.div<{ active: boolean; isLightColour: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 9px;
    line-height: 0;
    color: ${({ theme }) => theme.color.grey4};
    transform: translate(-50%, -50%);
    opacity: ${(props) => (props.active ? 1 : 0)};
    ${({ active, isLightColour }) =>
        active &&
        css`
            color: ${({ theme }) => (isLightColour ? theme.color.grey4 : theme.color.lightest)};
        `};
`;

export const Colour = styled.div<{ color: string; addBorder: boolean; selected: boolean }>`
    position: relative;
    width: ${(props) => (props.addBorder ? 29 : 30)}px;
    height: ${(props) => (props.addBorder ? 29 : 30)}px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    cursor: pointer;

    &:before {
        display: inline-block;
        width: ${(props) => (props.addBorder ? 37 : 38)}px;
        height: ${(props) => (props.addBorder ? 37 : 38)}px;
        margin-top: ${(props) => (props.addBorder ? -5 : -4)}px;
        margin-left: ${(props) => (props.addBorder ? -5 : -4)}px;
        content: "";
        border: 1px solid ${({ theme }) => theme.color.grey4};
        border-radius: 50%;
        transition: opacity 0.2s;
        opacity: ${(props) => (props.selected ? 1 : 0)};
    }

    &:hover:before {
        opacity: 1;
    }

    ${({ addBorder }) =>
        addBorder &&
        css`
            border: 1px solid ${({ theme }) => theme.color.grey2};
        `};
`;

export const Name = styled.div`
    font-size: 1.5rem;
    letter-spacing: -0.00032rem;
    line-height: 1.33;
    color: ${({ theme }) => theme.color.dark};
    cursor: pointer;
    ${Colour} + & {
        margin-left: 10px;
    }
`;
