import React, { ComponentType, FC, SVGProps } from "react";
import { DfEfficiencyClassCode } from "../../../shared-logic/utils/dfConstants";
import IconAPlusPlusPlus from "../../../../../assets/toyota/img/eco-badges/APlusPlusPlus.svg";
import IconAPlusPlus from "../../../../../assets/toyota/img/eco-badges/APlusPlus.svg";
import IconAPlus from "../../../../../assets/toyota/img/eco-badges/APlus.svg";
import IconA from "../../../../../assets/toyota/img/eco-badges/A.svg";
import IconB from "../../../../../assets/toyota/img/eco-badges/B.svg";
import IconC from "../../../../../assets/toyota/img/eco-badges/C.svg";
import IconD from "../../../../../assets/toyota/img/eco-badges/D.svg";
import IconE from "../../../../../assets/toyota/img/eco-badges/E.svg";
import IconF from "../../../../../assets/toyota/img/eco-badges/F.svg";
import IconG from "../../../../../assets/toyota/img/eco-badges/G.svg";

type EfficiencyClassIconType = { defaultColor: string; Icon: ComponentType<SVGProps<SVGSVGElement>> };

const EfficiencyClassIconsMap: Record<DfEfficiencyClassCode, EfficiencyClassIconType> = {
    [DfEfficiencyClassCode.APlusPlusPlus]: {
        defaultColor: "#00A651",
        Icon: IconAPlusPlusPlus,
    },
    [DfEfficiencyClassCode.APlusPlus]: {
        defaultColor: "#4DB848",
        Icon: IconAPlusPlus,
    },
    [DfEfficiencyClassCode.APlus]: {
        defaultColor: "#BED62F",
        Icon: IconAPlus,
    },
    [DfEfficiencyClassCode.A]: {
        defaultColor: "#FFF200",
        Icon: IconA,
    },
    [DfEfficiencyClassCode.B]: {
        defaultColor: "#FDB813",
        Icon: IconB,
    },
    [DfEfficiencyClassCode.C]: {
        defaultColor: "#F36F21",
        Icon: IconC,
    },
    [DfEfficiencyClassCode.D]: {
        defaultColor: "#ED1C24",
        Icon: IconD,
    },
    [DfEfficiencyClassCode.E]: {
        defaultColor: "#ED1C24",
        Icon: IconE,
    },
    [DfEfficiencyClassCode.F]: {
        defaultColor: "#ED1C24",
        Icon: IconF,
    },
    [DfEfficiencyClassCode.G]: {
        defaultColor: "#ED1C24",
        Icon: IconG,
    },
};

type PropsType = { efficiencyClass: DfEfficiencyClassCode; color?: string };

const EfficiencyClassIcon: FC<PropsType> = ({ efficiencyClass, color }) => {
    const { defaultColor, Icon } = EfficiencyClassIconsMap[efficiencyClass];

    return (
        <span style={{ color: color || defaultColor }}>
            <Icon />
        </span>
    );
};

export default EfficiencyClassIcon;
