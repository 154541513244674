import {
    MinMaxSliderFilterConfigType,
    MultipleChoiceFilterConfigType,
    MultipleChoiceValueType,
    PriceFilterConfigType,
} from "./constants/filterConfigConstants";
import { FinanceOptionType } from "../../../../common-deprecated/types/CommonTypes";

// ----------------------------------------------------------------------
// Slider helpers
// ----------------------------------------------------------------------
export const setSliderValue = <T extends MinMaxSliderFilterConfigType>(
    filter: T,
    valueType: "min" | "max",
    value: number,
): T => ({
    ...filter,
    [valueType === "min" ? "currentMinValue" : "currentMaxValue"]: value,
    active: true,
});

export const resetSlider = <T extends MinMaxSliderFilterConfigType>(filter: T): T => ({
    ...filter,
    currentMaxValue: filter.maxValue,
    currentMinValue: filter.minValue,
    active: false,
});

// ----------------------------------------------------------------------
// Multiple choice helpers
// ----------------------------------------------------------------------
export const selectMultiValue = <T extends MultipleChoiceFilterConfigType>(
    filter: T,
    ids: string[] | "all",
    selected: boolean,
): T => ({
    ...filter,
    active: true,
    values: filter.values.map((filterItem) => ({
        ...filterItem,
        selected: ids === "all" || ids.includes(filterItem.id) ? selected : filterItem.selected,
    })),
});

export const resetMultiValue = <T extends MultipleChoiceFilterConfigType>(filter: T): T => ({
    ...filter,
    active: false,
    values: filter.values.map((value) => ({ ...value, selected: false })),
});

export const applyMultiValueResultCount = <T extends MultipleChoiceValueType>(
    filterValues: T[],
    updates: { valueId: string; resultCount: number }[],
): T[] =>
    filterValues.map((value) => ({
        ...value,
        resultCount: updates.find((update) => update.valueId === value.id)?.resultCount || 0,
    }));

// ----------------------------------------------------------------------
// Price helpers
// ----------------------------------------------------------------------
export const setPrice = (
    filter: PriceFilterConfigType,
    type: "min" | "max",
    value: number,
    financeOption: FinanceOptionType,
): PriceFilterConfigType => ({
    ...filter,
    active: true,
    [financeOption]: { ...filter[financeOption], [type === "min" ? "currentMinValue" : "currentMaxValue"]: value },
});

export const resetPriceFilterOption = (
    filter: PriceFilterConfigType["cash" | "monthly"],
): PriceFilterConfigType["cash" | "monthly"] => ({
    ...filter,
    currentMaxValue: filter.maxValue,
    currentMinValue: filter.minValue,
    active: false,
});

export const resetPriceFilter = (filter: PriceFilterConfigType): PriceFilterConfigType => ({
    ...filter,
    cash: resetPriceFilterOption(filter.cash),
    monthly: resetPriceFilterOption(filter.monthly),
    active: false,
});

export const setPriceActive = (
    filter: PriceFilterConfigType,
    financeOption: FinanceOptionType,
): PriceFilterConfigType => {
    const inverseOption = financeOption === "monthly" ? "cash" : "monthly";
    return {
        ...filter,
        active: true,
        [financeOption]: { ...filter[financeOption], active: true },
        [inverseOption]: resetPriceFilterOption(filter[inverseOption]),
    };
};
