import styled, { css } from "styled-components";
import { getBreakpoint, getDirection } from "../../../themes/common";
import { theme } from "../../../styles/v2/toyota/theme/toyotaTheme";
import { bodyStyle } from "../../../styles/v2/toyota/globals/Type";

export const Filter = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Filters = styled.div<{ filterItems?: number }>`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: ${theme.space[2]}px;

    @supports not (gap: 16px) {
        > div:not(:nth-last-child(1)) {
            margin-${getDirection("right")}: ${theme.space[2]}px;
            @media ${getBreakpoint("up", "xl")} {
                margin-${getDirection("right")}: ${theme.space[4]}px;
            }
        }
    }

    @media ${getBreakpoint("up", "md")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        ${({ filterItems }) =>
            filterItems &&
            filterItems < 2 &&
            css`
                grid-template-columns: minmax(0, 1fr);
            `};
    }

    @media ${getBreakpoint("up", "xl")} {
        display: grid;
        gap: ${theme.space[4]}px;
        grid-template-columns: ${({ filterItems }) =>
            filterItems ? `repeat(${filterItems}, minmax(0, 1fr))` : "repeat(3, minmax(0, 1fr))"};
    }

    ${Filter} {
        ${({ filterItems }) =>
            filterItems &&
            filterItems % 2 !== 0 &&
            css`
                &:nth-last-child(1) {
                    grid-column: span 1;
                    @media ${getBreakpoint("up", "md")} {
                        grid-column: span 2;
                    }
                    @media ${getBreakpoint("up", "xl")} {
                        grid-column: span 1;
                    }
                }
            `};
    }
`;

export const Label = styled.label<{ isSmall?: boolean }>`
    margin-bottom: ${theme.space[1]}px;
    ${bodyStyle};
`;
