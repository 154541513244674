import React, { useRef } from "react";
import loadable from "@loadable/component";
import useStatus, { AdditionalModalsType } from "../../../../common-deprecated/hooks/useStatus";
import { useModalMap } from "../../../../common-deprecated/containers/ModalProvider";
import CarResultsContainer from "./car-results/CarResultsContainer";
import StaticFilterBar from "./active-filters/top-bar/StaticFilterBar";
import { useCarFilterSelector } from "../../redux/store";
import { CAR_FILTER_ID } from "../../utils/constants/filterConstants";
import useFixedFilterBar from "../../../../shared-logic/features/filters/hooks/useFixedFilterBar";
import { FILTER_BAR_HEIGHT } from "./active-filters/top-bar/styles/StaticFilterBarStyles";
import { EnvironmentEnum, getRetailerDebugToggleLink } from "../../../../common-deprecated/utils";
import useCarFilter from "../../hooks/useCarFilter";
import PageDisclaimers from "./common/PageDisclaimers";
import { aemToyotaCarFilterModalMap } from "./modals/modals";
import { MODAL_FILTER_CONFIG_HELPER } from "../../utils/modalConstants";
import GlobalStyle from "../../../../common-deprecated/styles/v2/common/GlobalStyle";
import DualCurrencyDisclaimer from "../../../../common-deprecated/components/DualCurrencyDisclaimer";
import { getUscExtraQueryBuilderOptions } from "../../../shared-logic/utils/uscUtils";
import TopFiltersContainer from "./active-filters/top-filters/TopFiltersContainer";
import { UscContext } from "../../../shared-logic/types/UscCommonTypes";
import * as Container from "../../../../common-deprecated/styles/v2/toyota/globals/Container";
import Background from "../../../../common-deprecated/styles/v2/toyota/utilities/Background";
import * as Styles from "./styles/CarFilterStyles";
import PageFinanceDisclaimer from "../../../shared-components/toyota/page-finance-disclaimer/PageFinanceDisclaimer";
import { MODAL_VEHICLE_FOR_SALE_ID } from "../../../shared-logic/utils/modalConstants";
import MobileStaticFilterBar from "./active-filters/top-bar/MobileStaticFilterBar";

const UsedCompareV2 = loadable(async () => {
    const component = await import(
        /* webpackChunkName: "integrated-compare-used-aem-toyota" */
        "../../../compare/components/IntegratedUsedCompareToyota"
    );
    return component.default;
});

const CarFilter = (): JSX.Element => {
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const hideTopFilters = useCarFilterSelector((state) =>
        currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsHideTopFilters
            : state.carFilterSettings.stockCarsHideTopFilters,
    );
    const { noContainerSpacing } = commonSettings.layoutOptions;

    const additionalModals: AdditionalModalsType = [
        {
            key: "i",
            settings: {
                type: MODAL_VEHICLE_FOR_SALE_ID,
                detailPageUrl:
                    currentFilter === UscContext.Used
                        ? carFilterSettings.urlUsedCarsDetailPage
                        : carFilterSettings.urlStockCarsDetailPage,
                landingPageUrl:
                    currentFilter === UscContext.Used
                        ? carFilterSettings.urlUsedCarsLandingPage
                        : carFilterSettings.urlStockCarsLandingPage,
            },
        },
        ...(commonSettings.environment !== EnvironmentEnum.Production
            ? [{ key: "f", settings: { type: MODAL_FILTER_CONFIG_HELPER } }]
            : []),
    ];

    const extraQueryBuilderOptions = getUscExtraQueryBuilderOptions(commonSettings);
    useStatus(commonSettings, "Car Filter", [getRetailerDebugToggleLink()], additionalModals, extraQueryBuilderOptions);

    const resultsContainerRef = useRef<HTMLDivElement>(null);
    const isFixed = useFixedFilterBar(resultsContainerRef, FILTER_BAR_HEIGHT);

    useCarFilter();
    useModalMap(aemToyotaCarFilterModalMap);

    return (
        // This id should always be on top as this is used by GFI to navigate/scroll to the component.
        <div id={CAR_FILTER_ID}>
            <GlobalStyle />
            {/* If compare is ever enabled for stock cars, make sure to also re-enable compare data fetch. */}
            {currentFilter !== UscContext.Stock && <UsedCompareV2 />}
            {!hideTopFilters && (
                <Background backgroundColor="grey2">
                    <TopFiltersContainer />
                </Background>
            )}

            <PageFinanceDisclaimer maxWidth={1440} />

            <Background backgroundColor="grey1">
                <StaticFilterBar isFixed={isFixed} />
                <MobileStaticFilterBar />

                <Container.default
                    maxWidth="1600px"
                    px={noContainerSpacing ? "0px" : { _: "24px !important", xxxl: "0 !important" }}
                >
                    <DualCurrencyDisclaimer />
                </Container.default>

                <Styles.Container noContainerSpacing={noContainerSpacing}>
                    <CarResultsContainer />
                </Styles.Container>
            </Background>
            <PageDisclaimers />
        </div>
    );
};

export default CarFilter;
