import styled from "styled-components";
import { getDirection } from "../../../themes/common";

const Label = styled.label`
    display: block;
    width: 100%;
    margin: 20px 0;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 1.6rem;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    line-height: 2.4rem;
    text-align: ${getDirection("left")};
    color: ${({ theme }) => theme.color.dark};
`;

export default Label;
