import { createLogic } from "redux-logic";
import { CarFilterLogicType } from "../store";
import {
    CAR_FILTER_ADD_SAVED_CAR,
    CAR_FILTER_REMOVE_SAVED_CAR,
    CarFilterAddSavedCar,
    CarFilterRemoveSavedCar,
} from "../actions/CarFiltersActions";
import { setSavedCars, SupportedSaveContexts } from "../../utils/localStorage";

const updateLocalStorageLogic = (createLogic as CarFilterLogicType<CarFilterAddSavedCar | CarFilterRemoveSavedCar>)({
    type: [CAR_FILTER_ADD_SAVED_CAR, CAR_FILTER_REMOVE_SAVED_CAR],
    process({ getState }, dispatch, done) {
        // Whenever a car is added/removed, sync this to localStorage
        const { carFilters, commonSettings } = getState();
        const { country, language } = commonSettings;
        const { currentFilter, savedCars } = carFilters;
        setSavedCars({ context: currentFilter, country, language }, savedCars[currentFilter as SupportedSaveContexts]);
        done();
    },
});

export default [updateLocalStorageLogic];
