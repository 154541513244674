import { useEffect, useState } from "react";
import Debug from "./Debug";
import { CoordinateType } from "./utils/dealerConstants";
import {
    DynamicMapOptionsType,
    DynamicMapReturnType,
    StaticMapOptionsType,
    StaticMapReturnType,
    GeocoderOptionsType,
    GeocoderReturnType,
    AutoCompleteOptionsType,
    AutoCompleteReturnType,
    FormattedMapboxResultType,
    MapboxResultType,
    FeatureType,
    MapCoordinateType,
} from "./types/ToyotaMapTypes";

// Documentation: https://map.toyota-europe.com/documentation/index.html
export type MapWrapperType = {
    DynamicMap: {
        new (container: HTMLElement | null, options: Partial<DynamicMapOptionsType>): DynamicMapReturnType;
    };
    StaticMap: {
        new (container: HTMLElement | null, options: Partial<StaticMapOptionsType>): StaticMapReturnType;
    };
    geocoder: {
        new (options: Partial<GeocoderOptionsType>): GeocoderReturnType;
    };
    addAutoComplete: (options: Partial<AutoCompleteOptionsType>) => AutoCompleteReturnType;
};

/**
 * Toyota map wrapper.
 * DISCLAIMER: when using this in React components, use the useToyotaMap hook as that will take care of initializing the map properly.
 */
class ToyotaMap {
    mapWrapper: MapWrapperType | null;

    // Initializing is used to make sure that the init logic is only executed once even though this class can be called multiple times during init.
    initializing: boolean;

    initialized: boolean;

    constructor() {
        this.initialized = false;
        this.initializing = false;
        this.mapWrapper = null;
    }

    formatMapboxFeature(feature: FeatureType): FormattedMapboxResultType {
        const result: FormattedMapboxResultType = {
            id: feature.id,
            name: feature.place_name,
            coords: { lng: feature.center[0], lat: feature.center[1] },
        };

        if (feature.bbox) {
            result.bounds = [
                { lng: feature.bbox[0], lat: feature.bbox[1] },
                { lng: feature.bbox[2], lat: feature.bbox[3] },
            ];
        }

        return result;
    }

    /**
     * Initialize the Toyota map wrapper.
     * This will either initialize the map wrapper (download mapbox wrapper and append CSS to DOM), or return the cached object.
     * This function should only be called once in the component lifecycle, any other calls to retrieve the wrapper can go through getWrapper() below.
     */
    async getWrapper(): Promise<NonNullable<MapWrapperType>> {
        if (!this.mapWrapper) {
            return new Promise((resolve) => {
                this.initializing = true;

                // Get the minified JS from CDN
                // mapsWrapper should be available as a require.js alias, configured by T1. (See OR-3070)
                window.require(["mapsWrapper"], (map) => {
                    this.mapWrapper = map;
                    resolve(map);
                });
            });
        } else {
            return this.mapWrapper;
        }
    }

    /**
     * Get a name its coordinates for a given set of coordinates.
     */
    async reverseGeocode(
        location: CoordinateType,
        mapboxToken: string,
    ): Promise<{ name: string; coords: MapCoordinateType } | null> {
        // Reverse geocoding through the Toyota wrapper doesn't seem to work as it doesnt propagate the token...
        // Create the URL ourselves as it is fairly straightforward.
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location.lon},${location.lat}.json?access_token=${mapboxToken}&limit=1`;
        try {
            const request = await fetch(url);
            const result = await request.json();
            if (result.features && result.features[0]) {
                return this.formatMapboxFeature(result.features[0]);
            }
        } catch (ex) {
            Debug.error("Failed to reverse geocode", ex);
        }
        return null;
    }

    async geocode<T extends boolean>(
        query: string,
        mapboxToken: string,
        country: string,
        location?: CoordinateType | null,
        formatResults?: T,
    ): Promise<T extends true ? FormattedMapboxResultType[] : MapboxResultType[]> {
        // Note: Removing "limit" from the url fixed a 403 issue... If request ever fails again, try to use the Toyota Mapbox geocode (but that doesn't support adding proximity)
        let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${mapboxToken}&country=${country}`;

        if (location) url += `&proximity=${location.lon},${location.lat}`;

        try {
            const request = await fetch(url);
            const result = await request.json();

            if (!result.features) return [];

            if (formatResults) {
                return result.features.slice(0, 3).map((feature: FeatureType) => this.formatMapboxFeature(feature));
            }

            return result.features;
        } catch (ex) {
            Debug.error("Failed to geocode", ex);
        }

        return [];
    }
}

// Use this variable to use toyotaMap logic outside of React components.
export const toyotaMap = new ToyotaMap();

/**
 * Use the toyotaMap wrapper in a React component.
 * This hook will make sure the map code is only initialized once even though it can be used in multiple components.
 */
export const useToyotaMap = (): MapWrapperType | null => {
    // Use state to expose the mapWrapper as this enables components to react on the mapWrapper becoming available.
    // While this does create a mapWrapper state for each component they should all refer to the same mapWrapper instance in the class above.
    const [mapWrapper, setMapWrapper] = useState<MapWrapperType | null>(null);

    useEffect(() => {
        const initializeMap = async (): Promise<void> => {
            const mapWrapperRef = await toyotaMap.getWrapper();
            setMapWrapper(mapWrapperRef);
        };
        initializeMap();
    }, []);

    return mapWrapper;
};
