import styled from "styled-components";

export const Button = styled.button.attrs((props) => ({
    className: "cmp-btn" as string,
}))``;

export const ButtonWrapper = styled.div.attrs((props) => ({
    className: "cmp-btn" as string,
}))``;

export default Button;
