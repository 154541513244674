import React from "react";
import { ModalViewType } from "../../../../types/CommonTypes";
import { ModalVersionType } from "../../../../utils/modalConstants";
import { IntegratedDebugModal } from "./IntegratedDebugModal";

const VersionModal = (props: ModalViewType<ModalVersionType>): JSX.Element => {
    const { close, show } = props;

    return (
        <IntegratedDebugModal props={{ show, close, variant: "version" }}>
            <div>
                <header>
                    <h1>Version</h1>
                </header>
                <section>
                    <p>
                        <strong>Build: </strong> {version}
                    </p>
                    <p>
                        <strong>Last update: </strong> {typeof timestamp === "string" ? timestamp : ""}
                    </p>
                    <p>
                        <strong>Git: </strong> {branch} - {commithash}
                    </p>
                </section>
            </div>
        </IntegratedDebugModal>
    );
};

export default VersionModal;
