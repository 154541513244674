/**
 * Helper function to determine when a min/max param combination is invalid. Used for min-max slider filters.
 *
 * @param min - External min value param
 * @param max - External max value param
 * @param minValue - Min value from the filter
 * @param maxValue - Max value from the filter
 */
export const minMaxValueIsInvalid = (min: number, max: number, minValue: number, maxValue: number): boolean => {
    // This is a list of all invalid combinations.
    return (
        Number.isNaN(min) ||
        Number.isNaN(max) ||
        min < minValue ||
        min >= maxValue ||
        min > max ||
        max > maxValue ||
        max < minValue ||
        max < min
    );
};

/**
 * Helper to parse encoded filter values.
 * Sometimes the query values are encoded by GFI (when component is or isn't cached?), see OR-4091
 * In addition some characters are encoded twice, using both decodeURIComponent and decodeURI fixes that, see OR-3840.
 */
export const decodeQueryValue = (input: string): string => decodeURIComponent(decodeURI(input));
