export const breakpoints = {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "1024px",
    xl: "1200px",
    xxl: "1440px",
    xxxl: "1540px",
    max: "1600px",
};

// Define the maximum width of a "container" for different screen sizes.
export const maxBreakpoints = {
    sm: "767px",
    md: "1023px",
    lg: "1199px",
    xl: "1439px",
    xxl: "1539px",
    xxxl: "1599px",
};

export const uscTheme = {
    maxBreakpoints,
    breakpoints,
} as const;
