import React from "react";
import * as Styles from "./styles/FilterPillButtonStyles";
import { IconClose } from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";

type FilterPillButtonType = {
    children: React.ReactNode;
    isPrimary?: boolean;
    onClick?: () => void;
    closeFn?: () => void;
    disabled?: boolean;
};

const FilterPillButton = ({ children, closeFn, onClick, isPrimary, disabled }: FilterPillButtonType): JSX.Element => (
    <Styles.FilterPill
        isPrimary={isPrimary}
        onClick={!disabled ? onClick : undefined}
        hasIcon={!!closeFn}
        disabled={!!disabled}
    >
        <span>{children}</span>
        {closeFn && (
            <Styles.Close size="xxs" iconColor="primaryWhite" onClick={closeFn}>
                <IconClose />
            </Styles.Close>
        )}
    </Styles.FilterPill>
);

export default FilterPillButton;
