const localeOverridesMap: Record<string, string> = {
    // original price formatting: fr-be: 144 531,00 € | nl-be: € 144.531,00
    // TFSBEL wants the format of their prices to be aligned across the two languages so,
    // we override the locale to nl-BE for fr-BE
    "fr-be": "nl-be",
    // The Icelandic locale is not supported by Intl.NumberFormat in Chromium based browsers
    // so we use the Danish locale instead as requested by the NMSC
    // see: https://bugs.chromium.org/p/chromium/issues/detail?id=956072&q=iceland&can=2
    "is-is": "da-dk",
};

/**
 * Used in context of the Intl.NumberFormat formatting method
 * 1. Returns a cross-browser supported locale, mainly to cover limited Intl locale support in Chromium browsers
 * 2. Returns a custom locale override when requested by the NMSC
 */
export const getIntlLocale = (cultureName: string): string => localeOverridesMap[cultureName] || cultureName;
