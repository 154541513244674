import React from "react";
import { useCarFilterLabel } from "../../../../../utils/constants/labels";
import MCFilterView from "../../multiple-choice/MCFilterView";
import * as Styles from "./styles/FilteredListStyles";
import useFilteredModelList from "../../../../../hooks/filters/useFilteredModelList";

type FilteredModelListType = { query: string };

const FilteredModelList = ({ query }: FilteredModelListType): JSX.Element | null => {
    const modelsLabel = useCarFilterLabel("carFilterModels");
    const { models, selectValue } = useFilteredModelList(query);

    if ((query && models.length === 0) || !query) return null;

    return (
        <Styles.Wrapper>
            <Styles.Label>{modelsLabel}</Styles.Label>
            <MCFilterView id="filteredModelList" values={models} columnWidth={1} selectValue={selectValue} />
        </Styles.Wrapper>
    );
};

export default FilteredModelList;
