import React, { useRef, useState } from "react";
import { CarFilterIdType, UsedCarFilterId, StockCarFilterId } from "../../../../shared-logic/types/UscCommonTypes";
import * as FilterStyles from "../../../../../common-deprecated/features/filters/styles/TopFiltersStyles";
import { useCloseOnOutsideClick } from "../../../../../common-deprecated/hooks";
import { PosType } from "../../../../../common-deprecated/types/CommonTypes";
import { getMousePosition } from "../../../../../shared-logic/features/filters/utils/helpers";
import useFilterLabels from "../../../hooks/active-filters/useFilterLabels";
import { useCarFilterSelector } from "../../../redux/store";
import FilterWrapper from "../filters/FilterWrapper";
import * as Combobox from "../../../../../common-deprecated/styles/v2/toyota/globals/Combobox";
import Icon from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";

type TopFilterSectionWrapperType = {
    filterId: CarFilterIdType;
    showFilter: (pos: PosType) => void;
};

/**
 * Wrapper for the TopFilterSection component used in CarFilter.
 */
const TopFilterSectionWrapper = ({ filterId, showFilter }: TopFilterSectionWrapperType): JSX.Element => {
    const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const filter = useCarFilterSelector((state) => state.carFilters[filterId]);
    const isLocationFilter = [UsedCarFilterId.Location, StockCarFilterId.Location].includes(filterId);

    const { midLabel } = useFilterLabels(filterId);

    useCloseOnOutsideClick(wrapperRef, () => setSelectIsOpen(false));

    return (
        <FilterStyles.Filter data-testid="top-filter-section-wrapper">
            <FilterStyles.Label isSmall htmlFor={`combobox-label-${filterId}`}>
                {filter.label}
            </FilterStyles.Label>
            <Combobox.Wrapper ref={wrapperRef}>
                <Combobox.Input
                    type="button"
                    id={`combobox-label-${filterId}`}
                    role="combobox"
                    aria-owns={`combobox-${filterId}`}
                    aria-autocomplete="list"
                    aria-expanded={selectIsOpen}
                    readOnly
                    value={midLabel.map((label) => label.text).join("")}
                    onClick={(evt) => {
                        setSelectIsOpen(!selectIsOpen);
                        showFilter(getMousePosition(evt));
                    }}
                />
                <Combobox.IconWrapper
                    aria-hidden="true"
                    isDown={selectIsOpen}
                    onClick={() => setSelectIsOpen(!selectIsOpen)}
                >
                    <Icon variant="chevronDown" />
                </Combobox.IconWrapper>
                <Combobox.Box id={`combobox-${filterId}`} noSpacing={isLocationFilter} fitContent={isLocationFilter}>
                    <FilterWrapper filterId={filterId} />
                </Combobox.Box>
            </Combobox.Wrapper>
        </FilterStyles.Filter>
    );
};

export default TopFilterSectionWrapper;
