import {
    CarFilterIdType,
    StockCarFilterId,
    UsedCarFilterId,
    UscContext,
} from "../../../shared-logic/types/UscCommonTypes";
import {
    baseMinMaxSliderFilterValue,
    CarTypeFilterEnum,
    getDefaultPriceFilter,
    defaultSentenceConfig,
    defaultValueLabelConfig,
    FilterConfigValueLabelType,
    MinMaxSliderBaseType,
    PriceFilterConfigType,
    SentenceConfigType,
} from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { getTagContent } from "../../../../common-deprecated/utils";
import { roundMinMax } from "../../../../shared-logic/features/filters/utils/formatters/formatterHelpers";
import { CarFilterSettingsType } from "../../redux/reducers/CarFilterSettingsReducer";
import { ExternalFilterSettingsType, SharedFilterConfigType } from "../../server/ExternalCarFilterSettingsType";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { MinMaxPriceAggregationType } from "../../server/used-cars/fetchUsedCarFilters";

export const getFilterConfig = (
    componentSettings: ExternalFilterSettingsType,
    type: UscContext,
    filterId: SharedFilterConfigType["filter"],
): { index: number; config: SharedFilterConfigType } | null => {
    const filterConfigs = componentSettings.filterConfigurationGroups[type];
    if (filterConfigs) {
        const index = filterConfigs.findIndex((config) => config.filter === filterId);
        if (index !== -1) return { index, config: filterConfigs[index] };
    }
    return null;
};

/**
 * Returns the disabled filters based on the disabledFilters query param
 */
export const getDisabledFilters = (commonSettings: CommonSettingsType): (CarTypeFilterEnum | CarFilterIdType)[] => {
    return (commonSettings.query.disabledFilters?.split(",") as (CarTypeFilterEnum | CarFilterIdType)[]) || [];
};

/**
 * Returns true when a filter is inside the disabledFilters query
 * Does not support carType, use getDisabledCarTypeFilters instead
 */
export const isFilterDisabled = (filterId: CarFilterIdType, commonSettings: CommonSettingsType): boolean => {
    const disabledFiltersIds = getDisabledFilters(commonSettings);
    return disabledFiltersIds.includes(filterId);
};

export const formatSentenceConfig = (
    carFilterSettings: CarFilterSettingsType,
    filterConfig: SharedFilterConfigType,
): SentenceConfigType => {
    // Match the string between the opening tag and the value tag.
    const leftLabelRegex = new RegExp("(.*)\\[VALUE]", "i").exec(filterConfig.sentenceLabel);
    const rightLabelRegex = new RegExp("\\[\\/VALUE](.*)", "i").exec(filterConfig.sentenceLabel);

    return {
        ...defaultSentenceConfig,
        leftLabel: leftLabelRegex ? leftLabelRegex[1] : "",
        rightLabel: rightLabelRegex ? rightLabelRegex[1] : "",
        valueLabel: getTagContent("VALUE", filterConfig.sentenceLabel) || "sentenceLabel",
        anyLabel: filterConfig.sentenceAnyLabel || "any",
        valueSeparator: carFilterSettings.labels.carFilterSentenceSeparator,
        finalValueSeparator: carFilterSettings.labels.carFilterSentenceFinalSeparator,
    };
};

export const formatValueLabelConfig = (filterConfig: SharedFilterConfigType): FilterConfigValueLabelType => ({
    ...defaultValueLabelConfig,
    value: filterConfig.valueLabel || "valueLabel",
    minValueText: filterConfig.minValueLabel || "minValueLabel",
    maxValueText: filterConfig.maxValueLabel || "maxValueLabel",
    minValueDesc: filterConfig.minValueLabelDescr || "",
    maxValueDesc: filterConfig.maxValueLabelDescr || "",
});

/**
 * Formats the min and max values for the price filter based
 * on the values configured in AEM.
 */
export const formatAemMinMaxValueConfig = (filterConfig: SharedFilterConfigType): MinMaxSliderBaseType => ({
    ...baseMinMaxSliderFilterValue,
    maxValue: roundMinMax(filterConfig.valueStep || 0, filterConfig.maxValue || 0),
    minValue: roundMinMax(filterConfig.valueStep || 0, filterConfig.minValue || 0),
    currentMinValue: roundMinMax(filterConfig.valueStep || 0, filterConfig.minValue || 0),
    currentMaxValue: roundMinMax(filterConfig.valueStep || 0, filterConfig.maxValue || 0),
    step: filterConfig.valueStep || 1,
    ...(filterConfig.unit && {
        unit: { value: filterConfig.unit, useAsPrefix: filterConfig.useUnitAsPrefix || false },
    }),
});

/**
 * Formats the min and max values for the price filter based
 * on the min and max values of the price aggregations.
 */
const formatDynamicMinMaxValueConfig = (
    minMaxPriceAggregations: MinMaxPriceAggregationType,
    step: number,
    configType: "cash" | "monthly",
): MinMaxSliderBaseType => ({
    ...baseMinMaxSliderFilterValue,
    maxValue: minMaxPriceAggregations[configType].maxPrice,
    minValue: minMaxPriceAggregations[configType].minPrice,
    currentMinValue: minMaxPriceAggregations[configType].minPrice,
    currentMaxValue: minMaxPriceAggregations[configType].maxPrice,
    step,
});

const getMinMaxValueConfig = (
    carFilterSettings: CarFilterSettingsType,
    type: UscContext,
    filterConfig: SharedFilterConfigType,
    configType: "cash" | "monthly",
    minMaxPriceAggregations?: MinMaxPriceAggregationType,
): MinMaxSliderBaseType => {
    const { usedCarsEnableDynamicPriceFilter, stockCarsEnableDynamicPriceFilter } = carFilterSettings;

    if (type === UscContext.Used && usedCarsEnableDynamicPriceFilter && minMaxPriceAggregations) {
        return formatDynamicMinMaxValueConfig(minMaxPriceAggregations, filterConfig.valueStep || 1, configType);
    } else if (type === UscContext.Stock && stockCarsEnableDynamicPriceFilter && minMaxPriceAggregations) {
        return formatDynamicMinMaxValueConfig(minMaxPriceAggregations, filterConfig.valueStep || 1, configType);
    } else {
        return formatAemMinMaxValueConfig(filterConfig);
    }
};

export const formatPriceConfig = (
    carFilterSettings: CarFilterSettingsType,
    commonSettings: CommonSettingsType,
    type: UscContext,
    priceConfigCash: ReturnType<typeof getFilterConfig>,
    priceConfigMonthly: ReturnType<typeof getFilterConfig>,
    minMaxPriceAggregations?: MinMaxPriceAggregationType,
): PriceFilterConfigType => {
    let filterConfig = getDefaultPriceFilter();
    if (priceConfigCash) {
        filterConfig = {
            ...filterConfig,
            show: true,
            index: priceConfigCash.index,
            label: priceConfigCash.config.label,
            sentenceConfig: formatSentenceConfig(carFilterSettings, priceConfigCash.config),
            cash: {
                ...filterConfig.cash,
                ...getMinMaxValueConfig(
                    carFilterSettings,
                    type,
                    priceConfigCash.config,
                    "cash",
                    minMaxPriceAggregations,
                ),
                valueLabelConfig: formatValueLabelConfig(priceConfigCash.config),
                show: true,
                unit: {
                    value: priceConfigCash.config.unit || "",
                    useAsPrefix: priceConfigCash.config.useUnitAsPrefix || false,
                },
            },
            disabled: isFilterDisabled(
                type === UscContext.Used ? UsedCarFilterId.Price : StockCarFilterId.Price,
                commonSettings,
            ),
        };

        // Even though the monthly config also contains sentence config and a label in Tridion, we use cash as the main reference.
        if (priceConfigMonthly) {
            filterConfig.monthly = {
                ...filterConfig.monthly,
                ...getMinMaxValueConfig(
                    carFilterSettings,
                    type,
                    priceConfigMonthly.config,
                    "monthly",
                    minMaxPriceAggregations,
                ),
                valueLabelConfig: formatValueLabelConfig(priceConfigMonthly.config),
                show: true,
                unit: {
                    value: priceConfigMonthly.config.unit || "",
                    useAsPrefix: priceConfigMonthly.config.useUnitAsPrefix || false,
                },
            };
        }
    }

    return filterConfig;
};
