import { UscContext, UscCustomerPromiseBannerType, UscVehicleSaleType } from "../../shared-logic/types/UscCommonTypes";
import { vehicleHasValidProgram, vehicleHasWarranty } from "../../shared-logic/utils/uscUtils";
import { CarFilterStateType } from "../redux/store";

export const enabledSavedCarsSelector = (state: CarFilterStateType): boolean => {
    const { carFilters, carFilterSettings } = state;

    return carFilters.currentFilter === UscContext.Used
        ? !carFilterSettings.usedCarsDisableSavedCars
        : !carFilterSettings.stockCarsDisableSavedCars;
};

export const customerPromiseBannerItemsSelector =
    (vehicleForSaleId: string, vehicleSaleType: UscVehicleSaleType) =>
    (state: CarFilterStateType): UscCustomerPromiseBannerType | null => {
        const { carFilters, carFilterSettings, carResults, commonSettings } = state;
        const { brand } = commonSettings;
        const context = carFilters.currentFilter;

        const carResult = carResults.dfCarResults.find((result) => result.result?.id === vehicleForSaleId)?.result;
        if (!carResult) return null;

        // If the vehicle is a pledged sale, regardless of the ucProgram, we return the
        // privatelySoldBannerDescriptionItems.
        if (vehicleSaleType === UscVehicleSaleType.Private) {
            return carFilterSettings.privatelySoldCustomerPromiseBanner;
        }

        // Stock cars don't have warranty. To allow NMSCs of SCLs to use the banner for other purposes
        // we show the nonUcProgramBannerDescriptionItems. This is documented in AEM.
        if (context === UscContext.Stock) {
            return carFilterSettings.nonUcProgramCustomerPromiseBanner;
        } else if (!vehicleHasWarranty(carResult)) {
            return null;
        }

        return vehicleHasValidProgram(carResult, brand)
            ? carFilterSettings.ucProgramCustomerPromiseBanner
            : carFilterSettings.nonUcProgramCustomerPromiseBanner;
    };
