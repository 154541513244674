import React, { useState } from "react";
import { ModalViewType } from "../../../../types/CommonTypes";
import { ModalReqLogType } from "../../../../utils/modalConstants";
import { IntegratedDebugModal } from "./IntegratedDebugModal";
import { useCommonSelector } from "../../../../redux/commonStore";
import { RequestLogType } from "../../../../utils/backendFetch";

const RequestLogModal = (props: ModalViewType<ModalReqLogType>): JSX.Element => {
    const { close, show } = props;

    const reqLog = useCommonSelector((state) => state.utils.requestLog);
    const [openedBody, setOpenedBody] = useState<number>(-1);
    const dedupedReqLogs = reqLog.reduce<RequestLogType[]>((logs, reqlogItem) => {
        const similarLog = logs.find(
            // adding extra safeties here to avoid crashes when badly typed fetchers fill the reqLog with null/undefined
            (log) =>
                log &&
                reqlogItem &&
                log.name === reqlogItem.name &&
                log.url === reqlogItem.url &&
                log.duration === reqlogItem.duration &&
                log.method === reqlogItem.method,
        );
        if (!similarLog) {
            // Replace all credentials with placeholders
            reqlogItem.url = reqlogItem.url.replace(/[A-Za-z0-9]{8}@[0-9a-f]{32}/, "secret@secret");
            logs.push(reqlogItem);
        }
        return logs;
    }, []);

    return (
        <IntegratedDebugModal props={{ show, close, variant: "request" }}>
            <header>
                <h1>Request log</h1>
            </header>
            <section>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>URL</th>
                            <th>Timing</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dedupedReqLogs.map((log, index) => {
                            let formattedDate = "";
                            let completeDate = "";
                            if (log.timestamp) {
                                const date = new Date(log.timestamp);
                                formattedDate = date.toTimeString();
                                completeDate = date.toString();
                            }

                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <tr key={index}>
                                    <td
                                        onClick={() => {
                                            setOpenedBody(openedBody === index ? -1 : index);
                                        }}
                                    >
                                        {log.name}
                                        {log.method === "POST" && <code>POST{log.body && "*"}</code>}
                                    </td>
                                    <td>
                                        <a href={log.url} target="_blank" rel="noopener noreferrer">
                                            {log.url}
                                        </a>
                                        {openedBody === index && log.body && (
                                            <code>
                                                <>{log.body}</>
                                            </code>
                                        )}
                                    </td>
                                    <td title={completeDate}>
                                        ⏱:&nbsp;{log.duration ?? "?"}ms&nbsp;-&nbsp;{formattedDate}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </section>
        </IntegratedDebugModal>
    );
};

export default RequestLogModal;
