import styled from "styled-components";
import Button from "../../../../../../common-deprecated/styles/toyota/Button";

export const Wrapper = styled.div`
    width: 100%;
    padding: 0;
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 8px;
    cursor: pointer;
    ${Button} {
        svg {
            color: ${({ theme }) => theme.color.primary};
        }
    }
`;

export const Body = styled.div`
    padding: 25px 0;
`;
