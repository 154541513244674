import { shallowEqual, useDispatch } from "react-redux";
import { CarFilterDispatchType, useCarFilterSelector } from "../../redux/store";
import { biasedSort, brandFilterValuesSelector, modelFilterValuesSelector } from "../../utils/filters";
import useFuseFilter from "../../../../common-deprecated/hooks/useFuseFilter";
import { CarTypeFilterModelType } from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { parseModelList } from "../../../../shared-logic/features/filters/utils/filterLabelUtils";
import Debug from "../../../../common-deprecated/Debug";
import { useCommonSelector } from "../../../../common-deprecated/redux/commonStore";
import { setSelectMultiFilter } from "../../redux/actions/CarFiltersActions";
import { StockCarFilterId, UsedCarFilterId, UscContext } from "../../../shared-logic/types/UscCommonTypes";
import { sortMultipleChoiceValues } from "../../../../shared-logic/features/filters/utils/filters";

type UseFilteredModelList = {
    models: CarTypeFilterModelType[];
    selectValue: (valueId: string, selected: boolean) => void;
};

/**
 * Used in FilteredModelList.
 */
const useFilteredModelList = (query: string): UseFilteredModelList => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const brands = useCarFilterSelector(brandFilterValuesSelector, shallowEqual);
    const models = useCarFilterSelector(modelFilterValuesSelector, shallowEqual);
    const filterContext = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const currentBrand = useCommonSelector((state) => state.commonSettings.brand);
    const mcValuesSortOption = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsMultipleChoiceValueSortOption
            : state.carFilterSettings.stockCarsMultipleChoiceValueSortOption,
    );

    const modelsWithBrandSelected = models.filter((model) => {
        const brand = brands.find((brandItem) => brandItem.id === model.brandId);
        return brand?.selected;
    });

    const filteredModelIds = useFuseFilter(query, modelsWithBrandSelected);
    const filteredModels = models
        .filter((model) => {
            const brand = brands.find((brandItem) => brandItem.id === model.brandId);

            // This should not happen as backend should take care of this, but add this as a failsafe to avoid crashes.
            if (!brand) {
                Debug.error(`Brand with id ${model.brandId as string} not found. Model: ${model.label}`);
                return false;
            }

            // True if the item is available.
            const isFiltered = filteredModelIds.includes(model.id);

            // OR-3014 - Support "Toyota Yaris" query also returning "Yaris" models.
            // This is a workaround where we specifically search for the model label as well.
            // This is required because the search logic doesn't think "Toyota Yaris" matches "Yaris" as the string is too different.
            // We also don't want to index "Toyota Yaris" by default as that would result in a "Toyota" query showing all toyota models instead of just the brand.
            const parsedQuery = query.toLowerCase();
            const brandModelMatch =
                parsedQuery.includes(model.label.toLowerCase()) && parsedQuery.includes(brand.label.toLowerCase());

            return isFiltered || brandModelMatch;
        })
        .sort((a, b) => {
            // Sort to make sure the models have the same order as the returned ids/
            const aIndex = filteredModelIds.findIndex((id) => a.id === id);
            const bIndex = filteredModelIds.findIndex((id) => b.id === id);

            return aIndex - bIndex;
        });

    const renderedModels = biasedSort(filteredModels, currentBrand, "brandId")
        .map((filteredModel) => {
            const foundBrand = brands.find((brand) => brand.id === filteredModel.brandId);
            return { ...filteredModel, label: `${foundBrand?.label || ""} ${filteredModel.label}` };
        })
        .filter(Boolean) as CarTypeFilterModelType[];

    const parsedModelList = parseModelList(renderedModels);

    const selectValue = (valueId: string, selected: boolean): void => {
        dispatch(
            setSelectMultiFilter(
                valueId.split(","),
                selected,
                filterContext === UscContext.Used ? UsedCarFilterId.Model : StockCarFilterId.Model,
            ),
        );
    };

    return {
        models: sortMultipleChoiceValues(parsedModelList, mcValuesSortOption),
        selectValue,
    };
};

export default useFilteredModelList;
