import React from "react";
import * as Styles from "./style/CarResultPriceStyles";
import { formatUscCashPrice, UscCashPriceType } from "../../../../../shared-logic/utils/uscPriceUtils";
import { useCommonLabel } from "../../../../../../common-deprecated/utils/commonLabels";
import DisclaimerIconWrapper from "../../common/DisclaimerIconWrapper";
import { CarFilterDisclaimerType } from "../../../../utils/disclaimerUtils";
import { useCommonSelector } from "../../../../../../common-deprecated/redux/commonStore";

type CarResultCashPriceType = {
    vehicleForSaleId: string;
    cashPrice: UscCashPriceType;
};

const CarResultCashPrice = (props: CarResultCashPriceType): JSX.Element | null => {
    const { cashPrice, vehicleForSaleId } = props;
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    const [exVatLabel, inclVatLabel] = useCommonLabel(["uscExclVatCashPriceFormat", "uscInclVatCashPriceFormat"]);

    const {
        formattedDiscount: { primaryPrice: primaryDiscountPrice, secondaryPrice: secondaryDiscountPrice },
        formattedCash: { primaryPrice, secondaryPrice },
        isPromotion,
    } = formatUscCashPrice(commonSettings, cashPrice, exVatLabel, inclVatLabel);

    return (
        <>
            {isPromotion && (
                <Styles.CashDiscount>
                    <span dangerouslySetInnerHTML={{ __html: primaryPrice }} />
                </Styles.CashDiscount>
            )}
            <Styles.Cash>
                {isPromotion ? (
                    <Styles.PriceCollection>
                        <span dangerouslySetInnerHTML={{ __html: primaryDiscountPrice }} />
                        {secondaryDiscountPrice && (
                            <span dangerouslySetInnerHTML={{ __html: secondaryDiscountPrice }} />
                        )}
                    </Styles.PriceCollection>
                ) : (
                    <Styles.PriceCollection>
                        <span dangerouslySetInnerHTML={{ __html: primaryPrice }} />
                        {secondaryPrice && <span dangerouslySetInnerHTML={{ __html: secondaryPrice }} />}
                    </Styles.PriceCollection>
                )}

                <DisclaimerIconWrapper vehicleForSaleId={vehicleForSaleId} type={CarFilterDisclaimerType.Cash} />
            </Styles.Cash>
        </>
    );
};

export default CarResultCashPrice;
