import styled from "styled-components";

export const IconAnimatedToggleArrowStyle = styled.span<{ isDown: boolean }>`
    position: relative;
    width: 20px;
    height: 20px;
    svg {
        transform: ${({ isDown }) => (isDown ? "none" : "rotate(180deg)")};
        transition: transform 0.3s;
    }
`;
