import React, { useRef, useState } from "react";
import { createPortal } from "react-dom";
import * as DOMUtils from "history/DOMUtils";
import { IconInfo } from "../../../styles/v2/toyota/globals/Icon";
import * as Styles from "../../../styles/v2/toyota/components/Tooltip";
import PopoverTooltip from "./PopoverTooltip";
import { useShouldShowModal } from "../../../hooks/useShouldShowModal";
import { ColorType } from "../../../styles/v2/toyota/theme/toyotaTheme";
import { IconWrapper } from "../../../styles/v2/toyota/globals/IconWrapper";
import SrOnly from "../../../styles/v2/toyota/utilities/ScreenreaderOnly";
import useClientSideRender from "../../../hooks/useClientSideRender";

export type PopoverType = {
    content?: string;
    useHTML?: boolean;
    showOnClick?: boolean;
    children?: React.ReactNode;
    iconColor?: ColorType;
    // Can be used to calculate the alignment of the tooltip at a later time (Defaults to true)
    // When a Popover is used in a modal you should set this variable to false and update it to true when the transition of the modal is complete
    initializeTooltipAlignment?: boolean;
    zIndex?: number; // A custom zIndex is needed in some occasions like in the grade-selector
    forceShowModal?: boolean; // When a tooltip is shown inside a scrollable modal, the position isn't recalculated correctly, and then we will show always a modal
};

/**
 * Render an info icon with a tooltip. Show a modal if there isn't enough screen space for the tooltip.
 */
const Popover = (props: PopoverType): JSX.Element => {
    const { content, useHTML, children, iconColor, initializeTooltipAlignment = true, forceShowModal, zIndex } = props;
    let { showOnClick } = props;
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const isClient = useClientSideRender();
    const iconRef = useRef<HTMLButtonElement>(null);
    const tooltipRef = useRef<HTMLElement>(null);

    // If a modal needs to be rendered the trigger for the modal should always be onClick and not hover.
    // This is why useShouldShowModal is also used here as this behaviour needs to be determined here while the other logic happens in PopoverTooltip
    const { modalVisible } = useShouldShowModal(tooltipRef, showOnClick);
    if (modalVisible) showOnClick = true;

    const onClose = (): void => setShowTooltip(false);

    // Necessary to fix hydration errors
    if (!isClient) {
        return <div />;
    }

    return (
        <Styles.TooltipWrapper onMouseLeave={showOnClick ? undefined : onClose} data-testid="common-popover">
            <Styles.TooltipButton
                ref={iconRef}
                onClick={(event) => {
                    event.stopPropagation();
                    if (showOnClick) setShowTooltip(!showTooltip);
                }}
                onFocus={(event) => {
                    event.stopPropagation();
                    if (!showOnClick) setShowTooltip(true);
                }}
                onBlur={(event) => {
                    event.stopPropagation();
                    if (!showOnClick) setShowTooltip(false);
                }}
                onMouseEnter={() => {
                    if (!showOnClick) setShowTooltip(true);
                }}
            >
                <IconWrapper iconColor={iconColor} size="lg" aria-hidden="true">
                    <IconInfo />
                </IconWrapper>
                {content && <SrOnly dangerouslySetInnerHTML={{ __html: content }} />}
                {showTooltip && <Styles.HitBoxButtonSpacer />}
            </Styles.TooltipButton>
            {DOMUtils.canUseDOM &&
                createPortal(
                    <PopoverTooltip
                        hide={!showTooltip}
                        ref={tooltipRef}
                        content={content}
                        useHTML={useHTML}
                        openedOnHover={!showOnClick}
                        onClose={onClose}
                        iconRef={iconRef}
                        initializeTooltipAlignment={initializeTooltipAlignment}
                        zIndex={zIndex}
                        forceShowModal={forceShowModal}
                    >
                        {children}
                    </PopoverTooltip>,
                    document.body,
                )}
        </Styles.TooltipWrapper>
    );
};

export default Popover;
