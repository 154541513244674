/**
 * In usc there is currently a split between v1 and v2.
 * In used-stock-cars and used-stock-cars-form this is split up by folders
 * In similar cars (car-filter) the changes are limited, so we decided to rename some files with a v2 prefix
 * In some cases we want to pass the version to some components/utils for some logic differences
 * Make sure to rethink this implementation if it would become to complex
 */
export enum UscVersion {
    v1 = "v1",
    v2 = "v2",
}
