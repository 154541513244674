import styled from "styled-components";
import {
    space,
    SpaceProps,
    flexbox,
    FlexboxProps,
    compose,
    layout,
    typography,
    LayoutProps,
    TypographyProps,
    position,
    PositionProps,
    display,
    DisplayProps,
} from "styled-system";

const Flex = styled.div<PositionProps & FlexboxProps>`
    display: flex;
    ${position};
    ${flexbox};
`;

export const Row = styled(Flex)<SpaceProps & LayoutProps & FlexboxProps>`
    flex: 1 1 auto;
    flex-wrap: wrap;
    margin: 0 -${({ theme }) => theme.spacer}px;
    ${space};
    ${layout};
    ${flexbox};
`;

type StyledSystemTypes = LayoutProps & SpaceProps & TypographyProps & FlexboxProps & PositionProps & DisplayProps;

const styleProps = compose(layout, space, typography, flexbox, position, display);
export const Col = styled.div<StyledSystemTypes>`
    padding: 0 ${({ theme }) => theme.spacer}px;
    ${styleProps};
`;

export default Flex;
