import { DealerResultType } from "../../../../common-deprecated/utils/dealerConstants";

export enum SimilarCarFilterId {
    Model = "model",
    Brand = "brand",
    FuelType = "fuelType",
    Category = "category",
    Transmission = "transmission",
    Location = "location",
    Price = "price",
    Mileage = "mileage",
    Year = "year",
}

export type SimilarCarDataType = {
    modelId: string;
    brandId: string;
    fuelTypeId: string;
    transmissionId: string;
    dealer: DealerResultType | null;
    price: number;
    mileage: number | null; // Some cars dont have a year or mileage. Use null explicitly as this simplifies checking if we actually need to use the value
    year: number | null;
};
