import {
    defaultMinMaxSliderFilter,
    defaultMultipleChoiceFilter,
    getDefaultPriceFilter,
    defaultSentenceConfig,
    MinMaxSliderFilterConfigType,
    MultipleChoiceFilterConfigType,
    PriceFilterConfigType,
    SentenceConfigType,
    CarTypeFilterModelType,
} from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import {
    CAR_FILTER_ADD_SAVED_CAR,
    CAR_FILTER_INIT,
    CAR_FILTER_REMOVE_SAVED_CAR,
    CAR_FILTER_RESET_LOCATION,
    CAR_FILTER_RESET_MULTI,
    CAR_FILTER_RESET_PRICE,
    CAR_FILTER_RESET_SLIDER,
    CAR_FILTER_SELECT_MULTI,
    CAR_FILTER_SET_ACTIVE_FILTERS,
    CAR_FILTER_SET_COLOUR_BI_TONE,
    CAR_FILTER_SET_CURRENT_FILTER,
    CAR_FILTER_SET_DEALER,
    CAR_FILTER_SET_DEALER_CACHE,
    CAR_FILTER_SET_DEALER_GROUP,
    CAR_FILTER_SET_DEALER_HOLDING,
    CAR_FILTER_SET_INITIALIZED,
    CAR_FILTER_SET_LOCATION,
    CAR_FILTER_SET_LOCATION_RANGE,
    CAR_FILTER_SET_NO_CARS_FOUND,
    CAR_FILTER_SET_PAGE,
    CAR_FILTER_SET_PRICE,
    CAR_FILTER_SET_PRICE_ACTIVE,
    CAR_FILTER_SET_SAVED_CARS,
    CAR_FILTER_SET_SIMILAR_CAR,
    CAR_FILTER_SET_SIMILAR_CAR_ATTEMPT,
    CAR_FILTER_SET_SIMILAR_CAR_ID,
    CAR_FILTER_SET_SLIDER_VALUE,
    CAR_FILTER_SET_TOTAL_PAGE_COUNT,
    CAR_FILTER_SET_USER_COORDS,
    CAR_FILTER_SET_ZERO_MILEAGE,
    CAR_FILTER_SHOW_MORE,
    CAR_FILTER_SHOW_SAVED_CARS,
    CAR_FILTER_UPDATE_MULTI_COUNT,
    CarFiltersActionsType,
} from "../actions/CarFiltersActions";
import {
    aggregationFilterIds,
    ColourFilterConfigType,
    DEFAULT_RESULT_COUNT,
    defaultColourFilter,
    defaultLocationFilter,
    defaultMileageFilterConfig,
    LocationFilterType,
    MileageFilterConfigType,
} from "../../utils/constants/filterConstants";
import {
    CarFilterIdType,
    StockCarFilterId,
    UsedCarFilterId,
    UscContext,
} from "../../../shared-logic/types/UscCommonTypes";
import {
    applyMultiValueResultCount,
    resetMultiValue,
    resetPriceFilter,
    resetSlider,
    selectMultiValue,
    setPrice,
    setPriceActive,
    setSliderValue,
} from "../../../../shared-logic/features/filters/utils/storeHelpers";
import { SimilarCarDataType } from "../../utils/constants/similarCarConstants";

export type CarFiltersReducerType = {
    initialized: boolean;
    initializedFilters: UscContext[];
    currentFilter: UscContext;
    activeFilters: CarFilterIdType[];
    showMoreFilters: boolean;
    more: SentenceConfigType;
    savedCars: {
        showOnlySavedCars: boolean;
        [UscContext.Used]: string[];
        [UscContext.Stock]: string[];
    };
    similarCar: {
        isActive: boolean; // isActive implies the current carFilter context is used as a similar car results component.
        vehicleForSaleId: string;
        carData: SimilarCarDataType | null;
        noCarsFound: boolean;
        fallbackAttempts: number;
    };
    pagination: {
        page: number; // Starts at 1.
        pageSize: number;
        totalPages: number;
    };

    // Used car filters.
    [UsedCarFilterId.Equipment]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.Seats]: MinMaxSliderFilterConfigType;
    [UsedCarFilterId.Doors]: MinMaxSliderFilterConfigType;
    [UsedCarFilterId.PowerOutput]: MinMaxSliderFilterConfigType;
    [UsedCarFilterId.Year]: MinMaxSliderFilterConfigType;
    [UsedCarFilterId.Transmission]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.Colour]: ColourFilterConfigType;
    [UsedCarFilterId.EcoLabel]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.Warranty]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.License]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.Mileage]: MileageFilterConfigType;
    [UsedCarFilterId.Location]: LocationFilterType;
    [UsedCarFilterId.Price]: PriceFilterConfigType;
    [UsedCarFilterId.VehicleStatus]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.EfficiencyClass]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.Deliverable]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.ModelYear]: MinMaxSliderFilterConfigType;
    [UsedCarFilterId.Brand]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.Model]: MultipleChoiceFilterConfigType<CarTypeFilterModelType>;
    [UsedCarFilterId.Category]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.FuelType]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.BodyStyle]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.DriveType]: MultipleChoiceFilterConfigType;
    [UsedCarFilterId.Ownership]: MultipleChoiceFilterConfigType;

    // Stock car filters.
    [StockCarFilterId.Equipment]: MultipleChoiceFilterConfigType;
    [StockCarFilterId.Seats]: MinMaxSliderFilterConfigType;
    [StockCarFilterId.Doors]: MinMaxSliderFilterConfigType;
    [StockCarFilterId.PowerOutput]: MinMaxSliderFilterConfigType;
    [StockCarFilterId.Transmission]: MultipleChoiceFilterConfigType;
    [StockCarFilterId.Colour]: ColourFilterConfigType;
    [StockCarFilterId.Location]: LocationFilterType;
    [StockCarFilterId.Price]: PriceFilterConfigType;
    [StockCarFilterId.VehicleStatus]: MultipleChoiceFilterConfigType;
    [StockCarFilterId.Brand]: MultipleChoiceFilterConfigType;
    [StockCarFilterId.Model]: MultipleChoiceFilterConfigType<CarTypeFilterModelType>;
    [StockCarFilterId.Category]: MultipleChoiceFilterConfigType;
    [StockCarFilterId.FuelType]: MultipleChoiceFilterConfigType;
    [StockCarFilterId.BodyStyle]: MultipleChoiceFilterConfigType;
    [StockCarFilterId.DriveType]: MultipleChoiceFilterConfigType;
    [StockCarFilterId.Mileage]: MinMaxSliderFilterConfigType;
};

export const carFiltersReducerInitialState: CarFiltersReducerType = {
    initialized: false,
    initializedFilters: [],
    showMoreFilters: false,
    currentFilter: UscContext.Used,
    activeFilters: [],
    savedCars: {
        showOnlySavedCars: false,
        [UscContext.Used]: [],
        [UscContext.Stock]: [],
    },
    similarCar: { isActive: false, vehicleForSaleId: "", carData: null, noCarsFound: false, fallbackAttempts: 0 },
    pagination: {
        page: 1,
        pageSize: DEFAULT_RESULT_COUNT, // Not used yet, keeping it for now if we ever want to let user choose result count per page.
        totalPages: 0,
    },

    // Used car filters
    more: defaultSentenceConfig,
    [UsedCarFilterId.Equipment]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.Seats]: defaultMinMaxSliderFilter,
    [UsedCarFilterId.Doors]: defaultMinMaxSliderFilter,
    [UsedCarFilterId.PowerOutput]: defaultMinMaxSliderFilter,
    [UsedCarFilterId.Year]: defaultMinMaxSliderFilter,
    [UsedCarFilterId.Transmission]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.Colour]: defaultColourFilter,
    [UsedCarFilterId.EcoLabel]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.Warranty]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.License]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.Mileage]: defaultMileageFilterConfig,
    [UsedCarFilterId.Location]: defaultLocationFilter,
    [UsedCarFilterId.Price]: getDefaultPriceFilter(),
    [UsedCarFilterId.VehicleStatus]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.EfficiencyClass]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.Deliverable]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.ModelYear]: defaultMinMaxSliderFilter,
    [UsedCarFilterId.Brand]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.Model]: defaultMultipleChoiceFilter as MultipleChoiceFilterConfigType<CarTypeFilterModelType>,
    [UsedCarFilterId.Category]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.FuelType]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.BodyStyle]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.DriveType]: defaultMultipleChoiceFilter,
    [UsedCarFilterId.Ownership]: defaultMultipleChoiceFilter,

    // Stock car filters
    [StockCarFilterId.Equipment]: defaultMultipleChoiceFilter,
    [StockCarFilterId.Seats]: defaultMinMaxSliderFilter,
    [StockCarFilterId.Doors]: defaultMinMaxSliderFilter,
    [StockCarFilterId.PowerOutput]: defaultMinMaxSliderFilter,
    [StockCarFilterId.Transmission]: defaultMultipleChoiceFilter,
    [StockCarFilterId.Colour]: defaultColourFilter,
    [StockCarFilterId.Location]: defaultLocationFilter,
    [StockCarFilterId.Price]: getDefaultPriceFilter(),
    [StockCarFilterId.VehicleStatus]: defaultMultipleChoiceFilter,
    [StockCarFilterId.Brand]: defaultMultipleChoiceFilter,
    [StockCarFilterId.Model]: defaultMultipleChoiceFilter as MultipleChoiceFilterConfigType<CarTypeFilterModelType>,
    [StockCarFilterId.Category]: defaultMultipleChoiceFilter,
    [StockCarFilterId.FuelType]: defaultMultipleChoiceFilter,
    [StockCarFilterId.BodyStyle]: defaultMultipleChoiceFilter,
    [StockCarFilterId.DriveType]: defaultMultipleChoiceFilter,
    [StockCarFilterId.Mileage]: defaultMinMaxSliderFilter,
};

const CarFiltersReducer = (
    state: CarFiltersReducerType = carFiltersReducerInitialState,
    action: CarFiltersActionsType,
): CarFiltersReducerType => {
    switch (action.type) {
        // ----------------------------------------------------------------------
        // Common actions
        // ----------------------------------------------------------------------
        case CAR_FILTER_INIT: {
            return {
                ...state,
                ...action.initState,
                initialized: true,
                initializedFilters: state.initializedFilters.concat(action.filter),
            };
        }

        case CAR_FILTER_SHOW_MORE: {
            return { ...state, showMoreFilters: action.show };
        }

        case CAR_FILTER_SET_CURRENT_FILTER: {
            return { ...state, currentFilter: action.currentFilter };
        }

        case CAR_FILTER_SET_ACTIVE_FILTERS: {
            return { ...state, activeFilters: action.activeFilters };
        }

        case CAR_FILTER_SET_INITIALIZED: {
            return { ...state, initialized: action.initialized };
        }

        case CAR_FILTER_SET_PAGE: {
            return { ...state, pagination: { ...state.pagination, page: action.page } };
        }

        case CAR_FILTER_SET_TOTAL_PAGE_COUNT: {
            return { ...state, pagination: { ...state.pagination, totalPages: action.count } };
        }

        // ----------------------------------------------------------------------
        // Similar car
        // ----------------------------------------------------------------------
        case CAR_FILTER_SET_SIMILAR_CAR_ID: {
            return { ...state, similarCar: { ...state.similarCar, isActive: true, vehicleForSaleId: action.id } };
        }

        case CAR_FILTER_SET_SIMILAR_CAR: {
            return { ...state, similarCar: { ...state.similarCar, carData: action.similarCar } };
        }

        case CAR_FILTER_SET_SIMILAR_CAR_ATTEMPT: {
            return { ...state, similarCar: { ...state.similarCar, fallbackAttempts: action.attempt } };
        }

        case CAR_FILTER_SET_NO_CARS_FOUND: {
            return { ...state, similarCar: { ...state.similarCar, noCarsFound: action.noCarsFound } };
        }

        // ----------------------------------------------------------------------
        // Saved car actions
        // ----------------------------------------------------------------------
        case CAR_FILTER_ADD_SAVED_CAR: {
            const { context, vehicleForSaleId } = action;
            return {
                ...state,
                savedCars: { ...state.savedCars, [context]: [...state.savedCars[context], vehicleForSaleId] },
            };
        }

        case CAR_FILTER_REMOVE_SAVED_CAR: {
            const { context, vehicleForSaleId } = action;
            return {
                ...state,
                savedCars: {
                    ...state.savedCars,
                    [context]: state.savedCars[context].filter((id) => id !== vehicleForSaleId),
                },
            };
        }

        case CAR_FILTER_SET_SAVED_CARS: {
            const { context, vehicleForSaleIds } = action;
            return { ...state, savedCars: { ...state.savedCars, [context]: vehicleForSaleIds } };
        }

        case CAR_FILTER_SHOW_SAVED_CARS: {
            return { ...state, savedCars: { ...state.savedCars, showOnlySavedCars: action.show } };
        }

        // ----------------------------------------------------------------------
        // Slider actions
        // ----------------------------------------------------------------------
        case CAR_FILTER_SET_SLIDER_VALUE: {
            const { filterId, valueType, value } = action;
            return { ...state, [filterId]: setSliderValue(state[filterId], valueType, value) };
        }

        case CAR_FILTER_RESET_SLIDER: {
            const { filterId } = action;
            return { ...state, [filterId]: resetSlider(state[filterId]) };
        }

        // ----------------------------------------------------------------------
        // Multiple choice actions
        // ----------------------------------------------------------------------
        case CAR_FILTER_SELECT_MULTI: {
            const { filterId, ids, selected } = action;

            return { ...state, [filterId]: selectMultiValue(state[filterId], ids, selected) };
        }

        case CAR_FILTER_RESET_MULTI: {
            const { filterId } = action;
            return { ...state, [filterId]: resetMultiValue(state[filterId]) };
        }

        case CAR_FILTER_UPDATE_MULTI_COUNT: {
            const { updates } = action;
            const newState = { ...state };

            // Set other state aggregations.
            aggregationFilterIds
                .filter((filterId) => updates[filterId])
                .forEach((filterId) => {
                    (newState[filterId] as MultipleChoiceFilterConfigType) = {
                        ...newState[filterId],
                        values: applyMultiValueResultCount(newState[filterId].values, updates[filterId]!),
                    };
                });

            return newState;
        }

        // ----------------------------------------------------------------------
        // Colour filter actions
        // ----------------------------------------------------------------------
        case CAR_FILTER_SET_COLOUR_BI_TONE: {
            return {
                ...state,
                [UsedCarFilterId.Colour]: { ...state[UsedCarFilterId.Colour], showBiTone: action.selected },
            };
        }

        // ----------------------------------------------------------------------
        // Price filter
        // ----------------------------------------------------------------------
        case CAR_FILTER_SET_PRICE: {
            const { filterId, financeOption, priceType, value } = action;
            return { ...state, [filterId]: setPrice(state[filterId], priceType, value, financeOption) };
        }

        case CAR_FILTER_RESET_PRICE: {
            const { filterId } = action;
            return { ...state, [filterId]: resetPriceFilter(state[filterId]) };
        }

        case CAR_FILTER_SET_PRICE_ACTIVE: {
            const { filterId, financeOption } = action;
            return { ...state, [filterId]: setPriceActive(state[filterId], financeOption) };
        }

        // ----------------------------------------------------------------------
        // Location filter
        // ----------------------------------------------------------------------
        case CAR_FILTER_SET_DEALER: {
            const { filterId } = action;
            return {
                ...state,
                [filterId]: {
                    ...state[filterId],
                    dealerGroup: null,
                    dealerHolding: null,
                    dealer: action.dealer,
                    range: null,
                    active: !!action.dealer,
                },
            };
        }

        case CAR_FILTER_SET_DEALER_GROUP: {
            const { filterId } = action;
            return {
                ...state,
                [filterId]: {
                    ...state[filterId],
                    dealerGroup: action.dealerGroup,
                    dealerHolding: null,
                    dealer: null,
                    range: null,
                    active: !!action.dealerGroup,
                },
            };
        }

        case CAR_FILTER_SET_DEALER_HOLDING: {
            const { filterId } = action;
            return {
                ...state,
                [filterId]: {
                    ...state[filterId],
                    dealerGroup: null,
                    dealerHolding: action.holding,
                    dealer: null,
                    range: null,
                    active: !!action.holding,
                },
            };
        }

        case CAR_FILTER_SET_LOCATION: {
            const { filterId } = action;
            const locationFilter = {
                ...state[filterId],
                range: action.location,
                dealerGroup: null,
                dealerHolding: null,
                dealer: null,
                active: !!action.location,
            };

            if (action.rangeValues) locationFilter.rangeValues = action.rangeValues;
            if (action.dealerCache) locationFilter.dealerCache = action.dealerCache;

            return { ...state, [filterId]: locationFilter };
        }

        case CAR_FILTER_SET_LOCATION_RANGE: {
            const { filterId } = action;
            if (state[filterId].range) {
                return {
                    ...state,
                    [filterId]: {
                        ...state[filterId],
                        range: { ...state[filterId].range!, range: action.range },
                    },
                };
            }
            return state;
        }

        case CAR_FILTER_RESET_LOCATION: {
            const { filterId } = action;
            return {
                ...state,
                [filterId]: {
                    ...state[filterId],
                    active: false,
                    range: null,
                    dealer: null,
                    dealerGroup: null,
                    dealerHolding: null,
                    rangeValues: state[filterId].rangeValues.map((rangeValue) => ({
                        ...rangeValue,
                        dealerCount: 0,
                    })),
                    dealerCache: [],
                },
            };
        }

        case CAR_FILTER_SET_USER_COORDS: {
            const { filterId } = action;
            return {
                ...state,
                [filterId]: {
                    ...state[filterId],
                    userCoords: action.coords,
                },
            };
        }

        case CAR_FILTER_SET_DEALER_CACHE: {
            const { filterId } = action;
            return { ...state, [filterId]: { ...state[filterId], dealerCache: action.dealerCache } };
        }

        // Mileage filter
        case CAR_FILTER_SET_ZERO_MILEAGE: {
            const { set } = action;
            return {
                ...state,
                [UsedCarFilterId.Mileage]: {
                    ...state[UsedCarFilterId.Mileage],
                    zeroMileage: set,
                    active: true,
                },
            };
        }

        default:
            return state;
    }
};

export default CarFiltersReducer;
