import React from "react";
import * as Styles from "./styles/LocationResultStyles";
import { IconMarker, IconCarSimilarCars } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";

type LocationResultType = { type: "location" | "dealer"; name: string; address?: string; onClick: () => void };

const LocationResult = (props: LocationResultType): JSX.Element => {
    const { type, name, address, onClick } = props;

    return (
        <Styles.Wrapper>
            <Styles.Button type="button" onClick={onClick}>
                <Styles.Icon>
                    {type === "location" && <IconMarker />}
                    {type === "dealer" && <IconCarSimilarCars />}
                </Styles.Icon>
                <Styles.DealerInfo>
                    {name}
                    {!!address && <Styles.Address>, {address}</Styles.Address>}
                </Styles.DealerInfo>
            </Styles.Button>
        </Styles.Wrapper>
    );
};

export default LocationResult;
