import { useEffect, useState } from "react";

const useClientSideRender = (): boolean => {
    // Keep this false by default as we need React to trigger a state change client side for proper rendering.
    const [isClientSide, setIsClientSide] = useState<boolean>(false);

    useEffect(() => {
        setIsClientSide(true);
    }, []);

    return isClientSide;
};

export default useClientSideRender;
