import React from "react";
import { IconClose, IconSearch, IconLocation } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { useCarFilterSelector } from "../../../../redux/store";
import Preloader from "../../../../../../common-deprecated/styles/v2/toyota/custom/Preloader";
import * as Flex from "../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import LocationResult from "./LocationResult";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import * as Styles from "./styles/LocationFilterStyles";
import LocationMap from "./LocationMap";
import LocationRange from "./LocationRange";
import useLocationFilter from "../../../../hooks/filters/useLocationFilter";
import * as Form from "../../../../../../common-deprecated/styles/v2/toyota/components/Form";
import { FormattedMapboxResultType } from "../../../../../../common-deprecated/types/ToyotaMapTypes";
import { DealerResultType } from "../../../../../../common-deprecated/utils/dealerConstants";
import { LocationFilterIdType } from "../../../../utils/constants/filterConstants";

type ResultComponentsPropsType<T extends FormattedMapboxResultType | DealerResultType> = {
    title: string;
    results: T[];
    selectResult: (result: T) => void;
};

const LocationResultComponents = ({
    title,
    results,
    selectResult,
}: ResultComponentsPropsType<FormattedMapboxResultType>): JSX.Element => {
    return (
        <>
            <Styles.ResultsTitleWrapper>
                <Styles.ResultsTitle>{title}</Styles.ResultsTitle>
            </Styles.ResultsTitleWrapper>
            {results.map((result) => (
                <Styles.Result key={result.id}>
                    <LocationResult type="location" name={result.name} onClick={() => selectResult(result)} />
                </Styles.Result>
            ))}
        </>
    );
};

const DealerResultComponents = ({
    title,
    results,
    selectResult,
}: ResultComponentsPropsType<DealerResultType>): JSX.Element => {
    return (
        <>
            <Styles.ResultsTitleWrapper>
                <Styles.ResultsTitle>{title}</Styles.ResultsTitle>
            </Styles.ResultsTitleWrapper>
            {results.map(
                (result) =>
                    // Type narrowing to assert that the result has an address property (= is a DealerResultType)
                    "address" in result && (
                        <Styles.Result key={result.id}>
                            <LocationResult
                                type="dealer"
                                address={`${result.address.line1} ${result.address.zip} ${result.address.city}`}
                                name={result.name}
                                onClick={() => selectResult(result)}
                            />
                        </Styles.Result>
                    ),
            )}
        </>
    );
};

type LocationFilterType = { filterId: LocationFilterIdType };
const LocationFilter = ({ filterId }: LocationFilterType): JSX.Element => {
    const locationFilter = useCarFilterSelector((state) => state.carFilters[filterId]);
    const deliverableFilter = useCarFilterSelector((state) => state.carFilters.usedCarDeliverable);
    const [placeholderLabel, locationsTitle, dealershipsTitle] = useCarFilterLabel([
        "carFilterLocationPlaceholder",
        "carFilterLocationItemsTitle",
        "carFilterLocationDealershipsTitle",
    ]);

    const {
        mapboxResults,
        dealerResults,
        searchQuery,
        loading,
        errorLabel,
        userLocationButtonClick,
        mapboxLocationClick,
        dealerLocationClick,
        onSearchInputChange,
        clearSearch,
        toggleCarDelivery,
    } = useLocationFilter(filterId);

    const selectedDealer = locationFilter.dealer;
    const selectedLocation = locationFilter.range;

    // Get placeholder label which is dynamic based on which location filter (if any) is active.
    let placeholderText = placeholderLabel;
    if (selectedDealer) placeholderText = selectedDealer.name;
    else if (selectedLocation) placeholderText = selectedLocation.name;

    const showDeliverable = deliverableFilter.show && !deliverableFilter.disabled;

    return (
        <Styles.Wrapper>
            <Styles.MapsWrapper hasFooter={showDeliverable}>
                <LocationMap filterId={filterId} />
            </Styles.MapsWrapper>
            <Flex.Col width={1} px={16} py={24}>
                <Styles.InputGroup isActive>
                    <input
                        type="text"
                        value={searchQuery}
                        placeholder={placeholderText}
                        onChange={onSearchInputChange}
                    />
                    <Styles.InputAppend>
                        {loading && <Preloader size={26} />}

                        {!loading && (selectedDealer || searchQuery || selectedLocation) && (
                            <Styles.InputAction onClick={clearSearch}>
                                <IconClose />
                            </Styles.InputAction>
                        )}

                        {!loading && !selectedDealer && !searchQuery && !selectedLocation && (
                            <Styles.InputAction>
                                <IconSearch />
                            </Styles.InputAction>
                        )}
                        <Styles.LocationButton
                            onClick={userLocationButtonClick}
                            isActive={!!selectedLocation && selectedLocation.type === "user" && !errorLabel}
                        >
                            <IconLocation />
                        </Styles.LocationButton>
                    </Styles.InputAppend>
                </Styles.InputGroup>

                <Styles.FormGroup>
                    <Styles.ResultsWrapper>
                        {dealerResults.length > 0 && (
                            <DealerResultComponents
                                title={dealershipsTitle}
                                results={dealerResults}
                                selectResult={dealerLocationClick}
                            />
                        )}
                        {mapboxResults.length > 0 && (
                            <LocationResultComponents
                                title={locationsTitle}
                                results={mapboxResults}
                                selectResult={mapboxLocationClick}
                            />
                        )}

                        {!!errorLabel && <Styles.ErrorMessage>{errorLabel}</Styles.ErrorMessage>}
                    </Styles.ResultsWrapper>
                    {!!selectedLocation && <LocationRange filterId={filterId} />}
                </Styles.FormGroup>
            </Flex.Col>
            {showDeliverable && (
                <Styles.Footer>
                    <Form.Checkbox my={0}>
                        <input
                            type="checkbox"
                            id="deliverableFilter"
                            checked={deliverableFilter.active}
                            onChange={(event) => toggleCarDelivery(event, deliverableFilter)}
                        />
                        <label htmlFor="deliverableFilter">{deliverableFilter.label}</label>
                    </Form.Checkbox>
                </Styles.Footer>
            )}
        </Styles.Wrapper>
    );
};

export default LocationFilter;
