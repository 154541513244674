import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { MultipleChoiceFilterIdType } from "../../../../utils/constants/filterConstants";
import { StockCarFilterId, UsedCarFilterId, UscContext } from "../../../../../shared-logic/types/UscCommonTypes";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../../redux/store";
import { setSelectMultiFilter } from "../../../../redux/actions/CarFiltersActions";
import MCFilterView from "./MCFilterView";
import { sortMultipleChoiceValues } from "../../../../../../shared-logic/features/filters/utils/filters";
import { getAemCarFilterTrackParamsSelector, trackAemMultipleChoiceFilter } from "../../../../utils/tracking";
import { biasedSort } from "../../../../utils/filters";
import { MultipleChoiceValueType } from "../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

/**
 * Default multiple choice filter
 */
const DefaultMCFilter = ({ filterId }: { filterId: MultipleChoiceFilterIdType }): JSX.Element => {
    const dispatch = useDispatch<CarFilterDispatchType>();

    const brand = useCarFilterSelector((state) => state.commonSettings.brand);
    const filterValues = useCarFilterSelector((state) => state.carFilters[filterId].values);
    const trackParams = useCarFilterSelector(getAemCarFilterTrackParamsSelector(filterId), shallowEqual);
    const mcValuesSortOption = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsMultipleChoiceValueSortOption
            : state.carFilterSettings.stockCarsMultipleChoiceValueSortOption,
    );

    // The values of the brand filter should always bias Toyota/Lexus to the top, regardless of the sort option
    const shouldBiasSort = filterId === UsedCarFilterId.Brand || filterId === StockCarFilterId.Brand;
    let sortedFilterValues: MultipleChoiceValueType[];
    if (shouldBiasSort) {
        sortedFilterValues = biasedSort(sortMultipleChoiceValues(filterValues, mcValuesSortOption), brand);
    } else {
        sortedFilterValues = sortMultipleChoiceValues(filterValues, mcValuesSortOption);
    }

    return (
        <MCFilterView
            id={filterId}
            columnWidth={filterId === UsedCarFilterId.License ? 1 / 2 : 1}
            values={sortedFilterValues}
            selectValue={(valueId, selected) => {
                dispatch(setSelectMultiFilter([valueId], selected, filterId));
                trackAemMultipleChoiceFilter(trackParams, filterValues, valueId);
            }}
        />
    );
};

export default DefaultMCFilter;
