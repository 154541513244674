import React from "react";
import * as Flex from "../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import * as Type from "../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import { CarFilterLabel } from "../../../utils/constants/labels";
import { UsedCarResultPromotionDisclaimerType } from "../../../utils/constants/filterRequestConstants";
import { formatDateIntl } from "../../../../shared-logic/utils/uscUtils";

type TooltipContentType = {
    promotionDisclaimer: UsedCarResultPromotionDisclaimerType;
    cultureName: string;
    // eslint fails because we use this proptype for 2 components
    // eslint-disable-next-line react/no-unused-prop-types
    lastItem?: boolean;
};

const FromUntilLabel = ({ promotionDisclaimer, cultureName }: TooltipContentType): JSX.Element | null => {
    const { effectiveTo, effectiveFrom } = promotionDisclaimer;

    const formattedEffectiveFrom = formatDateIntl(effectiveFrom, cultureName);
    const formattedEffectiveTo = formatDateIntl(effectiveTo, cultureName);

    if (effectiveTo && effectiveFrom) {
        return (
            <CarFilterLabel
                label="carFilterPromoFromUntilDate"
                params={{ from: formattedEffectiveFrom, until: formattedEffectiveTo }}
            />
        );
    } else if (effectiveTo && !effectiveFrom) {
        return <CarFilterLabel label="carFilterPromoFromDate" params={{ from: formattedEffectiveFrom }} />;
    } else if (!effectiveTo && effectiveFrom) {
        return <CarFilterLabel label="carFilterPromoUntilDate" params={{ until: formattedEffectiveTo }} />;
    }

    return null;
};

const TooltipContent = ({ lastItem, promotionDisclaimer, cultureName }: TooltipContentType): JSX.Element => {
    const { label, legalDisclaimer, effectiveTo, effectiveFrom } = promotionDisclaimer;

    return (
        <Flex.Col px={0} mb={lastItem ? 0 : 2}>
            {!!label && (
                <Type.Text as="h2" headingType="milli" styleType="headline" mb={2}>
                    {label}
                </Type.Text>
            )}
            {!!legalDisclaimer && (
                <Type.Text as="p" headingType="milli" mb={0}>
                    <span dangerouslySetInnerHTML={{ __html: legalDisclaimer }} />
                </Type.Text>
            )}
            {(!!effectiveTo || !!effectiveFrom) && (
                <Type.Text as="p" headingType="milli" mb={0}>
                    <FromUntilLabel promotionDisclaimer={promotionDisclaimer} cultureName={cultureName} />
                </Type.Text>
            )}
        </Flex.Col>
    );
};

export default TooltipContent;
