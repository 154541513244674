import { MouseEvent, MutableRefObject, SyntheticEvent, TouchEvent, useEffect, useRef, useState } from "react";
import { PosType } from "../types/CommonTypes";
import { getMousePosition, getTouchPosition } from "../../shared-logic/features/filters/utils/helpers";

type DragPropsType = {
    sliderX: number;
    setSliderX: (pos: number) => void;
    totalSliderWidth: number;
    viewportWidth: number;
    setEnableCssTransition: (enabled: boolean) => void;
};

// These constants are used for the infinite slider
// Currently constants defined here, makes it easier to make them variable in the future
export const EXTRA_ITEMS_FRONT = 4;
export const EXTRA_ITEMS_END = 4;
export const EXTRA_ITEMS = EXTRA_ITEMS_FRONT + EXTRA_ITEMS_END;

/**
 * Function used for calculating the index with the correct position and the remaining position
 * Helper function Infinite slider
 */
const getCurrentImageInfo = (
    xPosition: number,
    itemWidths: number[],
): { correctPosition: number; remaining: number; index: number } => {
    let correctPosition = 0;
    let currentPosition = xPosition;
    let index = 0;
    do {
        currentPosition += itemWidths[index];
        correctPosition -= itemWidths[index];
        index++;
    } while (currentPosition < 0);
    // Calculate if we should scroll to the previous car
    if (currentPosition >= itemWidths[index] / 2) {
        index--;
        correctPosition += itemWidths[index];
    }
    return { correctPosition, remaining: currentPosition, index };
};

/**
 * Duplicates the first and last items and add them in the end and front of the array.
 */
export const createInfiniteSliderItemsArray = <T>(originalItems: T[]): T[] => {
    const originalLength = originalItems.length;
    const extraItemsFront: T[] = [];
    const extraItemsEnd: T[] = [];
    for (let i = 0; i < EXTRA_ITEMS_FRONT; i++) {
        extraItemsFront.push(originalItems[(originalLength - i - 1 + originalLength) % originalLength]);
        extraItemsEnd.push(originalItems[i % originalLength]);
    }
    extraItemsFront.reverse();
    return [...extraItemsFront, ...originalItems, ...extraItemsEnd];
};

/**
 * Function used when the user starts dragging. Agnostic whether the event is a mouse or touch event.
 *
 * Contains all the required logic to start/stop dragging and logic related to actual dragging.
 * Also contains "elastic" logic which allows the user to drag the slider a bit further than the start/end of the slider.
 *
 * @param getPosition - Helper function to get the position of the touch/mouse event.
 * @param event - Actual mousestart/touchstart event
 * @param moveEvent - String of the move event that the function should use
 * @param endEvent - String of the end event that the function should use
 * @param dragProps - Additional properties related to dragging used to calculate/update the slider
 * @param isScrolling - React ref that will be true
 * @param itemWidth - Width of one item. Can only be used if every item has the same width. Will default to 50 px leeway if items have a variable width.
 * @param itemWidths - list of the width of every item.

 */
function startDrag<T extends Event, U extends SyntheticEvent>(
    getPosition: (event: T | U) => PosType,
    event: T | U,
    moveEvent: "mousemove" | "touchmove",
    endEvent: "mouseup" | "touchend",
    dragProps: DragPropsType,
    isScrolling: React.MutableRefObject<boolean>,
    itemWidth: number = 50,
    itemWidths: number[],
    isRTL?: boolean,
): void {
    const { sliderX, setSliderX, viewportWidth, totalSliderWidth, setEnableCssTransition } = dragProps;
    const initialPos = getPosition(event);
    let finalPos: number;
    let slideNext = false;
    setEnableCssTransition(false);
    // Move the slider.
    const moveFn = (evt: T): void => {
        window.requestAnimationFrame(() => {
            // Calculate the position delta.
            const currentPos = getPosition(evt);
            let posDiff = isRTL ? -(currentPos.x - initialPos.x) : currentPos.x - initialPos.x;

            // Don't allow to slide more then 1 image at a time.
            if (Math.abs(posDiff) > itemWidth) {
                posDiff = posDiff < 0 ? -itemWidth : itemWidth;
            }
            const newPos = sliderX + posDiff;

            // If the new position is different compared to the current slider position, update the slider.
            if (sliderX !== newPos) {
                // Update the finalPos constant.
                finalPos = newPos;
                isScrolling.current = true;

                // We want to prevent the user from dragging the slider too far, however for UX we want to allow some dragging.
                const maxDrag = itemWidth / 2;
                // easing function to "slow down" the dragging when the user goes beyond the drag limits.
                const easeOutCubic = (t: number): number => --t * t * t + 1;

                // This is the x value where the last part of the viewport is completely visible.
                // Further dragging beyond this value will be empty space.
                const maxLeftPos = -(totalSliderWidth - viewportWidth);

                if (newPos > 0) {
                    // User slided too far to the right, ease out to the max drag value.
                    const easeOutValue = Math.min(maxDrag, newPos);
                    const easePercentage = easeOutCubic(easeOutValue / maxDrag);

                    setSliderX(easePercentage * maxDrag);
                } else if (newPos < maxLeftPos) {
                    // Used slided too far to the left, ease out to max drag value.
                    const easeOutValue = Math.max(maxLeftPos - maxDrag, newPos);
                    const easePercentage = easeOutCubic(Math.abs((easeOutValue - maxLeftPos) / maxDrag));

                    setSliderX(maxLeftPos - easePercentage * maxDrag);
                } else {
                    // User slides within slider limit, update position.
                    setSliderX(newPos);
                }

                // Determine if the slider is sliding to the right or left.
                if (initialPos.x >= currentPos.x) slideNext = true;
            }
        });
    };
    window.addEventListener(moveEvent, moveFn as EventListener);

    const endFn = (): void => {
        window.removeEventListener(moveEvent, moveFn as EventListener);
        // If we have no final position this means the user did not drag, so no final animation is required.
        if (!finalPos) {
            // Reset isScrolling, this should be done onTransitionEnd in the wrapper component if the final scroll is animated.
            isScrolling.current = false;
            // Enable CSS transitions again as they are disabled on touch/mouse start.
            setEnableCssTransition(true);
            return;
        }

        window.requestAnimationFrame(() => {
            let calculatedFinalPos = finalPos;

            // Animate the slider into a correct position.
            if (itemWidths.length > 0) {
                const { correctPosition } = getCurrentImageInfo(calculatedFinalPos, itemWidths);
                calculatedFinalPos = correctPosition;
            } else if (finalPos > 0) {
                // If the final position is positive this means the slider is dragged too far to the left, reset to 0.
                calculatedFinalPos = 0;
            } else if (totalSliderWidth + finalPos < viewportWidth) {
                // If the final position is too low (the slider container will not cover the whole width of the screen)
                // the slider is dragged too far to the right.
                // Calculate the minimum finalPos so the slider still fills the container width.
                calculatedFinalPos = 0 - (totalSliderWidth - viewportWidth);
            } else if (itemWidth) {
                // Jump back to a car position. This will be used on mobile but not desktop.
                const remainder = Math.abs(calculatedFinalPos % itemWidth);
                if (remainder !== 0) {
                    // Calculate if we should scroll through to the next car or scroll back so we show the previous car in full.
                    // A coefficient is needed, determined by the direction so that the sliding is more smooth, especially on mobile.
                    // Without this, a.k.a. (itemWidth / 1), a full swipe (full image width) would be required to slide to the next / previous image.
                    // (itemWidth / 2) would result in a +/- half swipe, (itemWidth / 4 : 1) would result in even a quicker swipe, (itemWidth / 8 : 0.5) even quicker, ...
                    // Deviding by 8 (right) and 0.5 (left) seemed to result in the smoothest experience.
                    const itemWidthDeviderRight = 8;
                    const itemWidthDeviderLeft = 0.5;
                    if (remainder >= itemWidth / (slideNext ? itemWidthDeviderRight : itemWidthDeviderLeft)) {
                        calculatedFinalPos -= itemWidth - remainder;
                    } else {
                        calculatedFinalPos += remainder;
                    }
                }
            }
            // Enable the CSS easing animation.
            setEnableCssTransition(true);
            setSliderX(calculatedFinalPos);
        });
    };
    // Add once:true as a param, this will automatically unbind endFn once it is fired.
    window.addEventListener(endEvent, endFn, { once: true });
}

export type UseSliderType = {
    // Page slider
    rightArrowClickPage: () => void;
    leftArrowClickItem: () => void;
    resetSlider: () => void;

    // Infinite slider
    leftArrowClickPage: () => void;
    rightArrowClickItem: () => void;
    goToItem: (newSliderIndex: number) => void; // Goes to one specific item of the slider based on a new sliderIndex
    sliderIndex: number; // The current index of the infinite slider array.
    itemInFocus: number; // The real item in focus number based on the sliderIndex calculated by getItemInFocus. This is the correct number for using in the frontend

    // Shared
    startMouseDrag: (event: MouseEvent) => void;
    startTouchDrag: (event: TouchEvent) => void;
    leftArrowEnabled: boolean;
    rightArrowEnabled: boolean;
    enableCssTransition: boolean;
    // It is important that we expose the _ref_ and not the boolean itself to avoid React render ref bugs.
    isScrolling: MutableRefObject<boolean>;
    sliderX: number;
    enableDrag: boolean;
    totalSliderWidth: number;
    goToPage: (page: number) => void;
    onTransitionEnd: () => void;
};

/**
 * Base hook for slider implementations. Use together with a Container/Viewport/Track component.
 *
 * See GradeCardListView component for an example.
 * @param totalSliderWidth - Total width of the slider
 * @param viewportWidth - Total width of the viewport
 * @param itemWidth - Optional itemWidth which will be used for a more precise "elastic" swipe when the user slides to the end. If no itemWidth is used a default value will be used.
 * @param itemWidths - Optional itemWidths which can be used when the itemWidth varies.
 * Using this makes the useSlider an infinite slider. Adding the 4 last items at the front and 4 first items at the back is required!

 *
 */
const useSlider = (
    totalSliderWidth: number,
    viewportWidth: number,
    itemWidth?: number,
    itemWidths: number[] = [], // adding this correctly makes the slider an infiniteSlider
    isRTL?: boolean, // Adding this for some RTL support
): UseSliderType => {
    const [enableDrag, setEnableDrag] = useState<boolean>(true);
    const [sliderX, setSliderX] = useState<number>(0);
    const [enableCssTransition, setEnableCssTransition] = useState<boolean>(true);
    const [sliderIndex, setSliderIndex] = useState(EXTRA_ITEMS_FRONT); // only in infiniteSlider
    const [initialized, setInitialized] = useState(false); // only in infiniteSlider

    // Helper refs to be used outside of react state.
    const isScrolling = useRef<boolean>(false);

    // ----------------------------------------------------------------------
    // Page slider logic
    // ----------------------------------------------------------------------

    // Assuming that a page is one viewport width.
    // The naming uses "page" as goToViewport would be more confusing.
    const goToPage = (page: number): void => {
        if (page !== 0) {
            const newSliderX = 0 - viewportWidth * page;
            if (Math.abs(newSliderX) <= totalSliderWidth) setSliderX(newSliderX);
            else setSliderX(0 - (totalSliderWidth - viewportWidth));
        } else {
            setSliderX(0);
        }
    };

    // Scroll to the previous "page" or go the max left position.
    const leftArrowClickPage = (): void => {
        const newSliderX = sliderX + viewportWidth;
        if (totalSliderWidth + newSliderX >= viewportWidth && newSliderX <= 0) setSliderX(newSliderX);
        else setSliderX(0);
    };
    // Scroll to the next "page" or go the max right position.
    const rightArrowClickPage = (): void => {
        const newSliderX = sliderX - viewportWidth;
        if (totalSliderWidth + newSliderX >= viewportWidth) setSliderX(newSliderX);
        else setSliderX(0 - (totalSliderWidth - viewportWidth));
    };

    // Reset the slider to its initial position
    const resetSlider = (): void => {
        setSliderX(0);
    };

    // ----------------------------------------------------------------------
    // Infinite slider logic
    // ----------------------------------------------------------------------
    const isInfinite = itemWidths && itemWidths.length > EXTRA_ITEMS;
    const originalArrayLength = itemWidths.length > EXTRA_ITEMS ? itemWidths.length - EXTRA_ITEMS : 0;
    const passedBorderLeft = sliderIndex < EXTRA_ITEMS_FRONT;
    const passedBorderRight = sliderIndex > itemWidths.length - EXTRA_ITEMS_END - 1;
    const passedBorder = passedBorderLeft || passedBorderRight;

    // Goes to one specific item of the slider based based on a new sliderIndex
    const goToItem = (newSliderIndex: number): void => {
        let newSliderX = 0;
        let index = 0;
        do {
            newSliderX -= itemWidths[index];
            index++;
        } while (index < newSliderIndex);
        setSliderX(newSliderX);
        setSliderIndex(newSliderIndex);
    };

    // Scroll to the previous "item"
    const leftArrowClickItem = (): void => {
        // Temporary disable leftArrowClickItem when it passed a border and the onTransitionEnd didn't happen yet
        if (isInfinite && !passedBorder) goToItem(sliderIndex - 1);
    };
    // Scroll to the next "item"
    const rightArrowClickItem = (): void => {
        // Temporary disable leftArrowClickItem when it passed a border and the onTransitionEnd didn't happen yet
        if (isInfinite && !passedBorder) goToItem(sliderIndex + 1);
    };

    // Get the real item in focus number based on the sliderIndex
    const getItemInFocus = (): number => {
        // items at the front and - 1 because arrays start at zero
        let itemInFocus = sliderIndex - (EXTRA_ITEMS_FRONT - 1);
        if (itemInFocus === 0) itemInFocus = originalArrayLength;
        if (originalArrayLength && (itemInFocus > originalArrayLength || itemInFocus < 0)) {
            itemInFocus = (itemInFocus + originalArrayLength) % originalArrayLength;
        }
        return itemInFocus;
    };

    let leftOffset = 0;

    if (isInfinite) {
        // Calculate leftOffset to center the itemInFocus
        leftOffset = isInfinite ? (viewportWidth - itemWidths[sliderIndex]) / 2 : 0;
    }

    // Recalculate the sliderX position when the itemWidths changes.
    useEffect(() => {
        if (isInfinite) {
            if (!initialized && itemWidths.length) {
                goToItem(EXTRA_ITEMS_FRONT);
                setInitialized(true);
            } else {
                goToItem(sliderIndex);
            }
        }
    }, [itemWidths]);

    // Update itemInFocus if sliderX changes
    useEffect(() => {
        if (isInfinite) {
            const { index } = getCurrentImageInfo(sliderX, itemWidths);
            setSliderIndex(index);
        }
    }, [sliderX]);

    // ----------------------------------------------------------------------
    // Shared logic
    // ----------------------------------------------------------------------

    // Dragging logic which will be attached to the slider component.
    const dragProps = { sliderX, setSliderX, totalSliderWidth, setEnableCssTransition, viewportWidth };

    const startMouseDrag = (event: MouseEvent): void => {
        event.preventDefault();
        // Only start dragging if the user used the main mouse button
        if (event.button === 0) {
            startDrag(
                // @ts-ignore
                getMousePosition,
                event,
                "mousemove",
                "mouseup",
                dragProps,
                isScrolling,
                itemWidth,
                itemWidths,
                isRTL,
            );
        }
    };

    const startTouchDrag = (event: TouchEvent): void => {
        event.preventDefault();
        event.stopPropagation();
        startDrag(
            // @ts-ignore
            getTouchPosition,
            event,
            "touchmove",
            "touchend",
            dragProps,
            isScrolling,
            itemWidth,
            itemWidths,
            isRTL,
        );
    };

    const onTransitionEnd = (): void => {
        isScrolling.current = false;

        // Reset carousel to beginning/end when it passed a border in the infiniteSlider
        if (isInfinite && passedBorder) {
            let newIndex = sliderIndex - originalArrayLength;
            if (passedBorderLeft) newIndex = sliderIndex + originalArrayLength;

            setEnableCssTransition(false);
            goToItem(newIndex);
            setTimeout(() => {
                setEnableCssTransition(true);
            }, 100);
        }
    };

    // Enable drag if the slider doesn't fit in the viewport.
    useEffect(() => {
        const dragEnabled = viewportWidth < totalSliderWidth;
        if (dragEnabled !== enableDrag) {
            setEnableDrag(dragEnabled);

            // Also reset sliderX if necessary, else the slider can become static while it still contains offset positioning.
            if (!dragEnabled && sliderX !== 0) setSliderX(0);
        }
    }, [enableDrag, viewportWidth, totalSliderWidth, sliderX]);

    // Recalculate the sliderX position when the itemWidth changes.
    const previousItemWidth = useRef<number | undefined>(itemWidth);
    useEffect(() => {
        if (itemWidth && previousItemWidth.current && previousItemWidth.current !== itemWidth) {
            // Calculate the difference in percentage and update sliderX accordingly.
            const increase = itemWidth - previousItemWidth.current;
            const increasePercentage = increase / previousItemWidth.current;
            const newSliderX = sliderX + sliderX * increasePercentage;

            // Disable CSS transitions when updating sliderX else we'll "animate" the change on a width change.
            setEnableCssTransition(false);
            setSliderX(newSliderX);
            window.requestAnimationFrame(() => {
                setEnableCssTransition(true);
            });
        }
        previousItemWidth.current = itemWidth;
    }, [itemWidth]); //eslint-disable-line react-hooks/exhaustive-deps

    // Enable left arrow if the slider is draggable and we're not at the start of the slider.
    const leftArrowEnabled = enableDrag && sliderX < 0;

    // Enable right arrow if there is still "space" to slide to the left without showing whitespace.
    const rightArrowEnabled = enableDrag && totalSliderWidth + sliderX > viewportWidth;

    return {
        // Page slider
        rightArrowClickPage,
        leftArrowClickItem,
        resetSlider,
        // Infinite slider
        leftArrowClickPage,
        rightArrowClickItem,
        goToItem,
        sliderIndex,
        itemInFocus: isInfinite ? getItemInFocus() : 0,
        // Shared
        startMouseDrag,
        startTouchDrag,
        goToPage,
        onTransitionEnd,
        leftArrowEnabled,
        rightArrowEnabled,
        enableCssTransition,
        isScrolling,
        sliderX: isRTL ? -(sliderX + leftOffset) : sliderX + leftOffset,
        enableDrag,
        totalSliderWidth,
    };
};
export default useSlider;
