import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const IconWrapper = styled.button`
    width: 100%;
    padding: 0;
    text-align: left;
    background: none;
    border: none;
`;

export const EcoLabelFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        max-width: 48px;
    }

    &:not(:last-child) {
        margin-bottom: ${theme.space[1]}px;
    }
`;
