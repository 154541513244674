import { Dispatch, ReducersMapObject, Store } from "redux";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { CommonActionsType, CommonReducerType } from "../../../common-deprecated/redux/commonStore";
import carFiltersReducer, { CarFiltersReducerType } from "./reducers/CarFiltersReducer";
import modalReducer from "../../../common-deprecated/redux/reducers/ModalReducer";
import { getInitStoreByReducers } from "../../../common-deprecated/redux/storeHelpers";
import { CarFiltersActionsType } from "./actions/CarFiltersActions";
import { CreateLogicType } from "../../../common-deprecated/redux/typeHelpers";
import filterLogic from "./logic/filterLogic";
import usedCarResultLogic from "./logic/carResultLogic";
import utilReducer from "../../../common-deprecated/redux/reducers/UtilReducer";
import carResultsReducer, { CarResultsReducerType } from "./reducers/CarResultsReducer";
import { CarResultsActionsType } from "./actions/CarResultsActions";
import CommonSettingsReducer from "../../../common-deprecated/redux/reducers/CommonSettingsReducer";
import CarFilterSettingsReducer, { CarFilterSettingsType } from "./reducers/CarFilterSettingsReducer";
import { CarFilterSettingsActionsType } from "./actions/CarFilterSettingsActions";
import savedCarLogic from "./logic/savedCarLogic";
import { RetailerSettingsActionsType } from "../../../common-deprecated/features/retailer/redux/actions/RetailerSettingsActions";
import RetailerSettingsReducer, {
    RetailerSettingsType,
} from "../../../common-deprecated/features/retailer/redux/reducers/RetailerSettingsReducer";
import UsedCarCompareExternalDataReducer, {
    UsedCarCompareExternalDataReducerType,
} from "../../../common-deprecated/features/compare/redux/reducers/UsedCarCompareExternalDataReducer";

export type CarFilterStateType = CommonReducerType & {
    carFilters: CarFiltersReducerType;
    carResults: CarResultsReducerType;
    retailerSettings: RetailerSettingsType;
    carFilterSettings: CarFilterSettingsType;
    usedCarCompareExternalData: UsedCarCompareExternalDataReducerType;
};

export type CarFilterActionsType =
    | CommonActionsType
    | CarFiltersActionsType
    | RetailerSettingsActionsType
    | CarFilterSettingsActionsType
    | CarResultsActionsType;

export type CarFilterDispatchType = Dispatch<CarFilterActionsType>;

export type CarFilterLogicType<ActionsType extends CarFilterActionsType> = CreateLogicType<
    ActionsType,
    CarFilterStateType,
    CarFilterDispatchType
>;

export type CarFilterStoreType = Store<CarFilterStateType, CarFilterActionsType>;

export const useCarFilterSelector: TypedUseSelectorHook<CarFilterStateType> = useSelector;

export const carFilterReducers: ReducersMapObject<CarFilterStateType, any> = {
    carFilters: carFiltersReducer,
    carResults: carResultsReducer,
    modal: modalReducer,
    commonSettings: CommonSettingsReducer,
    carFilterSettings: CarFilterSettingsReducer,
    retailerSettings: RetailerSettingsReducer,
    usedCarCompareExternalData: UsedCarCompareExternalDataReducer,
    utils: utilReducer,
};

export const carFilterClientLogic = [...filterLogic, ...usedCarResultLogic, ...savedCarLogic];

export const carFilterServerStore = getInitStoreByReducers(carFilterReducers, []);
export const carFilterClientStore = getInitStoreByReducers(carFilterReducers, carFilterClientLogic);
