export const MODAL_VEHICLE_FOR_SALE_ID = "vehicleForSaleId" as const;
export type VehicleForSaleIdModalType = {
    type: typeof MODAL_VEHICLE_FOR_SALE_ID;
    landingPageUrl: string;
    detailPageUrl: string;
};

export const MODAL_USC_INJECTION = "uscInjection";
export type UscInjectionModalType = {
    type: typeof MODAL_USC_INJECTION;
    title: string;
    containerId: string;
    onClose: () => void;
    onCloseAnimationEnd?: () => void;
    injectionLoading?: boolean;
    injectionError?: boolean;
    className?: string;
};

export type SharedUscModalIdsType = typeof MODAL_VEHICLE_FOR_SALE_ID | typeof MODAL_USC_INJECTION;

export type SharedUscModalsType = VehicleForSaleIdModalType | UscInjectionModalType;
