import {
    CarTypeFilterType,
    MinMaxSliderBaseType,
    MinMaxType,
    MultipleChoiceValueSortOption,
    MultipleChoiceValueType,
    SliderButtonType,
} from "./constants/filterConfigConstants";

/**
 * Helper to easily retrieve selected carType filter values.
 */
export const getSelectedCarTypes = (
    { brands, carType, fuelType, models, transmission }: CarTypeFilterType,
    type: "label" | "id",
): { brands: string[]; models: string[]; fuelType: string[]; carType: string[]; transmission?: string[] } => {
    const selectedBrands = brands.filter((brand) => brand.selected);
    const selectedBrandsMap = selectedBrands.map((brand) => brand[type]);

    // Select models, but exclude models from a brand if the brand is already selected.
    const selectedModels = models
        .filter((model) => model.selected && !selectedBrands.find((brand) => brand.id === model.brandId))
        .map((model) => model[type]);

    const selectedCarTypes = carType.filter(({ selected }) => selected).map((option) => option[type]);

    const selectedFuelTypes = fuelType.filter(({ selected }) => selected).map((option) => option[type]);

    const selectedTransmissions = transmission?.filter(({ selected }) => selected).map((option) => option[type]);

    return {
        brands: selectedBrandsMap,
        models: selectedModels,
        fuelType: selectedFuelTypes,
        carType: selectedCarTypes,
        transmission: selectedTransmissions,
    };
};

/**
 * Helper function to parse actual min and max filter queries for the endpoint.
 * This makes sure that the max and min values are only added when relevant.
 */
type MinMaxBaseObjectType = { min: number | null; max: number | null };
export const getMinMaxQueryValue = <T extends MinMaxSliderBaseType>(
    filter: T,
    useDefaultMinMax: boolean = false,
): MinMaxBaseObjectType => {
    const result: MinMaxBaseObjectType = { min: null, max: null };
    if (filter.currentMinValue !== filter.minValue) result.min = filter.currentMinValue;
    if (filter.currentMaxValue !== filter.maxValue) result.max = filter.currentMaxValue;
    if (useDefaultMinMax) {
        result.min = result.min ?? filter.minValue;
        result.max = result.max ?? filter.maxValue;
    }
    return result;
};

export const sortArrayByLabel = <T extends { label: string }>(valueArray: T[]): T[] =>
    [...valueArray].sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();

        return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
    });

/**
 * Sorts an array of filter values by their result count,
 * and then by their label.
 */
const sortArrayByResultCount = <T extends MultipleChoiceValueType>(valueArray: T[]): T[] =>
    [...valueArray].sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        const resultCountA = a.resultCount ?? 0;
        const resultCountB = b.resultCount ?? 0;

        if (resultCountA > resultCountB) return -1;
        if (resultCountA < resultCountB) return 1;

        // If result count is the same, sort by label.
        // This is to make sure that the order is consistent.
        return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
    });

export const sortMultipleChoiceValues = <T extends MultipleChoiceValueType>(
    valueArray: T[],
    sortOption: MultipleChoiceValueSortOption,
): T[] => {
    switch (sortOption) {
        case MultipleChoiceValueSortOption.Label:
            return sortArrayByLabel(valueArray);
        case MultipleChoiceValueSortOption.ResultCount:
            return sortArrayByResultCount(valueArray);
        default:
            return valueArray;
    }
};

/**
 * Clamp a given input value to the given min and max values.
 * In short:
 * - If the input value is less than the min value, return the min value.
 * - If the input value is greater than the max value, return the max value.
 * - Otherwise, return the input value.
 */
export const clampInputValue = (
    value: number,
    clampValues: { initial: MinMaxType; current: MinMaxType },
    type: SliderButtonType,
    setValueFn: (value: number, type: SliderButtonType) => void,
): number | null => {
    const { initial, current } = clampValues;
    const { min: initialMin, max: initialMax } = initial;
    const { min: currentMin, max: currentMax } = current;

    if (type === "min") {
        if (value < initialMin) {
            return initialMin;
        }

        if (value > initialMax) {
            setValueFn(currentMax, "min");
            return null;
        }

        // Value is greater than the current max value
        // Set the current min value to the current max value
        // Set the current max value to the input value
        if (value > currentMax) {
            setValueFn(currentMax, "min");
            setValueFn(value, "max");
            return null;
        }

        return value;
    }

    if (value > initialMax) {
        return initialMax;
    }

    if (value < initialMin) {
        setValueFn(currentMin, "max");
        return null;
    }

    // Value is less than the current min value
    // Set the current max value to the current min value
    // Set the current min value to the input value
    if (value < currentMin) {
        setValueFn(currentMin, "max");
        setValueFn(value, "min");
        return null;
    }

    return value;
};
