import {
    vehicleHasWarranty,
    formatRegistrationDate,
    FullQuickSpecConfigType,
    getExternalVehicleAttrValue,
    LegalQuickSpecConfigType,
    QuickSpecConfigType,
    UscEcoQuickSpecs,
    UscQuickSpec,
    UscVehicleType,
    vehicleHasValidProgram,
} from "./uscUtils";
import { FormattedFullQuickSpecsType, FormattedQuickSpecsType } from "../../pdp/common/utils/types";
import Debug from "../../../common-deprecated/Debug";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { formatNumberIntl } from "../../../common-deprecated/settings/utils/commonSettingUtils";
import { getUscEcoQuickSpecConfig, NedcWltpDisclaimersLabelType, WeightedSpecsLabelType } from "./uscEcoUtils";
import {
    AemFuelTypeSpecNames,
    aemFuelTypeToDfFuelTypeMap,
    LegalSpecConfigType,
    MeasureType,
    VehicleEngineType,
} from "../types/UscCommonTypes";
import { DealerResultType } from "../../../common-deprecated/utils/dealerConstants";
import { formatPriceIntl } from "../../../common-deprecated/priceUtils";
import { DfDocumentType, DfExternalVehicleAttrs, DfFuelTypeId } from "./dfConstants";

export type QuickSpecLabelType = {
    yesLabel: string;
    noLabel: string;
};

// Shared type between usc, car-filter and usc-form containing all the relevant spec params
// Every component has his own selector returning these properties
export type UscSpecParamsType = {
    commonSettings: CommonSettingsType;
    settings: {
        disableMonthInRegistrationSpec: boolean;
        showZeroCo2SpecValues: boolean;
    };
    labels: {
        nedcWltpDisclaimerLabels: NedcWltpDisclaimersLabelType;
        quickSpecLabels: QuickSpecLabelType | null;
        weightedSpecsLabels: WeightedSpecsLabelType | null;
    };
};

/**
 * Adds data values from vehicle result to the quick spec config
 * @param config top or extra config from either used or stock cars
 * @param result vehicle result from which the data is picked
 * @param labels uscSettings.labels
 * @param cultureName commonSettings.culture.name
 * @param nedcWltpDisclaimerLabels The disclaimers used in the NEDC/WLTP specs (For the eco values)
 */
export const amendQuickSpecConfig = (
    config: QuickSpecConfigType,
    specParams: UscSpecParamsType,
    result: UscVehicleType,
    dealer: DealerResultType | null,
): FullQuickSpecConfigType => {
    const { commonSettings } = specParams;
    const { disableMonthInRegistrationSpec } = specParams.settings;
    const { quickSpecLabels } = specParams.labels;
    const { culture, brand } = commonSettings;
    const cultureName = culture.name;

    config.tooltipReference = config.type;

    switch (config.type) {
        case UscQuickSpec.Mileage:
            return {
                ...config,
                value: result.mileage ? formatNumberIntl(result.mileage.value || 0, cultureName) : "",
                unit: result.mileage ? result.mileage.unit.description : "",
            };
        case UscQuickSpec.Registration:
            // registrationDate has the format of YYYY-MM-DD
            // however, we want to show it as MM-YYYY if disableMonthInRegistrationSpec is false,
            // otherwise we show it as YYYY
            // If registrationDate is not available, we fallback to localRegistrationDate (OR-8028)
            const registrationDate = result.history?.registrationDate || result.history?.localRegistrationDate;

            return {
                ...config,
                value: registrationDate ? formatRegistrationDate(registrationDate, disableMonthInRegistrationSpec) : "",
            };
        case UscQuickSpec.FuelType:
            return {
                ...config,
                value: result.product.engine.displayFuelType || "",
            };
        case UscQuickSpec.Warranty:
            return {
                ...config,
                // warranty is available on result.warranty if vehicleHasWarranty is true
                value: vehicleHasWarranty(result) ? result!.warranty!.description : "", // hides warranty when NA
            };
        case UscQuickSpec.Program:
            return {
                ...config,
                value: vehicleHasValidProgram(result, brand) ? result!.ucProgram!.description : "", // hides uc program when it has no valid code
            };
        case UscQuickSpec.BodyType:
            return {
                ...config,
                value: result.product.bodyType,
            };
        case UscQuickSpec.Doors:
            return {
                ...config,
                value: `${result.product.doors}`,
            };
        case UscQuickSpec.Seats:
            return {
                ...config,
                value: `${result.product.seats}`,
            };
        case UscQuickSpec.Transmission:
            return {
                ...config,
                value: result.product.transmission.name || "",
            };
        case UscQuickSpec.PowerOutput:
            const vehiclePowerOutput = getUscVehiclePowerOutput(result.product.engine);
            return {
                ...config,
                value: vehiclePowerOutput ? `${vehiclePowerOutput.value}` : "",
                unit: vehiclePowerOutput?.unit.description,
            };
        case UscQuickSpec.Colour:
            return {
                ...config,
                value: result.exteriorColour,
            };
        case UscQuickSpec.InteriorColour:
            return {
                ...config,
                value: result.interiorColour,
            };
        case UscQuickSpec.TrimColour:
            return {
                ...config,
                value: result.trimColour,
            };
        case UscQuickSpec.Document: {
            // We just choose the first document available for now.
            // If vehicles ever start having multiple documents we need to further refine this based on label/documentCategory props.
            const document = result.documents?.[0];
            if (!document) return { ...config, value: "" };

            return {
                ...config,
                value: document.url,
                valueCode: document.label.code,
            };
        }
        case UscQuickSpec.OwnershipTax: {
            return {
                ...config,
                value: result.ownershipTax ? formatPriceIntl(commonSettings, result.ownershipTax).primaryPrice : "",
            };
        }
        case UscQuickSpec.EcoLabel:
            return {
                ...config,
                value: result.product.eco?.label?.description || "",
                valueCode: result.product.eco?.label?.code || "",
            };
        case UscQuickSpec.LicensePlate: {
            return { ...config, value: result.licensePlate };
        }
        case UscQuickSpec.VinNumber: {
            return { ...config, value: result.vin };
        }
        case UscQuickSpec.DisplayAuthorization: {
            return { ...config, value: dealer?.outletNmsc.uclAuthorizationNumber || "" };
        }
        case UscQuickSpec.EngineNumber: {
            return { ...config, value: result.product.engine.engineNumber || "" };
        }
        case UscQuickSpec.NextInspectionDate: {
            return { ...config, value: result.nextInspectionDate ? result.nextInspectionDate : "" };
        }
        case UscQuickSpec.IsPledgedSale: {
            // We receive isPledgedSale as a boolean, but we need to display it as a string (yes/no)
            return {
                ...config,
                value: (result.isPledgedSale ? quickSpecLabels?.yesLabel : quickSpecLabels?.noLabel) || "",
            };
        }
        case UscQuickSpec.ModelYear: {
            return { ...config, value: result.product.modelYear };
        }
        case UscQuickSpec.PreviousUsage: {
            return { ...config, value: result.history?.previousUsage?.description || "" };
        }
        case UscQuickSpec.PreviousOwners: {
            return { ...config, value: result.history?.previousOwners ? String(result.history?.previousOwners) : "" };
        }
        case UscQuickSpec.PreviousAccident: {
            return {
                ...config,
                value: (result.history?.previousAccident ? quickSpecLabels?.yesLabel : quickSpecLabels?.noLabel) || "",
            };
        }
        case UscQuickSpec.BatteryCapacity: {
            return {
                ...config,
                value: result.product.engine.batteryCapacity ? String(result.product.engine.batteryCapacity.value) : "",
                unit: result.product.engine.batteryCapacity?.unit.description || "",
            };
        }
        case UscQuickSpec.DriveTrain: {
            return {
                ...config,
                value: result.product.transmission?.gearbox || "",
            };
        }
        case UscQuickSpec.SdkCode: {
            return {
                ...config,
                value: getExternalVehicleAttrValue(result, DfExternalVehicleAttrs.sdkCode) || "",
            };
        }

        default:
            // Eco quickSpecs are shared.
            const parsedConfig = getUscEcoQuickSpecConfig(
                result.product,
                config as QuickSpecConfigType<UscEcoQuickSpecs>,
                specParams,
                result.product.eco,
            );

            // Log an error if we dont find a config as this could point to a bug in the loaded config.
            if (!parsedConfig) Debug.error(`QuickSpec config not found: ${config.type}`);
            return parsedConfig;
    }
};

export const amendQuickSpecConfigs = (
    configs: QuickSpecConfigType[],
    specParams: UscSpecParamsType,
    vehicle: UscVehicleType | null,
    dealer: DealerResultType | null,
): FullQuickSpecConfigType[] => {
    return vehicle
        ? configs
              .map((config) => amendQuickSpecConfig(config, specParams, vehicle, dealer))
              .filter((spec) => spec && !!spec.value)
        : configs.map((config) => ({
              ...config,
              loading: true,
              value: "",
          }));
};

/**
 * Reduces an array of AEM CFM quickSpecs into an object where
 * the keys are the spec types and the values are the spec titles.
 * This makes it easy to access the title of a spec by its type.
 * @param specs - An array of AEM CFM quickSpecs.
 * @returns An object of formatted quickSpecs.
 */
export const formatQuickSpecs = (specs: QuickSpecConfigType[]): FormattedQuickSpecsType =>
    specs.reduce((acc, spec) => {
        acc[spec.type] = {
            title: spec.title,
            tooltip: spec.tooltip,
        };
        return acc;
    }, {} as FormattedQuickSpecsType);

export const formatFullQuickSpecs = (specs: FullQuickSpecConfigType[]): FormattedFullQuickSpecsType =>
    specs.reduce((acc, spec) => {
        acc[spec.type] = {
            title: spec.title,
            value: spec.value,
            unit: spec.unit,
            tooltip: spec.tooltip,
            tooltipReference: spec.tooltipReference,
        };
        return acc;
    }, {} as FormattedFullQuickSpecsType);

/**
 * @param engine vehicle engine type
 * @returns powerOutput MeasureType
 */
export const getUscVehiclePowerOutput = (engine: VehicleEngineType): MeasureType | undefined => {
    const { powerOutputKilowatts, powerOutputHorsepower } = engine;

    if (!powerOutputHorsepower) {
        return powerOutputKilowatts;
    }

    if (!powerOutputKilowatts) {
        return powerOutputHorsepower;
    }

    return {
        ...powerOutputKilowatts,
        unit: {
            ...powerOutputKilowatts.unit,
            description: `${powerOutputKilowatts.unit.description} (${powerOutputHorsepower.value} ${powerOutputHorsepower.unit.description})`,
        },
    };
};

/**
 * Map of document spec images for each document type and country.
 * The image paths are relative to the resource path.
 */
const documentSpecImageMap: Partial<Record<DfDocumentType, Record<string, string>>> = {
    [DfDocumentType.CarPass]: {
        be: "/toyota/img/used-stock-cars/be/carpass-logo.png",
    },
    [DfDocumentType.DamageHistory]: {
        tr: "/toyota/img/used-stock-cars/tr/expertise-logo.png",
    },
};

/**
 * Returns the URL of the document spec image for the given type and country,
 * or null if no image is available.
 */
export const getUscDocumentSpecImageUrl = (type: DfDocumentType, commonSettings: CommonSettingsType): string | null => {
    const { country } = commonSettings;

    const foundImage = documentSpecImageMap[type]?.[country];

    return foundImage ? `${commonSettings.resourcePath}${foundImage}` : null;
};

export const getUscLegalSpecConfigs = (
    legalSpecConfig: LegalSpecConfigType,
    fuelTypeCode?: DfFuelTypeId,
): LegalQuickSpecConfigType[] => {
    if (!fuelTypeCode) return [];

    const fuelTypeName = aemFuelTypeToDfFuelTypeMap[fuelTypeCode];

    // Use the specs specific to the fuel type, fall back to global specs if not found.
    return legalSpecConfig[fuelTypeName as AemFuelTypeSpecNames] || legalSpecConfig.global || [];
};
const getConsumptionSpecConfig = (
    consumptionSpecs: QuickSpecConfigType[],
    type: UscQuickSpec,
): LegalQuickSpecConfigType | undefined => consumptionSpecs.find((spec) => spec.type === type);

const specMapping = {
    petrol: [
        UscQuickSpec.FcWltpCombined,
        UscQuickSpec.Co2WltpCombined,
        UscQuickSpec.EfficiencyClass,
        UscQuickSpec.EcolabelFcWltpCombined,
        UscQuickSpec.FcInnerCity,
        UscQuickSpec.FcOutskirts,
        UscQuickSpec.FcCountryRoad,
        UscQuickSpec.FcHighway,
    ],
    hybrid: [
        UscQuickSpec.FcCsCombined,
        UscQuickSpec.Co2CsCombined,
        UscQuickSpec.EfficiencyClass,
        UscQuickSpec.EcolabelFcWltpCombined,
        UscQuickSpec.FcInnerCity,
        UscQuickSpec.FcOutskirts,
        UscQuickSpec.FcCountryRoad,
        UscQuickSpec.FcHighway,
    ],
    diesel: [
        UscQuickSpec.FcWltpCombined,
        UscQuickSpec.Co2WltpCombined,
        UscQuickSpec.EfficiencyClass,
        UscQuickSpec.EcolabelFcWltpCombined,
        UscQuickSpec.FcInnerCity,
        UscQuickSpec.FcOutskirts,
        UscQuickSpec.FcCountryRoad,
        UscQuickSpec.FcHighway,
    ],
    pluginHybrid: [
        UscQuickSpec.EcCombined,
        UscQuickSpec.FcWltpCombined,
        UscQuickSpec.Co2WltpCombined,
        UscQuickSpec.EaerCombined,
        UscQuickSpec.EfficiencyClass,
        UscQuickSpec.EfficiencyClassCSMode,
        UscQuickSpec.EcolabelFcCsCombined,
        UscQuickSpec.FcInnerCity,
        UscQuickSpec.FcOutskirts,
        UscQuickSpec.FcCountryRoad,
        UscQuickSpec.FcHighway,
        UscQuickSpec.EcolabelEcCombined,
        UscQuickSpec.EcInnerCity,
        UscQuickSpec.EcOutskirts,
        UscQuickSpec.EcCountryRoad,
        UscQuickSpec.EcHighway,
    ],
    electric: [
        UscQuickSpec.EcCombined,
        UscQuickSpec.Co2WltpCombined,
        UscQuickSpec.PerCombined,
        UscQuickSpec.EfficiencyClass,
        UscQuickSpec.EcolabelEcCombined,
        UscQuickSpec.EcInnerCity,
        UscQuickSpec.EcOutskirts,
        UscQuickSpec.EcCountryRoad,
        UscQuickSpec.EcHighway,
    ],
    hydrogen: [
        UscQuickSpec.FcWltpCombined,
        UscQuickSpec.Co2WltpCombined,
        UscQuickSpec.EfficiencyClass,
        UscQuickSpec.EcolabelFcWltpCombined,
        UscQuickSpec.FcInnerCity,
        UscQuickSpec.FcOutskirts,
        UscQuickSpec.FcCountryRoad,
        UscQuickSpec.FcHighway,
    ],
};

/**
 * We're temporarily mapping TDG's consumption PDP spec category
 * to the desired legal spec category as the legal spec functionality
 * is not yet supported on the PDP.
 * TODO: remove this function once UC-800 is implemented
 */
export const mapConsumptionSpecsToLegalSpecs = (
    consumptionSpecs: QuickSpecConfigType[] | undefined,
): LegalSpecConfigType | null => {
    if (!consumptionSpecs) return null;

    const mapSpecs = (specs: UscQuickSpec[]): LegalQuickSpecConfigType[] =>
        specs
            .map((spec) => getConsumptionSpecConfig(consumptionSpecs, spec))
            .filter((spec): spec is LegalQuickSpecConfigType => !!spec);

    // For each entry in specMapping, map the specs to the legal spec config type
    // and convert the array back to an object
    return Object.fromEntries(
        Object.entries(specMapping).map(([key, specs]) => [key, mapSpecs(specs)]),
    ) as LegalSpecConfigType;
};
