import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Subtitle = styled.h2`
    margin-bottom: ${theme.space[2]}px;
    font-family: ${theme.fonts.semiBold};
    font-size: 1.3rem;
    line-height: 2rem;
    text-transform: uppercase;
`;

export const Description = styled.p`
    margin: ${theme.space[2]}px 0;
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.grey7};
`;
