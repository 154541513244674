import React, { Ref, RefObject, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCloseOnOutsideClick, useShowAfterFirstRender } from "../hooks";
import { useShouldShowModal, UseShouldShowModalInsteadType } from "./useShouldShowModal";
import { showModal } from "../redux/actions/ModalActions";
import { MODAL_CONTENT } from "../utils/modalConstants";

export enum OffsetAlignment {
    Left = "left",
    Right = "right",
    Top = "top",
    Bottom = "bottom",
}

export type OffsetAlignType = {
    horizontal: OffsetAlignment.Left | OffsetAlignment.Right;
    vertical: OffsetAlignment.Top | OffsetAlignment.Bottom;
} | null;

export type OffsetType = {
    align: OffsetAlignType;
    x: number;
    y: number;
};

export type PopoverTooltipType = {
    useHTML?: boolean;
    content?: string;
    hide?: boolean;
    openedOnHover?: boolean;
    onClose: () => void;
    small?: boolean;
    iconRef: RefObject<HTMLElement>;
    initializeTooltipAlignment?: boolean;
    children?: React.ReactNode;
    zIndex?: number;
    forceShowModal?: boolean;
};

export const useAlignElementToOtherElement = (
    iconRef: RefObject<HTMLElement>,
    tooltipRef: RefObject<HTMLElement>,
    tooltipVisible?: boolean,
): OffsetType => {
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [align, setAlign] = useState<OffsetAlignType>(null);
    const iconTopOffset = iconRef.current?.getBoundingClientRect().top;

    // Calculate tooltip position.
    useEffect(() => {
        if (!tooltipVisible) return;

        const updatePosition = (): void => {
            if (!iconRef.current || !tooltipRef.current || !tooltipVisible) return;

            const triggerRect = iconRef.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();

            // Calculate the available space above and below the trigger element
            const spaceAbove = triggerRect.top;

            // Determine whether to position the tooltip above or below the trigger HTMLDivElement
            const pos = spaceAbove >= tooltipRect.height ? "above" : "below";
            const yPos = pos === "above" ? triggerRect.top - (tooltipRect.height + 12) : triggerRect.bottom + 12;
            const xPos = triggerRect.left - 30;
            setOffset({ x: xPos, y: yPos });
            setAlign({
                horizontal:
                    triggerRect.left + tooltipRect.width >= window.innerWidth - 20
                        ? OffsetAlignment.Right
                        : OffsetAlignment.Left,
                vertical: triggerRect.top - tooltipRect.height <= 20 ? OffsetAlignment.Top : OffsetAlignment.Bottom,
            });
        };

        updatePosition();
        window.addEventListener("resize", updatePosition);
        window.addEventListener("scroll", updatePosition);

        return () => {
            window.removeEventListener("resize", updatePosition);
            window.removeEventListener("scroll", updatePosition);
        };
    }, [iconRef, tooltipRef, iconTopOffset, tooltipVisible]);

    return { align, ...offset };
};

type UsePopOverTooltipType = UseShouldShowModalInsteadType & {
    tooltipRef: RefObject<HTMLDivElement>;
    pointOffset: OffsetAlignType;
    show: boolean;
    offsetWithArrowLocation: { x: number; y: number };
};

export const usePopOverTooltip = (
    props: PopoverTooltipType,
    forwardedRef?: Ref<HTMLElement>,
    forceShowModal?: boolean,
): UsePopOverTooltipType => {
    const { iconRef, children, hide, onClose, useHTML = false, content = "", openedOnHover = false } = props;
    const dispatch = useDispatch();
    const tooltipRef = (forwardedRef || React.createRef()) as RefObject<HTMLDivElement>;

    // Show after first render as we need DOM dimensions for this component to work.
    const show = useShowAfterFirstRender();

    // Register close on outside click, this should not have impact if the
    // tooltip is a modal. disable outsideClick logic if tooltip is not shown and hoverTriggered
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useCloseOnOutsideClick(tooltipRef, !openedOnHover ? onClose : () => {}, hide);

    const { align, x: xPos, y: yPos } = useAlignElementToOtherElement(iconRef, tooltipRef, !hide);

    // Check if a modal should be used instead of rendering a tooltip.
    // This will happen on first render because we need to know the size of the tooltip.
    const { tooltipVisible, modalVisible } = useShouldShowModal(tooltipRef, false, forceShowModal);

    // Show modal if necessary.
    useEffect(() => {
        if (!hide && modalVisible && show) {
            dispatch(showModal({ type: MODAL_CONTENT, useHTML, onClose, content: content || children || "" }));
        }
    }, [hide, modalVisible, show]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        tooltipVisible,
        modalVisible,
        tooltipRef,
        pointOffset: align,
        show,
        offsetWithArrowLocation: { x: xPos, y: yPos },
    };
};
