import { FinanceInfoType } from "../../../common-deprecated/types/CommonTypes";
import {
    EuriborMonthlyPriceLabelKey,
    formatMonthlyPrice,
    formatNumberString,
    formatPriceIntl,
    KeyValueArrayType,
    MonthlyFinanceInfoLabelKey,
    MonthlyPriceLabelKey,
} from "../../../common-deprecated/priceUtils";
import { getDefaultPriceObject } from "../../../common-deprecated/constants";
import { DfPricingSourceCode } from "./dfConstants";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { hasDualCurrency } from "../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { UscVehicleType } from "./uscUtils";

// Price object used to render prices for a USC vehicle/accessory (Basically any price sourced from DF/PADOC).
// USC used to reuse PriceType but this was split off to closer match the DF data structures and USC requirements.
export type UscCashPriceType = {
    cashPriceInclVat: number;
    cashPriceExclVat: number;
    promoCashPriceInclVat: number;
    promoCashPriceExclVat: number;
};
export const defaultUscCashPrice: UscCashPriceType = {
    cashPriceInclVat: 0,
    cashPriceExclVat: 0,
    promoCashPriceInclVat: 0,
    promoCashPriceExclVat: 0,
};

/**
 * Check if a vehicle price should be shown as exVat.
 *
 * Use UscCashPriceType as this is used across UCL components.
 * This logic different from Build where exVat is determined by the flexibility matrix on a model level.
 * In USC cases it is determined on a vehicle price level.
 * As this function is used across UCL components this needs to use input params which can be shared across components.
 */
export const uscPriceIsExclVat = (cashPrice: UscCashPriceType): boolean =>
    !cashPrice.cashPriceInclVat && !!cashPrice.cashPriceExclVat;

/**
 * Inverse of uscPriceIsExclVat - a vehicle _not_ being exVat does not
 * necessarily mean it is inclVat.
 */
export const uscPriceIsInclVat = (cashPrice: UscCashPriceType): boolean =>
    !cashPrice.cashPriceExclVat && !!cashPrice.cashPriceInclVat;

export type FormatMonthlyFinancePriceReturnType = {
    monthlyFinanceLabels: KeyValueArrayType<MonthlyPriceLabelKey>;
    euriborFinanceLabels?: KeyValueArrayType<MonthlyPriceLabelKey>;
    hasPromotion: boolean;
    disclaimer?: string;
};

/**
 * Formats financing monthly prices, ideally this should not be used for anything else (like insurance in the past)
 */
export const formatMonthlyFinancePrice = (
    monthlyPrice: number,
    commonSettings: CommonSettingsType,
    financeInfo: FinanceInfoType,
    priceFormat: string,
    rateWithoutPromotions?: number,
): FormatMonthlyFinancePriceReturnType => {
    const monthlyFinanceLabels: KeyValueArrayType<MonthlyPriceLabelKey> = [];
    const euriborFinanceLabels: KeyValueArrayType<MonthlyPriceLabelKey> = [];

    const financeInfoReplacements: Record<MonthlyFinanceInfoLabelKey, string> = {
        term: financeInfo.term,
        productName: financeInfo.productName || "",
        taeg: financeInfo.effectiveInterestRate
            ? formatNumberString(financeInfo.effectiveInterestRate, commonSettings.culture.name)
            : "",
        tan: financeInfo.annualInterestRate
            ? formatNumberString(financeInfo.annualInterestRate, commonSettings.culture.name)
            : "",
        // residualValue and downPayment are price values, so apply formatPriceIntl as well.
        residualValue: financeInfo.residualValue
            ? formatPriceIntl(commonSettings, financeInfo.residualValue).primaryPrice
            : "",
        downPayment: financeInfo.downPayment
            ? formatPriceIntl(commonSettings, financeInfo.downPayment).primaryPrice
            : "",
        lastInstalment: financeInfo.lastInstalment
            ? formatPriceIntl(commonSettings, financeInfo.lastInstalment).primaryPrice
            : "",
        totalAmountFinanced: financeInfo.totalAmountFinanced
            ? formatPriceIntl(commonSettings, financeInfo.totalAmountFinanced).primaryPrice
            : "",
        amountFinanced: financeInfo.amountFinanced
            ? formatPriceIntl(commonSettings, financeInfo.amountFinanced).primaryPrice
            : "",
        totalCreditWithFees: financeInfo.totalCreditCostWithFee
            ? formatPriceIntl(commonSettings, financeInfo.totalCreditCostWithFee).primaryPrice
            : "",
        mileage: financeInfo.mileage || "",
        disclaimer: financeInfo.disclaimer?.value || "",
        margin: financeInfo.margin || "",
        euriborDate: financeInfo.euriborDate || "",
        euriborValue: financeInfo.euriborValue
            ? formatNumberString(financeInfo.euriborValue, commonSettings.culture.name)
            : "",
        euriborMonths: financeInfo.euriborMonths || "",
        installments: financeInfo.installments || "",
    };

    const financeLabels = formatMonthlyPrice(
        { ...getDefaultPriceObject(), monthly: monthlyPrice }, // TODO Update formatMonthlyPrice to only accept monthly price number.
        financeInfo,
        commonSettings,
        priceFormat,
        financeInfoReplacements,
        rateWithoutPromotions,
        true,
    );

    financeLabels.labels.forEach(({ key, value }) => {
        if (Object.values(EuriborMonthlyPriceLabelKey).includes(key as string as EuriborMonthlyPriceLabelKey)) {
            euriborFinanceLabels.push({ key, value });
        } else {
            monthlyFinanceLabels.push({ key, value });
        }
    });

    return {
        monthlyFinanceLabels,
        euriborFinanceLabels: euriborFinanceLabels.length ? euriborFinanceLabels : undefined,
        hasPromotion: financeLabels.hasPromotion,
        disclaimer: financeLabels.disclaimer,
    };
};

/**
 * Check if a cash price is a promotion.
 */
export const cashPriceIsPromotion = (cashPrice: UscCashPriceType): boolean => {
    const parsedCashPrice = uscPriceIsExclVat(cashPrice) ? cashPrice.cashPriceExclVat : cashPrice.cashPriceInclVat;
    const parsedPromoPrice = uscPriceIsExclVat(cashPrice)
        ? cashPrice.promoCashPriceExclVat
        : cashPrice.promoCashPriceInclVat;

    return parsedCashPrice !== parsedPromoPrice && parsedPromoPrice > 0;
};

/**
 * Get the cash price shown in the frontend from a UscCashPrice object (returning the correct promotion/vat price).
 */
export const getUscCashPriceValue = (cashPrice: UscCashPriceType): number => {
    const { cashPriceInclVat, cashPriceExclVat, promoCashPriceExclVat, promoCashPriceInclVat } = cashPrice;
    if (uscPriceIsExclVat(cashPrice)) {
        return cashPriceIsPromotion(cashPrice) ? promoCashPriceExclVat : cashPriceExclVat;
    } else {
        return cashPriceIsPromotion(cashPrice) ? promoCashPriceInclVat : cashPriceInclVat;
    }
};

type FormattedPrice = {
    primaryPrice: string;
    secondaryPrice?: string;
};

export type FormattedUscCashPriceType = {
    formattedCash: FormattedPrice;
    formattedDiscount: FormattedPrice;
    isPromotion: boolean;
};

/**
 * This function can be used to render a formatted cash price in the frontend.
 * Mostly usable in `xxxCashPrice` components.
 *
 * @param exVatLabel - This should be the uscExVatCashPriceFormat common label.
 * @param inclVatLabel - This should be the uscInclVatCashPriceFormat common label.
 */
export const formatUscCashPrice = (
    commonSettings: CommonSettingsType,
    cashPrice: UscCashPriceType,
    exVatLabel: string,
    inclVatLabel: string,
): FormattedUscCashPriceType => {
    const isExclVat = uscPriceIsExclVat(cashPrice);
    const isInclVat = uscPriceIsInclVat(cashPrice);
    const dualCurrencyEnabled = hasDualCurrency(commonSettings, false);

    const getPrice = (isDiscount = false): FormattedPrice => {
        // Determine the appropriate cash price value based on discount, VAT inclusion, and currency settings.
        const value = isDiscount
            ? isExclVat
                ? cashPrice.promoCashPriceExclVat
                : cashPrice.promoCashPriceInclVat
            : isExclVat
            ? cashPrice.cashPriceExclVat
            : cashPrice.cashPriceInclVat;

        const { primaryPrice, secondaryPrice } = formatPriceIntl(commonSettings, value, {}, dualCurrencyEnabled);

        if (dualCurrencyEnabled && secondaryPrice) {
            if (isExclVat) {
                return {
                    primaryPrice: exVatLabel.replace("%s", primaryPrice),
                    secondaryPrice: exVatLabel.replace("%s", secondaryPrice),
                };
            }

            if (isInclVat) {
                return {
                    primaryPrice: inclVatLabel.replace("%s", primaryPrice),
                    secondaryPrice: inclVatLabel.replace("%s", secondaryPrice),
                };
            }

            return {
                primaryPrice,
                secondaryPrice,
            };
        }

        return {
            primaryPrice: isExclVat
                ? exVatLabel.replace("%s", primaryPrice)
                : isInclVat
                ? inclVatLabel.replace("%s", primaryPrice)
                : primaryPrice,
        };
    };

    return {
        formattedCash: getPrice(),
        formattedDiscount: getPrice(true),
        isPromotion: cashPriceIsPromotion(cashPrice),
    };
};

/**
 * Returns true when the price should be shown.
 * ⚠️ Please also update the relevant UscDebugModal content if this ever changes ⚠️
 */
export const showUscPrice = (pricingSourceCode: DfPricingSourceCode | undefined | null): boolean => {
    return pricingSourceCode !== DfPricingSourceCode.PriceOnRequest;
};

/**
 * Check if price disclaimers (cash+monthly) should be shown as inline.
 * For now this is a TDK-only feature, so no setting for now.
 */
export const showUscInlinePriceDisclaimer = (country: string): boolean => country === "dk";

/**
 * Retrieve an UscCashPrice object from a USC vehicle.
 */
export const getUscCashPriceFromVehicle = (vehicle: UscVehicleType | null | undefined): UscCashPriceType => {
    return {
        cashPriceInclVat: vehicle?.price?.sellingPriceInclVAT || 0,
        cashPriceExclVat: vehicle?.price?.sellingPriceExclVAT || 0,
        promoCashPriceInclVat: vehicle?.promotion?.promotionPrice.sellingPriceInclVAT || 0,
        promoCashPriceExclVat: vehicle?.promotion?.promotionPrice.sellingPriceExclVAT || 0,
    };
};

/**
 * It's a legal requirement from TMI that the finance labels and the price are displayed as the same size,
 * for now we hardcode this (see UC-520).
 */
export const useBoldTextVariant = (country: string): boolean => country === "it";
