/**
 * Even though LocalStorage.ts and SessionStorage.ts exists, these utils offer a more robust approach to reading and storing storage data (and only for localStorage and sessionStorage data, no cookies).
 * Currently only used in Car-Filter and USC but can easily be used elsewhere as well.
 */

// ----------------------------------------------------------------------
// Base types and consts
// ----------------------------------------------------------------------
const contextMap = {
    new: "newCar",
    used: "usedCar",
    stock: "stockCar",
} as const;

export enum WebStorageType {
    local = "local",
    session = "session",
}

type ContextlessStorageType<T> = {
    [languageCountry: string]: T; // This is typing for the build as it does not require distiction between new/used/stock cars
};

export type StorageType<T> = {
    stockCar?: { [languageCountry: string]: T };
    usedCar?: { [languageCountry: string]: T };
    newCar?: { [languageCountry: string]: T };
};

export type StorageParams = {
    context?: "used" | "stock" | "new"; // No enum to keep compatibility with other components.
    country: string;
    language: string;
    storageId: string; // Usually something like "com.toyota.tme.t1.carFilter"
};

// ----------------------------------------------------------------------
// Storage fns
// ----------------------------------------------------------------------

const getStorage = (webStorageType: WebStorageType): Storage => {
    return webStorageType === WebStorageType.local ? localStorage : sessionStorage;
};

export const getStorageItem = <T>(params: StorageParams, webStorageType: WebStorageType): T | null => {
    let storage: Storage;
    try {
        // Trying to access local/sessions storage from inside an iframe in incognito mode throws an error, crashing the component.
        // This is not really a production issue, but added it to make development / debugging easier when dealing with local storage.
        storage = getStorage(webStorageType);
    } catch (exception) {
        return null;
    }

    const { context, country, language, storageId } = params;
    const data = storage.getItem(storageId);
    if (!data) return null;

    const languageCountry = `${language}-${country.toUpperCase()}`;

    if (context) {
        const parsedData: StorageType<T> = JSON.parse(data);
        const storageContext = contextMap[context];

        return parsedData[storageContext]?.[languageCountry] || null;
    } else {
        const parsedData: ContextlessStorageType<T> = JSON.parse(data);

        return parsedData[languageCountry] || null;
    }
};

/**
 * Set the current storage item. Note that this will overwrite the current item
 * @param params
 * @param storageItem
 * @param webStorageType - localstorage or sessionstorage
 */
export const setStorageItem = <T>(params: StorageParams, storageItem: T, webStorageType: WebStorageType): void => {
    const storage = getStorage(webStorageType);

    const { context, country, language, storageId } = params;
    const data = storage.getItem(storageId);
    const languageCountry = `${language}-${country.toUpperCase()}`;
    let objectToSave;

    // Make sure to either create the required properties or update the already existing ones.
    if (context) {
        objectToSave = (data ? JSON.parse(data) : {}) as StorageType<T>;
        const storageContext = contextMap[context];

        if (!objectToSave[storageContext]) {
            objectToSave[storageContext] = { [languageCountry]: storageItem };
        } else if (!objectToSave[storageContext]?.[languageCountry]) {
            objectToSave[storageContext]![languageCountry] = storageItem;
        } else {
            // Even though the typing should make sure that only properties from storageItem are present in storage make sure any unexpected values are not removed (as sometimes other parties can write to storage as well).
            // This is why the new storageItem is spread into the preexisting one.
            objectToSave[storageContext]![languageCountry] = {
                ...objectToSave[storageContext]![languageCountry],
                ...storageItem,
            };
        }
    } else {
        // If there is no context available. Just use country language (only used in build)
        objectToSave = (data ? JSON.parse(data) : {}) as ContextlessStorageType<T>;

        if (!objectToSave[languageCountry]) {
            objectToSave[languageCountry] = storageItem;
        } else {
            objectToSave[languageCountry] = {
                ...objectToSave[languageCountry],
                ...storageItem,
            };
        }
    }

    storage.setItem(storageId, JSON.stringify(objectToSave));
};
