import React, { useMemo, useRef } from "react";
import { LocationFilterIdType } from "../../../../utils/constants/filterConstants";
import Preloader from "../../../../../../common-deprecated/styles/toyota/Preloader";
import useLocationMap from "../../../../hooks/filters/useLocationMap";

const LocationMap = ({ filterId }: { filterId: LocationFilterIdType }): JSX.Element => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const { mapInitialized } = useLocationMap(mapContainer, filterId);
    // Only rerender when the preloader is toggled. Anything else should happen within the mapbox wrapper.
    return useMemo(
        () => (
            <>
                <div style={{ width: "100%", height: "100%" }} ref={mapContainer} />
                {!mapInitialized && <Preloader />}
            </>
        ),
        [mapInitialized],
    );
};

export default LocationMap;
