import { DefaultTheme } from "styled-components";
import { TridionThemeType } from "./common";

// empty colors can be removed after split-up,
// these are only relevant for Toyota
const color = {
    primary: "#1A1A1A",
    primaryHover: "#1A1A1A",
    lightest: "#FFF",
    dark: "#1A1A1A",
    grey1: "#F0F0F0",
    grey2: "#E3E6E7",
    grey3: "#F4F4F4",
    grey4: "#5B6164",
    grey5: "#B9C0C2",
    grey6: "",
    grey7: "",
    grey8: "",
    // Greys below are based on Toyota, not sure if relevant (yet) for Lexus.
    transparentGrey1: "rgba(0,0,0,0.5)",
    transparentGrey2: "rgba(240,240,240,0.65)",
    transparentGrey3: "rgba(108,112,115,0.65)",
    success: "#1A1A1A",
    info: "#1A1A1A",
    iconNeutral: "#1A1A1A",
    hybrid: "#1A1A1A",
    fuelCell: "#1A1A1A",
    orange: "",
    teal: "",
    deepPurple: "",
    cyan: "",
    border: "",
};

const fontFamily = {
    light: '"Nobel Light",Helvetica,Arial,Roboto,sans-serif',
    book: '"Nobel Book",Helvetica,Arial,Roboto,sans-serif',
    regular: '"Nobel",Helvetica,Arial,Roboto,sans-serif',
    bold: '"Nobel Bold",Helvetica,Arial,Roboto,sans-serif',
    displayRegular: '"Nobel",Helvetica,Arial,Roboto,sans-serif',
    displayBold: '"Nobel Bold",Helvetica,Arial,Roboto,sans-serif',
};

const textColor = {
    muted: color.dark,
    button: "#202020",
};

const spacer = 24; //default spacer, used for containers, rows and cols

const space = [0, 6, 12, 24, 48];

const breakpoints = {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
};

const zIndex = {
    popup: 900,
    fixed: 11000,
    sticky: 2050,
    overlay: 2100,
    modalBackdrop: 2200,
    modal: 2250,
    /* z-index below are from toyota, but needed to avoid typing errors */
    galleryNavHorizontal: 4,
    flipbook: 5,
    materialCloseButton: 10,
    compareScrollContainer: 665,
    compareMiniEditMenuSelection: 666,
    loaderLazy: 900,
    pageoverlayerInpage: 1000,
    miniCcDropdownMenu: 1001,
    navBar: 1010,
    pageoverlayerToolbar: 1040,
    expansionContent: 1050,
    ctabar: 1051,
    navSeconary: 1052,
    navPrimary: 1053,
    navbarMyToyota: 1054,
    pageoverlayerExpansion: 1056,
    mobileBasketFooter: 1057,
    materialbox: 1060,
    bubble: 1062,
    loaderFull: 1070,
    autocomplete: 1101,
    eprivacyDisclaimer: 1102, // should always have highest priority
};

const gradeListItemWidth = 285;

/* component specific */

/* COMPARE */
const compare: DefaultTheme["compare"] = {
    header: {
        height: "160px",
        background: color.grey3,
        borderRadius: "0",
        borderRadiusExpanded: "0",
        color: color.primary,
    },
    bar: {
        titleFontFamily: fontFamily.regular,
        titleFontSize: "17px",
        titleLineHeight: "25px",
        titleColor: color.primary,
        mainTitleColor: color.primary,
        subTitleColor: color.grey4,
        dividerColor: color.grey2,
    },
    entry: {
        titleFontFamily: fontFamily.regular,
        titleFontSize: "17px",
        titleLineHeight: "20px",
        titleColor: color.grey4,
        valueFontFamily: fontFamily.regular,
        valueColor: color.primary,
        valueFontSize: "22px",
        valueSuffixColor: color.primary,
        valueSuffixFontSize: "17px",
    },
    section: {
        titleFontFamily: fontFamily.regular,
        titleFontSize: "26px",
        titleColor: color.primary,
        titleTextTransform: "uppercase",
    },
    specs: {
        labelFontFamily: fontFamily.regular,
        labelFontSize: "17px",
        descriptionFontSize: "15px",
        descriptionFontColour: "#595D60",
        descriptionFontStyle: "normal",
        labelLineHeight: "20px",
        labelColor: color.grey4,
        labelMargin: "0 0 7px 0",
        valueFontFamily: fontFamily.regular,
        valueFontSize: "17px",
        valueColor: color.dark,
        valueFontLight: fontFamily.light,
    },
    swatches: {
        borderRadius: "100%",
    },
};

export const lexusTheme: TridionThemeType = {
    isLexus: true,
    isRTL: false, // This will be overwritten on component init.
    noContainerSpacing: false,
    breakpoints,
    color,
    colors: color, // Match styled-system font implementation.
    fontFamily,
    fonts: fontFamily, // Match styled-system font implementation.
    gradeListItemWidth,
    textColor,
    spacer,
    space,
    zIndex,
    compare,
};
