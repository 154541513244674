import styled from "styled-components";
import { toyotaTheme as theme } from "../../themes/toyotaTheme";

export const Wrapper = styled.div`
    margin: ${theme.space[3]}px 0;
    padding-top: ${theme.space[3]}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.grey3};
    border-top: 1px solid ${theme.colors.grey2};
`;

export const Title = styled.div`
    margin-bottom: ${theme.space[2]}px;
    font-family: ${theme.fonts.bold};
    text-transform: uppercase;
    color: ${theme.colors.dark};
`;

export const Text = styled.div`
    margin-bottom: ${theme.space[2]}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
`;
