import { css } from "styled-components";
import { theme } from "../theme/toyotaTheme";

export const focusStyle = css`
    &:focus-visible {
        outline: 2px solid ${theme.colors.outline};
        outline-offset: 2px;
    }

    @supports not selector(:focus-visible) {
        &:focus {
            outline: 2px solid ${theme.colors.outline};
            outline-offset: 2px;
        }
    }
`;
