import styled from "styled-components";

export const Wrapper = styled.div`
    padding-top: 20px;
`;

export const BrandTitle = styled.div`
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
`;
