import React, { useRef, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { CarFilterStateType, CarFilterStoreType, carFilterClientStore } from "./redux/store";
import { useInterval } from "../../common-deprecated/hooks/useInterval";

type PropsType = {
    initialState: CarFilterStateType;
    children: React.ReactNode;
};

const StoreWrapper = ({ initialState, children }: PropsType): JSX.Element => {
    const [retry, setRetry] = useState<number>(0);

    // Create a ref to store the store object
    // This is required to prevent the store from being recreated on every render
    const storeRef = useRef<CarFilterStoreType | undefined>(undefined);

    if (!initialState.carFilterSettings.useGlobalStore) {
        // No global store required. Initialize a new store object.
        storeRef.current = carFilterClientStore(initialState, initialState.commonSettings.component);
    } else if (window?.carFilterStore) {
        storeRef.current = window.carFilterStore;
        delete window.carFilterStore; // Delete the store from the window object to prevent it from being reused
    }

    useInterval(
        () => {
            setRetry(retry + 1);
        },
        storeRef.current ? null : 100,
    );

    if (storeRef.current) return <ReduxProvider store={storeRef.current}>{children}</ReduxProvider>;

    // Return empty fragment if store is not ready, this will be handled by a loading state
    // of the Car Filter component
    return <></>;
};

export default StoreWrapper;
