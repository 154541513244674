import styled, { css } from "styled-components";
import { getDirection } from "../../../../../../common-deprecated/themes/common";
import { breakpoints } from "../../../../../shared-logic/themes/toyotaTheme";
import IconWrapper from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const ScrollIndicator = styled.div`
    display: none;
    position: absolute;
    bottom: ${theme.space[5]}px;
    left: 50%;
    align-items: center;
    align-self: end;
    justify-content: center;
    width: 36px;
    height: 36px;
    color: ${theme.colors.primaryBlack};
    background-color: ${theme.colors.primaryWhite};
    border-radius: 100%;
    transform: translateX(-50%);
    z-index: 20;
`;

export const Wrapper = styled.div<{ scrollingVisible: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: ${theme.colors.primaryBlack};

    @supports (grid-template-rows: subgrid) {
        @media screen and (min-width: ${breakpoints.md}) {
            grid-template-rows: subgrid;
            display: grid;
            grid-row: auto / span var(--usc-car-result-subgrid-row, 6); /* This should be equal to the number of rows in the Wrapper of UsedCarResultStyles ! */
        }
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 56px;
        content: "";
        background: linear-gradient(0deg, rgba(40, 40, 48, 0) 0%, #282830 100%);
        z-index: 10;
    }

    &::after {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 140px;
        content: "";
        background: linear-gradient(180deg, rgba(40, 40, 48, 0) 0%, #282830 100%);
    }

    ${({ scrollingVisible }) =>
        scrollingVisible &&
        css`
            &::after {
                display: block;
            }

            ${ScrollIndicator} {
                display: flex;
            }
        `};
`;

export const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    @media screen and (min-width: ${breakpoints.md}) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    grid-row: 1 / -1;
    flex-direction: column;
    flex-grow: 1;
    padding: ${theme.space[3]}px;
    color: ${theme.colors.primaryWhite};
    overflow: auto;

    @media screen and (min-width: ${breakpoints.md}) {
        position: absolute;
        top: 0;
        padding: 56px ${theme.space[4]}px ${theme.space[4]}px;
    }
`;

export const Title = styled.h2`
    margin-bottom: ${theme.space[4]}px;
    font-family: ${theme.fonts.base};
    font-size: 3.4rem;
    font-weight: 400;
    line-height: 4rem;
    text-align: ${getDirection("left")};
    text-transform: none;
    color: ${theme.colors.primaryWhite};
`;

export const List = styled.ul`
    margin: 0 0 0 ${theme.space[3]}px;
    padding: 0;
`;

export const ListItem = styled.li`
    margin: 0 0 ${theme.space[2]}px;
    padding: 0;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryWhite};

    ${IconWrapper} {
        margin-${getDirection("right")}: ${theme.space[2]}px;
    }
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    padding: ${theme.space[3] / 2}px ${theme.space[3]}px;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    background-color: ${theme.colors.primaryWhite};
`;
