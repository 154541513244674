import styled from "styled-components";

export const Image = styled.img<{ show: boolean }>`
    height: auto;
    opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const Wrapper = styled.div<{ height?: number | undefined }>`
    display: flex;
    position: relative;
    width: 100%;
    max-height: 100%;
`;

export const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const Loader = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.color.grey1};
`;

export const PlaceholderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;
