import styled from "styled-components";

export const Container = styled.div`
    overflow: hidden;
`;

type PanelWrapperType = { currentPanel: number; totalPanelCount: number };
export const Wrapper = styled.div.attrs<PanelWrapperType>(({ currentPanel, totalPanelCount }) => ({
    style: { transform: `translateX(-${currentPanel * (100 / totalPanelCount)}%)` },
}))<PanelWrapperType>`
    display: flex;
    width: ${(props) => 100 * props.totalPanelCount}%;
    transition: transform 0.25s ease-in-out;
`;

export const ItemWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const Item = styled.div`
    height: 100%;
    text-align: left;
`;
