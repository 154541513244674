import { ModalsType, ModalIdsType } from "../../types/CommonTypes";

export const ADD_PRELOADED_MODAL: "modal/addPreloadedModal" = "modal/addPreloadedModal";
export const SHOW_MODAL: "modal/show" = "modal/show";
export const HIDE_MODAL: "modal/hide" = "modal/hide";
export const UPDATE_MODAL_SETTINGS: "modal/updateSettings" = "modal/updateSettings";
export const CLEAR_MODAL: "modal/clear" = "modal/clear";
export const CLEAR_ALL_MODALS: "modal/clearAll" = "modal/clearAll";

export type AddPreloadedModalType = {
    type: typeof ADD_PRELOADED_MODAL;
    settings: ModalsType;
    modalIndex: number;
};

export type ShowModalType = {
    type: typeof SHOW_MODAL;
    settings: ModalsType;
    modalIndex: number;
};
export type HideModalType = { type: typeof HIDE_MODAL; modalId?: ModalIdsType };

export type UpdateModalSettingsType = {
    type: typeof UPDATE_MODAL_SETTINGS;
    settings: Partial<ModalsType>;
    modalToUpdate: ModalIdsType;
};
export type ClearModalType = { type: typeof CLEAR_MODAL; modalId?: ModalIdsType };
export type ClearAllModalsType = { type: typeof CLEAR_ALL_MODALS };

/**
 * @description With the new way of rendering the modals this is a new dispatcher that can be used to preload modals. This will render the modal but keep it hidden
 * @param settings
 * @param modalIndex
 */
export const addPreloadedModal = (settings: ModalsType, modalIndex = 0): AddPreloadedModalType => ({
    type: ADD_PRELOADED_MODAL,
    settings,
    modalIndex,
});

export const showModal = (settings: ModalsType, modalIndex = 0): ShowModalType => ({
    type: SHOW_MODAL,
    settings,
    modalIndex,
});

/**
 * !! Only use this in edge cases where it is necessary, otherwise use the close functions of the {@link ModalProvider}
 * @description This is the new way of unrendering the modals where the modals check the show property and hide accordingly. This causes fewer rerenders and also allows for the modals to have closing animations. Using this might mean some refactor for the modal you are using
 * @param modalId
 */
export const hideModal = (modalId: ModalIdsType): HideModalType => ({ type: HIDE_MODAL, modalId });
export const updateModalSettings = (
    settings: Partial<ModalsType>,
    modalToUpdate: ModalIdsType,
): UpdateModalSettingsType => ({
    type: UPDATE_MODAL_SETTINGS,
    settings,
    modalToUpdate,
});

/**
 * !! Only use this in edge cases where it is necessary, otherwise use the close functions of the {@link ModalProvider}
 * @deprecated - This is used in applications where the webstandard dialog is not used yet. use hideModal instead when these criteria do not match your application.
 * @description - This is the old way of unrendering the modals where the modals are just cleared out of the DOM. This causes the modals to rerender everytime when shown and removes closing animations.
 * @param modalId
 */
export const clearModal = (modalId: ModalIdsType): ClearModalType => ({ type: CLEAR_MODAL, modalId });

export const clearAllModals = (): ClearAllModalsType => ({ type: CLEAR_ALL_MODALS });

export type ModalActionsType =
    | AddPreloadedModalType
    | ShowModalType
    | HideModalType
    | UpdateModalSettingsType
    | ClearModalType
    | ClearAllModalsType;
