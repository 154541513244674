import { DefaultTheme } from "styled-components";
import { toyotaTheme as toyotaThemeV1 } from "./toyotaTheme";
import { lexusTheme as lexusThemeV1 } from "./lexusTheme";
import { styledSystemTheme as toyotaThemeV2 } from "../styles/v2/toyota/theme/toyotaTheme";
import { styledSystemTheme as lexusThemeV2 } from "../styles/v2/lexus/theme/lexusTheme";
import { CommonSettingsType } from "../settings/fetchCommonSettings";
import { isLexus, isRTL } from "../settings/utils/commonSettingUtils";

// Get the correct theme depending on settings.
// This is in a separate file as we could not include this in common.js because webpack couldnt figure out the dependency (theme => common => theme)
const getTheme = (settings: CommonSettingsType): DefaultTheme => {
    const isRTLSetting = isRTL(settings);
    const isLexusSetting = isLexus(settings);
    const { noContainerSpacing } = settings.layoutOptions;

    // for now we also include the old V1 styles because not all build components were updated to aem styles yet
    return isLexusSetting
        ? { ...lexusThemeV1, ...lexusThemeV2, isRTL: isRTLSetting, noContainerSpacing }
        : { ...toyotaThemeV1, ...toyotaThemeV2, isRTL: isRTLSetting, noContainerSpacing };
};

export default getTheme;
