import { useEffect, useRef, useState } from "react";

/**
 * This hook returns the latest value that changed between max and min value.
 * Used for animations in min/max sliders.
 */
const useLatestMinMaxValue = (minValue: number, maxValue: number, defaultValue: number): number => {
    const minRef = useRef<number>(minValue);
    const maxRef = useRef<number>(maxValue);

    const [latestValue, setLatestValue] = useState<number>(defaultValue);

    useEffect(() => {
        if (minRef.current !== minValue) {
            minRef.current = minValue;
            setLatestValue(minValue);
        } else if (maxRef.current !== maxValue) {
            maxRef.current = maxValue;
            setLatestValue(maxValue);
        }
    }, [minValue, maxValue]);

    return latestValue;
};

export default useLatestMinMaxValue;
