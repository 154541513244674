import { aemToyotaCommonModalMap } from "../../../../../common-deprecated/components/modals/aem/modals";
import { ModalMapType } from "../../../../../common-deprecated/types/CommonTypes";
import IntegratedUscFormModal from "../../../../shared-components/toyota/modals/integrated-modal/v1/IntegratedUscFormModal";
import {
    CarFilterModalIdsType,
    MODAL_FILTER_CAR_FILTERS,
    MODAL_FILTER_CONFIG_HELPER,
    MODAL_FILTER_VEHICLE_SALE_TYPE,
    MODAL_FILTER_SORT_FILTERS,
} from "../../../utils/modalConstants";
import ConfigHelperModal from "./ConfigHelperModal";
import { MODAL_USC_FORM } from "../../../../shared-logic/utils/integrated-modal/integratedModalConstants";
import {
    MODAL_USC_INJECTION,
    MODAL_VEHICLE_FOR_SALE_ID,
    SharedUscModalIdsType,
} from "../../../../shared-logic/utils/modalConstants";
import VehicleForSaleIdModal from "../../../../shared-components/toyota/modals/vehicle-for-sale-id-modal/VehicleForSaleIdModal";
import CarFilterVehicleSaleTypeModal from "./CarFilterVehicleSaleTypeModal";
import CarFiltersModal from "./CarFiltersModal";
import SortFiltersModal from "./SortFiltersModal";
import UscInjectionModal from "./UscInjectionModal";

export const aemToyotaCarFilterModalMap: ModalMapType<CarFilterModalIdsType & SharedUscModalIdsType> = {
    ...aemToyotaCommonModalMap,
    [MODAL_FILTER_CONFIG_HELPER]: ConfigHelperModal,
    [MODAL_USC_FORM]: IntegratedUscFormModal,
    [MODAL_VEHICLE_FOR_SALE_ID]: VehicleForSaleIdModal,
    [MODAL_FILTER_VEHICLE_SALE_TYPE]: CarFilterVehicleSaleTypeModal,
    [MODAL_FILTER_CAR_FILTERS]: CarFiltersModal,
    [MODAL_FILTER_SORT_FILTERS]: SortFiltersModal,
    [MODAL_USC_INJECTION]: UscInjectionModal,
};
