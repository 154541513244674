import styled, { css } from "styled-components";
import { theme, ColorType } from "../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getDirection } from "../../../../common-deprecated/themes/common";
import { breakpoints } from "../../../shared-logic/themes/toyotaTheme";
import { focusStyle } from "../styles/utils/Accessibility.styles";

export const Backdrop = styled.div<{ isAuthor?: boolean; noscroll?: boolean; active: boolean }>`
    display: flex;
    position: fixed;
    overflow-y: auto;
    inset: 0;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.5s ease-out;
    opacity: 0;
    overflow: hidden;
    z-index: ${theme.zIndex.overlay};
    pointer-events: none;

    ${({ noscroll }) =>
        noscroll &&
        css`
            overflow-y: auto;
        `}

    ${({ active }) =>
        active &&
        css`
            opacity: 1;
            pointer-events: all;
        `}

    @supports not (inset: 0) {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export const Dialog = styled.div<{ size?: string; active?: boolean; transitionEnd?: boolean }>`
    @keyframes show-modal {
        from {
            transform: translateY(100vh);
        }

        to {
            transform: translateY(0);
        }
    }

    @keyframes hide-modal {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(100vh);
        }
    }

    display: flex;
    position: fixed;
    bottom: 0;
    flex-direction: column;
    align-self: center;
    width: auto;
    min-width: 320px;
    min-height: 100px;
    max-height: 720px;
    background-color: #fff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    z-index: calc(1060 + 2); /* Show dialog above sticky website navigation */
    backface-visibility: hidden;
    ${({ active }) =>
        css`
            animation: ${active ? "show-modal" : "hide-modal"} 0.5s ease-out;
        `}

    @media screen and (min-width: ${breakpoints.md}) {
        min-width: 450px;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        bottom: auto;
        max-width: 1200px;
        border-radius: 16px;
    }

    ${({ size }) =>
        size === "small" &&
        css`
            width: 100vw;
            height: 100%;
            max-height: calc(100% - 60px);

            @media screen and (min-width: ${breakpoints.md}) {
                width: 720px;
                height: 100%;
            }

            @media screen and (min-width: ${breakpoints.lg}) {
                --body-spacing-block: ${theme.space[3]}px;
                --body-spacing-inline: ${theme.space[3]}px;
                /* Calculate modal height + center position */
                --modal-spacing-y: 100px;
                --modal-height: calc(100dvh - (var(--modal-spacing-y) * 2));
                --top: var(--modal-spacing-y);
                width: 1140px;
                height: var(--modal-height);
                top: var(--top);
                max-height: var(--modal-height);
            }
        `}

    ${({ size }) =>
        size === "medium" &&
        css`
            --header-spacing-inline: ${theme.space[2]}px;
            --header-bg-color: ${theme.colors.grey1};
            --body-spacing-inline: ${theme.space[3]}px;

            width: 100vw;
            height: 100%;
            max-height: calc(100% - 60px);

            @media screen and (min-width: ${breakpoints.lg}) {
                --header-spacing-inline: 0;
                --header-spacing-block: 0 ${theme.space[5]}px;
                --header-bg-color: ${theme.colors.primaryWhite};
                --body-spacing-inline: 0;
                --body-spacing-block: 0;
                /* Calculate modal height + center position */
                --modal-spacing-y: 40px;
                --modal-height: calc(100dvh - (var(--modal-spacing-y) * 2));
                --top: var(--modal-spacing-y);
                width: 1140px;
                height: var(--modal-height);
                top: var(--top);
                max-height: var(--modal-height);
                padding: ${theme.space[5]}px;
            }
        `}

    ${({ size, active }) =>
        size === "fullscreen" &&
        css`
            width: 100vw;
            max-height: 100%;
            height: calc(100dvh - 4rem);

            border-radius: 16px 16px 0 0;

            @media screen and (min-width: ${breakpoints.md}) {
                transform: ${active ? "translateY(0)" : "translateY(calc(100dvh - 40px))"};
            }

            @media screen and (min-width: ${breakpoints.lg}) {
                --body-spacing-block: 56px;
                bottom: 0;
                max-width: 100%;
                border-radius: 16px 16px 0 0;
            }
        `};

    ${({ size }) =>
        size === "fullwidthautoheight" &&
        css`
            --header-bg-color: ${theme.colors.grey1};
            width: 100vw;
            max-width: 100%;
            height: auto;
            max-height: calc(100% - 16px);
            border-radius: 16px 16px 0 0;

            @media screen and (min-width: ${breakpoints.lg}) {
                bottom: 0;
                height: auto;
                border-radius: 16px 16px 0 0;
            }
        `};

    ${({ size }) =>
        size === "fullwidth" &&
        css`
            width: 100vw;
            max-width: 100%;
            height: calc(100dvh - 180px);

            border-radius: 16px 16px 0 0;

            @media screen and (min-width: ${breakpoints.lg}) {
                bottom: 0;
                height: auto;
                border-radius: 16px 16px 0 0;
            }
        `};

    ${({ size }) =>
        size === "fillwindow" &&
        css`
            width: 100vw;
            min-width: 100vw;
            max-width: 100%;
            height: 100dvh;
            max-height: none;

            border-radius: 0;

            @media screen and (min-width: ${breakpoints.md}) {
                bottom: 0;
                left: 0;
            }
            @media screen and (min-width: ${breakpoints.lg}) {
                max-width: none;
                border-radius: 0;
            }
        `};

    ${({ size }) =>
        size === "external" &&
        css`
            --button-position: absolute;
            --button-inset-block-start: ${theme.space[2]}px;
            --button-inset-inline-end: ${theme.space[3]}px;

            width: 100vw;
            height: 100%;
            max-height: calc(100dvh - 4rem);

            @media screen and (min-width: ${breakpoints.lg}) {
                --header-spacing-inline: 0;
                --header-spacing-block: 0;
                --body-spacing-inline: 0;
                --body-spacing-block: 0;
                /* Calculate modal height + center position */
                --modal-spacing-y: 4rem;
                --modal-height: calc(100dvh - (var(--modal-spacing-y) * 2));
                --top: var(--modal-spacing-y);
                width: 1140px;
                height: var(--modal-height);
                top: var(--top);
                max-height: var(--modal-height);
                padding: ${theme.space[2]}px ${theme.space[5]}px;
            }
        `};

    ${({ size }) =>
        size === "fitcontent" &&
        css`
            bottom: auto;
            width: 100vw;
            height: 100%;
            max-height: calc(100% - 60px);
            border-radius: 16px;

            @media screen and (min-width: ${breakpoints.md}) {
                width: 720px;
                height: auto;
            }

            @media screen and (min-width: ${breakpoints.lg}) {
                --body-spacing-block: ${theme.space[3]}px;
                --body-spacing-inline: ${theme.space[3]}px;
                /* Calculate modal height + center position */
                --modal-spacing-y: 50%
                --modal-height: calc(100dvh - (var(--modal-spacing-y) * 2));
                --top: var(--modal-spacing-y);
                width: 1140px;
                top: var(--top);
                max-height: var(--modal-height);
            }
        `}

    ${({ transitionEnd }) =>
        transitionEnd &&
        css`
            transform: none;
        `};
`;

export const Header = styled.header<{ bgColour?: ColorType; noBorder?: boolean }>`
    --_spacing-block: var(--header-spacing-block, 16px);
    --_spacing-inline: var(--header-spacing-inline, 24px);
    --_header-bg-color: var(--header-bg-color, ${theme.colors.primaryWhite});
    --button-position: absolute;
    --button-inset-block-start: ${theme.space[2]}px;
    --button-inset-inline-end: ${theme.space[2]}px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    min-height: 50px; /* Avoids that the scrollbar overlaps the modal close button */
    padding: ${theme.space[2]}px;
    background-color: var(--_header-bg-color);
    border-bottom: 1px solid ${theme.colors.grey2};
    z-index: ${theme.zIndex.sticky};

    /* Alignment title */
    /* Line-height should be the same height as the height of the button */
    h2 {
        line-height: 3.2rem;
    }

    @media screen and (min-width: ${breakpoints.md}) {
        padding-block: var(--_spacing-block);
        padding-inline: var(--_spacing-inline);
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        --button-inset-inline-end: ${theme.space[3]}px;

        align-items: flex-start;
        padding-block: var(--_spacing-block);
        padding-inline: var(--_spacing-inline);

        /* Alignment title */
        h2 {
            line-height: 4rem;
        }
    }

    ${({ bgColour }) =>
        bgColour &&
        css`
            background-color: ${bgColour};
        `};

    ${({ noBorder }) =>
        noBorder &&
        css`
            border-bottom: none;
        `};
`;

export const Body = styled.div<{ noSpacing?: boolean; noVerticalSpacing?: boolean; noHorizontalSpacing?: boolean }>`
    --_spacing-inline: var(--body-spacing-inline, ${theme.space[2]}px);
    --_spacing-block: var(--body-spacing-block, ${theme.space[2]}px);

    flex-grow: 1;
    padding-block: var(--_spacing-block);
    padding-inline: var(--_spacing-inline);
    overflow: hidden;
    overflow-y: auto;

    @media screen and (min-width: ${breakpoints.lg}) {
        padding-block: var(--_spacing-block);
        padding-inline: var(--_spacing-inline);
    }

    ${(props) =>
        props.noSpacing &&
        css`
            padding: 0;
            @media screen and (min-width: ${breakpoints.lg}) {
                padding: 0;
            }
        `};

    ${(props) =>
        props.noHorizontalSpacing &&
        css`
            padding-inline: 0;
            @media screen and (min-width: ${breakpoints.lg}) {
                padding-inline: 0;
            }
        `};

    ${(props) =>
        props.noVerticalSpacing &&
        css`
            padding-block: 0;
            @media screen and (min-width: ${breakpoints.lg}) {
                padding-block: 0;
            }
        `};
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
`;

export const Button = styled.button`
    --_button-position: var(--button-position, static);
    --_button-inset-block-start: var(--button-inset-block-start, auto);
    --_button-inset-inline-end: var(--button-inset-inline-end, auto);

    display: flex;
    position: var(--_button-position);
    inset-block-start: var(--_button-inset-block-start);
    inset-inline-end: var(--_button-inset-inline-end);
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: ${theme.colors.primaryWhite};
    background-color: ${theme.colors.primaryBlack};
    border-radius: 50%;

    ${focusStyle};

    @supports not (inset-block-start: 0) {
        top: var(--_button-inset-block-start);
        ${getDirection("right")}: var(--_button-inset-inline-end);
    }
`;
