import React from "react";
import * as Styles from "./styles/ResultPlaceholderImageStyles";
import Placeholder from "../../../../../../common-deprecated/svg/toyota/placeholder.svg";

const ResultPlaceholderImage = (): JSX.Element => (
    <Styles.PlaceholderWrapper>
        <Placeholder data-testid="result-image-placeholder" />
    </Styles.PlaceholderWrapper>
);

export default ResultPlaceholderImage;
