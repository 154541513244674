import React from "react";
import * as ButtonStyles from "../../../styles/v2/toyota/components/Button";

type ButtonProps = {
    href?: string;
    onClick?: (event: any) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    target?: string;
    rel?: string;
    classNames?: string;
    children: React.ReactNode;
    hasIcon?: boolean;
    hasIconRight?: boolean;
    leftAlign?: boolean;
    tabIndex?: number;
    stretchedLink?: boolean;
    disableBorder?: boolean;
};

/**
 * ButtonTertiary - Button Link style
 * @example
 *   <ButtonTertiary>
 *     Label
 *   </ButtonTertiary>
 */
const ButtonTertiary = (props: ButtonProps): JSX.Element => {
    const {
        href,
        onClick,
        disabled,
        fullWidth,
        children,
        target,
        classNames,
        rel,
        hasIcon,
        hasIconRight,
        leftAlign,
        tabIndex,
        stretchedLink,
        disableBorder,
    } = props;

    return (
        <ButtonStyles.ButtonTertiary
            as={href ? "a" : "button"}
            role={href ? "link" : "button"}
            href={href && !disabled ? href : undefined}
            type={!href ? "button" : undefined}
            className={[classNames || "", disabled ? "disabled" : ""].join(" ")}
            disabled={!href && disabled}
            aria-disabled={disabled || undefined}
            target={href ? target : undefined}
            rel={href && rel}
            onClick={onClick}
            fullWidth={fullWidth}
            hasIcon={hasIcon}
            hasIconRight={hasIconRight}
            leftAlign={leftAlign}
            tabIndex={tabIndex}
            stretchedLink={stretchedLink}
            disableBorder={disableBorder}
        >
            {children}
        </ButtonStyles.ButtonTertiary>
    );
};

export default ButtonTertiary;
