import {
    Action,
    applyMiddleware,
    compose,
    createStore,
    Reducer,
    ReducersMapObject,
    Store,
    combineReducers,
} from "redux";
import { configureStore, EnhancedStore, PreloadedState } from "@reduxjs/toolkit";
import { createLogicMiddleware, Logic } from "redux-logic";
import { CommonSettingsType } from "../settings/fetchCommonSettings";
import { CommonStateType } from "../utils/commonLabels";

const composeEnhancers = (
    id: string,
    country: string = "",
    language: string = "",
    env: string = "",
    brand: string = "",
): typeof compose => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return typeof window !== "undefined" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsCreators, serialize...
              // see https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
              name: `${id} - ${country}/${language} - ${env} ${brand}`,
              id: `${id} - ${country}/${language} - ${env} ${brand}`,
              maxAge: 100, // Spinner fires lots of actions, so increased default value (50) to 100 to avoid instant clears when the spinner is used.
              // Popup uses dom nodes in Redux state. Sanitize the output to prevent the debugger from crashing.
              // Don't use the popup show action constant as we want to prevent webpack from adding popup action/reducers to every component build.
              actionSanitizer: (action: Action) =>
                  action.type === "popup/show" ? { ...action, origElement: "<<DOM_NODE>>" } : action,
              stateSanitizer: (
                  state: any, // eslint-disable-line @typescript-eslint/no-explicit-any
              ) =>
                  state.popup && state.popup.origElement
                      ? { ...state, popup: { ...state.popup, origElement: "<<DOM_NODE>>" } }
                      : state,
          })
        : compose;
};

export const getInitStore =
    (reducers: Reducer, logic: Logic[] = []) =>
    <S extends { [x: string]: any } | undefined = any>(
        state: Partial<S> | undefined,
        name: string = "",
        attachLogicMiddleware: boolean = false,
    ): Store<S> => {
        if (logic.length) {
            const storeLogic = createLogicMiddleware(logic);
            const store = createStore(
                reducers,
                state as PreloadedState<S>,
                composeEnhancers(name)(applyMiddleware(storeLogic)),
            );

            // Attach logic if required.
            // TODO Remove
            // This enables accessing store.logicMiddleware.whenComplete which can be used when we want to know when the store is done executing logic.
            // Based on https://github.com/jeffbski/redux-logic/issues/57
            // @ts-ignore
            if (attachLogicMiddleware) store.logicMiddleware = storeLogic;
            // @ts-ignore
            return store;
        }

        return createStore(reducers, state as PreloadedState<S>, composeEnhancers(name)());
    };

const addExtraStoreHelpers = (
    store: Store,
    reducers: ReducersMapObject,
    storeLogic: ReturnType<typeof createLogicMiddleware> | null,
): void => {
    // Add custom logicMiddleware function to the store
    if (storeLogic) store.logicMiddleware = storeLogic;

    // Attach original reducers to the store.
    (store as Store & { baseReducers: ReducersMapObject }).baseReducers = reducers;

    // Add custom addReducers function to the store
    store.addReducers = (newReducers: ReducersMapObject): void => {
        if (newReducers.commonSettings) delete newReducers.commonSettings;
        if (newReducers.settings) delete newReducers.settings;

        const newBaseReducers = {
            ...(store as Store & { baseReducers: ReducersMapObject }).baseReducers,
            ...newReducers,
        };
        const combinedReducers = combineReducers(newBaseReducers);

        store.replaceReducer(combinedReducers);

        // Update baseReducers so the addReducers can be used multiple times
        (store as Store & { baseReducers: ReducersMapObject }).baseReducers = newBaseReducers;
    };
};

// TODO migrate getInitStore to getInitStoreByReducers.
/**
 * Get an initStore function by providing the reducers and logic.
 *
 * This function also adds a addReducers function to the store which will add/replace a given set of reducers to the store.
 */
export const getInitStoreByReducers =
    (reducers: ReducersMapObject, logic: Logic[] = []) =>
    <S extends { [x: string]: any } | undefined = any>(state: Partial<S> | undefined, name: string = ""): Store<S> => {
        let store: Store;
        const combinedReducers = combineReducers(reducers);

        const wrappedComposeEnhancers = (): typeof compose => {
            // Doing some TS overriding here as the typing here is very broad anyway.
            // Most components should have commonSettings in the reducers as they will probably break if they dont.
            if ((state as any)?.commonSettings) {
                const { country, language, environment, brand } = (state as any).commonSettings as CommonSettingsType;
                return composeEnhancers(name, country, language, environment, brand);
            }
            return composeEnhancers(name);
        };

        const storeLogic = logic.length ? createLogicMiddleware(logic) : null;

        if (storeLogic) {
            store = createStore(
                combinedReducers,
                state as PreloadedState<S>,
                wrappedComposeEnhancers()(applyMiddleware(storeLogic)),
            );
        } else {
            store = createStore(combinedReducers, state as PreloadedState<S>, wrappedComposeEnhancers()());
        }

        addExtraStoreHelpers(store, reducers, storeLogic);

        return store;
    };

export const getInitToolkitStore =
    (reducers: ReducersMapObject, logic: Logic[] = []) =>
    <S = any>(state: Partial<S> | undefined, name: string = ""): EnhancedStore<S> => {
        let storeName = name;

        if ((state as unknown as CommonStateType)?.commonSettings) {
            const { country, language, environment, brand } = (state as unknown as CommonStateType)
                .commonSettings as CommonSettingsType;

            storeName = `${name} - ${country}/${language} - ${environment} ${brand}`;
        }
        const storeLogic = createLogicMiddleware(logic);
        const store = configureStore({
            reducer: reducers,
            middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(storeLogic),
            devTools: {
                name: storeName,
                maxAge: 100,
            },
            preloadedState: state,
        });

        addExtraStoreHelpers(store, reducers, storeLogic);

        return store;
    };
