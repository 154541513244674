// The equivalent of the Tridion flexibility matrix sections.
import { LicenseFeesType, PriceConfigType } from "../types/CommonTypes";

export enum AemFmComponent {
    All = "all",
    KeySpecs = "keyspecs",
    EngineSelector = "engineselector",
    CarHero = "carhero",
    CarHeroGrade = "carherograde",
    Compare = "compare",
    ModelFilter = "modelfilter",
    OptionalEquipment = "optionalequipment",
    Build = "build",
    CarFilterStock = "stockcarfilter", // If any other specific used/stock versions of components are added consider reworking how OR parses these.
    CarFilterUsed = "usedcarfilter",
    Pdp = "pdp",
    LeasingFilter = "leasingfilter",
    LeasingDetails = "leasingdetails",
    Pricelist = "pricelist",
    Carconfig = "aphcarconfig",
    GradeSelector = "aphgradeselector",
}

// Tridion version: flexMatrixPriceTypes
// TODO See OR-5317 for implementation of "in-hide-discount-info" and "ex-hide-discount-info".
export enum AemFmPriceType {
    InclVat = "include",
    ExclVat = "exclude",
}

export type AemPriceConfigType = PriceConfigType;

// ----------------------------------------------------------------------
// Parsed AEM Flexibility matrix
// ----------------------------------------------------------------------
export type AemFmConfig = {
    component: AemFmComponent;
    config: {
        modelCode?: string;
        context?: "new" | "used" | "stock";
        price: {
            type: AemFmPriceType;
            enabled: boolean;
            format?: string;
            exactFormat?: string;
            licenseFees?: LicenseFeesType;
            strikethroughDisabled?: boolean;
        };
        insurance: {
            enabled: boolean;
            format?: string;
        };
        finance: {
            enabled: boolean;
            format?: string;
        };
    };
    lastModified: string;
    path: string | null;
};

export type ParsedAemFlexibilityMatrix = {
    default: AemFmConfig[];
    custom: AemFmConfig[];
};

// ----------------------------------------------------------------------
// AEM Flexibility matrix response
// ----------------------------------------------------------------------
export type FlexibilityModel = {
    priceModel: {
        priceType: AemFmPriceType;
        priceDisabled: boolean;
        priceFormat?: string;
        exactFormat?: string;
        licenseFees?: LicenseFeesType;
        strikethroughDisabled: boolean;
    };
    insuranceModel: {
        insuranceEnabled: boolean;
        insuranceFormat?: string;
    };
    financeModel: {
        financeEnabled: boolean;
        financeFormat?: string;
    };
};

export type DefaultModel = {
    component: AemFmComponent;
    flexibilityModel: FlexibilityModel & {
        overrideModel?: string;
        model?: string;
    };
    lastModified: string;
    lastModifiedBy: string;
};

export type CustomModel = {
    component: AemFmComponent;
    flexibilityModel: FlexibilityModel & {
        model?: string;
        overrideModel?: string; // Only model-filter uses the overrideModel (for now)
        uscContext?: "used" | "stock"; // Only used in USC PDP for now, car-filter has its own component name.
    };
    path: string;
    lastModified: string;
    lastModifiedBy: string;
};

export type AemFlexibilityMatrix = {
    default: DefaultModel[];
    custom: CustomModel[];
};
