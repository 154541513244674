import styled, { css } from "styled-components";

/**
 * LineClamp is a utility component that will proper truncate a string to a certain number of lines.
 * You must use this function inside a styled-component to apply the line-clamp css property.
 * @example
 * export const Title = styled(Type.H2)`
 *   ${lineClamp};
 *   font-family: ${theme.fonts.regular};
 *   font-size: 2rem;
 * `;
 */
export const lineClampStyle = css<{ lines?: number }>`
    --line-clamp: ${({ lines }) => lines || 2};
    /* stylelint-disable */
    display: -webkit-box;
    max-width: 100%;
    line-clamp: var(--line-clamp);
    -webkit-line-clamp: var(--line-clamp);
    box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: var(--word-break, "none");
    overflow: hidden;
    /* Hyphenation rules are language-specific. In HTML, the language is determined by the lang attribute, and browsers will hyphenate only if this attribute is present and the appropriate hyphenation dictionary is available. */
    /* With a soft-hyphen character (&shy;), the user can improve the place where the word will break. */
    hyphens: auto;
    white-space: normal;

    /* Automatically use "word-break: break-all" for single lines */
    /* (https://css-tricks.com/css-switch-case-conditions) */
    --is-single-line: 1 - clamp(0, calc(var(--line-clamp) - 1), var(--line-clamp));
    --delay: calc(-1s * (var(--is-single-line, 1) - 1));
    animation: states 1s var(--delay) paused;
    /* stylelint-enable */
    @keyframes states {
        0% {
            word-break: break-word;
        }
    }
`;

/**
 * Lineclamp
 * A helper component to easy truncate text without creating a new component
 */
export const LineClamp = styled.span<{ lines?: number }>`
    &,
    & > span {
        ${lineClampStyle};
    }
`;
