import styled, { css } from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getDirection } from "../../../../../../common-deprecated/themes/common";

export const Wrapper = styled.nav`
    --spacing-top: ${theme.space[5]}px;
    --spacing-bottom: ${theme.space[6]}px;
    display: flex;
    justify-content: center;
    margin: var(--spacing-top) 0 var(--spacing-bottom) 0;
`;

export const PaginationList = styled.ul`
    display: flex;
    align-items: center;
    gap: ${theme.space[3] / 2}px;
    margin: 0;
    padding: 0;
    list-style: none;

    @supports not (gap: 0) {
        & > li:not(:nth-last-child(1)) {
            margin-${getDirection("right")}: ${theme.space[3] / 2}px;
        }
    }
`;

export const Button = styled.button<{
    isActive?: boolean;
    isHidden?: boolean;
    hasOnlyIcon?: boolean;
    disabled?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ hasOnlyIcon }) => (hasOnlyIcon ? "4px 0 4px" : "4px 8px 4px")};
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ hasOnlyIcon }) => (hasOnlyIcon ? theme.colors.primaryBlack : theme.colors.grey7)};
    border-bottom: 2px solid transparent;
    &:hover,
    &:focus {
        color: ${theme.colors.primaryBlack};
        border-bottom-color: ${theme.colors.primaryBlack};
    }
    ${({ isActive }) =>
        isActive &&
        css`
            color: ${theme.colors.primaryBlack};
            border-bottom-color: ${theme.colors.primaryBlack};
        `};
    ${({ isHidden }) =>
        isHidden &&
        css`
            visibility: hidden;
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `};
`;

export const Dots = styled.span`
    align-self: flex-start;
    padding: 0 4px;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.grey7};
`;
