import React from "react";
import * as ButtonStyles from "../../../styles/v2/toyota/components/Button";
import * as Text from "../../../styles/v2/toyota/utilities/Text";

type ButtonProps = {
    key?: number;
    href?: string;
    onClick?: (event: React.MouseEvent) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    target?: string;
    rel?: string;
    classNames?: string;
    children: React.ReactNode;
    stopPropagation?: boolean;
};

/**
 * ButtonSecondary - Button Outline style
 * @example
 *   <ButtonSecondary>
 *     <Text.Button>Label</Text.Button>
 *   </ButtonSecondary>
 */
const ButtonSecondary = (props: ButtonProps): JSX.Element => {
    const { key, href, onClick, disabled, fullWidth, children, target, classNames, rel } = props;
    return (
        <ButtonStyles.ButtonSecondary key={key} fullWidth={fullWidth}>
            <Text.Button
                as={href ? "a" : "button"}
                role={href ? "link" : "button"}
                href={href && !disabled ? href : undefined}
                type={!href ? "button" : undefined}
                className={[classNames || "", disabled ? "disabled" : ""].join(" ")}
                disabled={!href && disabled}
                aria-disabled={disabled || undefined}
                target={href ? target : undefined}
                rel={href && rel}
                onClick={(event: React.MouseEvent) => {
                    if (props.stopPropagation) event.stopPropagation();

                    if (onClick) onClick(event);
                }}
            >
                {children}
            </Text.Button>
        </ButtonStyles.ButtonSecondary>
    );
};

export default ButtonSecondary;
