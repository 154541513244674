import { getStorageItem, setStorageItem, WebStorageType } from "../../../common-deprecated/utils/storageUtils";
import { UscContext } from "../../shared-logic/types/UscCommonTypes";

// ----------------------------------------------------------------------
// Helper types and consts
// ----------------------------------------------------------------------
const LOCAL_STORAGE_ID = "com.toyota.tme.t1.carFilter";

export type SupportedSaveContexts = UscContext.Used | UscContext.Stock;

// These fields are optional to catch any implementation bugs at T1 side as well.
export type CarFilterLocalStorageItemType = {
    favouriteCars?: { vehicleForSaleIds?: string[] }; // This property is shared with the T1 Tridion PDP
};

// Type used in the functions for easy param propagation.
type CarFilterLocalStorageParams = {
    context: UscContext;
    country: string;
    language: string;
};

// ----------------------------------------------------------------------
// Storage fns
// ----------------------------------------------------------------------

/**
 * Get the car filter local storage object if available.
 */
export const getCarFilterLocalStorage = (params: CarFilterLocalStorageParams): CarFilterLocalStorageItemType | null => {
    return getStorageItem({ ...params, storageId: LOCAL_STORAGE_ID }, WebStorageType.local);
};

/**
 * Set car filter local storage, only overwriting the values supplied in the storageItem param.
 */
export const setCarFilterLocalStorage = (
    params: CarFilterLocalStorageParams,
    storageItem: CarFilterLocalStorageItemType,
): void => {
    setStorageItem({ ...params, storageId: LOCAL_STORAGE_ID }, storageItem, WebStorageType.local);
};

/**
 * Returns an array of favourite vehicleForSaleIds.
 */
export const getSavedCars = (params: CarFilterLocalStorageParams): string[] => {
    return getCarFilterLocalStorage(params)?.favouriteCars?.vehicleForSaleIds || [];
};

/**
 * Returns an array of favourite vehicleForSaleIds.
 */
export const setSavedCars = (params: CarFilterLocalStorageParams, vehicleForSaleIds: string[]): void => {
    setCarFilterLocalStorage(params, { favouriteCars: { vehicleForSaleIds } });
};

// ----------------------------------------------------------------------
// Storage fns only for usc (car filter uses redux actions for adding/removing saved cars !!)
// ----------------------------------------------------------------------

/**
 * Adds an id to the list of favourite vehicleForSaleIds .
 */
export const addSavedCar = (params: CarFilterLocalStorageParams, vehicleForSaleId: string): void => {
    const savedCars = getSavedCars(params);
    const alreadySaved = savedCars.findIndex((id) => id === vehicleForSaleId) !== -1;
    if (!alreadySaved) setSavedCars(params, [...savedCars, vehicleForSaleId]);
};

/**
 * Removes an id from the list of favourite vehicleForSaleIds .
 */
export const removeSavedCar = (params: CarFilterLocalStorageParams, vehicleForSaleId: string): void => {
    const savedCars = getSavedCars(params);
    const filteredSavedCars = savedCars.filter((id) => id !== vehicleForSaleId);
    setSavedCars(params, filteredSavedCars);
};

/**
 * Return true if vehicleForSaleId is inside list of favourite vehicleForSaleIds.
 */
export const isSavedCar = (params: CarFilterLocalStorageParams, vehicleForSaleId: string): boolean => {
    const savedCars = getSavedCars(params);
    return savedCars.findIndex((id) => id === vehicleForSaleId) !== -1;
};
