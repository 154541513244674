import React from "react";
import * as Styles from "./styles/UsedCarResultStyles";
import { IconWrapper } from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import IconDelivery from "../../../../../../common-deprecated/svg/toyota/aem/truck-v2.svg";
import { Ellipsis } from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Ellipsis";
import { useCarFilterSelector } from "../../../../redux/store";
import { UsedCarFilterId, UsedCarResultType } from "../../../../../shared-logic/types/UscCommonTypes";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import { doesDealerOfferCarDelivery } from "../../../../../shared-logic/utils/uscUtils";

const UsedCarResultDeliveryBadge = ({ result }: { result: UsedCarResultType }): JSX.Element | null => {
    const deliverableFilter = useCarFilterSelector((state) => state.carFilters[UsedCarFilterId.Deliverable]);
    const availableForDeliveryLabel = useCarFilterLabel("carFilterAvailableForDelivery");

    // Only show the badge if the deliverable filter is configured and the current car is deliverable.
    // We can assume it is configured when the filter has more than one value.
    const showDeliveryBadge = deliverableFilter.values.length > 0 && doesDealerOfferCarDelivery(result.dealer);

    return showDeliveryBadge ? (
        <Styles.DeliveryBadge>
            <IconWrapper>
                <IconDelivery />
            </IconWrapper>
            <Ellipsis maxWidth="100%">{availableForDeliveryLabel}</Ellipsis>
        </Styles.DeliveryBadge>
    ) : null;
};

export default UsedCarResultDeliveryBadge;
