import styled, { css, keyframes } from "styled-components";
import { theme } from "../theme/toyotaTheme";

const backdropAnimation = keyframes`
    0% { opacity: 0; }
    100% { opacity: .4; }
`;

export const Backdrop = styled.div<{ zIndex?: number }>`
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    content: "";
    background: ${theme.colors.primaryBlack};
    cursor: auto;
    opacity: 0;
    animation: ${backdropAnimation} forwards 0.5s;
    z-index: ${theme.zIndex.overlay};

    ${({ zIndex }) =>
        zIndex &&
        css`
            z-index: ${zIndex};
        `};
`;

export default Backdrop;
