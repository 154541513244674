import styled from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { maxBreakpoints } from "../../../../../shared-logic/themes/toyotaTheme";
import * as FilterResultCountStyles from "./styles/FilterResultCountStyles";

export const Wrapper = styled.div`
    display: none;
    @media screen and (max-width: ${maxBreakpoints.md}) {
        display: block;
        position: fixed;
        bottom: 0;
        inset-inline: 0;
        width: 100vw;
        background-color: ${theme.colors.primaryWhite};
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        box-shadow: 0px -15px 80px 0px rgba(0, 0, 0, 0.04), 0px -4.52px 24.118px 0px rgba(0, 0, 0, 0.05),
            0px -1.88px 10.017px 0px rgba(0, 0, 0, 0.03), 0px -0.68px 3.623px 0px rgba(0, 0, 0, 0.02);
        z-index: ${theme.zIndex.fixed - 1}; /* z-index should be 1 lower then primary navigation */

        @supports not (inset-inline: 0) {
            left: 0;
            right: 0;
        }
    }
`;

export const Button = styled.button`
    display: flex;
    column-gap: ${theme.space[1]}px;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.space[3] / 2}px ${theme.space[2]}px;
    font-family: ${theme.fonts.semiBold};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.primaryBlack};
    background-color: ${theme.colors.primaryWhite};
    border-radius: 0;

    & > span {
        text-align: start;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    ${Button} {
        width: 50%;
        &:nth-child(1) {
            border-right: 1px solid ${theme.colors.grey2};
            border-top-left-radius: 12px;
        }
        &:nth-child(2) {
            border-top-right-radius: 12px;
        }
        &:only-child {
            width: 100%;
            border-right: 0;
            border-top-right-radius: 12px;
        }
    }
`;

export const FilterResultCount = styled.div`
    padding: ${theme.space[1]}px ${theme.space[2]}px;
    text-align: center;
    background-color: ${theme.colors.grey2};

    ${FilterResultCountStyles.Label},
    ${FilterResultCountStyles.Value} {
        font-size: 1.3rem;
        line-height: 1.5;
    }

    ${FilterResultCountStyles.Value} {
        font-family: ${theme.fonts.semiBold};
    }
`;

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
    color: ${theme.colors.primaryBlack};
`;
