import React, { useCallback, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import * as Flex from "../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../../redux/store";
import ButtonSlider from "../../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSlider";
import { setSliderValue, setZeroMileage } from "../../../../redux/actions/CarFiltersActions";
import {
    SLIDER_INCREASING,
    SliderButtonType,
} from "../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import FilterCheckInput from "../../common/FilterCheckInput";
import { useCarFilterLabel, useCarFilterOptionalLabel } from "../../../../utils/constants/labels";
import { UsedCarFilterId } from "../../../../../shared-logic/types/UscCommonTypes";
import { getAemCarFilterTrackParamsSelector, trackAemSingleValueSliderFilter } from "../../../../utils/tracking";
import { formatNumberIntl } from "../../../../../../common-deprecated/settings/utils/commonSettingUtils";
import RangeSliderInput from "../../RangeSliderInput/RangeSliderInput";

const MileageFilter = (): JSX.Element => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const filter = useCarFilterSelector((state) => state.carFilters[UsedCarFilterId.Mileage]);
    const cultureName = useCarFilterSelector((state) => state.commonSettings.culture.name);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const trackParams = useCarFilterSelector(getAemCarFilterTrackParamsSelector(UsedCarFilterId.Mileage), shallowEqual);
    const viewZeroMileageLabel = useCarFilterLabel("carFilterViewZeroMileage");
    const zeroMileageLabel = useCarFilterOptionalLabel("carFilterZeroMileage");

    const enableZeroMileage = carFilterSettings.usedCarEnableZeroMileage;

    const { minValue, maxValue, step, currentMaxValue, currentMinValue, valueLabelConfig, zeroMileage, unit } = filter;
    const { value: valueLabel, maxValueText, minValueText } = valueLabelConfig;

    const [currentSliderValues, setCurrentSliderValues] = useState({ min: currentMinValue, max: currentMaxValue });

    const setValueFn = useCallback(
        (newValue: number, type: SliderButtonType): void => {
            dispatch(setSliderValue(type, newValue, UsedCarFilterId.Mileage));
            trackAemSingleValueSliderFilter(trackParams, newValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [trackParams],
    );

    const getValueLabelFn = useCallback(
        (value: number): string => {
            if (value === 0) return zeroMileageLabel;
            return valueLabel.replace("{value}", formatNumberIntl(value, cultureName));
        },
        [valueLabel, cultureName, zeroMileageLabel],
    );

    return (
        <Flex.Row>
            {enableZeroMileage && (
                <Flex.Col width={1}>
                    <FilterCheckInput
                        checked={zeroMileage}
                        label={viewZeroMileageLabel}
                        onChange={() => dispatch(setZeroMileage(!zeroMileage))}
                        id="mileage"
                    />
                </Flex.Col>
            )}

            <Flex.Col width={1}>
                <RangeSliderInput
                    initialValues={{ min: currentMinValue, max: currentMaxValue }}
                    currentValues={{ min: currentSliderValues.min, max: currentSliderValues.max }}
                    initialClampValues={{ min: minValue, max: maxValue }}
                    setValueFn={setValueFn}
                    labels={{
                        from: minValueText,
                        to: maxValueText,
                        [unit?.useAsPrefix ? "prefix" : "suffix"]: unit?.value,
                    }}
                    name="mileage"
                />
                <ButtonSlider
                    maxValue={maxValue}
                    minValue={minValue}
                    step={step}
                    currentMaxValue={currentMaxValue}
                    currentMinValue={currentMinValue}
                    enableMinValue
                    order={SLIDER_INCREASING}
                    setValue={setValueFn}
                    getValueLabel={getValueLabelFn}
                    filterName={filter.label}
                    showValueLabel={false}
                    setSliderValues={(min, max) => setCurrentSliderValues({ min, max })}
                />
            </Flex.Col>
        </Flex.Row>
    );
};

export default MileageFilter;
