import styled from "styled-components";
import { getDirection } from "../../../../../../common-deprecated/themes/common";
import * as FilterDropdownStyles from "./FilterDropdownStyles";
import * as InputGroupStyle from "../../../../../../common-deprecated/styles/v2/toyota/components/InputGroup";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div`
    text-align: ${getDirection("left")};
    ${FilterDropdownStyles.Wrapper} {
        background: none;
    }

    ${InputGroupStyle.Wrapper} {
        background-color: ${theme.colors.primaryWhite};
    }
`;
