import { Action, Location } from "history";
import { FinanceOptionType, PersonalizationType, ErrorLogType } from "../../types/CommonTypes";
import { RequestLogType } from "../../utils/backendFetch";

export const SET_DOM_TARGET: "util/setDomTarget" = "util/setDomTarget";
export const SET_FINANCE_OPTION: "util/setFinanceOption" = "util/setFinanceOption";
export const SET_ACTIVE_PERSONALISATION: "util/setActivePersonalisation" = "util/setActivePersonalisation";
export const SET_IS_BUILD: "util/setIsBuild" = "util/setIsBuild";
export const SHOW_OVERLAY_LOADER: "util/showOverlayLoader" = "util/showOverlayLoader";
export const HIDE_OVERLAY_LOADER = "util/hideOverlayLoader";
export const HISTORY_EVENT: "util/historyEvent" = "util/historyEvent";
export const SET_SCROLL_TO_FINANCE: "util/setScrollToFinance" = "util/setScrollToFinance";
export const BUY_WAIT_FOR_ACTIONS: "buy/waitForActions" = "buy/waitForActions";
export const BUY_CAR: "buy/buyCar" = "buy/buyCar";
export const RESERVE_CAR: "reserve/reserveCar" = "reserve/reserveCar";
export const LEASE_CAR: "lease/leaseCar" = "lease/leaseCar";
export const SHOW_TRADEIN: "util/showTradeIn" = "util/showTradeIn";
export const SET_MODEL_FILTERS_LOADED: "util/setModelFiltersLoaded" = "util/setModelFiltersLoaded";
export const ADD_REQUEST_LOG: "util/addRequestLog" = "util/addRequestLog";
export const ADD_ERROR_LOG: "util/addErrorLog" = "util/addErrorLog";
export const SHOW_MOBILE_BASKET: "util/showMobileBasket" = "util/showMobileBasket";
export const START_EXTERIOR_SPIN_PRELOAD: "util/startExteriorSpinPreload" = "util/startExteriorSpinPreload";
export const SET_EXTERIOR_SPIN_FRAME: "util/setExteriorSpinFrame" = "util/setExteriorSpinFrame";
export const SET_3D_INTERIOR_POSITION: "util/set3DInteriorPosition" = "util/set3DInteriorPosition";
export const SET_3D_EXTERIOR_POSITION: "util/set3ExteriorPosition" = "util/set3ExteriorPosition";
export const SET_HLIST_PAGE: "util/setHListPage" = "util/setHListPage";
export const SHOW_WINTERWHEEL: "util/showWinterWheel" = "util/showWinterWheel";
export const SHOW_TOP_SPIN_VIEW: "util/showTopSpinView" = "util/showTopSpinView";
export const SET_ACTIVE_EQUIPMENT_ITEM_CODE: "util/setActiveEquipmentCode" = "util/setActiveEquipmentCode";
export const SET_EXTERIOR_SPIN_3D: "util/setExteriorSpin3D" = "util/setExteriorSpin3D";
export const SET_FILTER_EQUIPMENT: "util/setFilterEquipment" = "util/setFilterEquipment";

type SetDomTargetType = { type: typeof SET_DOM_TARGET; target: string };
export type SetFinanceOptionType = {
    type: typeof SET_FINANCE_OPTION;
    option: FinanceOptionType;
    noHistoryPush: boolean;
};
export type SetActivePersonalisationType = {
    type: typeof SET_ACTIVE_PERSONALISATION;
    personalization: PersonalizationType;
};
export enum ExteriorSpin3DState {
    Displayed = "displayed",
    Hidden = "hidden",
    Error = "error",
}

type SetIsBuildType = { type: typeof SET_IS_BUILD; build: boolean };
type HideOverlayLoaderType = { type: typeof HIDE_OVERLAY_LOADER };
type ShowOverlayLoaderType = { type: typeof SHOW_OVERLAY_LOADER };
export type HistoryEventType = { type: typeof HISTORY_EVENT; location: any; actionType: string };
type SetScrollToFinanceType = { type: typeof SET_SCROLL_TO_FINANCE; scroll: boolean };
export type SetWaitForBuyActionsType = { type: typeof BUY_WAIT_FOR_ACTIONS; waiting: boolean };
export type BuyCarType = { type: typeof BUY_CAR };
export type ReserveCarType = { type: typeof RESERVE_CAR };
export type LeaseCarType = { type: typeof LEASE_CAR };
export type ShowTradeInType = { type: typeof SHOW_TRADEIN; show: boolean; previousShow?: boolean };
type SetModelFiltersLoadedType = { type: typeof SET_MODEL_FILTERS_LOADED; loaded: boolean };
type AddRequestLogType = { type: typeof ADD_REQUEST_LOG; requestLog: RequestLogType[] };
type AddErrorLogType = { type: typeof ADD_ERROR_LOG; errorLog: ErrorLogType[] };
type ShowMobileBasketType = { type: typeof SHOW_MOBILE_BASKET; show: boolean };
type StartExteriorSpinPreloadType = { type: typeof START_EXTERIOR_SPIN_PRELOAD; key: string };
type SetExteriorSpinFrameType = { type: typeof SET_EXTERIOR_SPIN_FRAME; key: string; frame: number };
type SetExteriorSpin3DType = { type: typeof SET_EXTERIOR_SPIN_3D; is3D: ExteriorSpin3DState };
type Set3DInteriorPosition = { type: typeof SET_3D_INTERIOR_POSITION; x: number; y: number; z: number };
type set3DExteriorPosition = { type: typeof SET_3D_EXTERIOR_POSITION; x: number; y: number; z: number };
type SetHListPageType = { type: typeof SET_HLIST_PAGE; className: string; page: number };
type ShowWinterWheelType = { type: typeof SHOW_WINTERWHEEL; show: boolean };
type ShowTopSpinViewType = { type: typeof SHOW_TOP_SPIN_VIEW; show: boolean };
type SetActiveEquipmentCodeType = { type: typeof SET_ACTIVE_EQUIPMENT_ITEM_CODE; itemCode: string };
type SetFilterEquipmentType = { type: typeof SET_FILTER_EQUIPMENT; filterEquipment: string };

// This should only be used during component server side render.
export const setDomTarget = (target: string): SetDomTargetType => ({ type: SET_DOM_TARGET, target });

export const setFinanceOption = (option: FinanceOptionType, noHistoryPush: boolean = false): SetFinanceOptionType => ({
    type: SET_FINANCE_OPTION,
    option,
    noHistoryPush,
});

export const setFilterEquipment = (filterEquipment: string): SetFilterEquipmentType => ({
    type: SET_FILTER_EQUIPMENT,
    filterEquipment,
});

export const setActivePersonalisation = (personalization: PersonalizationType): SetActivePersonalisationType => ({
    type: SET_ACTIVE_PERSONALISATION,
    personalization,
});

export const setModelFiltersLoaded = (loaded: boolean): SetModelFiltersLoadedType => ({
    type: SET_MODEL_FILTERS_LOADED,
    loaded,
});

export const setIsBuild = (build: boolean): SetIsBuildType => ({ type: SET_IS_BUILD, build });

export const showOverlayLoader = (): ShowOverlayLoaderType => ({ type: SHOW_OVERLAY_LOADER });
export const hideOverlayLoader = (): HideOverlayLoaderType => ({ type: HIDE_OVERLAY_LOADER });

export const historyEvent = (location: Location, actionType: Action): HistoryEventType => ({
    type: HISTORY_EVENT,
    location,
    actionType,
});

export const setScrollToFinance = (scroll: boolean): SetScrollToFinanceType => ({
    type: SET_SCROLL_TO_FINANCE,
    scroll,
});

// Buy actions are part of util for now, not worth it to create a separate reducer for 2 variables.
export const setWaitForBuyActions = (waiting: boolean): SetWaitForBuyActionsType => ({
    type: BUY_WAIT_FOR_ACTIONS,
    waiting,
});
export const buyCar = (): BuyCarType => ({ type: BUY_CAR });
export const reserveCar = (): ReserveCarType => ({ type: RESERVE_CAR });
export const leaseCar = (): LeaseCarType => ({ type: LEASE_CAR });

export const addRequestLog = (requestLog: RequestLogType[]): AddRequestLogType => ({
    type: ADD_REQUEST_LOG,
    requestLog,
});

export const addErrorLog = (errorLog: ErrorLogType[]): AddErrorLogType => ({
    type: ADD_ERROR_LOG,
    errorLog,
});

export const showMobileBasket = (show: boolean): ShowMobileBasketType => ({ type: SHOW_MOBILE_BASKET, show });

export const showWinterWheel = (show: boolean): ShowWinterWheelType => ({ type: SHOW_WINTERWHEEL, show });

export const showTopSpinView = (show: boolean): ShowTopSpinViewType => ({ type: SHOW_TOP_SPIN_VIEW, show });

export const startExteriorSpinPreload = (key: string): StartExteriorSpinPreloadType => ({
    type: START_EXTERIOR_SPIN_PRELOAD,
    key,
});

export const setExteriorSpinFrame = (key: string, frame: number): SetExteriorSpinFrameType => ({
    type: SET_EXTERIOR_SPIN_FRAME,
    key,
    frame,
});

export const setExteriorSpin3D = (is3D: ExteriorSpin3DState): SetExteriorSpin3DType => ({
    type: SET_EXTERIOR_SPIN_3D,
    is3D,
});

export const set3DInteriorPosition = (x: number, y: number, z: number): Set3DInteriorPosition => ({
    type: SET_3D_INTERIOR_POSITION,
    x,
    y,
    z,
});

export const set3DExteriorPosition = (x: number, y: number, z: number): set3DExteriorPosition => ({
    type: SET_3D_EXTERIOR_POSITION,
    x,
    y,
    z,
});

export const setHListPage = (className: string, page: number): SetHListPageType => ({
    type: SET_HLIST_PAGE,
    className,
    page,
});

export const setActiveEquipmentCode = (itemCode: string): SetActiveEquipmentCodeType => ({
    type: SET_ACTIVE_EQUIPMENT_ITEM_CODE,
    itemCode,
});

export type UtilActionsType =
    | SetDomTargetType
    | SetFinanceOptionType
    | SetActivePersonalisationType
    | HideOverlayLoaderType
    | ShowOverlayLoaderType
    | HistoryEventType
    | SetScrollToFinanceType
    | SetWaitForBuyActionsType
    | BuyCarType
    | ReserveCarType
    | LeaseCarType
    | ShowTradeInType
    | SetIsBuildType
    | SetModelFiltersLoadedType
    | AddRequestLogType
    | AddErrorLogType
    | ShowMobileBasketType
    | StartExteriorSpinPreloadType
    | SetExteriorSpinFrameType
    | SetExteriorSpin3DType
    | Set3DInteriorPosition
    | set3DExteriorPosition
    | SetHListPageType
    | ShowWinterWheelType
    | ShowTopSpinViewType
    | SetActiveEquipmentCodeType
    | SetFilterEquipmentType;
