import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { DisclaimerLocation } from "../../../../../common-deprecated/types/CommonTypes";
import { CarFilterDisclaimerType, getDisclaimerSelector } from "../../../utils/disclaimerUtils";
import { useCarFilterSelector } from "../../../redux/store";
import * as Styles from "../../../../../common-deprecated/styles/v2/toyota/components/InlineDisclaimer";

type Props = { disclaimerType: CarFilterDisclaimerType; vehicleForSaleId: string; reference?: string };
const InlineDisclaimer: FC<Props> = ({ disclaimerType, vehicleForSaleId, reference }) => {
    const disclaimers = useCarFilterSelector(getDisclaimerSelector(vehicleForSaleId), shallowEqual);
    const inlineDisclaimer = disclaimers.find(
        (disclaimer) =>
            disclaimer.location === DisclaimerLocation.Inline &&
            disclaimer.type === disclaimerType &&
            disclaimer.reference === reference,
    );

    if (!inlineDisclaimer) return null;

    return <Styles.Text dangerouslySetInnerHTML={{ __html: inlineDisclaimer.value }} />;
};

export default InlineDisclaimer;
