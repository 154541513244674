import styled, { css } from "styled-components";
import { breakpoints } from "../../../../../../shared-logic/themes/toyotaTheme";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as Button from "../../../../../../../common-deprecated/styles/v2/toyota/components/Button";

export const Slider = styled.div`
    flex: 1;
    min-height: 30px;
    padding-top: ${theme
        .space[2]}px; /* This used to be margin-top but this caused issues in height calculations of the SidebarWrapper. See OR-3964 */
    padding-bottom: ${theme.space[1]}px;
    overflow: hidden;

    @media screen and (min-width: ${breakpoints.lg}) {
        height: auto;
    }
`;

export const SliderViewport = styled.div`
    position: relative;
    width: 100%;
    min-height: 30px;
    margin: 0 auto;
    overflow: hidden;

    @media screen and (min-width: ${breakpoints.lg}) {
        height: auto;
        overflow: auto;
    }
`;

type TrackType = { width?: number; left: number; enableTransition: boolean };
export const Track = styled.div.attrs<TrackType>((props) => ({
    style: { transform: `translateX(${props.left}px)` },
}))<TrackType>`
    display: flex;
    left: 0;
    flex-direction: row;
    width: ${(props) => props.width}px;
    ${(props) =>
        props.enableTransition &&
        css`
            transition: transform 0.5s;
        `};

    @media screen and (min-width: ${breakpoints.lg}) {
        position: relative;
        flex-wrap: wrap;
        width: 100%;
    }
`;

export const Prerender = styled.div`
    height: 0;
    opacity: 0;
    overflow: hidden;
`;

export const PillWrapper = styled.div`
    display: inline-block;
    padding-right: 8px;

    @media screen and (min-width: ${breakpoints.lg}) {
        padding-bottom: 8px;
    }
`;

export const PillText = styled.span<{ strikethrough: boolean }>`
    ${({ strikethrough }) =>
        strikethrough &&
        css`
            text-decoration: line-through;
        `};
`;

export const ClearButton = styled(Button.GreyRounded)`
    display: inline-block;
    min-width: 100%;
    margin-top: ${theme.space[1]}px;
    margin-bottom: ${theme.space[1]}px;
    text-align: center;
    background-color: ${theme.colors.grey2};

    &:hover {
        color: ${theme.colors.primaryWhite};
        background-color: ${theme.colors.grey4};
    }
`;
