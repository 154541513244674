import { useEffect, useState } from "react";

/**
 * Helper to efficiently render a filter dropdown in a filter sidebar.
 * This is basically a 2-pass render where first the content of the dropdown is rendered, after which the actual dropdown animation should happen
 *
 * @param show - True if the dropdown should be rendered
 * @returns renderDropdownContainer: This should trigger an initial render of the filter but *without* the dropdown opened.
 * @returns propagatedShow: this will be true when the dropdown should actually be opened (ideally with a CSS transition).
 */
const useFilterDropDown = (show: boolean): { propagatedShow: boolean; renderDropdownContainer: boolean } => {
    const [renderDropdownContainer, setRenderDropdownContainer] = useState<boolean>(false);
    const [propagatedShow, setPropagatedShow] = useState<boolean>(false);

    /**
     * Some explanation on the React wizardry happening below:
     *
     * By default DropDownContainer will always render its children and hide them when required for CSS transition purposes.
     * Because we use lottie animations that is not ideal as all animations are initialized while they are not shown, so we don't want this "hidden render".
     *
     * The hook setup below works around this by hiding the complete DropdownContainer by default and triggering a two pass render when it needs to be shown.
     * On a "show click", the first render will render the DropdownContainer with the "hidden children".
     * Second render will propagate the show boolean which will show the children and trigger the animation.
     */
    useEffect(() => {
        if (show && !renderDropdownContainer) {
            setRenderDropdownContainer(true);
            window.requestAnimationFrame(() => setPropagatedShow(true));
        } else if (show !== propagatedShow) {
            setPropagatedShow(show);
        }
    }, [show, renderDropdownContainer, propagatedShow]);

    return { propagatedShow, renderDropdownContainer };
};

export default useFilterDropDown;
