import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div`
    position: relative;
`;

export const Button = styled.button`
    position: relative;
    padding: ${theme.space[1]}px ${theme.space[2]}px 8px;
    font-size: 1.3rem;
    line-height: 1;
    color: ${theme.colors.primaryWhite};
    background: ${theme.colors.primaryBlack};
    border-radius: 20px;
    opacity: 0.8;
    z-index: ${theme.zIndex.sticky};
`;
