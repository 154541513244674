import styled, { css } from "styled-components";
import { bodyStyle } from "../../styles/v2/toyota/globals/Type";
import { theme } from "../../styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div<{ isSmall?: boolean; variantOutline?: boolean; isPdf?: boolean }>`
    ${bodyStyle};
    display: flex;
    position: sticky;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: ${theme.space[3] / 2}px ${theme.space[3]}px;
    color: ${theme.colors.grey4};
    background-color: ${theme.colors.grey1};

    ${({ variantOutline }) =>
        variantOutline &&
        css`
            background-color: transparent;
            color: ${theme.colors.grey1};
            border: 1px solid ${theme.colors.grey2};
        `};

    ${({ isPdf }) =>
        isPdf &&
        css`
            margin: ${theme.space[1]}px 0;
            color: ${theme.colors.grey4};
        `};
`;
