import { RetailerSettingsType } from "../reducers/RetailerSettingsReducer";

export const RETAILER_SETTINGS_INIT = "retailerSettings/init";

type InitRetailerSettings = {
    type: typeof RETAILER_SETTINGS_INIT;
    initState: RetailerSettingsType;
};

export const initRetailerSettings = (initState: RetailerSettingsType): InitRetailerSettings => ({
    type: RETAILER_SETTINGS_INIT,
    initState,
});

export type RetailerSettingsActionsType = InitRetailerSettings;
