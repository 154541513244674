import { CommonSettingsType, ModelMapType } from "../fetchCommonSettings";
import { ModelMapValueType } from "../../types/SettingsType";
import {
    CarDBModelId,
    CERTIFICATION_CORRELATED_NEDC,
    CERTIFICATION_NEDC,
    CERTIFICATION_WLTP,
    EcolabelType,
    EMPTY_GUID,
    GUID_REGEX,
    LEXUS,
} from "../../constants";
import { AssetSizeType, EcoTagType, FilterAssetType, ModelGenerationType } from "../../types/CommonTypes";
import { CommonLabelType } from "../../utils/commonLabels";
import { formatNumber } from "../../Globalize";
import { getIntlLocale } from "../../utils/i18nUtils";

export const isLexus = (commonSettings: CommonSettingsType): boolean => commonSettings.brand === LEXUS;

/**
 * Retrieve the URL for an OR API endpoint.
 * This used to be based on a separate "api" var but doesn't seem required.
 */
export const getAPI = (settings: CommonSettingsType, endpoint: string): string => {
    return `${settings.resourcePath}/v1/api/${endpoint}/${settings.country}/${settings.language}`;
};

/**
 * Returns type of the ecoLabel to be displayed.
 * Returns false when no label should be displayed
 */
export const getEcoBadgeType = (
    settings: CommonSettingsType,
    ecoTag?: EcoTagType | false,
    modelId: string = "", // only required for new cars
): EcolabelType | false => {
    if (settings.country !== "es") return false;
    if (ecoTag) {
        // override is needed for filter components (OR-3725)
        const zeroEmissionsModels: string[] = [
            CarDBModelId.Mirai,
            CarDBModelId.PriusPHEV,
            CarDBModelId.RAV4PHEV,
            CarDBModelId.ProaceEV,
        ];
        if (
            [EcoTagType.Electric, EcoTagType.Plugin, EcoTagType.FuelCell].includes(ecoTag) ||
            zeroEmissionsModels.includes(modelId)
        ) {
            return EcolabelType.Zero;
        } else if (![EcoTagType.Diesel, EcoTagType.Petrol].includes(ecoTag)) {
            return EcolabelType.Eco;
        }
    }
    return false;
};

/**
 * Returns if the locale uses RTL writing
 */
export const isRTL = (settings: CommonSettingsType): boolean => {
    return !!settings.culture.isRTL;
};

/**
 * Returns if the locale is Israel (he-il)
 */
export const isIsrael = (cultureName: string): boolean => {
    return cultureName.toLowerCase() === "he-il";
};

/**
 * Get the wltp disclaimer label
 */
export const getWLTPLabel = (settings: CommonSettingsType, certification: number, electric: boolean): string => {
    let key: CommonLabelType | "" = "";
    switch (certification) {
        case CERTIFICATION_NEDC:
            key = "T1.disclaimerNedc";
            break;
        case CERTIFICATION_CORRELATED_NEDC:
            key = "T1.disclaimerCorrelatedNedc";
            break;
        case CERTIFICATION_WLTP:
            key = electric ? "T1.disclaimerWltpElectric" : "T1.disclaimerWltp";
            break;
    }
    const disclaimer = key && settings.labels[key] ? settings.labels[key] : key;
    // Work around for Lexus where they have translated the disclaimer to be the actual name of the key
    const keyWithoutT1 = key.replace("T1.", "");
    return typeof disclaimer === "undefined" || disclaimer === key || disclaimer === keyWithoutT1 ? "" : disclaimer;
};

/**
 * Retrieve a modelMap object by the modelId
 */
export const getModelMapValueById = <T extends { modelMap: ModelMapType }>(
    settings: T,
    modelId: string,
    nextGen: boolean = false,
): ModelMapValueType | null => {
    return (
        Object.values(settings.modelMap).find(
            (model) => model.id === modelId && (nextGen ? model.isNewGeneration : true),
        ) || null
    );
};

/**
 * Retrieve a modelMap object by the modelId, internal/local code, name...
 */
export const searchModelMapValue = <T extends { modelMap: ModelMapType }>(
    settings: T,
    query: string,
): ModelMapValueType | null => {
    if (!query) return null;
    if (new RegExp(GUID_REGEX).exec(query)) return getModelMapValueById(settings, query);

    return (
        Object.entries(settings.modelMap).find(([key, val]) => {
            return (
                query === key.toLowerCase() ||
                query === (val.internalCode && val.internalCode.toLowerCase()) ||
                query === (val.localCode && val.localCode.toLowerCase()) ||
                query === (val.name && val.name.toLowerCase()) ||
                query === (val.ccName && val.ccName.toLowerCase())
            );
        })?.[1] || null
    );
};

/**
 * Try to get a next generation match for a modelId.
 */
export const getNextGenerationMatchFromModelMap = (
    settings: CommonSettingsType,
    modelId: string,
): ModelMapValueType | null => {
    return settings.query.modelGeneration === ModelGenerationType.Next
        ? getModelMapValueById(settings, modelId, true)
        : null;
};

/**
 * Get a model token with a modelId.
 */
export const getModelToken = (
    settings: CommonSettingsType,
    modelId: string,
    forceCheck: boolean = false,
): string | null => {
    const { enableTokens } = settings;
    if (!forceCheck && (!enableTokens || settings.query.disableTokens)) return null;

    // First check for next gen models.
    let model = getNextGenerationMatchFromModelMap(settings, modelId);

    // No next gen model found, use current gen model.
    if (!model) model = getModelMapValueById(settings, modelId, false);

    return model && model.token && model.token !== EMPTY_GUID ? model.token : null;
};

export const sanitizeAEMModelMap = (modelMap: ModelMapType): ModelMapType => {
    return Object.entries(modelMap).reduce((acc, [key, val]) => {
        return {
            ...acc,
            [key]: {
                ...val,
                links: {
                    ...val.links,
                    carGrade:
                        val.links?.carGrade && !val.links.carGrade.includes("undefined") ? val.links.carGrade : "",
                },
            },
        };
    }, {} as ModelMapType);
};

export const getAEMModelPage = (commonSettings: CommonSettingsType, modelId: string): string => {
    const modelMatch = getModelMapValueById(commonSettings, modelId);
    if (!modelMatch?.links?.modelPage) return "";
    return modelMatch.links.modelPage;
};

export const getAEMBuildPage = (
    commonSettings: CommonSettingsType,
    modelId: string,
    colorCode?: string,
    carId?: string,
): string => {
    const modelMatch = getModelMapValueById(commonSettings, modelId);
    if (!modelMatch?.links?.buildPage) return "";
    if (!colorCode || !carId) return modelMatch.links.buildPage;
    return `${modelMatch.links.buildPage}.${modelMatch.localCode}.${colorCode}.${carId}`;
};

/**
 * Gets the AEM grade Page
 * @deprecated Grade page no longer exists so can't be linked anymore
 */
export const getAEMGradePage = (
    commonSettings: CommonSettingsType,
    modelId: string,
    colorCode?: string,
    carId?: string,
): string => {
    const modelMatch = getModelMapValueById(commonSettings, modelId);
    if (!modelMatch?.links?.carGrade || modelMatch.links.carGrade.includes("undefined"))
        return modelMatch?.links?.modelPage || "";
    if (!colorCode || !carId) return modelMatch.links.carGrade;
    return `${modelMatch.links.carGrade}.${modelMatch.localCode}.${colorCode}.${carId}`;
};

/**
 * Resized car image from cardb
 */
export const getCarImageFromCardb = (
    settings: CommonSettingsType,
    assets: FilterAssetType[],
    type: AssetSizeType,
    width: number,
    height: number,
): string | null => {
    if (!assets.length) return null;

    const file = assets.find((asset) => asset.type === type)?.file;
    if (!file) return null;

    return `${settings.cardbAssetPath}${width}x${height}/${file}`;
};

/**
 * Returns true if the page is loaded from Salesman app and sends commands to Billboard
 */
export const isMaster = (settings: CommonSettingsType): boolean => {
    return !!settings.query.salesmanmaster;
};

/**
 * Returns true if the page is loaded from Billboard app and receives commands from Salesman
 */
export const isSlave = (settings: CommonSettingsType): boolean => {
    return !!(settings.query.salesmanslave || settings.query.salesmanslavechrome);
};

/**
 * Returns true if the page is loaded from either Billboard or Salesman apps
 */
export const isMasterOrSlave = (settings: CommonSettingsType): boolean => {
    return isMaster(settings) || isSlave(settings);
};

export const isCodDealer = (commonSettings: CommonSettingsType): boolean => {
    return !!commonSettings.query.coddealer;
};

export const isCodCustomer = (commonSettings: CommonSettingsType): boolean => {
    return !!commonSettings.query.codcustomer;
};

export const isCod = (commonSettings: CommonSettingsType): boolean => {
    return isCodDealer(commonSettings) || isCodCustomer(commonSettings);
};

export const isRetailerApps = (commonSettings: CommonSettingsType): boolean => {
    return isMasterOrSlave(commonSettings) || isCod(commonSettings);
};

/** Method checks if the build is integrated as part of a dealer site or not */
export const isDealer = (commonSettings: CommonSettingsType): boolean => {
    return !!commonSettings.query.dealer;
};

export const getTyreLabelsUrl = (settings: CommonSettingsType, eprel: string): string => {
    // For future fine tuning, same properties are supported as exposed by CCIS, ex.:
    // https://images.toyota-europe.com/eprel/width/200/padding/5/background-colour/ffffff/380820.png
    // TODO Move the defaulting to the fetcher/formatter
    const url = settings.tyreLabelService || "https://images.toyota-europe.com/eprel/";
    return `${url}${eprel}.png`;
};

/**
 * Used to format numbers according to the given locale
 * Allows a fallback to the old Globalize function if needed.
 */
export const formatNumberIntl = (
    value: number,
    cultureName: string,
    options?: Intl.NumberFormatOptions,
    fallback?: boolean,
): string => {
    const intlLocale = getIntlLocale(cultureName);

    // fallback is using Globalize, which doesn't need the Intl API override
    if (fallback) return formatNumber(value, cultureName);
    return new Intl.NumberFormat(intlLocale, options).format(value);
};

export const showRetailPriceMonthly = (settings: CommonSettingsType): boolean => {
    return settings.country === "44" || settings.country === "gb";
};

export const hasAlternativeEcoSpecFlow = (settings: CommonSettingsType): boolean => {
    return settings.country === "de";
};

export const hasRightHandDrive = (settings: CommonSettingsType): boolean => {
    return settings.country === "cy" || settings.country === "ie" || settings.country === "gb";
};

/**
 * Whether or not the Legal Banner Disclaimer should be shown
 * This is currently a TNL specific feature
 * @param settings
 * @returns Boolean based on country & if the legalBanner data from globalSettings is populated
 */
export const showLegalBannerDisclaimer = (settings: CommonSettingsType): boolean => {
    return settings.country === "nl" && !!settings.legalBanner;
};
