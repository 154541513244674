import {
    HIDE_MODAL,
    SHOW_MODAL,
    ModalActionsType,
    UPDATE_MODAL_SETTINGS,
    CLEAR_MODAL,
    CLEAR_ALL_MODALS,
    ADD_PRELOADED_MODAL,
} from "../actions/ModalActions";
import { ModalsType } from "../../types/CommonTypes";

export type ModalType = {
    show: boolean;
    settings: ModalsType | null;
    modalIndex: number;
};

export type ModalReducerType = {
    modals: ModalType[];
};

const initialModalState = {
    show: false,
    settings: null,
    modalIndex: 0,
};

export const initialState: ModalReducerType = {
    modals: [],
};

export default (state: ModalReducerType = initialState, action: ModalActionsType): ModalReducerType => {
    switch (action.type) {
        case ADD_PRELOADED_MODAL:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    {
                        ...initialModalState,
                        settings: action.settings,
                        modalIndex: action.modalIndex,
                    },
                ],
            };
        case SHOW_MODAL:
            const filteredModals = state.modals.filter((m) => m.settings?.type !== action.settings.type);

            return {
                ...state,
                modals: [
                    ...filteredModals,
                    {
                        ...initialModalState,
                        show: true,
                        settings: action.settings,
                        modalIndex: action.modalIndex,
                    },
                ].sort((firstModal, secondModal) => firstModal.modalIndex - secondModal.modalIndex),
            };

        case HIDE_MODAL:
            return {
                ...state,
                modals: state.modals.reduce((modalArr, newModal) => {
                    if (newModal.settings?.type === action.modalId) return [...modalArr, { ...newModal, show: false }];
                    else return [...modalArr, newModal];
                }, [] as ModalType[]),
            };
        case CLEAR_MODAL:
            return { ...state, modals: state.modals.filter((mod) => mod.settings?.type !== action.modalId) };

        case CLEAR_ALL_MODALS:
            return initialState;

        case UPDATE_MODAL_SETTINGS:
            const { settings, modalToUpdate } = action;

            const foundModal = state.modals.find((modal) => modal.settings?.type === modalToUpdate);

            if (foundModal) {
                const updatedModal = { ...foundModal, settings: { ...foundModal.settings, ...settings } };

                const newModals = state.modals.map((modal) => {
                    if (modal.settings?.type === modalToUpdate) return updatedModal;

                    return modal;
                });

                return { ...state, modals: newModals as ModalType[] };
            }

            return state;

        default:
            return state;
    }
};
