import styled from "styled-components";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import LabelStyle from "../../../../../../../../common-deprecated/features/filters/styles/Label";

export const Wrapper = styled.div``;

export const Brand = styled.div`
    padding: ${theme.space[2]}px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.grey2};

    button {
        font-family: ${theme.fonts.base};
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
`;

export const Label = styled(LabelStyle)`
    margin: ${theme.space[3]}px 0 ${theme.space[2]}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryBlack};
`;
