// ----------------------------------------------------------------------
// Sentence value parsing config
// ----------------------------------------------------------------------
export type SentenceConfigType = {
    leftLabel: string;
    rightLabel: string;
    valueLabel: string;
    anyLabel: string;
    valueSeparator: string;
    finalValueSeparator: string;
    mergeIdenticalValues: boolean;
};

export const defaultSentenceConfig: SentenceConfigType = {
    leftLabel: "",
    rightLabel: "",
    valueLabel: "{value}",
    anyLabel: "any",
    valueSeparator: ", ",
    finalValueSeparator: " and ",
    mergeIdenticalValues: false,
};

// ----------------------------------------------------------------------
// Filter base types used across all filters
// ----------------------------------------------------------------------
export type FilterConfigBaseType = {
    show: boolean;
    index: number;
    active: boolean;
    sentenceConfig: SentenceConfigType;
    label: string;
    titleLabel: string; // Added this for model filter (Could be necessary for car-filter at some point)
    externalIdentifier: string;
    disabled: boolean; // A disabled filter can have a prefilled value but is not shown in the car-filter
};

export const defaultConfigBase: FilterConfigBaseType = {
    show: false,
    index: 0,
    active: false,
    sentenceConfig: { ...defaultSentenceConfig },
    label: "",
    titleLabel: "",
    externalIdentifier: "",
    disabled: false,
};

export type FilterConfigValueLabelType = {
    minValueText: string;
    minValueDesc: string;
    maxValueText: string;
    maxValueDesc: string;
    value: string;
};

export const defaultValueLabelConfig: FilterConfigValueLabelType = {
    minValueText: "{value}",
    minValueDesc: "",
    maxValueText: "{value}",
    maxValueDesc: "",
    value: "{value}",
};

// ----------------------------------------------------------------------
// MultipleChoice filters
// ----------------------------------------------------------------------
type MultipleChoiceValueContentType = {
    id: string;
    label: string;
    selected: boolean;
    selectable: boolean;
    // resultCount is only used for used car filters, but it was overkill to create a separate type for this.
    resultCount?: number;
    parentId?: string;
};

export type MultipleChoiceValueType = MultipleChoiceValueContentType & {
    externalIdentifier?: string;
    children?: MultipleChoiceValueContentType[];
};

export type MultipleChoiceFilterConfigType<T extends MultipleChoiceValueType = MultipleChoiceValueType> =
    FilterConfigBaseType & {
        values: T[];
    };

export const defaultMultipleChoiceFilter: MultipleChoiceFilterConfigType<MultipleChoiceValueType> = {
    ...defaultConfigBase,
    show: false,
    values: [],
};

// Custom id for the custom hybrid classification.
export const HYBRID_CLASSIFICATION: "hybridFuelType" = "hybridFuelType";

export enum MultipleChoiceValueSortOption {
    Label = "label",
    ResultCount = "resultCount",
}

// ----------------------------------------------------------------------
// Plus minus filters
// ----------------------------------------------------------------------
export type PlusMinusFilterConfigType = FilterConfigBaseType & {
    max: number;
    min: number;
    current: number;
    compareMode: "plus" | "equal" | "minus";
    valueLabelConfig: FilterConfigValueLabelType;
};

export const defaultPlusMinusFilter: PlusMinusFilterConfigType = {
    ...defaultConfigBase,
    show: false,
    max: 0,
    min: 0,
    current: 0,
    compareMode: "plus",
    valueLabelConfig: defaultValueLabelConfig,
};

// ----------------------------------------------------------------------
// Slider filters
// ----------------------------------------------------------------------
export type SliderFilterConfigType = FilterConfigBaseType & {
    max: number;
    min: number;
    customMax: number;
    customMin: number;
    current: number;
    step: number;
    order: SliderValueOrderType;
    valueLabelConfig: FilterConfigValueLabelType;
};

export const SLIDER_INCREASING: "increasing" = "increasing";
export const SLIDER_DECREASING: "decreasing" = "decreasing";
export type SliderValueOrderType = typeof SLIDER_DECREASING | typeof SLIDER_INCREASING;

export const defaultSliderFilter: SliderFilterConfigType = {
    ...defaultConfigBase,
    max: 0,
    min: 0,
    customMax: 0,
    customMin: 0,
    current: 0,
    step: 0,
    order: SLIDER_INCREASING,
    valueLabelConfig: defaultValueLabelConfig,
};

// ----------------------------------------------------------------------
// Min max slider filters
// ----------------------------------------------------------------------

export type MinMaxSliderBaseType = {
    maxValue: number;
    minValue: number;
    customMaxValue: number;
    customMinValue: number;
    currentMinValue: number;
    currentMaxValue: number;
    step: number;
    valueLabelConfig: FilterConfigValueLabelType;
    unit?: { value: string; useAsPrefix: boolean };
};

export const baseMinMaxSliderFilterValue: MinMaxSliderBaseType = {
    maxValue: 0,
    minValue: 0,
    customMaxValue: 0,
    customMinValue: 0,
    currentMinValue: 0,
    currentMaxValue: 0,
    step: 0,
    valueLabelConfig: { ...defaultValueLabelConfig },
};

export type MinMaxSliderFilterConfigType = MinMaxSliderBaseType & FilterConfigBaseType;

export const defaultMinMaxSliderFilter: MinMaxSliderFilterConfigType = {
    ...baseMinMaxSliderFilterValue,
    ...defaultConfigBase,
};

// ----------------------------------------------------------------------
// Price filters
// ----------------------------------------------------------------------
export type PriceFilterConfigType = FilterConfigBaseType & {
    cash: MinMaxSliderBaseType & { show: boolean; active: boolean };
    monthly: MinMaxSliderBaseType & { show: boolean; active: boolean };
};

export const getDefaultPriceFilter = (): PriceFilterConfigType => ({
    ...defaultConfigBase,
    cash: { ...baseMinMaxSliderFilterValue, show: false, active: false },
    monthly: { ...baseMinMaxSliderFilterValue, show: false, active: false },
});

// ----------------------------------------------------------------------
// Proace filters
// ----------------------------------------------------------------------
export const FILTER_PROACE: "proace" = "proace";

export const COMMERCIAL_FILTER_CODE = "Commercial"; // CarDB code

// ----------------------------------------------------------------------
// Car type filters (Car-Filter and Model-Filter)
// ----------------------------------------------------------------------
export enum CarTypeFilterEnum {
    Brands = "brands",
    Models = "models",
    CarType = "carType",
    FuelType = "fuelType",
}

// Models from a DF data source can also have comma separated ids, see OR-3909.
export type CarTypeFilterModelType = MultipleChoiceValueType & { brandId: string };
export type CarTypeFilterType = FilterConfigBaseType & {
    brands: MultipleChoiceValueType[];
    models: CarTypeFilterModelType[];
    carType: MultipleChoiceValueType[];
    fuelType: MultipleChoiceValueType[];
    transmission: MultipleChoiceValueType[];
    disabledCarTypeFilters: CarTypeFilterEnum[];
};
export const defaultCarTypeFilterConfig: CarTypeFilterType = {
    ...defaultConfigBase,
    brands: [],
    models: [],
    carType: [],
    fuelType: [],
    transmission: [],
    disabledCarTypeFilters: [],
};

// ----------------------------------------------------------------------
// Button slider constants
// ----------------------------------------------------------------------
export const BUTTON_MAX: "max" = "max";
export const BUTTON_MIN: "min" = "min";
export type SliderButtonType = typeof BUTTON_MAX | typeof BUTTON_MIN;

// ----------------------------------------------------------------------
// Default values
// ----------------------------------------------------------------------

export type MinMaxType = {
    min: number;
    max: number;
};
