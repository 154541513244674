import { getStorageItem, setStorageItem, WebStorageType } from "../../../common-deprecated/utils/storageUtils";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { CarFiltersReducerType } from "../redux/reducers/CarFiltersReducer";
import { UscContext } from "../../shared-logic/types/UscCommonTypes";

// ----------------------------------------------------------------------
// Helper types and consts
// ----------------------------------------------------------------------
const SESSION_STORAGE_ID = "com.toyota.tme.t1.carFilter";

export type CarFilterSessionStorageItemType = {
    carFilterResultPosition?: number; // Used for scrolling back to the previous y position.
};

export type CarFilterSessionStorageParams = {
    context: UscContext;
    country: string;
    language: string;
};

// ----------------------------------------------------------------------
// Storage fns
// ----------------------------------------------------------------------

export const getCarFilterSessionStorage = (
    params: CarFilterSessionStorageParams,
): CarFilterSessionStorageItemType | null => {
    return getStorageItem({ ...params, storageId: SESSION_STORAGE_ID }, WebStorageType.session);
};

export const setCarFilterSessionStorage = (
    params: CarFilterSessionStorageParams,
    storageItem: CarFilterSessionStorageItemType,
): void => {
    setStorageItem({ ...params, storageId: SESSION_STORAGE_ID }, storageItem, WebStorageType.session);
};

// ----------------------------------------------------------------------
// Helper fns
// ----------------------------------------------------------------------

export const getSessionStorageParams = ({
    commonSettings,
    carFilters,
}: {
    commonSettings: CommonSettingsType;
    carFilters: CarFiltersReducerType;
}): CarFilterSessionStorageParams => ({
    country: commonSettings.country,
    language: commonSettings.language,
    context: carFilters.currentFilter,
});
