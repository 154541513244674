import React from "react";
import { shallowEqual } from "react-redux";
import { useCarFilterLabel } from "../../../../../utils/constants/labels";
import BrandModelList from "./BrandModelList";
import * as Style from "./styles/BrandListStyles";
import { brandFilterValuesSelector, formatBrands } from "../../../../../utils/filters";
import { useCarFilterSelector } from "../../../../../redux/store";
import { UscContext } from "../../../../../../shared-logic/types/UscCommonTypes";

const ModelFilterBody = (): JSX.Element => {
    const currentBrand = useCarFilterSelector((state) => state.commonSettings.brand);
    const brands = useCarFilterSelector(brandFilterValuesSelector, shallowEqual);
    const mcValuesSortOption = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsMultipleChoiceValueSortOption
            : state.carFilterSettings.stockCarsMultipleChoiceValueSortOption,
    );

    const brandsLabel = useCarFilterLabel("carFilterBrands");

    const formattedBrands = formatBrands(brands, currentBrand, mcValuesSortOption);

    return (
        <Style.Wrapper>
            <Style.Label>{brandsLabel}</Style.Label>
            {formattedBrands.length &&
                formattedBrands.map((brand) => brand.selected && <BrandModelList key={brand.id} brandId={brand.id} />)}
        </Style.Wrapper>
    );
};

export default ModelFilterBody;
