import { AEMThemeType } from "../../../../themes/common";

const colors = {
    primaryRed: "#FF0022", // Used for branded elements and dividers
    primaryRedHover: "#D40605", // Used for hover states of the primary red
    primaryBlack: "#282830", // Used for all text and to make elements stand out
    primaryWhite: "#FFFFFF", // Used for text and to give breathing space
    highlightBlack: "#15151B", // Only used for highlights of black on black backgrounds. Not for text.
    grey1: "#F5F5F5", // Used for solid backgrounds
    grey2: "#E4E4E4", // Used for dividers
    grey3: "#A8AAAC", // Used for secondary titles
    grey4: "#6C7073", // Used for labels
    grey5: "#6A6B70",
    grey7: "#6C7073", // Used for labels (currently same as grey4 but keep names as styleguide, which is 7),
    grey8: "#585D5F",
    outline: "#0072F0", // Form elements outline colour
    hybrid: "#0072F0", // Secondary colour - hybrid
    fuelCell: "#3F51B5", // secondary colour - Indigo
    success: "#4CAF50",
    successLight: "#D2F4C9",
    successDark: "#0E5911",
    danger: "#FAD2DB",
    dangerDark: "#960101",
    border: "#DDD",
    bz4x: "#003641",
    bz4xHover: "#46A4B0",
    kinto: "#00708D",
} as const;

export type ColorType = keyof typeof colors;

export type IconSizeType = "xxs" | "xs" | "sm" | "md" | "lg" | "xl";

const iconSize = {
    xxs: ".8rem",
    xs: "1.2rem",
    sm: "1.4rem",
    md: "1.6rem",
    lg: "1.8rem",
    xl: "2.2rem",
};

const fonts = {
    base: '"Toyota Base", Arial,Helvetica,sans-serif',
    regular: '"Toyota Regular", Arial,Helvetica,sans-serif',
    semiBold: '"Toyota SemiBold", Arial,Helvetica,sans-serif',
    bold: '"Toyota Bold", Arial,Helvetica,sans-serif',
};

const spacer = 32;

const space = [0, 8, 16, 24, 32, 40, 80, 152];

const breakpoints = {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1440px",
    xxxl: "1600px",
};

const zIndex = {
    gradesNav: 5,
    popup: 700,
    dropdown: 1000,
    navBar: 1010,
    sticky: 1020,
    fixed: 1030,
    modalBackdrop: 1040,
    modal: 1050,
    overlay: 1060, // pageoverlayer inpage
    pageoverlayerExpansion: 1056,
    materialbox: 1060,
    popover: 1060,
    tooltip: 1070,
};

export type ZIndexType = keyof typeof zIndex;

export const styledSystemTheme: AEMThemeType = {
    isRTL: false, // This will be overwritten on component init.
    noContainerSpacing: false,
    breakpoints,
    space,
};

export const theme = {
    colors,
    fonts,
    iconSize,
    spacer,
    space,
    zIndex,
} as const;
