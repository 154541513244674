import styled from "styled-components";
import * as InputGroupStyle from "../../../../../../../common-deprecated/features/filters/styles/InputGroup";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";
import Preloader from "../../../../../../../common-deprecated/styles/toyota/Preloader";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

const footerHeight = 6; // footerHeight unit = rem

export const Wrapper = styled.div`
    position: relative;
    min-height: 453px;
`;

export const FormGroup = styled.div`
    position: relative;
    width: 100%;
`;

export const ResultsWrapper = styled.div`
    position: relative;
    width: 100%;
    max-height: 310px;
    margin-top: -2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: auto;
`;

export const Result = styled.div`
    width: 100%;
    border-top: 1px solid ${theme.colors.grey2};
`;

export const ErrorMessage = styled.div`
    width: 100%;
    height: 44px;
    padding-top: 9px;
    text-align: center;
    color: ${theme.colors.grey4};
    background: ${theme.colors.primaryWhite};
    border-top: 1px solid ${theme.colors.grey2};
`;

export const MapsWrapper = styled.div<{ hasFooter?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ hasFooter }) => (hasFooter ? `calc(100% - ${footerHeight}rem)` : "100%")};
`;

export const InputAction = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding-top: 2px;
    color: ${theme.colors.primaryBlack};
    background: none;
    border: none;
`;

export const LocationButton = styled.button<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background: none;
    border: none;
    border-left: 1px solid ${theme.colors.grey2};
`;

export const InputGroup = styled(InputGroupStyle.Wrapper)`
    input {
        flex: 1 1 auto;
        width: 1%;
        padding: 10px 12px;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
`;

export const InputAppend = styled(InputGroupStyle.InputAppend)`
    position: static;
    top: auto;
    ${getDirection("right")}: auto;
    width: auto;
    height: auto;

    svg {
        color: ${theme.colors.primaryBlack};
        background-color: transparent;
    }

    ${Preloader} {
        left: auto;
        right: 44px;
        width: 44px;
        height: 44px;
    }
`;

export const RangeFilterIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 12px;
    border-left: 1px solid ${theme.colors.grey2};
`;

export const RangeFilterIcon = styled.span``;

export const Footer = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${footerHeight}rem;
    padding: ${theme.space[2]}px ${theme.space[3]}px;
    background-color: ${theme.colors.primaryWhite};
`;

export const ResultsTitleWrapper = styled.div`
    width: 100%;
    padding: 6px ${theme.space[2]}px;
    background-color: ${theme.colors.grey1};
    border-top: 1px solid ${theme.colors.grey2};
    border-bottom: 1px solid ${theme.colors.grey2};

    & + ${Result} {
        border-top: 0;
    }
`;

export const ResultsTitle = styled.span`
    font-family: ${theme.fonts.semiBold}; /* mobile/h7 */
    font-size: 1.3rem;
    line-height: 153.846%; /* 2rem */
    text-transform: uppercase;
    color: ${theme.colors.grey7};
`;
