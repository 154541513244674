import styled, { css } from "styled-components";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import { theme } from "../theme/toyotaTheme";

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    margin-bottom: ${theme.space[4]}px;
    white-space: nowrap;
    vertical-align: middle;
    @media ${getBreakpoint("up", "lg")} {
        white-space: normal;
    }
`;

export const Tab = styled.button<{ isActive?: boolean; isSmall?: boolean }>`
    flex: 0 0 50%;
    padding: ${theme.space[2]}px ${theme.space[3]}px;
    text-align: ${getDirection("left")};
    background: ${theme.colors.grey2};
    ${({ isActive }) =>
        isActive &&
        css`
            background: ${theme.colors.primaryWhite};
            cursor: default !important;
        `};
`;
