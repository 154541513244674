import { TypedUseSelectorHook, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { CommonSettingsActionsType } from "./actions/CommonSettingsActions";
import { ModalReducerType } from "./reducers/ModalReducer";
import { CommonSettingsType } from "../settings/fetchCommonSettings";
import { UtilStateType } from "./reducers/UtilReducer";
import { ModalActionsType } from "./actions/ModalActions";
import { UtilActionsType } from "./actions/UtilActions";
import { NotificationReducerType } from "./reducers/NotificationReducer";
import { NotificationActionsType } from "./actions/NotificationActions";

export type CommonActionsType =
    | CommonSettingsActionsType
    | UtilActionsType
    | ModalActionsType
    | NotificationActionsType;

export type CommonDispatchType = Dispatch<CommonActionsType>;

export type CommonReducerType = {
    commonSettings: CommonSettingsType;
    utils: UtilStateType;
    modal: ModalReducerType;
    notification?: NotificationReducerType;
};

export const useCommonSelector: TypedUseSelectorHook<CommonReducerType> = useSelector;
