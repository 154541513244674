import React, { useEffect, useRef, useState } from "react";
import { ResizeObserver } from "@juggle/resize-observer"; // Not yet supported on Tizen 4, used for retailer screens
import { CarFilterContentBlockConfigType } from "../../../redux/reducers/CarFilterSettingsReducer";
import { IconChevronDown } from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import * as Styles from "./styles/ContentBlockStyles";

const ContentBlock = ({ contentBlockConfig }: { contentBlockConfig: CarFilterContentBlockConfigType }): JSX.Element => {
    const { items, title } = contentBlockConfig;
    const [scrollingVisible, setScrollingVisible] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const ref = contentRef.current;

        const checkScroll = (): void => {
            if (ref) {
                const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
                const isScrollingNeeded = clientHeight <= scrollHeight;

                if (!isScrollingNeeded) {
                    setScrollingVisible(!isScrollingNeeded);
                    return;
                }

                const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;
                setScrollingVisible(!isScrolledToBottom);
            }
        };

        ref?.addEventListener("scroll", checkScroll);
        checkScroll();

        const resizeObserver = new ResizeObserver(() => {
            if (ref?.clientHeight) checkScroll();
        });

        resizeObserver.observe(ref as HTMLElement);

        return () => {
            ref?.removeEventListener("scroll", checkScroll);
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <Styles.Wrapper role="complementary" aria-label={title} scrollingVisible={scrollingVisible}>
            <Styles.ContentWrapper ref={contentRef}>
                <Styles.Content>
                    <Styles.Title>{title}</Styles.Title>
                    <Styles.List>
                        {items.map((listItem, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Styles.ListItem key={index}>{listItem}</Styles.ListItem>
                        ))}
                    </Styles.List>
                    {/* TODO: Add this button once AEM supports adding URL to Content Blocks */}
                    {/* {!isRetailer(commonSettings) && <Styles.Button type="button">{buttonLabel}</Styles.Button>} */}
                </Styles.Content>
            </Styles.ContentWrapper>

            <Styles.ScrollIndicator>
                <IconChevronDown />
            </Styles.ScrollIndicator>
        </Styles.Wrapper>
    );
};

export default ContentBlock;
