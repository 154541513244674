import styled from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
    color: ${theme.colors.primaryBlack};
`;

export const ModalFooter = styled.div`
    position: sticky;
    bottom: 0;
    padding: ${theme.space[2]}px;
    background-color: ${theme.colors.primaryWhite};
    border-top: 1px solid ${theme.colors.grey2};
`;

export const ListSortFilter = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        margin: 0;
        padding: ${theme.space[2]}px;
        border-bottom: 1px solid ${theme.colors.grey2};
    }
`;
