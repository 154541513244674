import { RefObject, useEffect, useRef, useState } from "react";
import { useCarFilterSelector } from "../../redux/store";
import { MapWrapperType, useToyotaMap } from "../../../../common-deprecated/ToyotaMap";
import { CoordinateType } from "../../../../common-deprecated/utils/dealerConstants";
import { LocationFilterIdType } from "../../utils/constants/filterConstants";
import { getMapboxLatLng } from "../../../../common-deprecated/utils";

type UseLocationMap = {
    mapInitialized: boolean;
};

/**
 * Used in LocationMap.
 */
const useLocationMap = (mapContainer: RefObject<HTMLDivElement>, filterId: LocationFilterIdType): UseLocationMap => {
    const mapboxToken = useCarFilterSelector((state) => state.commonSettings.mapboxToken);
    const country = useCarFilterSelector((state) => state.commonSettings.country);
    const brand = useCarFilterSelector((state) => state.commonSettings.brand);
    const locationFilter = useCarFilterSelector((state) => state.carFilters[filterId]);
    const mapRef = useRef<InstanceType<MapWrapperType["DynamicMap"]> | null>(null);
    const [mapInitialized, setMapInitialized] = useState<boolean>(false);

    const markerImage = brand === "toyota" ? "toyota-red-logo" : "lexus-blue-logo";

    // Initialize mapbox map once it is available.
    const mapWrapper = useToyotaMap();
    useEffect(() => {
        if (mapWrapper && mapContainer.current) {
            const initMap = async () => {
                mapRef.current = new mapWrapper.DynamicMap(mapContainer.current, {
                    accessToken: mapboxToken,
                    boundsPaddingTop: 100,
                });

                // Uncomment code below to easily test mapbox transitions in console.
                // window.testMap = mapRef.current;

                // Set initialized flag true which should hide the preloader and trigger effects below.
                setMapInitialized(true);
            };
            initMap();
        }
    }, [mapWrapper]);

    // ----------------------------------------------------------------------
    // Location update helper variables
    // ----------------------------------------------------------------------
    const selectedLocation = locationFilter.range;
    const selectedDealer = locationFilter.dealer;

    let currentLocation: CoordinateType | null = null;
    if (selectedDealer) currentLocation = selectedDealer.geoLocation;
    else if (selectedLocation) currentLocation = selectedLocation.coords;

    // Create a key which is used to trigger map transition effects below.
    const locationKey = JSON.stringify(currentLocation) + Number(selectedLocation?.range);

    // ----------------------------------------------------------------------
    // Map transitions
    // ----------------------------------------------------------------------
    const previousRange = useRef<number>(locationFilter.range?.range || 0);
    useEffect(() => {
        // This effect handles all map transitions/initialisations.
        // It should be triggered after a map init or whenever something related to the content on the map changes.
        if (mapInitialized && mapRef.current) {
            // Remove previous icons from the map as with a locationKey change the content on the map should be updated.
            mapRef.current.clearMarkers();

            if (selectedLocation) {
                if (selectedLocation.range === 0 && previousRange.current !== 0) {
                    // User selected national, zoom to country bounds.
                    mapRef.current.focusOn(country, { boundsPaddingTop: 100 });
                } else {
                    // Specific range selected, retrieve dealers to be shown based on the current range.
                    const mapDealers = locationFilter.dealerCache.filter(
                        (dealer) => dealer.distance <= selectedLocation.range,
                    );

                    if (mapDealers.length) {
                        const markers = mapDealers.map((mapDealer) => getMapboxLatLng(mapDealer.geoLocation));
                        mapRef.current.addMarkers(markers, {
                            markerImage,
                            fitToBounds: true,
                            boundsPaddingTop: 185,
                        });
                    }
                }

                mapRef.current.addMarkers([getMapboxLatLng(selectedLocation.coords)], {
                    markerImage: selectedLocation.type === "user" ? "toyota-person-blue" : "toyota-circle-blue",
                });

                previousRange.current = selectedLocation.range;
            } else if (selectedDealer) {
                // Dealer selected, add dealer icon to the map.
                const mapboxCoords = getMapboxLatLng(selectedDealer.geoLocation);
                mapRef.current.addMarkers([mapboxCoords], {
                    markerImage,
                    boundsPaddingTop: 185,
                    fitToBounds: true,
                });
            } else {
                // No location, zoom back to country context.
                mapRef.current.focusOn(country);
            }
        }
    }, [locationKey, mapInitialized]);

    return { mapInitialized };
};
export default useLocationMap;
