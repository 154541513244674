import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Group = styled.div`
    display: flex;
    position: absolute;
    top: ${theme.space[2]}px;
    right: ${theme.space[2]}px;
    flex-direction: column;
    gap: ${theme.space[2]}px;
`;

export const Badge = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    padding: 4px 16px;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    color: ${theme.colors.primaryBlack};
    background: ${theme.colors.primaryWhite};
    border-radius: 20px;
    opacity: 0.95;
    z-index: ${theme.zIndex.sticky};
`;
