// Seems overkill to add a style folder for this override?
import styled from "styled-components";
import * as Modal from "../../../../styles/v2/toyota/globals/Modal";
import * as Flex from "../../../../styles/v2/toyota/globals/Flex";
import { getDirection } from "../../../../themes/common";

/**
 * These styles are independent of toyota/lexus or tridion/aem,
 * so most values are hardcoded so site styling doesn't break the modals
 */

export const Wrapper = styled(Modal.Body)`
    padding: 0 16px; /* overwrites the old modal css applied by .or-modal-body */
`;

export const Row = styled(Flex.Row)<{ isClickable?: boolean }>`    
    padding: 8px 0;
    font-size: 12px;
    line-height: 1.6;
    background: ${(props) => props.theme.color.lightest};
    cursor: ${(props) => (props.isClickable ? "pointer" : "default")};
    overflow-wrap: break-word;

    &:nth-child(even) {
        background: ${(props) => props.theme.color.grey1};
    }

    strong {
        margin-${getDirection("right")}: 8px;
        font-size: 13px;
    }

    select {
        border-radius: 3px;
        border-width: 1px;
    }

    span + select {
        margin-${getDirection("left")}: 6px;
    }

    input[type=checkbox] {
        margin-${getDirection("right")}: 6px;
        vertical-align: middle;
    }
`;

export const LinksWrapper = styled.div`
    a {
        margin: 0 2px;
        &:last-child {
            margin-right: 0;
        }
        &:not(:first-of-type)::before {
            display: inline-block;
            margin-right: 4px;
            content: " - ";
        }
    }
`;

export const Code = styled.code`
    display: block;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
`;
