// Wrapper component used to animate the basket dropdown transition with CSS.
// A height transition requires an actual px height to work, however the basket needs a dynamic height because the content height can change.
// This component solves the problem by dynamically setting the height when a transition is needed but defaulting to "auto" outside of a transition.
import React, { useEffect, useRef, useState } from "react";
import { useAemNavOffset } from "../../hooks";
import * as Style from "./DropdownContentStyles";

type DropdownContentType = {
    show: boolean;
    children: React.ReactNode;
    scrollIntoView?: boolean;
    // maxHeightTopOffset can be used to make sure content positioned above
    // the dropdowncontainer remains in the viewport when opening the dropdown content (for example a dropdown label or an extra navbar)
    // the standard navOffset is already taken into account
    maxHeightTopOffset?: number;
    id?: string;
    role?: string;
    labelledby?: string;
};

const DropdownContainer = (props: DropdownContentType): JSX.Element => {
    const { show, children, scrollIntoView = false, maxHeightTopOffset = 0, id, role, labelledby } = props;
    const contentRef = useRef<HTMLDivElement>(null);
    const [showChildren, setShowChildren] = useState<boolean>(show);
    const [dropdownHeight, setDropdownHeight] = useState<number | "auto">(show ? "auto" : 0);

    const navOffset = useAemNavOffset();

    useEffect(() => {
        // React tries to be smart and combine some of these side effect renders in one go.
        // Use window.requestAnimationFrame to force a rerender so we always get the height CSS transitions.
        window.requestAnimationFrame(() => {
            if (contentRef.current) {
                const { top, height, bottom } = contentRef.current!.getBoundingClientRect();
                if (show && !showChildren) {
                    // Children need to be shown, set the height which will trigger an animation.
                    setDropdownHeight(height);
                    setShowChildren(true);

                    // Scroll into view when the opening dropdown is not completely in view.
                    const viewportDiffToBottomOfDropdownContainer = bottom - window.innerHeight;
                    if (viewportDiffToBottomOfDropdownContainer > 0 && scrollIntoView) {
                        // If the dropdown content is higher than the available viewport height, scroll to the top of the dropdown container
                        if (window.innerHeight - navOffset - maxHeightTopOffset <= height) {
                            const viewportDiffToTopOfDropdownContainer = top - navOffset - maxHeightTopOffset;
                            window.scrollTo({
                                behavior: "smooth",
                                top: window.pageYOffset + viewportDiffToTopOfDropdownContainer,
                            });
                        } else {
                            // Scroll to the bottom of the container
                            window.scrollTo({
                                behavior: "smooth",
                                top: window.pageYOffset + viewportDiffToBottomOfDropdownContainer,
                            });
                        }
                    }
                } else if (!show && showChildren && dropdownHeight === "auto") {
                    // Children need to be hidden, set height to px first so we're able to animate the closing (see next if statement) .
                    setDropdownHeight(height);
                } else if (!show && showChildren) {
                    setDropdownHeight(0);
                    setShowChildren(false);
                }
            }
        });
    }, [show, dropdownHeight, showChildren, scrollIntoView, navOffset, maxHeightTopOffset]);

    return (
        <Style.DropdownContent
            show={showChildren}
            height={dropdownHeight}
            onTransitionEnd={(event) => {
                if (showChildren && event.propertyName === "height" && dropdownHeight !== 0) {
                    // Transition to full height has been completed, set height to auto to account for changing content.
                    setDropdownHeight("auto");
                }
            }}
            id={id}
            role={role}
            aria-labelledby={labelledby}
            // The inert global attribute is a Boolean attribute indicating that the browser will ignore the element.
            // Documentation: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert
            ref={(node) => node && (showChildren ? node.removeAttribute("inert") : node.setAttribute("inert", ""))}
        >
            <Style.DropdownChildWrapper ref={contentRef}>{children}</Style.DropdownChildWrapper>
        </Style.DropdownContent>
    );
};

export default DropdownContainer;
