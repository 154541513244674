import Debug from "../Debug";
import { DealerResultType } from "./dealerConstants";

export type AEMTrackingProductType = {
    category: string;
    subCategory?: string;
    productInfo?: {
        // common
        productId?: string;
        productName?: string;
        modelName?: string;
        price?: string;
        currencyCode?: string;
        engineType?: string;

        // leasing
        monthlyfee?: string;

        // used cars
        manufacturerId?: string;
        manufacturerName?: string;
        modelVersion?: string;
        licensePlate?: string; // Used for TES UCL tracking
        ecoLabel?: string; // Used for TES UCL tracking

        // new cars
        tmeModelId?: string; // should be modelCode, even though the param name mentions id
        gradeId?: string;
        gradeName?: string;
        engineId?: string;
        engineName?: string;
        engineIsHybrid?: 0 | 1;
        bodytypeId?: string;
        bodytypeName?: string;
        transmissionId?: string;
        transmissionName?: string;
    };
};

export type AEMTrackingFinanceType = {
    products?: {
        productName?: string;
        currencyCode?: string;
        monthlyfee?: string;
        duration?: string;
    }[];
    settings?: {
        calculationname: string;
        currentvalue: string;
    }[];
};

export type AEMTrackingDataLayerType = {
    event: {
        name:
            | "componentevent"
            | "workflowevent"
            | "searchevent"
            | "ctaevent"
            | "assetevent"
            | "formevent"
            | "carconfigevent"
            | "carconfigstep"
            | "configdetailsevent"
            | "deliveryevent"
            | "fincalcevent"
            | "dealerevent";
        componentName: string;
        componentGroup: string;
        action: string;
        label?: string;
    };
    workflow?: {
        name: string;
        step: string;
        conversion?: number;
        variant?: string;
    };
    finance?: AEMTrackingFinanceType;
    product?: AEMTrackingProductType[];
    dealer?: {
        name: string;
        dealerId: string;
        city: string;
        zipCode: string;
        region: string;
        dealerLocalId?: string; // Used for TES UCL tracking
        email: string; // Used for TES UCL tracking
        phoneNumber: string; // Used for TES UCL tracking
    };
    search?: {
        type: string;
        query: string;
        results: number;
        filters: string;
    };
    form?: {
        name: string;
        step: string;
        source: string; // Car Filter or Detail Page
        conversion?: number;
    };
    configuration?: {
        type: "carconfig";
        modelId: string;
        carPrice?: string;
        totalPrice?: string;
        toyotaCode?: string;
        isHybrid?: 0 | 1;
        accessories: 0 | 1;
        options: 0 | 1;
        packs: 0 | 1;
        services: 0 | 1;
    };
    delivery?: {
        products: {
            productname: string;
            price: string;
            currencyCode: string;
        }[];
        settings: {
            calculationname: string;
            currentvalue: string;
        }[];
    };
    page?: Record<string, unknown>;
    userinfo?: unknown;
};

export type AEMTrackingShortEventType = {
    component: string;
    action: string;
    group: string;
    label?: string;
    name?: AEMTrackingDataLayerType["event"]["name"];
};

export const trackEvent = (
    { name, component, group, action, label }: AEMTrackingShortEventType,
    additionalObjects: Omit<AEMTrackingDataLayerType, "event"> | null = null,
    additionalEventData: Record<string, string> | null = null,
): void => {
    const data: AEMTrackingDataLayerType = {
        event: {
            name: name || "componentevent",
            componentName: component,
            componentGroup: group || "new-cars",
            action,
            label,
            ...additionalEventData,
        },
        ...additionalObjects,
    };

    const channel = window?.T1?.channels?.statistics;
    const topic = window?.dxp?.constants?.TOPICS_T1.STATS_TRACK;

    if (channel && topic) {
        channel.publishAsync(topic, { extraData: data });
    } else {
        Debug.warn("trackEvent triggered, but analytics postal channel/topic don't exist", data);
    }
};

/**
 * Data of the "base" datalayer describes the current default state. Behind the scenes it will be used to amend the event data.
 *
 * You might still have to send the same properties via trackEvent where applicable / requested. For example on the used cars PDP:
 * - When interacting with different cars inside the SimilarCars slider, the productInfo fields will be different from the base one
 * - When interacting with dealer specific components we still track the dealer in question even though it's the same dealer in the baseLayer
 *
 * Keep in mind this does not log a message, nor does this update the datalayer directly. Instead this data will be pushed to the datalayer on the next event
 */
export const updateTrackingBaseLayer = (
    data: Omit<AEMTrackingDataLayerType, "event" | "workflow">,
    overwrite?: boolean,
): void => {
    const channel = window?.T1?.channels?.statistics;
    const topic = window?.dxp?.constants?.TOPICS_T1.STATS_BASE_UPDATE;
    if (!channel || !topic) {
        Debug.warn("updateBase triggered, but analytics postal channel/topic don't exist", data);
        return;
    }

    if (overwrite) {
        channel.publishAsync(topic, { baseObj: data });
    } else {
        channel.publishAsync(topic, { extend: data });
    }
};

export const getDealerAEMTrackingInfo = (dealer: DealerResultType): Required<AEMTrackingDataLayerType["dealer"]> => ({
    dealerId: dealer.id,
    dealerLocalId: dealer.localId,
    name: dealer.name,
    city: dealer.address.city,
    region: dealer.address.region,
    zipCode: dealer.address.zip,
    email: dealer.email,
    phoneNumber: dealer.phoneNumber,
});
