import styled from "styled-components";
import * as FormStyles from "../../../../../../../common-deprecated/styles/v2/toyota/components/Form";
import { getBreakpoint } from "../../../../../../../common-deprecated/themes/common";

export const Wrapper = styled.div`
    ${FormStyles.Header} {
        margin-left: -40px;
        margin-right: -40px;

        @media ${getBreakpoint("up", "lg")} {
            margin-left: -64px;
            margin-right: -64px;
        }
    }
`;
