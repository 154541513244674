import * as DOMUtils from "history/DOMUtils";
import { QueryType } from "../../../../server/types";
import { CommonSettingsType } from "../../../../settings/fetchCommonSettings";
import { QueryBuilderStateType } from "../hooks/useToolsModal";
import { queryStringToCorrectEnv, objectToQueryString, queryStringToObject } from "../../../../utils";
import {
    COMPONENT_BUILDANDBUY,
    COMPONENT_CAR_CONFIG,
    COMPONENT_CAR_FILTER,
    COMPONENT_CAR_FILTER_HEADER,
    COMPONENT_GRADE_SELECTOR,
    COMPONENT_LEASING_FILTER,
    COMPONENT_LEASING_HEADER,
    ComponentType,
} from "../../../../../shared-logic/server/components";
import { ToolsModalEnvNameType } from "../../../../utils/environments";

/**
 * Returns the target URL with the query params provided in the queryObjects array.
 */
export const getCombinedToolUrl = (
    targetUrl: string,
    queryObjects: QueryBuilderStateType,
    commonSettings: CommonSettingsType,
    extraQueryParams?: Record<string, any>,
): string => {
    // convert QueryBuilderStateType to more basic type that objectToQueryString can use
    const builderQueryParams = Object.entries(queryObjects).reduce((newQueryObjectMap, [queryName, options]) => {
        if (!options.inputTouched || options.currentValue === "") return newQueryObjectMap;
        return {
            ...newQueryObjectMap,
            [queryName]: options.currentValue,
        };
    }, {} as Record<keyof QueryType, unknown>);

    const location = DOMUtils.canUseDOM ? window.location : { origin: "", pathname: "", search: "" };
    const windowQueryParams: Record<string, string> = queryStringToObject(location.search);
    const queryParams = commonSettings.query ? { ...commonSettings.query } : windowQueryParams;

    if (targetUrl.includes("localhost")) delete queryParams?.useGlobalStore; // localhost car-filter will not work with useGlobalStore, so we remove it.

    let queryString = objectToQueryString({ ...queryParams, ...extraQueryParams, ...builderQueryParams });

    // In some occasions we want to change the queryString to the correct variant according to the environment.
    // E.g. build CUSTOMIZE -> apheleia CONFIGURE.
    // Note that Apheleia has it's own ToolsModal.
    if (commonSettings.component === COMPONENT_BUILDANDBUY) {
        queryString = queryStringToCorrectEnv(queryString, commonSettings.component, targetUrl);
    }

    const seperator = queryString ? "?" : "";

    return `${targetUrl}${seperator}${queryString}`;
};

// With the recent introduction of the Top Filters components (UCL & Leasing Filters),
// there's now the possibility of having 2 components on the same page (the filter, and the filter header).
// As this causes the issue of the wrong component being defined in commonSettings.component,
// we now need to redefine the component to ensure that the correct component is used in the Tools Modal.
export const redefineComponent = (
    currentComponent: ComponentType,
    selectedComponent: ToolsModalEnvNameType,
): ComponentType => {
    if (currentComponent === COMPONENT_CAR_FILTER_HEADER) return COMPONENT_CAR_FILTER;
    if (currentComponent === COMPONENT_LEASING_HEADER) return COMPONENT_LEASING_FILTER;
    if (currentComponent === COMPONENT_GRADE_SELECTOR) return COMPONENT_GRADE_SELECTOR;

    if (currentComponent === COMPONENT_CAR_CONFIG && selectedComponent.component === "NewCars")
        return COMPONENT_BUILDANDBUY;
    if (currentComponent === COMPONENT_BUILDANDBUY && selectedComponent.component === "Apheleia")
        return COMPONENT_CAR_CONFIG;

    return currentComponent;
};
