import styled from "styled-components";
import * as DropDown from "../../../../../../common-deprecated/components/dropdown/DropdownContentStyles";
import * as TabToggle from "../../../../../../common-deprecated/styles/v2/toyota/components/Tabtoggle";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { breakpoints } from "../../../../../shared-logic/themes/toyotaTheme";

export const Wrapper = styled.div<{ show: boolean }>`
    position: relative;
    border-bottom: 1px solid ${({ show }) => (show ? theme.colors.grey2 : "transparent")};

    ${DropDown.DropdownContent} {
        padding: 0;
        background-color: ${theme.colors.grey1};

        @media screen and (min-width: ${breakpoints.lg}) {
            padding: 0;
            background-color: transparent;
        }
    }
`;

export const DropdownContent = styled.div`
    margin: 0;
    padding: ${theme.space[2]}px;
    background: ${theme.colors.primaryWhite};

    @media screen and (min-width: ${breakpoints.lg}) {
        margin: 0;
        padding: ${theme.space[1]}px 0 ${theme.space[3]}px;
        background: transparent;
    }

    ${TabToggle.Wrapper} {
        margin-top: -${theme.space[2]}px;
        padding: ${theme.space[1] / 2}px;
        background: ${theme.colors.grey2};
        @media screen and (min-width: ${breakpoints.lg}) {
            margin-top: -${theme.space[3]}px;
        }
    }
    ${TabToggle.Tab} {
        padding-top: 12px;
        padding-bottom: 12px;
        font-family: ${theme.fonts.regular};
        font-size: 1.3rem;
        text-align: center;
        text-transform: uppercase;
    }
`;

export const Header = styled.div<{ show?: boolean }>`
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: ${theme.space[2]}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    border-bottom: 1px solid ${({ show }) => (show ? "transparent" : theme.colors.grey2)};
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.lg}) {
        margin: 0;
        padding: ${theme.space[2]}px 0;
    }
`;

export const IconWrapper = styled.div<{ isDown: boolean }>`
    display: inline-block;
    width: 20px;
    height: 20px;

    .icon::before,
    svg {
        transform: ${(props) => (props.isDown ? "rotate(180deg)" : "none")};
        transition: transform 0.3s;
    }
`;
