import { createLogic } from "redux-logic";
import { CarFilterLogicType } from "../store";
import {
    CAR_FILTER_LOCATION_ACTIONS,
    CAR_FILTER_SELECT_MULTI,
    CAR_FILTER_SHOW_MORE,
    CarFilterSelectMultiType,
    CarFilterShowMoreType,
    LocationFilterActionsType,
    resetMultiFilter,
    CarFilterResetMultiType,
    CAR_FILTER_RESET_MULTI,
    CarFilterSetPageType,
    CAR_FILTER_SET_PAGE,
} from "../actions/CarFiltersActions";
import { DfCarSortOrder } from "../../utils/constants/filterConstants";
import { UsedCarFilterId, StockCarFilterId, UscContext } from "../../../shared-logic/types/UscCommonTypes";
import { replaceQueryParams } from "../../../../common-deprecated/History";
import { setUsedCarSortOrder } from "../actions/CarResultsActions";
import { updateQueryValue } from "../../../../common-deprecated/redux/actions/CommonSettingsActions";

// Propagate the showMoreFilters state to query.
const showMoreFilterLogic = (createLogic as CarFilterLogicType<CarFilterShowMoreType>)({
    type: [CAR_FILTER_SHOW_MORE],
    process({ getState, action }, dispatch, done) {
        replaceQueryParams({ showMoreFilters: action.show ? null : "false" });
        done();
    },
});

// Logic related to multiple choice selecting.
const selectMultiLogic = (createLogic as CarFilterLogicType<CarFilterSelectMultiType>)({
    type: CAR_FILTER_SELECT_MULTI,

    // eslint-disable-next-line consistent-return
    validate({ getState, action }, next) {
        const { filterId, selected, ids } = action;
        const filter = getState().carFilters[filterId];

        if (!selected) {
            // If values are deselected, check if there are any other selected values.
            // If not, reset the filter.
            const noValuesActive = !filter.values.find((val) => val.selected && !ids.includes(val.id));
            if (noValuesActive && filter.active) return next(resetMultiFilter(filterId));
        }

        next(action);
    },
    process({ getState, action }, dispatch, done) {
        const { filterId, selected } = action;
        const filterContext = getState().carFilters.currentFilter;

        // If no brands are selected, reset the model filter.
        if (filterId === UsedCarFilterId.Brand || filterId === StockCarFilterId.Brand) {
            const modelFilter = filterContext === UscContext.Used ? UsedCarFilterId.Model : StockCarFilterId.Model;
            if (!selected) dispatch(resetMultiFilter(modelFilter));
        }

        done();
    },
});

const resetMultiLogic = (createLogic as CarFilterLogicType<CarFilterResetMultiType>)({
    type: CAR_FILTER_RESET_MULTI,

    // eslint-disable-next-line consistent-return
    process({ getState, action }, dispatch, done) {
        const { filterId } = action;
        const filterContext = getState().carFilters.currentFilter;

        // If the brand filter is reset, reset the model filter.
        if (filterId === UsedCarFilterId.Brand || filterId === StockCarFilterId.Brand) {
            const modelFilter = filterContext === UscContext.Used ? UsedCarFilterId.Model : StockCarFilterId.Model;
            dispatch(resetMultiFilter(modelFilter));
        }

        done();
    },
});

// Enable/disable distance sorting when the location filter is enabled/disabled.
const distanceSortLogic = (createLogic as CarFilterLogicType<LocationFilterActionsType>)({
    type: CAR_FILTER_LOCATION_ACTIONS,

    process({ action, getState }, dispatch, done) {
        const { carFilters, carResults } = getState();
        const { filterId } = action;
        const locationFilterIsActive = carFilters[filterId].active;

        if (locationFilterIsActive && carResults.dfCarSortOrder !== DfCarSortOrder.Distance) {
            dispatch(setUsedCarSortOrder(DfCarSortOrder.Distance));
        } else if (!locationFilterIsActive && carResults.dfCarSortOrder === DfCarSortOrder.Distance) {
            dispatch(setUsedCarSortOrder(DfCarSortOrder.Published));
        }

        done();
    },
});

// Pagination: when a page is set, append the page to the query.
const paginationLogic = (createLogic as CarFilterLogicType<CarFilterSetPageType>)({
    type: CAR_FILTER_SET_PAGE,
    process({ action }, dispatch, done) {
        const { page } = action;

        replaceQueryParams({ page: page > 1 ? page : null });
        dispatch(updateQueryValue({ page: page > 1 ? page : undefined }));

        done();
    },
});

export default [showMoreFilterLogic, distanceSortLogic, selectMultiLogic, resetMultiLogic, paginationLogic];
