/**
 * Helpers specifically for used/stock car functionality.
 */
import { Request } from "express";
import { QueryDslQueryContainer } from "@elastic/elasticsearch/lib/api/types";
import { defaultFinanceInfoObject } from "../../../common-deprecated/constants";
import { FinanceInfoType, UsedCarsTranslationResultType } from "../../../common-deprecated/types/CommonTypes";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import {
    COMPONENT_CAR_FILTER,
    COMPONENT_USED_STOCK_CARS_PDF,
    COMPONENT_USED_STOCK_CARS_V2,
    COMPONENT_USED_STOCK_CARS_V2_PDF,
} from "../../../shared-logic/server/components";
import { UscVehicleReducerType } from "../../pdp/common/redux/reducers/UscVehicleReducer";
import {
    AEM_AUTHOR_URL_SUFFIX,
    EnvironmentEnum,
    getParamFromRequest,
    getRetailerAppsQueryString,
    isAemAuthorUrl,
} from "../../../common-deprecated/utils";
import { UscEcoValueMeasureSystem } from "./uscEcoUtils";
import {
    DF_CODE_NOT_AVAILABLE,
    DfEquipmentCategoryCode,
    DfExternalVehicleAttrs,
    DfFuelTypeId,
    DfOwnershipCode,
    DfTargetChannelCode,
    DfUcProgram,
    UsedCarCompareEquipmentCategoryType,
} from "./dfConstants";
import { StockTypeMapping } from "../../car-filter/utils/constants/filterConstants";
import {
    UscContext,
    UscVehicleSaleType,
    UsedCarResultType,
    VehicleType,
    UscCustomerPromiseBannerType,
    LocalDealerFormDataType,
} from "../types/UscCommonTypes";
import { DealerOperation, DealerResultType } from "../../../common-deprecated/utils/dealerConstants";
import { DealerInfoItemType } from "../../pdp/common/utils/helpers";
import { SpecSection } from "../../pdp/common/utils/constants";
import { CommonStateType, getCommonLabel } from "../../../common-deprecated/utils/commonLabels";
import { QueryBuilderStateType } from "../../../common-deprecated/features/new-cars/debug-modal/components/ToolsModal";
import { getAemMonthlyRateFormat } from "../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { MonthlyPriceLabelKey } from "../../../common-deprecated/priceUtils";
import { AemFmComponent } from "../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { formatMonthlyFinancePrice } from "./uscPriceUtils";
import { LoadedUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { AEMBannerDescriptionItemType } from "../../../common-deprecated/types/SettingsType";
import { getIntlLocale } from "../../../common-deprecated/utils/i18nUtils";

export enum UscEnv {
    Production = "production",
    Uat = "uat",
}

// Shared vehicle type between ES and GQL
// TODO: Optimise usage of this type at a later phase
export type UscVehicleType = UsedCarResultType | VehicleType;

// Gateway items for USC functionality. Shared across used/stock and toyota/lexus, but usually there are UAT and Prod versions available.
export type UscGatewayItemsType = {
    dfElasticSearchService: string;
    padocElasticSearchService: string;
    padocElasticSearchServiceUsername: string;
    padocElasticSearchServicePassword: string;
    referenceApiService: string;
    referenceApiUsername: string;
    referenceApiPassword: string;
    dfVehicleGatewayUrl: string;
    dfVehicleGatewayUsername: string;
    dfVehicleGatewayPassword: string;
    makolabInsuranceGateway: string;
    A2DGateway: string; // Elastic search endpoint
    A2DIdpId: string; // Used for Oauth. idp = 'Identity Provider ID' (Azure related term)
    A2DClientId: string; // Used for Oauth
    A2DClientSecret: string; // Also Oauth
    deliveryCostToken: string; // Currently used for Driiveme, keeping it as a general name in case the provider changes.
    deliveryCostUrl: string; // Currently used for Driiveme, keeping it as a general name in case the provider changes.
};

/**
 * Format the DF financingOffer data structure to the FinanceInfo structure used in OR.
 */
type FinanceOfferBaseType = {
    product?: string;
    legalDisclaimer?: string;
    legalDisclaimers?: UsedCarsTranslationResultType[];
    monthlyPaymentInclVAT?: number | string;
    downPayment?: string;
    duration?: string;
    interestRate?: string;
    effectiveInterestRate?: string;
    residualValue?: string;
    lastInstalment?: string;
    numberOfInstalments?: string;
    margin?: string;
    euribor?: string;
    euriborMonths?: string;
    euriborDate?: string;
    productName?: string;
};

type FinanceInfoReturnType = {
    financeInfo: FinanceInfoType | null;
    monthlyPrice: number;
};

export const formatUscFinanceInfo = (
    country: string,
    language: string,
    financingOffers?: FinanceOfferBaseType[],
): FinanceInfoReturnType => {
    const data: FinanceInfoReturnType = { financeInfo: null, monthlyPrice: 0 };

    if (!financingOffers || !financingOffers[0]) return data;

    // ⚠️ In Makolab this property is called 'installmentsNumber'. DF renamed this property to 'numberOfInstalments' at their side
    // I map this here from 'numberOfInstalments' to 'installmentsNumber' to keep consistency for future refactoring
    const installmentsNumber = financingOffers[0].numberOfInstalments;

    data.financeInfo = {
        ...defaultFinanceInfoObject,
        disclaimer: {
            value: financingOffers[0].legalDisclaimers
                ? getTranslation(financingOffers[0].legalDisclaimers, country, language)
                : "",
        },
        annualInterestRate: financingOffers[0].interestRate || "",
        effectiveInterestRate: financingOffers[0].effectiveInterestRate || "",
        term: financingOffers[0].duration || installmentsNumber || "",
        residualValue: financingOffers[0].residualValue || "",
        downPayment: financingOffers[0].downPayment || "",
        lastInstalment: financingOffers[0].lastInstalment || "",
        margin: financingOffers[0].margin || "",
        euriborValue: financingOffers[0].euribor || "",
        euriborMonths: financingOffers[0].euriborMonths || "",
        euriborDate: financingOffers[0].euriborDate || "",
        productName: financingOffers[0].productName || "",
    };

    data.monthlyPrice = Number(financingOffers[0].monthlyPaymentInclVAT || 0);

    return data;
};

/**
 * Open Detail pdf with tyCode, will fallback to using vehicleForSaleId if not set
 */
export const getUscPdfUrl = (
    commonSettings: CommonSettingsType,
    vehicle: UscVehicleReducerType,
    tyCode?: string | null,
    useV2: boolean = false,
): string => {
    const vehicleQueryParam = tyCode ? `tyCode=${tyCode}` : `vehicleForSaleId=${vehicle.result?.id || ""}`;
    const uscEnvParam = commonSettings.query.uscEnv ? `&uscEnv=${commonSettings.query.uscEnv}` : "";

    return (
        `${commonSettings.resourcePath}/${commonSettings.country}/${commonSettings.language}/` +
        `${useV2 ? COMPONENT_USED_STOCK_CARS_V2_PDF : COMPONENT_USED_STOCK_CARS_PDF}?uscContext=${vehicle.type}` +
        `&${vehicleQueryParam}&brand=${commonSettings.brand}${uscEnvParam}`
    );
};

/**
 * These query params need to be passed between standalone versions of the carfilter and pdp components.
 */
export const getSharedUscStandaloneQueryParams = (commonSettings: CommonSettingsType): string => {
    const { query } = commonSettings;

    const uscEnvParam = query.uscEnv ? `&uscEnv=${query.uscEnv}` : ""; // Only add these to standalone page URLs as T1 doesnt support this and needs env var updates
    const brandParam = query.brand ? `&brand=${query.brand}` : "";
    const pageParam = query.page ? `&page=${query.page}` : "";
    const disabledFiltersParam = query.disabledFilters ? `&disabledFilters=${query.disabledFilters}` : "";

    const retailerQueryString = getRetailerAppsQueryString(commonSettings);
    const retailerParam = retailerQueryString ? `&${retailerQueryString}` : "";

    const variantBrandParam = query.variantBrand ? `&variantBrand=${String(query.variantBrand)}` : "";

    return `${uscEnvParam}${brandParam}${retailerParam}${variantBrandParam}${pageParam}${disabledFiltersParam}`;
};

// Information required from a USC vehicle to construct an SEO friendly URL.
// As car data structure is different between USC and car-filter this is a separate object type.
export type UscVehicleUrlInfo = {
    manufacturer: string;
    model: string;
    firstRegistrationDate: string;
    productionDate: string;
    bodyType: string;
    transmissionType: string;
    marketingFuelType: string;
    vehicleForSaleId: string;
};

/**
 * Get a URL for the USC PDP.
 *
 * The seoUrl param will enable the usage of the 'SEO friendly' URL described in https://toyota-europe.atlassian.net/wiki/spaces/UCP/pages/43746060/SEO+friendly+URLs
 */
export const getUscUrl = (
    commonSettings: CommonSettingsType,
    detailPageUrl: string,
    context: "used" | "stock",
    vehicleInfo: UscVehicleUrlInfo,
): string => {
    const { country, language, isStandalone } = commonSettings;
    const { manufacturer, model, bodyType } = vehicleInfo;
    const { transmissionType, marketingFuelType, vehicleForSaleId } = vehicleInfo;
    const { firstRegistrationDate, productionDate } = vehicleInfo;

    const yearItem = context === "used" ? firstRegistrationDate : productionDate;

    const urlDelimiter = "."; // aem will parse slashes to sub pages so we use a dot instead

    const shouldHideBrand = shouldBrandBeHidden(model, manufacturer);

    const enhancedUrl = [
        shouldHideBrand ? "" : manufacturer,
        model,
        yearItem ? String(new Date(yearItem).getFullYear()) : "",
        bodyType,
        transmissionType,
        marketingFuelType,
        vehicleForSaleId,
    ]
        .filter(Boolean)
        .map(
            (value) =>
                value
                    // 'Normalize' the string, removing characters like 'î'  amd replacing them with 'i'.
                    // Based on https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
                    .normalize("NFD")
                    .replace(/N\/A/g, "") // Remove "N/A" (VG not available code)
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/[()]/g, "") // Remove brackets
                    .replace(/[\s,/]+/g, "-") // Replace spaces, slashes and commas with a dash
                    .replace(/[+]/g, "plus"), // Replace '+' with 'plus'
        )
        .map((value) => value.replace(/[.]/g, "")) // remove dots on aem since this breaks the selector into multiple pieces
        .join("-")
        .replace(/-+/g, "-") // Remove consecutive dashes (possible if multiple values are empty)
        .toLowerCase();

    if (isStandalone) {
        const sharedQueryParams = getSharedUscStandaloneQueryParams(commonSettings);

        return `/${country}/${language}/${COMPONENT_USED_STOCK_CARS_V2}?uscContext=${context}&vehicleForSaleId=${vehicleForSaleId}${sharedQueryParams}`;
    }

    // AEM Author with "view as published" urls
    if (isAemAuthorUrl(detailPageUrl)) {
        const fullPageUrl = detailPageUrl.replaceAll(AEM_AUTHOR_URL_SUFFIX, "");
        return `${fullPageUrl}${urlDelimiter}${encodeURI(enhancedUrl)}${AEM_AUTHOR_URL_SUFFIX}`; // encodeURI as a failsafe but most of the special cases should already be handled above.
    }

    return `${detailPageUrl}${urlDelimiter}${encodeURI(enhancedUrl)}`;
};

/**
 * Generates a retailer specific URL for the Car Filter page.
 * This is done by adding the &location=dealerId query param to the Car Filter URL.
 * We can safely use this instead of the useBackToCarFilterUrl hook, as we don't need to load the previous filter state.
 */
export const getUscCarFilterUrlForDealer = (
    commonSettings: CommonSettingsType,
    landingPageUrl: string,
    context: "used" | "stock",
    dealerId: string,
): string => {
    const { isStandalone, country, language } = commonSettings;

    if (isStandalone) {
        const sharedQueryParams = getSharedUscStandaloneQueryParams(commonSettings);

        return `/${country}/${language}/${COMPONENT_CAR_FILTER}?carFilter=${context}&location=dealerId:${dealerId}${sharedQueryParams}`;
    }

    if (isAemAuthorUrl(landingPageUrl)) {
        const fullPageUrl = landingPageUrl.replaceAll(AEM_AUTHOR_URL_SUFFIX, "");

        return `${fullPageUrl}${AEM_AUTHOR_URL_SUFFIX}&scrollToCarFilter=true&location=dealerId:${dealerId}`;
    }

    const separator = landingPageUrl.includes("?") ? "&" : "?";

    return `${landingPageUrl}${separator}scrollToCarFilter=true&location=dealerId:${dealerId}`;
};

/**
 * Retrieve the uscEnv setting for AEM environments.
 * AEM doesnt have a global uscEnv setting (yet) so we only read the request params and base other envs on the env.ENVIRONMENT param.
 */
export const getAemUscEnvSetting = (req: Request): UscEnv => {
    return (
        getParamFromRequest(req, "uscEnv") ||
        ([EnvironmentEnum.Production, EnvironmentEnum.Preview].includes(process.env.ENVIRONMENT)
            ? UscEnv.Production
            : UscEnv.Uat)
    );
};

// Generates a Google Maps link for the given dealer
// In the past, we searched using geo coordinates, but that showed the geo coordinates of the dealer's office, not the dealer's name on the result.
// By searching using the dealer's name and address, we get the dealer's name on the result.
export const getMapsLink = (dealer: DealerResultType): string => {
    const { city, zip, country, line1, line2 } = dealer.address;
    const { name } = dealer;

    const address = [line1, line2, zip, city, country].filter(Boolean).join(", ");

    // Encode the address and name to prevent special characters from breaking the link
    const [encodedAddress, encodedName] = [address, name].map((value) =>
        // We use encodeURIComponent instead of encodeURI because we want to encode the spaces as %20 instead of + (which is the default for encodeURI)
        // We also encode the special characters that are not encoded by encodeURIComponent (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#description)
        encodeURIComponent(value).replace(/[!'()*]/g, (char) => `%${char.charCodeAt(0).toString(16)}`),
    );

    return `https://www.google.com/maps/search/?api=1&query=${encodedName}+${encodedAddress}`;
};

export enum UscQuickSpec {
    Mileage = "mileage",
    Registration = "registration",
    FuelType = "fuelType",
    Warranty = "warranty",
    Program = "program",
    BodyType = "bodyType",
    Doors = "doors",
    Transmission = "transmission",
    PowerOutput = "powerOutput",
    Colour = "colour",
    InteriorColour = "interiorColour",
    TrimColour = "trimColour",
    EcoLabel = "ecoLabel",
    Seats = "seats",
    LicensePlate = "licensePlate",
    Document = "document",
    OwnershipTax = "ownershipTax",
    EfficiencyClass = "efficiencyClass",
    EfficiencyClassText = "efficiencyClassText",
    EfficiencyClassCSMode = "efficiencyClassCSMode",
    CombinedCo2 = "combinedCo2", // NEDC or WLTP
    Co2NedcCombined = "co2NedcCombined",
    Co2WltpCombined = "co2WltpCombined",
    Co2CsCombined = "co2CsCombined",
    FcNedcCombined = "fcNedcCombined",
    FcWltpCombined = "fcWltpCombined",
    FcCsCombined = "fcCsCombined",
    FcInnerCity = "fcInnerCity", // implicit WLTP
    FcOutskirts = "fcOutskirts", // implicit WLTP
    FcCountryRoad = "fcCountryRoad", // implicit WLTP
    FcHighway = "fcHighway", // implicit WLTP
    EcCombined = "ecCombined", // implicit WLTP
    EcInnerCity = "ecInnerCity", // implicit WLTP
    EcOutskirts = "ecOutskirts", // implicit WLTP
    EcCountryRoad = "ecCountryRoad", // implicit WLTP
    EcHighway = "ecHighway", // implicit WLTP
    EaerCombined = "eaerCombined", // implicit WLTP
    EaerCity = "eaerCity", // implicit WLTP
    PerCombined = "perCombined", // implicit WLTP
    PerCity = "perCity", // implicit WLTP
    VinNumber = "vinNumber",
    DisplayAuthorization = "displayAuthorization",
    EngineNumber = "engineNumber",
    NextInspectionDate = "nextInspectionDate",
    IsPledgedSale = "isPledgedSale",
    ModelYear = "modelYear",
    BootCapacity = "bootCapacity",
    Cylinders = "cylinders",
    Displacement = "displacement",
    FiscalPower = "fiscalPower",
    MaxSpeed = "maxSpeed",
    Acceleration = "acceleration",
    Gearbox = "gearbox",
    TyreInfo = "tyreInfo",
    TyreInfoMinEfficiencyClass = "tyreInfoMinEfficiencyClass",
    TyreInfoMinWetGripClass = "tyreInfoMinWetGripClass",
    TyreInfoMinRollingNoise = "tyreInfoMinRollingNoise",
    EuroClass = "euroClass",
    Consumption = "consumption",
    PreviousAccident = "previousAccident",
    PreviousUsage = "previousUsage",
    PreviousOwners = "previousOwners",
    BatteryCapacity = "batteryCapacity",
    DriveTrain = "driveTrain",
    SdkCode = "sdkCode",

    // TDG Ecolabel specific
    EcolabelFcWltpCombined = "ecolabelFcWltpCombined",
    EcolabelEcCombined = "ecolabelEcCombined",
    EcolabelFcCsCombined = "ecolabelFcCsCombined",
}

export const uscWeightedQuickSpecs = [
    UscQuickSpec.CombinedCo2,
    UscQuickSpec.Co2NedcCombined,
    UscQuickSpec.Co2WltpCombined,
    UscQuickSpec.FcNedcCombined,
    UscQuickSpec.FcWltpCombined,
    UscQuickSpec.FcCsCombined,
    UscQuickSpec.EcCombined,
    UscQuickSpec.EfficiencyClassText,
    UscQuickSpec.EcolabelEcCombined,
] as const;
export type UscWeightedQuickSpecs = (typeof uscWeightedQuickSpecs)[number];

export const uscEcoQuickSpecs = [
    UscQuickSpec.CombinedCo2,
    UscQuickSpec.Co2NedcCombined,
    UscQuickSpec.Co2WltpCombined,
    UscQuickSpec.Co2CsCombined,
    UscQuickSpec.FcNedcCombined,
    UscQuickSpec.FcWltpCombined,
    UscQuickSpec.EcolabelFcWltpCombined,
    UscQuickSpec.FcCsCombined,
    UscQuickSpec.EcolabelFcCsCombined,
    UscQuickSpec.FcInnerCity,
    UscQuickSpec.FcOutskirts,
    UscQuickSpec.FcCountryRoad,
    UscQuickSpec.FcHighway,
    UscQuickSpec.EcCombined,
    UscQuickSpec.EcolabelEcCombined,
    UscQuickSpec.EcInnerCity,
    UscQuickSpec.EcOutskirts,
    UscQuickSpec.EcCountryRoad,
    UscQuickSpec.EcHighway,
    UscQuickSpec.EaerCombined,
    UscQuickSpec.EaerCity,
    UscQuickSpec.PerCombined,
    UscQuickSpec.PerCity,
    UscQuickSpec.Consumption,
    UscQuickSpec.EfficiencyClass,
    UscQuickSpec.EfficiencyClassText,
    UscQuickSpec.EfficiencyClassCSMode,
] as const;

export type UscEcoQuickSpecs = (typeof uscEcoQuickSpecs)[number];
export const isEcoQuickSpec = (quickSpec: UscQuickSpec): quickSpec is UscEcoQuickSpecs =>
    uscEcoQuickSpecs.includes(quickSpec as UscEcoQuickSpecs);

export const ecoNedcQuickSpecs: UscQuickSpec[] = [UscQuickSpec.Co2NedcCombined, UscQuickSpec.FcNedcCombined];
export const ecoWltpQuickSpecs: UscQuickSpec[] = [
    UscQuickSpec.Co2WltpCombined,
    UscQuickSpec.Co2CsCombined,
    UscQuickSpec.FcWltpCombined,
    UscQuickSpec.EcolabelFcWltpCombined,
    UscQuickSpec.FcCsCombined,
    UscQuickSpec.EcolabelFcCsCombined,
    UscQuickSpec.FcInnerCity,
    UscQuickSpec.FcOutskirts,
    UscQuickSpec.FcCountryRoad,
    UscQuickSpec.FcHighway,
    UscQuickSpec.EcCombined,
    UscQuickSpec.EcolabelEcCombined,
    UscQuickSpec.EcInnerCity,
    UscQuickSpec.EcOutskirts,
    UscQuickSpec.EcCountryRoad,
    UscQuickSpec.EcHighway,
    UscQuickSpec.EaerCombined,
    UscQuickSpec.EaerCity,
    UscQuickSpec.PerCombined,
    UscQuickSpec.PerCity,
    UscQuickSpec.EfficiencyClassText,
];

export type SpecCategoryType = {
    type: SpecSection;
    title: string;
    specs: QuickSpecConfigType[];
};

export type QuickSpecConfigType<T = UscQuickSpec> = {
    type: T;
    tooltip?: string;
    title: string;
    tooltipReference?: UscEcoValueMeasureSystem | UscQuickSpec;
};

export type LegalQuickSpecConfigType = QuickSpecConfigType<UscQuickSpec> & {
    isLegal?: boolean;
};

export type FullQuickSpecConfigType = QuickSpecConfigType &
    LegalQuickSpecConfigType & {
        value: string;
        valueCode?: string;
        unit?: string;
        loading?: boolean;
    };

/**
 * Get a country code to be used for DF queries.
 */
export const getDfCountryCode = (country: string): string => {
    // Global test cars are added to the TZ country, AEM environment for these is global which uses zz.
    // Override the country to tz, note that everything else still uses zz/en (settings, label config, etc).
    return country === "zz" ? "tz" : country;
};

export type EnrichmentStatusType = {
    lastEnrichment: typeof Date;
    lastEnrichmentSuccess: boolean | null;
    lastEnrichmentStatusMessage: string;
};

/**
 * Checks if the given fuelTypeCode is allowed to be displayed on the PDP page/car filter results.
 */
export const showFuelTypeTag = (fuelTypeCode: DfFuelTypeId): boolean =>
    !![DfFuelTypeId.Hybrid, DfFuelTypeId.Electric, DfFuelTypeId.Hydrogen, DfFuelTypeId.PluginHybrid].includes(
        fuelTypeCode,
    );

/**
 * Gets common filters used in Data Foundation ES queries.
 * These should be applied across _all_ DF queries.
 */
export const getCommonDfEsFilters = (country: string, filter: UscContext): QueryDslQueryContainer[] => [
    { term: { "stockType.code": StockTypeMapping[filter] } },
    { term: { "country.code": getDfCountryCode(country).toUpperCase() } },
];

/**
 * Checks if the given dealer offers the carDelivery service.
 */
export const doesDealerOfferCarDelivery = (dealer: UsedCarResultType["dealer"]): boolean =>
    !!dealer?.operations.includes(DealerOperation.UsedCarsDelivery);

/**
 * Returns the target channel to be used in the DF GraphQL query.
 * The target channel is used to filter the vehicles to be returned by the query.
 * @param brand - toyota or lexus
 * @param uscContext - used or stock
 */
export const getTargetChannel = (brand: "toyota" | "lexus", context: UscContext): DfTargetChannelCode => {
    if (context === "used") {
        return brand === "toyota" ? DfTargetChannelCode.UCL : DfTargetChannelCode.LUCL;
    }

    return brand === "toyota" ? DfTargetChannelCode.SCL : DfTargetChannelCode.LSCL;
};

/**
 * Used to format the firstRegistrationDate field.
 * We receive the date in the format YYYY-MM-DD, but we need to display it as MM-YYYY (e.g. 01-2020).
 */
export const formatRegistrationDate = (date: string, hideMonth: boolean): string => {
    const [year, month] = date.split("-");
    return hideMonth ? year : `${month}-${year}`;
};

/**
 * Hacky code alert!
 * A dealer link can be one of two things:
 * 1. A dealer website (e.g. toyotauppsala.se/)
 * 2. A retailer page (e.g. https://www.toyota.fr/dealer/00cd2-cff39-3c11c-bd600-01200-1)
 * We need to be able to differentiate between these two types of link, and the only way to do that is to check the URL.
 * Specifically, we check if the URL contains the retailer page path /dealer/, as well as the dealer uuid.
 * If both of these are present, we assume it's a retailer page.
 *
 * @param dealerInfoItem The dealer info item.
 * @param dealerUuid The dealer uuid.
 * @returns True if the dealer link is a retailer page, false otherwise.
 */
export const isRetailerPage = (dealerInfoItem: DealerInfoItemType, dealerUuid: string): boolean =>
    dealerInfoItem.type === "website" &&
    dealerInfoItem.link.includes("/dealer/") &&
    dealerInfoItem.link.includes(dealerUuid);

export const dualCurrencyDisclaimerSelector = (state: CommonStateType): string => {
    const { commonSettings } = state;

    const { currencyMultiplier } = commonSettings;
    const dualCurrencySymbol = commonSettings.culture.numberFormat?.secondaryCurrency?.symbol || "";
    const label = getCommonLabel(state, "uscDualCurrencyDisclaimer", {
        value: `1 EUR = ${currencyMultiplier} ${dualCurrencySymbol as string}`,
    });

    return label;
};

/**
 * Returns the correct uc program code depending on brand
 */
export const getValidUcProgramCode = (brand: string): DfUcProgram => {
    return brand === "toyota" ? DfUcProgram.ToyotaApprovedUse : DfUcProgram.LexusSelect;
};

/**
 * Check if a vehicle has a valid ucProgram based on the brand
 */
export const vehicleHasValidProgram = (vehicle: UscVehicleType | null, brand: string): boolean => {
    return vehicle?.ucProgram?.code === getValidUcProgramCode(brand);
};

/**
 * Returns the USC extraQueryBuilderOptions
 */
export const getUscExtraQueryBuilderOptions = (commonSettings: CommonSettingsType): QueryBuilderStateType => {
    return {
        uscEnv: {
            label: "USC Environment",
            queryValues: ["uat", "production"],
            isDropdown: true,
            currentValue: commonSettings.query.uscEnv || "",
        },
    };
};

/**
 * Retrieve the text in the [disclaimer] tags in the flexibility matrix.
 * Note this is not the actual disclaimer but the label for the link which triggers the disclaimer.
 * Mostly relevant for components using the disclaimerUtils setup.
 */
export const getDisclaimerLinkLabel = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    monthlyPrice: number,
    financeInfo: FinanceInfoType,
    context: "new" | "used" | "stock" = "new",
): string => {
    const monthlyPriceFormat = getAemMonthlyRateFormat(commonSettings, aemComponent, context);
    const { monthlyFinanceLabels } = financeInfo
        ? formatMonthlyFinancePrice(monthlyPrice, commonSettings, financeInfo, monthlyPriceFormat)
        : { monthlyFinanceLabels: [] };

    const disclaimerLabel = monthlyFinanceLabels.find((label) => label.key === MonthlyPriceLabelKey.Disclaimer);

    return disclaimerLabel?.value || "";
};

export const getUsedCarCompareEquipmentCategories = (
    car: LoadedUsedCompareCarType,
): UsedCarCompareEquipmentCategoryType[] => {
    return car.equipment.reduce((acc: UsedCarCompareEquipmentCategoryType[], item) => {
        // Not all equipment have categories, the default category in the response is the one containing all those who don't have a category
        const defaultCategory = DfEquipmentCategoryCode.Others;
        const categoryCode = item.equipmentType?.code || defaultCategory;
        const categoryName = item.equipmentType?.description || "";
        const category =
            acc.find((cat) => cat.code === categoryCode) ||
            // Create new object, push it and retrieve it immediately using the new length
            acc[
                acc.push({
                    code: categoryCode,
                    name: categoryName,
                    items: [],
                }) - 1
            ];
        category.items.push({
            code: item.genericEquipment?.code || item.equipmentKey?.code || "",
            name: item.displayEquipment?.description || "",
        });
        return acc;
    }, []);
};

export const getTranslation = (
    translations: UsedCarsTranslationResultType[],
    country: string,
    language: string,
): string => {
    // Try to find language translation first.
    const languageTranslation = translations.find(
        (translation) => translation.language.toLowerCase() === `${language}-${country}`,
    )?.description;
    if (languageTranslation) return languageTranslation;

    // Language translation not found, try to find the default translation.
    const defaultTranslation = translations.find(
        (translation) => translation.language.toLowerCase() === "default",
    )?.description;
    if (defaultTranslation) return defaultTranslation;

    // Default not found, return empty string.
    return "";
};

export const getExternalVehicleAttrValue = (
    vehicleResult: UscVehicleType,
    attribute: DfExternalVehicleAttrs,
): string => {
    return vehicleResult.externalVehicleAttributes.find((attr) => attr.code === attribute)?.value || "";
};

/**
 * Returns the Sale Type of a vehicle.
 * There are three types:
 * - Approved: The vehicle has a valid program
 * - Non-Approved: The vehicle doesn't have a valid program
 * - Private: The vehicle is owned by a private person to be sold
 *
 * NOTE: This feature was implemented using the isPledgedSale, but this field
 * is going to be replaced with the new reference field "Ownership". To not break
 * the feature, we're keeping isPledgedSale as a fallback while using the new field.
 */
export const getVehicleSaleType = (
    vehicle: UscVehicleType,
    brand: string,
    enablePrivatelySold: boolean = false,
    commonSettings: CommonSettingsType,
): {
    vehicleSaleType: UscVehicleSaleType;
    label: string;
} => {
    const { isPledgedSale: pledgedSaleVehicleValue, ownership } = vehicle;

    const isPledgedSale = ownership?.code === DfOwnershipCode.PrivatelyOwned || pledgedSaleVehicleValue;

    // If the vehicle is pledged, it's a private sale
    if (isPledgedSale && enablePrivatelySold) {
        return {
            vehicleSaleType: UscVehicleSaleType.Private,
            label: ownership?.description || getCommonLabel({ commonSettings }, "uscPrivatelySold"),
        };
    }

    // Otherwise, check if the vehicle has a valid program to determine if it's Toyota Approved or Non-Approved
    return vehicleHasValidProgram(vehicle, brand)
        ? { vehicleSaleType: UscVehicleSaleType.Approved, label: getCommonLabel({ commonSettings }, "uscApproved") }
        : {
              vehicleSaleType: UscVehicleSaleType.NonApproved,
              label: getCommonLabel({ commonSettings }, "uscNonApproved"),
          };
};

/**
 * Check if a vehicle has a warranty.
 *
 * Make sure to match this logic in car-filter as well, see usedCarResultFormatter.
 */
export const vehicleHasWarranty = (vehicle: UscVehicleType | null): boolean => {
    return !!vehicle?.warranty && vehicle?.warranty.code !== DF_CODE_NOT_AVAILABLE;
};

/**
 * Helper function to help with the parsing of old/new customer promise banner formats.
 * To ensure that we don't break anything when we update dxp-usc in UC-877, we use the new
 * format if it's available, otherwise we parse the old items into the new format.
 */
export const formatCustomerPromiseBannerBackCompat = (
    titleLabel?: string,
    possibleNewFormat?: UscCustomerPromiseBannerType,
    possibleFallbackItems?: AEMBannerDescriptionItemType[],
): UscCustomerPromiseBannerType => {
    return (
        possibleNewFormat || {
            title: titleLabel || "",
            bannerItems: possibleFallbackItems || [],
        }
    );
};

/**
 * Formats a date using the Intl.DateTimeFormat API.
 */
export const formatDateIntl = (date: string, locale: string, options?: Intl.DateTimeFormatOptions): string => {
    const intlLocale = getIntlLocale(locale);

    return new Intl.DateTimeFormat(intlLocale, options).format(new Date(date));
};

export const getUscVehicleUrlInfoFromVehicle = (vehicle: UscVehicleType): UscVehicleUrlInfo => ({
    model: vehicle.product.model,
    transmissionType: vehicle.product.transmission.transmissionType?.description || "",
    bodyType: vehicle.product.bodyType,
    manufacturer: vehicle.product.brand.description,
    vehicleForSaleId: vehicle.id,
    marketingFuelType: vehicle.product.engine.marketingFuelType.description,
    firstRegistrationDate: vehicle.history?.registrationDate || "",
    productionDate: vehicle.productionDate || "",
});

/**
 * Data sent to the local dealer form.
 * See https://toyota-europe.atlassian.net/wiki/spaces/UCP/pages/43746063/Local+Contact+Dealer+form, make sure that page is up to date whenever something changes.
 */
export const getLocalDealerFormData = (
    vehicle: UscVehicleType,
    vehicleUrl: string,
    dealer: DealerResultType | null,
    insuranceQuoteId?: string | null,
    financeQuoteId?: string | null,
    tyCode?: string,
): LocalDealerFormDataType => {
    return {
        vehicleForSaleId: vehicle.id,
        brand: vehicle.product.brand.description,
        model: vehicle.product.model,
        image: vehicle.images[0]?.url || "",
        grade: getGradeName(vehicle),
        engine: getEngineName(vehicle),
        cashPrice: vehicle.price?.sellingPriceInclVAT || 0,
        fuelType: vehicle.product.engine.fuelType.description,
        mileage: vehicle.mileage?.value || 0,
        firstRegistrationDate: vehicle.history?.registrationDate || "",
        vin: vehicle.vin,
        dealerName: dealer?.name || "",
        dealerAddress: dealer ? `${dealer.address.line1} ${dealer.address.zip} ${dealer.address.city}` : "",
        url: vehicleUrl,
        t1DataLayerModel: "",
        tyCode: tyCode || "",
        insuranceQuoteId: insuranceQuoteId || "",
        financeQuoteId: financeQuoteId || "",
    };
};

/**
 * Get the grade name to be used in the frontend.
 */
export const getGradeName = (vehicle: UscVehicleType): string => {
    // It works like this because the grade.description field is retrieved from an external source.
    // In some cases it doesn't contain a description so the gradeDescription field can be used as fallback.
    return vehicle.product.grade?.gradeDescription || "";
};

/**
 * Get the engine name to be used in the frontend.
 */
export const getEngineName = (vehicle: UscVehicleType): string => {
    // It works like this because the "engineKey" field is retrieved from an external source.
    // In some cases it doesn't contain a description so the engine.description field can be used as fallback.
    return vehicle.product.engine.engineKey?.description || vehicle.product.engine.description;
};

/**
 * Returns the vehicle title which is the combination of the brand and model
 * This was moved into a util to make sure this title is the same on multiple locations
 * Some locations: Basket, Basket Details Modal, Breadcrumbs, Car Filter tiles
 */
export const getVehicleTitle = (product: UscVehicleType["product"]): string => {
    const { brand, model } = product;

    return shouldBrandBeHidden(model, product.brand.description) ? model : `${brand.description} ${model}`;
};

/**
 * Checks if the model name contains the brand,
 * in which case we should hide the brand in the vehicle title.
 */
export const shouldBrandBeHidden = (modelName: string, brand: string): boolean =>
    modelName.toLowerCase().includes(brand.toLowerCase());

/**
 * Constant indicating the "threshold" for the vehicle aggregations feature.
 * If the vehicleCount is higher than the threshold, we show "more than 5 vehicles remaining"
 * Otherwise, "less than 5 vehicles remaining" is shown.
 * In the future this should be replaced by a configurable setting if more NMSCs start making
 * use of this feature.
 */
export const VEHICLE_AGGREGATION_THRESHOLD = 5;
