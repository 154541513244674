import styled, { css } from "styled-components";
import * as CheckboxStyle from "../../../../../../common-deprecated/styles/v2/toyota/components/Input";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getDirection } from "../../../../../../common-deprecated/themes/common";

// eslint-disable-next-line prefer-destructuring
export const Wrapper = styled(CheckboxStyle.Wrapper)`
    min-height: 34px;
`;

export const Checkbox = styled(CheckboxStyle.Checkbox)<{ disabled?: boolean }>`
    color: ${theme.colors.primaryBlack};
    ${(props) =>
        props.disabled &&
        css`
            color: ${theme.colors.grey7};
            cursor: default;
        `}
`;

export const InfoText = styled.span`
    display: inline-block;
    margin-${getDirection("left")}: ${theme.space[1]}px;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.grey7};
`;

export const Text = styled.span<{ srOnly?: boolean }>`
    ${({ srOnly }) =>
        srOnly &&
        css`
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            white-space: nowrap;
            border: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            clip-path: inset(50%);
        `};
`;
