import React from "react";
import { createGlobalStyle } from "styled-components";

type Props = {
    enabled: boolean;
    selectors: string[];
};

const BodyStyle = createGlobalStyle`
      body {
          overflow: hidden !important;
      }
`;

const SelectorStyle = createGlobalStyle<{ selector: string }>`
      ${({ selector }) => selector} {
          z-index: auto !important;
      }
`;

/**
 * Use this component to unset the z-index on an external element that contains the OR component.
 * This should be used when the OR component needs to render something fullscreen, as without this the main content div has a z-index of 1, causing overlap with the navigation.
 * See OR-1942 (for the maincontent wrapper)
 *
 * (This used to be the useUnsetZIndex hook)
 */
const UnsetZIndexHelper = ({ enabled, selectors }: Props): JSX.Element | null => {
    if (!enabled) return null;

    return (
        <>
            <BodyStyle />
            {selectors.map((selector) => {
                return <SelectorStyle key={selector} selector={selector} />;
            })}
        </>
    );
};

export default UnsetZIndexHelper;
