import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { theme } from "../theme/toyotaTheme";
import { legalStyle } from "../globals/Type";

export const Text = styled.div<SpaceProps>`
    margin: ${theme.space[1]}px 0 ${theme.space[2]}px;
    color: ${theme.colors.grey4};
    ${legalStyle};
    ${space};
`;
