import styled from "styled-components";
import { getDirection } from "../../../../themes/common";

export const HiddenModelId = styled.span`
    float: ${getDirection("right")};
    color: ${({ theme }) => theme.color.lightest};
`;
export const UnorderedList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

export const ListItem = styled.li`
    padding-bottom: 22px;
`;

export const Description = styled.div`
    margin-bottom: 10px;
    padding: 10px;
    font-weight: bold;
    color: #d32f2f;
    background-color: #ffcdd2;
    border: 1px solid #f44336;
`;
