import {
    POSTAL_CHANNEL_T1_DIALOG,
    POSTAL_CHANNEL_T1_HASH,
    POSTAL_TOPIC_HASH_ADD,
    POSTAL_TOPIC_T1_MATERIALBOX_CLOSE,
    POSTAL_TOPIC_T1_MATERIALBOX_OPEN,
    PostalChannelType,
    PostalTopicType,
} from "../constants";
import Debug from "../Debug";

export const getPostal = (): Postal => {
    const debugPostal = false; // enabling this will allow you to debug all postal events

    let postal;
    try {
        // eslint-disable-next-line global-require, import/no-unresolved
        postal = require("postal");
        if (debugPostal) {
            postal.addWireTap((data: any, message: any) => {
                if (message.channel !== "postal") console.log(message);
            });
        }
    } catch (exception) {
        console.error("Could not load Postal", exception);
    }

    return postal;
};

export const postalPublish = (
    payload: IEnvelope<any> & { channel: PostalChannelType; topic: PostalTopicType },
): void => {
    /**
     * In a try catch statement considering some DOM objects are not present in the context of our stand alone application
     * See screenshot in PR #4171 for more info
     */
    try {
        if (!window) throw new Error("Window scope not available. Postal is only available client side.");
        getPostal().publish(payload);
    } catch (ex) {
        Debug.error((ex as Error).message);
    }
};
/**
 * Save a Toyota code to MyToyota account
 */

export const openSaveToMyToyotaModal = (tyCode?: string | null): void => {
    const configCode = tyCode ? `/configCode=${tyCode}` : "";

    postalPublish({
        channel: POSTAL_CHANNEL_T1_HASH,
        topic: POSTAL_TOPIC_HASH_ADD,
        data: `/publish/CARCONFIG_SAVE_CONFIG${configCode}`,
    });
};
type MaterialBoxOptionsType = {
    type?: string;
    url?: string;
    html?: string;
    globalClose?: boolean;
    onClose?: () => void;
    height?: number;
    width?: number;
    styleClass?: string;
};
/**
 * Documentation about the GFI material box can be found here:
 * http://t1-production-client.herokuapp.com/html/features_doc/components/materialbox.html
 */
export const openMaterialBox = (data: MaterialBoxOptionsType): void => {
    postalPublish({
        channel: POSTAL_CHANNEL_T1_DIALOG,
        topic: POSTAL_TOPIC_T1_MATERIALBOX_OPEN,
        data,
    });
};

export const closeMaterialBox = (): void => {
    postalPublish({
        channel: POSTAL_CHANNEL_T1_DIALOG,
        topic: POSTAL_TOPIC_T1_MATERIALBOX_CLOSE,
    });
};
