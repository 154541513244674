import styled, { css } from "styled-components";
import { theme } from "../theme/toyotaTheme";

export const ToggleItem = styled.button<{ isActive?: boolean }>`
    position: relative;
    width: 143px;
    min-height: 54px;
    max-height: 64px;
    margin: 0;
    padding: 12px;
    font-family: ${theme.fonts.semiBold};
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: ${theme.colors.primaryBlack};
    background-color: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    overflow: hidden;
    pointer-events: auto;
    z-index: 10;
`;

export const Wrapper = styled.div<{ isMonthlyActive?: boolean; isActive?: boolean; fullWidth?: boolean }>`
    display: flex;
    position: relative;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    max-width: ${({ fullWidth }) => (fullWidth ? "none" : "295px")};

    padding: 5px;
    background-color: ${theme.colors.grey2};

    &::before {
        display: block;
        position: absolute;
        top: 5px;
        left: ${({ isMonthlyActive, isActive, fullWidth }) =>
            isMonthlyActive || isActive ? (fullWidth ? "calc(50% - 5px)" : "calc(100% - 143px - 5px)") : "5px"};
        width: ${({ fullWidth }) => (fullWidth ? "50%" : "143px")};
        height: calc(100% - 10px);
        min-height: 54px;
        max-height: 64px;
        content: "";
        background-color: ${theme.colors.primaryWhite};
        transition: left 0.3s ease-out;
        z-index: 5;
    }

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            ${ToggleItem} {
                width: 50%;
            }
        `}
`;

export const LineClamp = styled.span`
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* stylelint-enable */
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;

    /* Updated font-size so it will be a bit more readable on COD */
    @media (min-width: 992px) and (max-width: 1200px) {
        font-size: 1.1rem;
    }
`;
