import loadable from "@loadable/component";

export const IntegratedDebugModal = loadable(async () => {
    const component = await import(
        /* webpackChunkName: "integrated-debug-modal" */
        /* webpackPreFetch: true */
        "./DebugModal"
    );
    return component.default;
});
