import styled from "styled-components";

export const Wrapper = styled.span`
    display: inline-block;
    position: relative;
    top: -8px;
    height: 24px;
    white-space: normal;
`;

export const WrapperPopup = styled.div``;
