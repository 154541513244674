import { RETAILER_SETTINGS_INIT, RetailerSettingsActionsType } from "../actions/RetailerSettingsActions";
import { RetailerLabelType, retailerLabels } from "../../utils/labels";

export type RetailerModelDataType = {
    modelCode: string;
    buildPagePath: string;
    apheleiaLiveStatusWebsite: boolean;
    apheleiaLiveStatusEnabledModel: boolean;
    useLbxJourney: boolean;
};

export type RetailerSettingsType = {
    initialized: boolean;
    labels: Record<RetailerLabelType, string>;
    modelData: RetailerModelDataType[];
};

export const initialStateRetailerSettings: RetailerSettingsType = {
    initialized: false,
    labels: { ...retailerLabels },
    modelData: [],
};

/**
 * This reducer should only be initialized when loading the retailer variant of a component (?retailerscreen=true)
 */
const RetailerSettingsReducer = (
    state: RetailerSettingsType = initialStateRetailerSettings,
    action: RetailerSettingsActionsType,
): RetailerSettingsType => {
    switch (action.type) {
        case RETAILER_SETTINGS_INIT: {
            return { ...state, ...action.initState, initialized: true };
        }

        default:
            return state;
    }
};

export default RetailerSettingsReducer;
