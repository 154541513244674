import styled from "styled-components";
import { getDirection } from "../../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.button`
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 100%;
    padding-block: 6px;
    padding-inline: ${theme.space[3] / 2}px;
    background: ${theme.colors.primaryWhite};
    border: 1px solid ${theme.colors.grey3};
    border-radius: 4px;

    &:disabled {
        background: ${theme.colors.grey1};
    }

    @supports not (padding-inline: 0) {
        padding: 6px ${theme.space[3] / 2}px;
    }
`;

export const Label = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space[1]}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.primaryBlack};

    @supports not (padding-inline: 0) {
        svg {
            margin-${getDirection("right")}: ${theme.space[1]}px;
        }
    }
`;

export const InfoIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-start: auto;
    color: ${theme.colors.grey7};

    @supports not (margin-inline: 0) {
        margin-${getDirection("left")}: auto;
    }
`;
