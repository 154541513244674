import React from "react";
import { Indicator } from "./Radio.styles";

type RadioPropsType = {
    checked?: boolean;
    position?: "left" | "right";
    id: string;
    label?: string;
    name?: string;
    value?: string;
    disabled?: boolean;
    onChange?: () => void;
};

const Radio: React.FC<RadioPropsType> = (props) => {
    const { checked, position = "right", id, label, name, value, disabled, onChange } = props;
    return (
        <Indicator position={position}>
            <input
                type="radio"
                id={id}
                name={name}
                disabled={disabled}
                checked={checked}
                value={value}
                onChange={onChange}
            />
            <label htmlFor={id}>{label}</label>
        </Indicator>
    );
};

export default Radio;
