import styled, { css } from "styled-components";
import {
    display,
    DisplayProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    width,
    WidthProps,
    flex,
    FlexboxProps,
    compose,
} from "styled-system";
import { theme, ColorType } from "../theme/toyotaTheme";
import { Button, ButtonWrapper } from "../globals/Button";
import Control from "../globals/Control";
import * as Text from "../utilities/Text";
import { IconWrapper, ToggleIconWrapper } from "../globals/IconWrapper";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import { focusStyle } from "../utilities/Focus";
import { Ellipsis } from "../utilities/Ellipsis";

export type ButtonType = {
    large?: boolean;
    light?: boolean;
    dark?: boolean;
    center?: boolean;
    selected?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    stretchedLink?: boolean;
} & DisplayProps &
    LayoutProps &
    SpaceProps &
    FlexboxProps &
    WidthProps;

export const styleProps = compose(display, layout, flex, space, width);

export const EllipsisText = styled.span.attrs<{ color?: ColorType } & DisplayProps>(() => ({
    className: "btn-text",
}))<{ color?: ColorType } & DisplayProps>`
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${display}
    ${({ color }) =>
        color &&
        css`
            color: ${theme.colors[color]};
        `};
`;

export const Clamp = styled.span<{ textColor?: ColorType }>`
    display: block;
    min-width: 75px;
    max-height: 34px;
    line-height: 1;
    white-space: normal;
    ${({ textColor }) =>
        textColor &&
        css`
            color: ${theme.colors[textColor]};
        `};
    @media ${getBreakpoint("up", "lg")} {
        display: block;
        width: 100%;
        min-width: auto;
        overflow: hidden;
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
    }
`;

/**
 * You can also use this component for a link.
 * Then you need to use the `as`-attribute.
 * @Example
 * <Button.Primary as="a"></Button.Primary>
 */

export const Primary = styled(Button).attrs<ButtonType>((props) => ({
    className: [
        "btn-primary",
        props.disabled ? "btn-disabled" : "",
        props.light ? "white" : "",
        props.center ? "d-flex align-items-center" : "",
    ].join(" "),
}))<ButtonType>`
    ${styleProps};
    ${focusStyle};
`;

export const PrimaryWhite = styled(Primary).attrs<ButtonType>((props) => ({
    className: "white",
}))<ButtonType>``;

export const Secondary = styled(Button).attrs<ButtonType>((props) => ({
    className: [
        "btn-secondary",
        props.disabled ? "btn-disabled" : "",
        props.light ? " white" : "",
        props.dark ? " text-dark" : "",
    ].join(" "),
}))<ButtonType>`
    ${styleProps};
`;

export const ButtonIcon = styled(Button).attrs<ButtonType>((props) => ({
    className: `btn-icon-monochrome${props.light ? " white" : ""}`,
}))<ButtonType>`
    ${styleProps};
`;

/**
 * Specific button variations
 */
export const ViewMore = styled(Button).attrs((props) => ({
    className: "view-more",
}))``;

/**
 * Button Icon (icon only)
 * @Example
 * <Button.Icon large><IconRemove /></Button.Icon>
 */
export const Icon = styled(Control).attrs<ButtonType>((props) => ({
    className: ["control-reset", props.large ? "large" : ""].join(" "),
}))<ButtonType>`
    ${styleProps};
`;

/**
 * Custom button styles I couldn't find in the CSS file
 */

export const Outline = styled(Secondary)<{ withHorizontalSpacing?: boolean }>`
    ${styleProps};

    ${(props) =>
        props.width &&
        `
        ${Text.Button} {
            width: 100%;
        }
    `}

    ${({ withHorizontalSpacing }) =>
        withHorizontalSpacing &&
        css`
            padding-left: ${theme.space[3]}px !important;
            padding-right: ${theme.space[3]}px !important;
        `};
`;

export const OutlineGrey = styled(Secondary)`
    margin: 0;
    border: 1px solid ${theme.colors.grey3};
    border-bottom: 1px solid ${theme.colors.grey3} !important; /* Is necessary to override parent styles */

    &:hover {
        /* Is necessary to override parent styles */
        border-color: ${theme.colors.grey3} !important;
        opacity: 1 !important;
    }

    ${IconWrapper} {
        display: flex;
    }
`;

export const PrimaryGrey = styled(Primary).attrs<ButtonType>(() => ({
    className: "white",
}))<ButtonType>`
    display: flex;
    align-items: center;
    column-gap: ${theme.space[1]}px;
    padding: ${theme.space[3] / 2}px;
    background-color: ${theme.colors.grey1} !important;
    ${styleProps};

    ${Ellipsis} {
        color: ${theme.colors.primaryBlack} !important;
    }

    ${Text.Button} {
        padding: 0;
        color: ${theme.colors.primaryBlack} !important;
        background-color: ${theme.colors.grey1} !important;
        border-width: 0 !important;

        &:hover,
        &:focus {
            color: ${theme.colors.primaryBlack} !important;
            background-color: transparent !important;
        }
    }

    .dxp-icon {
        color: ${theme.colors.primaryBlack};
    }

    &:hover,
    &:focus {
        background-color: ${theme.colors.grey2} !important;
    }
`;

export const GreyRounded = styled(Button).attrs<ButtonType>((props) => ({}))<{ hasOnlyIcon?: boolean } & ButtonType>`
    display: flex;
    height: 32px;
    margin: 0;
    padding: ${theme.space[1] / 2}px ${theme.space[2]}px;
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.primaryBlack};
    background-color: ${theme.colors.grey1};
    border-radius: 20px;
    &:hover {
        background-color: ${theme.colors.grey2};
    }
    ${({ hasOnlyIcon }) =>
        hasOnlyIcon &&
        css`
            justify-content: center;
            width: 32px;
            padding: ${theme.space[1] / 2}px 0;
            text-align: center;
        `};
    ${EllipsisText} {
        --borderColor: transparent;
        --hoverBorderColor: transparent;
        --activeBorderColor: transparent;
    }
    ${styleProps};
`;

export const Cta = styled(Button).attrs<ButtonType>((props) => ({
    className: ["btn-primary white", props.disabled ? "btn-disabled" : ""].join(" "),
}))<{ hasIconRight?: boolean; transparent?: boolean } & ButtonType>`
    display: flex;
    padding-inline-start: 0 !important;
    ${styleProps};

    ${Text.Button} {
        --color: ${theme.colors.primaryBlack};
        --backgroundColor: transparent;
        padding: 0;
        border-width: 0;

        &:hover,
        &:focus {
            --hoverColor: ${theme.colors.primaryBlack};
            --hoverBackgroundColor: transparent;
        }
    }

    ${IconWrapper} {
        align-self: flex-start;
        ${({ hasIconRight }) =>
            hasIconRight
                ? css`
                      margin-inline-start: ${theme.space[2]}px;
                  `
                : css`
                      margin-inline-end: ${theme.space[2]}px;
                  `};
        .icon {
            width: 33px;
            height: 33px;
            line-height: 3.3rem;
            background-color: ${({ transparent }) => (transparent ? "transparent" : theme.colors.grey1)};
            border-radius: 50%;
        }
    }
    &:active {
        background-color: transparent !important;
    }
`;

export const CtaBasket = styled(Button).attrs<ButtonType>((props) => ({
    className: ["bg-white text-dark", props.disabled ? "btn-disabled" : ""].join(" "),
}))<ButtonType>`
    display: block;
    width: 100%;
    height: auto;
    margin: ${theme.space[3]}px 0;
    padding: 0 !important;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: ${getDirection("left")};
    ${display}
    ${IconWrapper} {
        align-self: flex-start;
        margin-inline-start: ${theme.space[1]}px;
    }
    &:hover {
        border-color: ${theme.colors.grey2};
    }
`;

/**
 * Checkbox is a button variant that looks like a checkbox.
 * It replaces the old colourwheel (rainbow) button.
 */
export const Checkbox = styled.button<ButtonType>`
    display: flex;
    align-items: center;
    background-color: ${theme.colors.primaryWhite};

    ${IconWrapper} {
        display: flex;
        align-items: center;
        align-self: flex-end;
        justify-content: center;
        width: 32px;
        min-width: 32px;
        height: 32px;
        color: ${theme.colors.primaryBlack};
        background-color: ${theme.colors.primaryWhite};
        border: 2px solid ${theme.colors.primaryBlack};
        border-radius: 4px;
        transition: background-color 0.3s;
        &::before {
            position: relative;
            top: 3px;
        }
        ${({ selected }) =>
            selected &&
            css`
                color: ${theme.colors.primaryWhite};
                background-color: ${theme.colors.primaryBlack};
                border-color: ${theme.colors.primaryBlack};
            `};
        ${({ disabled, selected }) =>
            disabled &&
            selected &&
            css`
                color: ${theme.colors.grey3};
                background-color: ${theme.colors.primaryWhite};
                border-color: ${theme.colors.grey3};
                cursor: default;
            `};
        ${({ disabled, selected }) =>
            disabled &&
            !selected &&
            css`
                opacity: 0.2;
                cursor: default;
            `}
    }

    ${Text.Button} {
        margin-${getDirection("right")}: ${theme.space[2]}px;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${theme.colors.grey4};
        ${(props) =>
            props.disabled &&
            css`
                color: ${theme.colors.grey7};
            `};
    }

    /* Custom style for bz4x color theme */
    .bz & {
        ${IconWrapper} {
            color: ${theme.colors.bz4x};
            border-color: ${theme.colors.bz4x};
        }

        &[aria-pressed="true"] {
            ${IconWrapper} {
                color: ${theme.colors.primaryWhite};
                background-color: ${theme.colors.bz4x};
            }
        }
    }

    ${styleProps};
    ${focusStyle};
`;

/* BUTTON ICON STYLES */

const linkHasIconRightStyles = css`
    display: flex;
    align-items: center;
    ${IconWrapper}, ${ToggleIconWrapper} {
        margin-${getDirection("left")}: ${theme.space[1]}px;
    }

    .dxp-icon::before {
        position: relative;
        top: 2px;
    }
`;

const linkHasIconStyles = css<LinkType>`
    display: inline-flex;
    align-items: center;
    ${IconWrapper}, ${ToggleIconWrapper} {
        margin-${getDirection("right")}: ${theme.space[1]}px;
    }

    .dxp-icon::before {
        position: relative;
        top: 2px;
    }
`;

type LinkType = {
    hasIcon?: boolean;
    hasIconRight?: boolean;
    hasBorder?: boolean;
    color?: ColorType;
} & ButtonType;

/**
 * Button.Link defaults to small buttons. Add the `large` prop for having a larger button.
 */

export const Link = styled.button<LinkType>`
    display: inline-block;
    margin: 0;
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${(props) => (props.color ? theme.colors[props.color] : theme.colors.primaryBlack)};
    border: none;

    ${IconWrapper} {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ${(props) => props.hasIcon && linkHasIconStyles};
    ${(props) => props.hasIconRight && linkHasIconRightStyles};
    ${(props) =>
        props.large &&
        css`
            font-size: 1.6rem;
            line-height: 2.4rem;
        `};
    ${(props) =>
        props.hasBorder &&
        css`
            padding-bottom: ${theme.space[1] / 2}px;
            border-bottom: 1px solid ${theme.colors.primaryBlack};
        `};

    ${styleProps};
    ${focusStyle};
`;

/**
 * ButtonWithIcon is a button variant that will embed and icon and the Button.Link variant
 * @example
 * <Button.ButtonWithIcon hasSmallText>
 *   <IconWrapper>
 *     <IconTree />
 *   </IconWrapper>
 *   <Button.Link>
 *      Text
 *   </Button.Link>
 * </Button.ButtonWithIcon>
 */
export const ButtonWithIcon = styled.div<{ hasIconRight?: boolean; hasSmallText?: boolean } & SpaceProps>`
    display: flex;
    align-items: center;
    color: ${theme.colors.outline};
    ${space};
    ${Text.Button} {
        font-family: ${theme.fonts.semiBold};
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-transform: uppercase;
        color: ${theme.colors.outline};
        border-bottom: 1px solid ${theme.colors.outline};
        ${({ hasSmallText }) =>
            hasSmallText &&
            css`
                font-size: 1.3rem;
                line-height: 2rem;
            `};
    }
    ${({ hasIconRight }) =>
        hasIconRight &&
        css`
            display: inline-flex;
            flex-direction: row-reverse;
        `};
`;

/**
 * Styles Buttons/CTA's
 */

const hoverStyles = css`
    &:focus,
    &:hover {
        ${IconWrapper} {
            color: inherit;
        }
    }
`;

export const ButtonPrimary = styled(ButtonWrapper).attrs<ButtonType>((props) => ({
    className: "btn-primary",
}))<ButtonType>`
    /* Override default CSS */
    margin: 0;
    ${hoverStyles};

    ${(props) =>
        props.color &&
        css`
            .btn-text {
                --borderColor: ${theme.colors[props.color as ColorType]};
                --backgroundColor: ${theme.colors[props.color as ColorType]};
                --activeBackgroundColor: ${theme.colors[props.color as ColorType]};
                --activeBorderColor: ${theme.colors[props.color as ColorType]};
                --hoverBackgroundColor: ${theme.colors[props.color as ColorType]};
                --hoverBorderColor: ${theme.colors[props.color as ColorType]};

                &:hover,
                &:active,
                &:focus {
                    filter: brightness(90%);
                }
            }
        `};

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            & > .btn-text {
                width: 100%;
            }
        `};
`;

export const ButtonSecondary = styled(ButtonWrapper).attrs<ButtonType>((props) => ({
    className: "btn-secondary",
}))<ButtonType>`
    /* Override default CSS */
    margin: 0;

    ${hoverStyles};

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            & > .btn-text {
                width: 100%;
            }
        `};
`;

export const ButtonCta = styled(ButtonWrapper).attrs<ButtonType>((props) => ({
    className: "btn-secondary",
}))<{ hasUspIcon?: boolean } & ButtonType>`
    /* Override default CSS */
    display: flex;
    margin: 0;

    & > ${Text.Button} {
        --borderColor: ${theme.colors.grey3};
        display: flex;
        column-gap: ${theme.space[1]}px;
        align-items: center;
        width: 100%;
        padding: ${theme.space[3] / 2}px;
        font-family: ${theme.fonts.base};
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: start;
        text-overflow: clip;
        color: ${theme.colors.primaryBlack};
        white-space: normal;
        overflow: visible;

        ${({ hasUspIcon }) =>
            hasUspIcon &&
            css`
                padding: ${theme.space[1] / 2}px ${theme.space[2]}px ${theme.space[1] / 2}px ${theme.space[1]}px;
            `};

        &[disabled],
        &[aria-disabled="true"] {
            color: ${theme.colors.grey2};
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    .disabled {
        color: ${theme.colors.grey2};
        cursor: not-allowed;
        pointer-events: none;
    }

    ${IconWrapper} {
        margin-${getDirection("left")}: auto;
    }

    ${hoverStyles};
`;

export const ButtonTertiary = styled.button<
    { disableBorder?: boolean; hasIcon?: boolean; hasIconRight?: boolean; leftAlign?: boolean } & ButtonType
>`
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0;
    line-height: 4rem;
    text-align: center;
    text-overflow: ellipsis;
    color: var(--color);
    white-space: nowrap;
    background-color: var(--backgroundColor);
    border-color: var(--borderColor);
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    opacity: 1;
    overflow: hidden;

    ${({ disableBorder }) =>
        disableBorder &&
        css`
            border-style: none;
        `};

    &:is(:focus, :hover) {
        --hoverColor: ${theme.colors.grey7}; /* override base CSS */
        --hoverBorderColor: ${theme.colors.grey7}; /* override base CSS */
        --backgroundColor: var(--hoverBackgroundColor);
        --color: var(--hoverColor);
        --borderColor: var(--hoverBorderColor);
        --underlineColor: var(--hoverBorderColor);
        color: var(--hoverColor);
    }

    &[disabled],
    &[aria-disabled="true"] {
        color: ${theme.colors.grey2};
        cursor: not-allowed;
        pointer-events: none;
    }

    .bz & {
        --color: ${theme.colors.bz4x};
        &:is(:focus, :hover) {
            --color: ${theme.colors.bz4xHover};
            --hoverColor: ${theme.colors.bz4xHover};
            --borderColor: ${theme.colors.bz4xHover};
        }
    }

    ${({ stretchedLink }) =>
        stretchedLink &&
        css`
            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                pointer-events: auto;
                content: "";
                background-color: rgba(0, 0, 0, 0);
            }
        `}

    ${({ hasIcon, hasIconRight }) =>
        (hasIcon || hasIconRight) &&
        css`
            display: flex;
            flex-direction: ${hasIconRight ? "row-reverse" : "row"};
            align-items: center;
            column-gap: ${theme.space[1]}px;

            /* fix position icon */
            .dxp-icon::before {
                position: relative;
                top: 1px;
            }
        `};
`;

export const ButtonQuaternary = styled.button<
    { hasIcon?: boolean; hasIconRight?: boolean; small?: boolean } & ButtonType
>`
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0;
    line-height: 4.8rem;
    text-align: center;
    text-overflow: ellipsis;
    color: var(--color);
    white-space: nowrap;
    background-color: var(--backgroundColor);
    border: none;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    opacity: 1;
    overflow: hidden;

    &:is(:focus, :hover) {
        --hoverColor: ${theme.colors.grey7}; /* override base CSS */
        --backgroundColor: var(--hoverBackgroundColor);
        --color: var(--hoverColor);
        --underlineColor: var(--hoverBorderColor);
    }

    ${({ hasIcon, hasIconRight }) =>
        (hasIcon || hasIconRight) &&
        css`
            display: flex;
            flex-direction: ${hasIconRight ? "row-reverse" : "row"};
            align-items: center;
            column-gap: ${theme.space[1]}px;

            /* fix position icon */
            .dxp-icon::before {
                position: relative;
                top: 1px;
            }
        `};

    ${(props) =>
        props.small &&
        css`
            font-size: 1.3rem;
            line-height: 2rem;
        `};

    &[disabled],
    &[aria-disabled="true"] {
        color: ${theme.colors.grey2};
        cursor: not-allowed;
        pointer-events: none;
    }

    .bz & {
        --color: ${theme.colors.bz4x};
        &:is(:focus, :hover) {
            --color: ${theme.colors.bz4xHover};
            --borderColor: ${theme.colors.bz4xHover};
        }
    }
`;

export const ButtonFullwidth = styled.div.attrs(() => ({
    className: "btn-full-width",
}))``;
