import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { CarFilterSettingsType } from "../redux/reducers/CarFilterSettingsReducer";
import { DEFAULT_RESULT_COUNT } from "./constants/filterConstants";
import { UsedCarResultType, LocalDealerFormDataType, UscContext } from "../../shared-logic/types/UscCommonTypes";
import { CarFiltersReducerType } from "../redux/reducers/CarFiltersReducer";
import {
    COMPONENT_CAR_FILTER_RESULTS,
    COMPONENT_USED_STOCK_CARS,
    COMPONENT_USED_STOCK_CARS_V2,
} from "../../../shared-logic/server/components";
import { carFilterValuesToParams } from "./params";
import { UscVehicleUrlInfo } from "../../shared-logic/utils/uscUtils";
import { getLocationFilterId } from "./filters";
import { formatNumber } from "../../../common-deprecated/Globalize";
import { DealerResultType } from "../../../common-deprecated/utils/dealerConstants";
import { CarFilterLabelType } from "./constants/labels";
import { getRetailerAppsQueryString } from "../../../common-deprecated/utils";

export const getCarFilterUrl = (
    commonSettings: CommonSettingsType,
    carFilterSettings: CarFilterSettingsType,
    carFilters: CarFiltersReducerType,
): string => {
    const { resourcePath, country, language, isStandalone } = commonSettings;
    const { currentFilter } = carFilters;
    const { urlStockCarsLandingPage, urlUsedCarsLandingPage } = carFilterSettings;

    const filterParams = carFilterValuesToParams(carFilters, currentFilter);
    const retailerQueryString = getRetailerAppsQueryString(commonSettings);

    const queryParamString = Object.entries(filterParams)
        .map(([key, value]) => `${key}=${value}`)
        .concat(isStandalone ? [] : ["scrollToCarFilter=true"])
        .concat(retailerQueryString ? [retailerQueryString] : [])
        .join("&");

    const retailerParam = retailerQueryString ? `&${retailerQueryString}` : "";

    const baseStandaloneUrl = `${resourcePath}/${country}/${language}/car-filter?carFilter=${currentFilter}`;
    const baseIntegratedUrl = currentFilter === UscContext.Used ? urlUsedCarsLandingPage : urlStockCarsLandingPage;

    return isStandalone
        ? `${baseStandaloneUrl}${queryParamString ? `&${queryParamString}${retailerParam}` : ""}`
        : `${baseIntegratedUrl}${queryParamString ? `?${queryParamString}` : ""}`;
};

/**
 * Simplified version of getCarFilterUrl, specifically for URLs we use in the
 * PaginationControls component (a hrefs on the page number buttons).
 * To support canonicalization on integrated environments, page should be the only parameter in the URL.
 */
export const getPaginationUrl = (
    commonSettings: CommonSettingsType,
    carFilterSettings: CarFilterSettingsType,
    carFilters: CarFiltersReducerType,
    page: number,
): string => {
    const { resourcePath, country, language, isStandalone } = commonSettings;
    const { currentFilter } = carFilters;
    const { urlStockCarsLandingPage, urlUsedCarsLandingPage } = carFilterSettings;

    const queryParamString = page > 1 ? `page=${page}` : "";
    const standaloneUrl = `${resourcePath}/${country}/${language}/car-filter?carFilter=${carFilters.currentFilter}${
        queryParamString ? `&${queryParamString}` : ""
    }`;
    const integratedUrl = `${
        currentFilter === UscContext.Used ? urlUsedCarsLandingPage : urlStockCarsLandingPage
    }?${queryParamString}`;

    return isStandalone ? standaloneUrl : integratedUrl;
};

export const getContentBlockConfig = (
    carFilterSettings: CarFilterSettingsType,
    context: UscContext,
): null | CarFilterSettingsType["contentBlockConfigurations"][number] => {
    return carFilterSettings.contentBlockConfigurations.find((config) => config.context === context) || null;
};

/**
 * Used for limiting the amount of results the car filter results component displays
 */
export const getTotalResultCount = (commonSettings: CommonSettingsType, carFilters: CarFiltersReducerType): number => {
    const { pagination } = carFilters;

    if (
        commonSettings.component === COMPONENT_CAR_FILTER_RESULTS ||
        commonSettings.component === COMPONENT_USED_STOCK_CARS
    ) {
        return carFilters.similarCar.isActive ? 5 : 6;
    } else if (commonSettings.component === COMPONENT_USED_STOCK_CARS_V2) {
        return 8;
    }

    if (pagination.page === 1) return DEFAULT_RESULT_COUNT - 1; // -1 to account for ValueProposition block

    return DEFAULT_RESULT_COUNT;
};

export const getUscVehicleUrlInfoFromResult = (vehicle: UsedCarResultType): UscVehicleUrlInfo => ({
    model: vehicle.product.model,
    transmissionType: vehicle.product.transmission.transmissionType?.description || "",
    bodyType: vehicle.product.bodyType,
    manufacturer: vehicle.product.brand.description,
    vehicleForSaleId: vehicle.id,
    marketingFuelType: vehicle.product.engine.marketingFuelType.description,
    firstRegistrationDate: vehicle.history?.registrationDate || "",
    productionDate: vehicle.productionDate,
});

/**
 * Get the altTag for an image of a UsedCarResult
 */
export const getUsedCarResultImageAltTag = (result: UsedCarResultType): string => {
    const { dealer, product, history } = result;
    const { brand, model, engine, grade, doors, carType } = product;
    const year = history?.registrationDate ? new Date(history.registrationDate).getFullYear() : "";

    return `${year} ${brand.description} ${model as string} ${(grade?.gradeDescription as string) || ""} - ${
        dealer?.name || ""
    } - ${carType.description as string} ${doors} ${(engine.description as string) || ""}`;
};

/**
 *  Returns a formatted dealer distance if available.
 *  Even though the car result object also has a dealer object we need to retrieve the dealer distance from the dealerCache.
 *  DealerCache is updated through the location filter and contains a list of dealers with actual ranges compared to the static dealer object of a car result.
 */
export const getDealerDistance = (
    dealer: DealerResultType | null,
    carFilters: CarFiltersReducerType,
    commonSettings: CommonSettingsType,
): string => {
    if (!dealer) return "";

    const locationFilterId = getLocationFilterId(carFilters.currentFilter);

    const foundDealer = carFilters[locationFilterId].dealerCache.find((dealerItem) => dealerItem.id === dealer.id);
    return foundDealer ? formatNumber(foundDealer?.distance, commonSettings.culture.name, "n1") : "";
};

/**
 * returns the correct pageDisclaimerLabel based on the carFilterContext
 */
export const getPageDisclaimerLabel = (currentFilter: UscContext): CarFilterLabelType => {
    switch (currentFilter) {
        case UscContext.Used:
            return "carFilterPageDisclaimerUsed";
        case UscContext.Stock:
            return "carFilterPageDisclaimerStock";
    }
};

/**
 * Data sent to the local dealer form when opened on the Car Filter.
 * This should stay aligned with https://toyota-europe.atlassian.net/wiki/spaces/UCP/pages/43746063/Local+Contact+Dealer+form
 * and the USC equivalent.
 */
export const getLocalDealerFormData = (vehicle: UsedCarResultType, vehicleUrl: string): LocalDealerFormDataType => {
    return {
        vehicleForSaleId: vehicle.id,
        brand: vehicle.product.brand.description,
        model: vehicle.product.model,
        image: vehicle.images[0]?.url || "",
        grade: vehicle.product.grade?.gradeDescription || "",
        engine: vehicle.product.engine.description || "",
        cashPrice: vehicle.price.sellingPriceInclVAT,
        fuelType: vehicle.product.engine.fuelType.description,
        mileage: vehicle.mileage?.value || 0,
        firstRegistrationDate: vehicle.history?.registrationDate || "",
        vin: vehicle.vin,
        dealerName: vehicle.dealer?.name || "",
        dealerAddress: vehicle.dealer
            ? `${vehicle.dealer.address.line1} ${vehicle.dealer.address.zip} ${vehicle.dealer.address.city}`
            : "",
        url: vehicleUrl,
        t1DataLayerModel: "",
        // Not relevant for the Car Filter as we'll never have a tyCode
        // but as this is shared between PDP and Car Filter we need to include it.
        tyCode: "",
        insuranceQuoteId: "",
        financeQuoteId: "",
    };
};

/**
 * Returns the correct CarFilter result label based on the total result count
 */
export const getResultsLabel = (resultCount: number): CarFilterLabelType => {
    let seeResultsLabelKey: CarFilterLabelType = "carFilterSeeResults";
    if (resultCount === 1) seeResultsLabelKey = "carFilterSeeResult";
    if (resultCount === 0) seeResultsLabelKey = "carFilterNoResults";
    return seeResultsLabelKey;
};
