/**
 * Wrapper for Globalize
 */
// @ts-ignore
import Globalize from "globalize";
import { CommonSettingsType } from "./settings/fetchCommonSettings";

// We don't import the actual "CACHE_MIN_AEM_SETTINGS" constant because it would
// include the cache file in the frontend build
const CACHE_MIN_AEM_SETTINGS = 1800;

/**
 * For more info on the formatting, see Globalize v0.1.1 docs:
 * https://github.com/globalizejs/globalize/tree/v0.1.1#number-formatting
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GlobalizeObjectType = any;

const globalizeContainer: {
    [name: string]: {
        primary: GlobalizeObjectType;
        secondary?: GlobalizeObjectType;
        currencyMultiplier?: number;
        timestamp: Date;
    };
} = {};

/**
 * Returns true when a secondary culture was added to the Globalize config and dual currency should be active
 */
export const hasSecondaryCulture = (cultureName: string): boolean => {
    return !!globalizeContainer[cultureName].secondary;
};

const getLocalGlobalize = (cultureName: string, secondary = false): GlobalizeObjectType => {
    if (secondary && hasSecondaryCulture(cultureName)) {
        return globalizeContainer[cultureName].secondary;
    }
    return globalizeContainer[cultureName].primary;
};

const getSecondaryPrice = (units: string | number, cultureName: string): number => {
    // Fallback to 0 when there is no currencyMultiplier
    return parseFloat(String(units)) * (globalizeContainer[cultureName].currencyMultiplier || 0);
};

/**
 * Used to format prices
 * @secondary True when the secondary price should be shown. This is possible when a country has dual currency
 */
export function formatPrice(
    units: string | number,
    cultureName: string,
    spaceToNbsp: boolean = false,
    secondary = false,
): string {
    const localGlobalize = getLocalGlobalize(cultureName, secondary);
    const price = secondary ? getSecondaryPrice(units, cultureName) : parseFloat(String(units));
    const formattedPrice = localGlobalize.format(price, "c");
    return spaceToNbsp ? formattedPrice.replace(" ", "&nbsp;") : formattedPrice;
}

export function formatDate(date: Date, format: string, cultureName: string): string {
    const localGlobalize = getLocalGlobalize(cultureName, false);
    return localGlobalize.format(date, format);
}

/**
 * Format a large number, eg: 100000 => 100.000
 */
export const formatNumber = (value: number, cultureName: string, formatType: string = "n0"): string => {
    const localGlobalize = getLocalGlobalize(cultureName, false);
    return localGlobalize.format(value, formatType);
};

export default (
    countryIsocode: string,
    culture: CommonSettingsType["culture"],
    currencyMultiplier?: number,
): { primary: GlobalizeObjectType; secondary?: GlobalizeObjectType; currencyMultiplier?: number } => {
    // TODO This implementation assumes GlobalizeMiddleware is ALWAYS called before anything else happens.
    /**
     * Check if the culture data exists in the globalizeContainer
     * globalizeContainer exists server-side & client-side
     * Server-side, the container is set ONCE on reboot/deploy
     * Expiration makes it so server-side culture settings expire and stay in SYNC (OR-7664)
     */
    const cultureDataExists = !!globalizeContainer[culture.name];
    const cultureDataTimestamp = cultureDataExists ? globalizeContainer[culture.name].timestamp.getTime() : 0;
    const isGlobalizeExpired = Date.now() - cultureDataTimestamp > CACHE_MIN_AEM_SETTINGS * 1000;

    if (!cultureDataExists || isGlobalizeExpired) {
        globalizeContainer[culture.name] = {
            primary: new Globalize(culture),
            timestamp: new Date(),
        };
        globalizeContainer[culture.name].primary.cultures[culture.name] = culture;

        // Add a secondary culture when dual currency is active
        if (currencyMultiplier && culture.numberFormat?.secondaryCurrency) {
            const secondaryCulture = {
                ...culture,
                numberFormat: { ...culture.numberFormat, currency: culture.numberFormat.secondaryCurrency },
            };

            globalizeContainer[culture.name].secondary = new Globalize(secondaryCulture);
            globalizeContainer[culture.name].currencyMultiplier = currencyMultiplier;
            globalizeContainer[culture.name].secondary.cultures[culture.name] = culture;
        }
    }
    return globalizeContainer[culture.name];
};
