import { createRoot, hydrateRoot } from "react-dom/client";
import { loadableReady } from "@loadable/component";
import { CommonSettingsType } from "../settings/fetchCommonSettings";
import { RenderMethod } from "../utils";

export const renderReact = (
    target: string,
    commonSettings: CommonSettingsType,
    app: JSX.Element,
    forceClientRender: boolean = false,
): void => {
    loadableReady(
        () => {
            if (commonSettings.renderMethod === RenderMethod.Client || forceClientRender) {
                const root = createRoot(document.getElementById(target)!);

                root.render(app);
            } else {
                hydrateRoot(document.getElementById(target)!, app);
            }
        },
        {
            namespace: target,
        },
    );
};
