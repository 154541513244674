import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";
import { BreakPointValuesType, getBreakpoint, getDirection } from "../../../../themes/common";
import { IconWrapper } from "./IconWrapper";
import { theme } from "../theme/toyotaTheme";
import * as Text from "../utilities/Text";

/**
 * Example modal
 * @example
 * <Modal.Wrapper isActive>
 *   <Modal.Dialog isActive hasFadeInAnimation isFullscreen size>
 *     <Modal.Content>
 *       <Modal.Header>
 *        <Modal.Title>Title</Modal.Title>
           <Modal.Button hasText lightBg={false}>
 *           <IconWrapper>
 *             <IconClose />
 *           </IconWrapper>
 *           <Text.Button>ButtonText</Text.Button>
 *         </Modal.Button>
 *       </Modal.Header>
 *       <Modal.Body>... children ...</Modal.Body>
 *     </Modal.Content>
 *   </Modal.Dialog>
 * </Modal.Wrapper>
 */

type ModalType = {
    isActive?: boolean;
    hasFadeInAnimation?: boolean /* The modal has a default slideIn animation (from bottom to top). If this prop is true then the modal will fade in. */;
    isFullscreen?: boolean;
    size?: BreakPointValuesType;
    isAuthor?: boolean;
};

export const Wrapper = styled.div.attrs<ModalType>((props) => ({
    className: ["material-box-panels", props.isActive ? "active" : ""].join(" "),
}))<ModalType>`
    z-index: ${theme.zIndex.materialbox + 5} !important;

    ${({ isAuthor }) =>
        isAuthor &&
        css`
            position: absolute;
        `}
`;

type DialogProps = ModalType & { fullWidth?: boolean; isAuthor?: boolean };

export const Dialog = styled.div.attrs<DialogProps>((props) => ({
    className: [
        "material-box",
        props.isActive ? "active" : "",
        props.isFullscreen ? "fullscreen" : "",
        props.hasFadeInAnimation ? "ready" : "",
    ].join(" "),
}))<DialogProps>`
    max-width: 100% !important;
    overflow: hidden !important;

    @keyframes show-modal {
        from {
            transform: translateY(100vh);
        }

        to {
            transform: translateY(0);
        }
    }

    @keyframes hide-modal {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(100vh);
        }
    }

    ${({ isActive }) =>
        css`
            animation: ${isActive ? "show-modal" : "hide-modal"} 0.5s ease-out;
        `}
    /*
     * The !important is necessary to override the default CSS styles.
     * If the viewport size < "lg", we fall back on the default CSS styles
     */
    @media ${getBreakpoint("up", "lg")} {
        ${({ size, fullWidth }) =>
            size === "sm" &&
            !fullWidth &&
            css`
                width: 100% !important;
                max-width: 500px !important;
            `};
        ${({ size, fullWidth }) =>
            size === "md" &&
            !fullWidth &&
            css`
                width: 100% !important;
                max-width: 768px !important;
            `};
        ${({ size, fullWidth }) =>
            size === "lg" &&
            !fullWidth &&
            css`
                width: 100% !important;
                max-width: 1140px !important;
            `};
        ${({ size, fullWidth }) =>
            size === "xl" &&
            !fullWidth &&
            css`
                width: 100% !important;
                max-width: 1440px !important;
            `};
    }

    ${({ isAuthor }) =>
        isAuthor &&
        css`
            align-self: flex-start !important;
            margin-top: 50px !important;
        `}
`;

export const Content = styled.div.attrs({
    className: "material-box-content" as string,
})``;

// Default Button, but is never used in the Marlon components
export const Close = styled.a.attrs<{ noBackground?: boolean }>(() => ({
    className: "btn btn-close visible-box",
}))<{ noBackground?: boolean }>`
    ${({ noBackground }) =>
        noBackground &&
        css`
            background-color: transparent !important;
        `};
    ${IconWrapper} {
        margin-${getDirection("right")}: ${theme.space[1]}px;
    }
`;

export const Button = styled.button<{ hasText?: boolean; lightBg?: boolean }>`
    display: flex;
    position: absolute;
    top: ${theme.space[2]}px;
    align-items: center;

    ${IconWrapper} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-${getDirection("right")}: ${({ hasText }) => (hasText ? `${theme.space[1]}px` : 0)};
        color: ${({ lightBg }) => (lightBg ? theme.colors.primaryBlack : theme.colors.primaryWhite)};
        background-color: ${({ lightBg }) => (lightBg ? theme.colors.primaryWhite : theme.colors.primaryBlack)};
        border-radius: 50%;
        opacity: 0.9;
    }

    ${Text.Button} {
        display: ${({ hasText }) => (hasText ? "inline-block" : "none")};
    }

`;

export const Title = styled.h2.attrs<{ isCentered?: boolean }>(() => ({
    className: "t-delta-text",
}))<{ isCentered?: boolean; smallText?: boolean }>`
    margin-bottom: 0 !important; /* Override default CSS styles */
    text-align: ${({ isCentered }) => (isCentered ? "center" : getDirection("left"))};

    ${({ smallText }) =>
        smallText &&
        css`
            font-family: ${theme.fonts.base};
            font-size: 1.6rem;
            line-height: 2.4rem;
        `};
`;

export const BUTTON_ALIGNMENT_TYPES = ["left", "right"] as const;
type headerTypes = {
    hasBackground?: boolean;
    buttonAlignment?: (typeof BUTTON_ALIGNMENT_TYPES)[number];
    hasBorder?: boolean;
};

export const Header = styled.div<headerTypes>`
    position: sticky;
    top: 0;
    padding: ${theme.space[3]}px ${theme.space[3]}px ${theme.space[4]}px;
    background-color: ${({ hasBackground }) => (hasBackground ? theme.colors.grey1 : theme.colors.primaryWhite)};
    z-index: 1;

    ${Button} {
        ${({ buttonAlignment }) =>
            buttonAlignment
                ? css`
                      ${getDirection(buttonAlignment)}: ${theme.space[2]}px;
                  `
                : css`
                      ${getDirection("right")}: ${theme.space[2]}px;
                  `};
    }

    ${Title} {
        ${({ buttonAlignment }) =>
            buttonAlignment &&
            buttonAlignment === "left" &&
            css`
                display: none;
            `};
    }

    ${({ hasBorder }) =>
        hasBorder &&
        css`
            border-bottom: 1px solid ${theme.colors.grey2};
        `};

    @media ${getBreakpoint("up", "lg")} {
        padding: ${theme.space[5]}px ${theme.space[5]}px ${theme.space[4]}px;
    }
`;

export const Body = styled.div<{ noSpacing?: boolean; darkVariant?: boolean }>`
    max-height: calc(100vh - 60px - 98px);
    padding: ${theme.space[3]}px;
    overflow-y: auto;

    @media ${getBreakpoint("up", "sm")} {
        max-height: calc(100vh - 120px - 98px);
    }

    @media ${getBreakpoint("up", "md")} {
        padding: ${theme.space[3]}px ${theme.space[3]}px ${theme.space[5]}px;
    }

    @media ${getBreakpoint("up", "lg")} {
        padding: ${theme.space[4]}px ${theme.space[5]}px ${theme.space[5]}px;
    }

    ${({ noSpacing }) =>
        noSpacing &&
        css`
            padding: 0;
            @media ${getBreakpoint("up", "md")} {
                padding: 0;
            }
            @media ${getBreakpoint("up", "lg")} {
                padding: 0;
            }
        `};

    ${({ darkVariant }) =>
        darkVariant &&
        css`
            background-color: ${theme.colors.grey1};

            @media ${getBreakpoint("up", "md")} {
                padding: ${theme.space[3]}px;
            }

            @media ${getBreakpoint("up", "lg")} {
                padding: ${theme.space[5]}px;
            }
        `};
`;

export const Actions = styled.div<{ rightAlignment?: boolean; hasBorderTop?: boolean } & SpaceProps>`
    display: flex;
    padding: ${theme.space[3]}px;
    ${space};

    ${({ rightAlignment }) =>
        rightAlignment &&
        css`
            justify-content: flex-end;
            gap: ${theme.space[3]}px;
        `};

    ${({ hasBorderTop }) =>
        hasBorderTop &&
        css`
            border-top: 1px solid ${theme.colors.grey2};
        `};
`;
