/**
 * Common theming functionality.
 */

// ----------------------------------------------------------------------
// Base types
// ----------------------------------------------------------------------
import { useContext, useState, useEffect } from "react";
import { DefaultTheme, ThemeContext } from "styled-components";
import { ColorProps, ResponsiveValue } from "styled-system";
import { useWindowDimensions } from "../hooks";

export const BREAK_POINT_VALUES = ["xs", "sm", "md", "lg", "xl", "xxl"];
export type BreakPointValuesType = (typeof BREAK_POINT_VALUES)[number];
export type BreakPointDirectionType = "up" | "down" | "only";
export type BreakPointType = { [bp in BreakPointValuesType]: string };
export type ColorType =
    | "primary"
    | "primaryHover"
    | "lightest"
    | "dark"
    | "grey1"
    | "grey2"
    | "grey3"
    | "grey4"
    | "grey5"
    | "grey6"
    | "grey7"
    | "grey8"
    | "transparentGrey1"
    | "transparentGrey2"
    | "transparentGrey3"
    | "success"
    | "info"
    | "iconNeutral"
    | "hybrid"
    | "fuelCell"
    | "orange"
    | "teal"
    | "deepPurple"
    | "cyan"
    | "border";

export type FontFamiliesType = {
    light: string;
    book: string;
    regular: string;
    bold: string;
    displayRegular: string;
    displayBold: string;
};

// Something is going wrong with styled systems typing, so we manually defined color
export type ThemeTypedColorProps = ColorProps & {
    color?: ResponsiveValue<ColorType>;
    bg?: ResponsiveValue<ColorType>;
    backgroundColor?: ResponsiveValue<ColorType>;
};

// Context-specific layout options propagated when embedding the component.
export type LayoutOptionsType = {
    noContainerSpacing: boolean; // This is used to remove outside container spacing to allow easier alignment to external grids when embedding the component.
};

// The other properties were put into a theme const instead, see common/styles/v2/<brand>/theme
export type AEMThemeType = LayoutOptionsType & {
    isRTL: boolean;
    breakpoints: BreakPointType;
    space: number[];
};

export type TridionThemeType = LayoutOptionsType & {
    color: { [color in ColorType]: string };
    colors: { [color in ColorType]: string }; // Match styled-system colors implementation.
    fontFamily: FontFamiliesType;
    fonts: FontFamiliesType; // Match styled-system fonts implementation.
    textColor: { muted: string; button: string };
    spacer: number;
    space: number[];
    breakpoints: BreakPointType;
    isLexus: boolean;
    isRTL: boolean;
    zIndex: {
        popup: number;
        fixed: number;
        sticky: number;
        overlay: number;
        modalBackdrop: number;
        modal: number;
        galleryNavHorizontal: number;
        flipbook: number;
        materialCloseButton: number;
        compareScrollContainer: number;
        compareMiniEditMenuSelection: number;
        loaderLazy: number;
        pageoverlayerInpage: number;
        miniCcDropdownMenu: number;
        navBar: number;
        expansionContent: number;
        ctabar: number;
        navSeconary: number;
        navPrimary: number;
        navbarMyToyota: number;
        pageoverlayerExpansion: number;
        mobileBasketFooter: number;
        materialbox: number;
        bubble: number;
        loaderFull: number;
        autocomplete: number;
        eprivacyDisclaimer: number;
    };

    // component specific properties (legacy)
    compare: {
        header: {
            height: string;
            background: string;
            borderRadius: string;
            color: string;
            borderRadiusExpanded: string;
        };
        bar: {
            titleFontFamily: string;
            titleFontSize: string;
            titleLineHeight: string;
            titleColor: string;
            mainTitleColor: string;
            subTitleColor: string;
            dividerColor: string;
        };
        entry: {
            titleFontFamily: string;
            titleFontSize: string;
            titleColor: string;
            titleLineHeight: string;
            valueFontFamily: string;
            valueColor: string;
            valueFontSize: string;
            valueSuffixColor: string;
            valueSuffixFontSize: string;
        };
        section: {
            titleFontFamily: string;
            titleFontSize: string;
            titleColor: string;
            titleTextTransform: string;
        };
        specs: {
            labelFontFamily: string;
            labelFontSize: string;
            descriptionFontSize: string;
            descriptionFontColour: string;
            descriptionFontStyle: string;
            labelLineHeight: string;
            labelColor: string;
            labelMargin: string;
            valueFontFamily: string;
            valueFontSize: string;
            valueColor: string;
            valueFontLight: string;
        };
        swatches: {
            borderRadius: string;
        };
    };
    gradeListItemWidth: number;
};

// ----------------------------------------------------------------------
// Helper functions
// ----------------------------------------------------------------------

// Get the next breakpoint based on the provided breakpoint value.
export const breakpointNext = (breakpoints: BreakPointType, value: BreakPointValuesType): BreakPointValuesType => {
    const keys = Object.keys(breakpoints) as BreakPointValuesType[];
    const index = keys.indexOf(value);
    return index < keys.length ? keys[index + 1] : keys[0];
};

// Helper function to get a breakpoint string.
const getBreakPointString = (
    breakpoints: BreakPointType,
    type: BreakPointDirectionType,
    breakpointValue: BreakPointValuesType,
): string => {
    switch (type) {
        case "up":
            return `screen and (min-width: ${breakpoints[breakpointValue]})`;

        case "down":
            return `screen and (max-width: ${breakpoints[breakpointValue]})`;

        case "only": {
            const nextBreakpoint = breakpointNext(breakpoints, breakpointValue);
            return `screen and (min-width: ${breakpoints[breakpointValue]}) and (max-width: ${breakpoints[nextBreakpoint]})`;
        }
        default:
            throw new Error("Invalid type");
    }
};

export const getNumberFromPx = (breakpoint: string): number => Number(breakpoint.replace("px", ""));

export const getBreakpoint = (type: BreakPointDirectionType, breakpointValue: BreakPointValuesType) => {
    return ({ theme }: { theme: DefaultTheme }): string =>
        getBreakPointString(theme.breakpoints, type, breakpointValue);
};

export const getDirection = (direction: "left" | "right") => {
    return ({ theme }: { theme: DefaultTheme }): "left" | "right" => {
        if (direction === "left") return theme.isRTL ? "right" : "left";
        else return theme.isRTL ? "left" : "right";
    };
};

/**
 * returns true when corresponding css breakpoint is active, analogue to getBreakPointString in common/themes/common
 * WARNING: Please refrain from using this hook, it will be phased out later in favor of splitting mobile and desktop implementations of a component
 */
export const useBreakpoint = (type: BreakPointDirectionType, breakpoint: BreakPointValuesType): boolean => {
    const allBreakpoints = useContext(ThemeContext)?.breakpoints;
    const windowWidth = useWindowDimensions().width || parseInt(allBreakpoints?.lg);
    const [isActive, setActive] = useState(false);

    const getBreakPointValue = (name: BreakPointValuesType): number => {
        return getNumberFromPx(allBreakpoints[name]);
    };

    useEffect((): void => {
        const breakpointValue = getBreakPointValue(breakpoint);
        if (type === "up") setActive(windowWidth >= breakpointValue);
        else if (type === "down") setActive(windowWidth <= breakpointValue);
        else if (type === "only") {
            const nextBreakpoint = getBreakPointValue(breakpointNext(allBreakpoints, breakpoint));
            setActive(windowWidth > breakpointValue && windowWidth <= nextBreakpoint);
        }
    }, [windowWidth]);

    return isActive;
};

export const useTheme = (): DefaultTheme => useContext(ThemeContext);

/**
 * Simple helper to use in the .attrs() from styled-components to easily add a className to a component.
 * If you need anything more (additional styled-component props) use a custom attrs function.
 */
export const addClassName = (className: string): (() => { className: string }) => {
    return () => ({ className });
};
