import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import ModalBaseView from "../../../../../../common-deprecated/components/modals/aem/ModalBaseView";
import * as Flex from "../../../../../../common-deprecated/styles/toyota/Flexbox";
import Preloader from "../../../../../../common-deprecated/styles/toyota/Preloader";
import * as Modal from "../../../../../../common-deprecated/styles/v2/toyota/globals/Modal";
import * as IntegratedFormStyles from "./styles/IntegratedFormStyles";
import { IconWrapper } from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { ModalViewType } from "../../../../../../common-deprecated/types/CommonTypes";
import { ContactDealerType } from "../../../../../shared-logic/utils/integrated-modal/integratedModalConstants";
import { UscContext as uscContextType } from "../../../../../shared-logic/types/UscCommonTypes";
import Icon from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";

// Async loading of UscForm.
const IntegratedUscForm = loadable(async () => {
    const component = await import(
        /* webpackChunkName: "integrated-usc-form-toyota" */
        "../../../../../used-stock-cars-form/v1/components/toyota/IntegratedUscForm"
    );
    return component.default;
});

// This modal is not actually used in UscForm itself, but is used to embed the UscForm modal into Compare/USC component.
const IntegratedUscFormModal = (props: ModalViewType<ContactDealerType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { vehicleForSaleId, externalData, vehicle, dealer, uscContext, modalTitle } = modalSettings;

    const [showDealerForm, setShowDealerForm] = useState<boolean>(false);
    const [dealerFormInitializing, setDealerFormInitializing] = useState<boolean>(true);

    // Show the dealer form on first render
    useEffect(() => {
        setShowDealerForm(true);
    }, []);

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="lg">
            <Modal.Header>
                <Modal.Title isCentered>{modalTitle}</Modal.Title>
                <Modal.Button onClick={close}>
                    <IconWrapper>
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                {showDealerForm && (
                    <IntegratedFormStyles.Wrapper>
                        {dealerFormInitializing && (
                            <Flex.Col
                                display="flex"
                                width="100%"
                                height={400}
                                justifyContent="center"
                                px={0}
                                position="relative"
                            >
                                <Preloader />
                            </Flex.Col>
                        )}
                        <IntegratedUscForm
                            onInitFinish={() => setDealerFormInitializing(false)}
                            vehicleForSaleId={vehicleForSaleId}
                            context={uscContext as uscContextType}
                            externalData={externalData}
                            vehicle={vehicle}
                            dealer={dealer}
                        />
                    </IntegratedFormStyles.Wrapper>
                )}
            </Modal.Body>
        </ModalBaseView>
    );
};

export default IntegratedUscFormModal;
